import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  .main {
    background: ${Colors.endeavour};
    width: 100%;
    height: 36px;
    display: flex;

    a {
      color: ${Colors.white};
      text-decoration: none;
    }

    .auth {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-left: auto;

      a {
        color: ${Colors.white};
        margin-left: 20px;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .main-max-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: -webkit-fill-available;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;

    .title-logo {
      color: ${Colors.white};
      cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${Colors.white};
    height: 64px;
    width: 100%;
  }

  .auth img {
    margin: 0px 5px;
  }

  .auth .user-circle {
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Colors.white};
      margin-left: 5px;
      color: ${Colors.orient};
      border-radius: 12px;
      width: 25px;
      height: 25px;
    }

    .user-credentials {
      cursor: pointer;
    }

    .wrapper-menu {
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 37%;
      transform: translate(-37%, 0);
      top: 40px;
      width: max-content;
      background: ${Colors.white};
      z-index: 1;
      min-width: 170px;

      div,
      button {
        padding: 10px;
        font-weight: normal;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        color: ${Colors.emperor};
      }

      button {
        background: transparent;
        border: none;
        width: 100%;
        text-align: left;
      }

      .view-profile {
        font-size: 14px;
        line-height: 20px;
        padding-left: 2px;
        padding-bottom: 0;
        color: ${Colors.gray};
        font-weight: normal;
      }

      div:hover,
      button:hover {
        background: ${Colors.alabaster};
      }
    }

    .wrapper-menu:after {
      bottom: 100%;
      left: 40%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .wrapper-menu:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: ${Colors.alabaster};
      border-width: 10px;
      margin-left: -10px;
    }
  }

  img {
    cursor: pointer;
  }

  .find-patient {
    position: relative;

    .wrapper-tooltip-patients {
      position: absolute;
      background: ${Colors.white};
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      width: max-content;
      top: 70px;
      left: 60%;
      transform: translate(-60%, 0);

      .element {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        cursor: pointer;
        min-width: 315px;

        .name {
          margin-right: 50px;
        }
      }

      .element:hover {
        background: #768692;
      }
      .element:hover div {
        color: ${Colors.white};
      }
      .divider {
        border-top: 2px solid ${Colors.alabaster};
        margin: 0 20px;
      }
      .view-patients {
        div {
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 19px;
          color: ${Colors.orient};
          cursor: pointer;
          padding: 10px;
          min-width: 315px;
        }
      }
    }

    .wrapper-tooltip-patients:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: ${Colors.white};
      border-width: 10px;
      margin-left: -10px;
    }
  }

  .wrapper-search-input {
    position: relative;

    .search {
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }

  .wraper-notify {
    position: relative;
  }

  .wrapper-icon-notify {
    position: relative;
    cursor: pointer;

    .notification-number {
      position: absolute;
      top: -5px;
      right: 0px;
      background: ${Colors.coralRed};
      font-size: 12px;
      line-height: 15px;
      color: ${Colors.white};
      font-weight: 500;
      height: 15px;
      border-radius: 10px;
      text-align: center;
      width: auto;
      min-width: 15px;
    }

    .icon-click-notify {
      margin-right: 10px;
    }
  }

  .wrapper-notifications-modal {
    position: absolute;
    background: ${Colors.white};
    width: 320px;
    z-index: 1;
    left: -147px;
    top: 37px;
    padding: 20px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);

    hr {
      color: ${Colors.alabaster};
      opacity: 1;
    }

    .element {
      display: flex;
      margin-bottom: 18px;
      cursor: pointer;
    }

    .view-notifications {
      div {
        display: flex;
        justify-content: left;
        font-size: 16px;
        line-height: 19px;
        color: ${Colors.orient};
        cursor: pointer;
        padding-left: 8px;
      }
      div:hover {
        color: ${Colors.endeavour};
      }
    }
  }

  .wrapper-notifications-modal:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .wrapper-notifications-modal:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${Colors.white};
    border-width: 10px;
    margin-left: -10px;
  }
`;
