import React from "react";
import PropTypes from "prop-types";

import { typeOfPAF } from "../../../constants/typeOfPAF";

const PatientCategory = ({ registerPatient, selectTypeOfPatient }) => {
  return (
    <React.Fragment>
      <React.Fragment>
        <h1 className="select-category-txt">
          Please select the patient prescription authorisation form
        </h1>

        <hr className="gray-hr" />

        <div className="m-top-3 m-bot-3 min-height">
          <button
            className={
              "full " +
              (registerPatient?.type === typeOfPAF.MALE
                ? "green-light-button"
                : "second-button patient-category-button")
            }
            onClick={() => selectTypeOfPatient(typeOfPAF.MALE)}
          >
            Male
          </button>
          <button
            className={
              "full " +
              (registerPatient?.type === typeOfPAF.WOMANOFCP
                ? "green-light-button"
                : "second-button patient-category-button")
            }
            onClick={() => selectTypeOfPatient(typeOfPAF.WOMANOFCP)}
          >
            Woman of Childbearing Potential
          </button>
          <button
            className={
              "full " +
              (registerPatient?.type === typeOfPAF.WOMANOFNCP
                ? "green-light-button"
                : "second-button patient-category-button")
            }
            onClick={() => selectTypeOfPatient(typeOfPAF.WOMANOFNCP)}
          >
            Woman of Non-Childbearing Potential
          </button>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

PatientCategory.propTypes = {
  registerPatient: PropTypes.object,
  selectTypeOfPatient: PropTypes.func,
};

export default PatientCategory;
