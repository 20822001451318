import moment from "moment";
import { useEffect, useState } from "react";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";
import { handleCatch } from "../../../shared/helpers";
import BrochureLink from "../Brochure/BrochureLink";
import MahButton from "./MahButton";
import MahLogo from "./MahLogo";

function MahSelection({
  receivedForm,
  openFinishModal,
  onPrevious,
  setFinishModalContent,
  dispensePAF,
  pafID,
  setModalNonCompliantPAFWarning,
  product_id,
  setIsOperationInProgress,
  isOperationInProgress,
  hcp,
}) {
  const [mahs, setMahs] = useState([]);
  const [validateScreenFields, setValidateScreenFields] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [form, setForm] = useState({
    prescription_id: pafID,
    marketing_holder_id: null,
    confirmation_risk_1: false,
    confirmation_risk_2: false,
    indication_offLabel: null,
  });

  const [indication_offlabel, setIndication_offlabel] = useState(null);
  const [modalDisplay, setModalDisplay] = useState(null);

  const findMarketingHolder = mahs.find(
    (element) => element.id === form.marketing_holder_id
  );
  const checkofflabel =
    !!findMarketingHolder &&
    findMarketingHolder?.indication_ids?.includes(receivedForm.indication_id);

  useEffect(() => {
    setIndication_offlabel(checkofflabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkofflabel]);

  useEffect(() => {
    getMAHs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const clickComplete = () => {
    const areAllFields = Object.values(validateScreenFields).every((field) => {
      return field.isValid;
    });

    if (
      areAllFields &&
      indication_offlabel === false &&
      modalDisplay === false
    ) {
      setModalDisplay(true);
      setModalNonCompliantPAFWarning((prevState) => ({
        ...prevState,
        svg: true,
        isOpen: true,
        title: "Continue with off-license use",
        description:
          "You have selected an indication that is not officially approved for use for this Marketing Authorisation Holder. Are you sure you want to proceed?",
      }));
    } else if (areAllFields) {
      setIsOperationInProgress(true);

      setFinishModalContent({
        title: "Prescription Authorisation Form Completed",
        content: "The PAF has been completed and can be dispensed.",

        onFinish: () => {
          // Prevent further calls if the operation is in progress
          if (isOperationInProgress) {
            return;
          }
          // Set the operation in progress
          setIsOperationInProgress(true);
          dispensePAF(form)
            .then(() => {
              // After the operation is completed, set the flag back to false
              setIsOperationInProgress(false);
            })
            .catch((error) => {
              console.error("Error:", error);
              setIsOperationInProgress(false);
            });
        },
      });
      openFinishModal();
    } else {
      setShowErrors(true);
    }
  };

  const validateForm = () => {
    const { marketing_holder_id, confirmation_risk_1, confirmation_risk_2 } =
      form;

    setValidateScreenFields({
      marketing_holder_id: {
        isValid: !!marketing_holder_id,
      },
      confirmation_risk_1: {
        isValid: confirmation_risk_1 === true,
      },
      confirmation_risk_2: {
        isValid: confirmation_risk_2 === true,
      },
    });
  };

  const getMAHs = async () => {
    await Axios.get(APIVariables.MAHS + "/" + product_id)
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setMahs(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };
  const handleChange = (e) => {
    if (e.target?.checked !== undefined) {
      const { checked, name } = e.target;
      setForm((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setForm((prevState) => ({ ...prevState, marketing_holder_id: e }));
    }
  };

  return (
    <div className="layout-modal nominate-dispenser">
      <div className="nominate-dispenser-container">
        <div className="step font-size-18">Step 3/3</div>
        <div className="title font-size-18 mt-5">MAH Selection*:</div>
        <div className="subtitle doveGray-color">
          Please select the designated MAH in which you are dispensing
          medication from:
        </div>
        <div className="row">
          {showErrors && !validateScreenFields.marketing_holder_id?.isValid && (
            <span className="required-text unset-bottom-left">Required</span>
          )}
          <div className="buttons_container">
            {mahs.map((mah, index) => {
              return !!mah.logo ? (
                <MahLogo
                  src={mah.logo}
                  alt={mah.name}
                  key={`mah_logo_${index}`}
                  selected={mah.id === form.marketing_holder_id}
                  onClick={() => {
                    handleChange(mah.id);
                    setModalDisplay(false);
                  }}
                />
              ) : (
                <MahButton
                  text={mah.name}
                  key={`mah_button_${mah.name}_${index}`}
                  onClick={() => {
                    handleChange(mah.id);
                    setModalDisplay(false);
                  }}
                  selected={mah.id === form.marketing_holder_id}
                />
              );
            })}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <label
              htmlFor="confirmation_risk_1"
              className={
                "custom-checkbox font-size-16 mt-4 mb-4 " +
                (showErrors &&
                !validateScreenFields?.confirmation_risk_1?.isValid
                  ? "red-label"
                  : "")
              }
            >
              I confirm that I am ordering on behalf of a registered pharmacy
              and that {hcp.name} will be dispensed in accordance with the risk
              minimisation procedures for {hcp.name}, as specified by
              <b
                className={
                  showErrors &&
                  !validateScreenFields?.confirmation_risk_1?.isValid
                    ? "red-label"
                    : ""
                }
              >
                {" " +
                  (mahs?.find((mah) => mah.id === form.marketing_holder_id)
                    ?.full_name || "")}{" "}
              </b>
              in the{" "}
              <BrochureLink
                className={
                  showErrors &&
                  !validateScreenFields?.confirmation_risk_1?.isValid
                    ? "red-label"
                    : ""
                }
                hcp={hcp}
              />
              .
              <input
                type="checkbox"
                id="confirmation_risk_1"
                name="confirmation_risk_1"
                value={form.confirmation_risk_1}
                onChange={handleChange}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <label
              htmlFor="confirmation_risk_2"
              className={
                "custom-checkbox font-size-16 mt-4 mb-4 " +
                (showErrors &&
                !validateScreenFields?.confirmation_risk_2?.isValid
                  ? "red-label"
                  : "")
              }
            >
              I confirm that treatment lengths will be limited to a maximum of 4
              weeks supply for women of childbearing potential and a maximum of
              12 weeks for males and women of non-childbearing potential
              patients. For women of child bearing potential dispensing will be
              within 7 days of the date of prescription.
              <input
                type="checkbox"
                id="confirmation_risk_2"
                name="confirmation_risk_2"
                value={form.confirmation_risk_2}
                onChange={handleChange}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="info-container">
          <div className="pharma-name" style={{ width: "25%" }}>
            <label>Pharmacist name:</label>
            <input
              type="text"
              value={receivedForm?.approved_or_rejected_by}
              disabled
            />
          </div>
          <div className="date-container" style={{ width: "25%" }}>
            <label>Date:</label>
            <div className="date">{moment().format("DD / MM / YYYY")}</div>
          </div>
        </div>
        <div className="wrapper-buttons-select-type">
          <button className="prev" onClick={() => onPrevious()}>
            Previous
          </button>
          <div className="flex-between-center">
            <div className="symbol-idication orient">
              *symbol indicating a mandatory field
            </div>
            <button
              className="primary-button custom-login-button"
              onClick={() => clickComplete()}
            >
              Complete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MahSelection;
