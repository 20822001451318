import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import StyledRegister from "./StyledRegister";

import CloseIcon from "../../assets/icons/close.svg";
import { ReactComponent as CheckRegister } from "../../assets/icons/checkLime.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning-red.svg";
import { ReactComponent as Error } from "../../assets/icons/error.svg";
import RegisterBlur from "../../assets/images/register_blur.png";

import {
  formatPhoneNumber,
  handleCatch,
  validateEmail,
  validatePassword,
} from "../../shared/helpers";

import { Auth } from "../../routes/Constants";
import QuitModal from "../common/Modal/QuitModal";
import APIVariables from "../../services/api-variables";
import RegisterForm from "./RegisterForm";
import Loader from "../common/Loader/newLoader";
import InformationGuideRead from "./informationGuideRead";

//constants
import { registrationCategory } from "../../constants/register.constants";
import { errorMessages } from "../../constants/errorMessages.constants";
import Axios from "../../services/Axios";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const Register = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const [stateRegister, setStateRegister] = useState(1);
  const [registerForm, setRegisterForm] = useState({});
  const [wasLinkClicked, setWasLinkClicked] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [registerModalIsOpen, setIsOpenRegister] = useState(false);
  const [downloadedBrochures, setDownloadedBrochures] = useState([]);

  const [quitModalIsOpenError, setIsOpenQuitError] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const user_type =
    registerForm.type === registrationCategory.PRESCRIBER_PRESCRIBER
      ? 1
      : registerForm.type === registrationCategory.PHARMACY && 2;

  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      boxShadow: "0px 0px 40px rgb(0 0 0 / 20%)",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  const [quitModalIsOpen, setIsOpenQuit] = useState(false);

  const [modalMessage, setErrorModalMessage] = useState({
    title: "",
    description: "",
  });

  const [isInformationGuideRead, setInformationGuideRead] = useState(false);
  const [confirmInformationGuideRead, setConfirmInformationGuideRead] =
    useState(false);
  const [openGuideRead, setOpenGuideRead] = useState(false);

  function closeRegisterForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function closeModalQuitError() {
    setIsOpenQuitError(false);
    setErrorModalMessage({
      title: "",
      description: "",
    });
  }

  function logIn() {
    navigate(Auth.LOGIN);
  }

  const selectTypeOfCategory = (type) => {
    if (registerForm.type) {
      if (type !== registerForm.type) {
        setRegisterForm({ type });
      }
    } else {
      setRegisterForm((prevState) => ({
        ...prevState,
        type,
      }));
    }
  };

  async function nextPage() {
    const { type } = registerForm;

    if (stateRegister === 1) {
      if (Object.keys(registerForm).length === 0) {
        return;
      } else {
        setIsSubmitting(false);
        setStateRegister(stateRegister + 1);
      }
    }

    if (
      stateRegister === 2 &&
      (type.includes(registrationCategory.PRESCRIBER) ||
        type === registrationCategory.PHARMACY)
    ) {
      setIsSubmitting(true);
      setIsLoading(true);
      if (areAllFieldsValidScreen2) {
        const response = await submitForm();
        if (!!response) {
          setIsSubmitting(false);
          setIsLoading(false);
          const invalidEmail = (response || []).find((element) =>
            element.includes("email")
          );

          const invalidRegistrationNumber = (response || []).find((element) =>
            element.includes("registration_number")
          );

          const invalidPhoneNumber = (response || []).find((element) =>
            element.includes("phone_number")
          );

          const invalidPassword = (response || []).find((element) =>
            element.includes("password")
          );

          if (
            invalidEmail === undefined &&
            invalidRegistrationNumber === undefined &&
            invalidPhoneNumber === undefined &&
            invalidPassword === undefined
          ) {
            setStateRegister(stateRegister + 1);
            setIsSubmitting(false);
          }
        }
      } else {
        setIsLoading(false);
      }
    }
    if (stateRegister === 3 && type.includes(registrationCategory.PRESCRIBER)) {
      if (!areAllFieldsValidScreen3) {
        if (type === registrationCategory.PRESCRIBER_PRESCRIBER) {
          setErrorModalMessage({
            title: errorMessages.PRESCRIBER_NOT_AGREED_REQUIRMENTS,
            description:
              errorMessages.PRESCRIBER_NOT_AGREED_REQUIRMENTS_DESCRIPTION,
          });
        }
        setIsSubmitting(false);
        setIsOpenQuitError(true);
      } else {
        if (brochureIdAndName.length !== 0 && wasLinkClicked === false) {
          setErrorModalMessage({
            title: errorMessages.MUST_CLICK_LINK_TITLE,
            description: (
              <div>
                <p>Please go back and read the following brochures:</p>
                <ul>
                  {brochureIdAndName.map((element) => (
                    <p key={element.id}>&#8226; {element.brochure_name}</p>
                  ))}
                </ul>
                <p>
                  Once read, click Register and acknowledge your reading of the
                  information guide.
                </p>
              </div>
            ),
          });
          setIsSubmitting(false);
          setIsOpenQuitError(true);
        } else if (!isInformationGuideRead) {
          setOpenGuideRead(true);
        } else {
          await submitForm();
        }
      }
    }

    if (stateRegister === 1) {
      setRegisterForm({ ...registerForm, country: "United Kingdom" });
    }

    if (stateRegister === 3 && type === registrationCategory.PHARMACY) {
      if (!areAllFieldsValidScreen3) {
        setIsSubmitting(false);
        setIsOpenQuitError(true);
        setErrorModalMessage({
          title: errorMessages.PHARMACY_NOT_AGREED_REQUIRMENTS,
          description:
            errorMessages.PHARMACY_NOT_AGREED_REQUIRMENTS_DESCRIPTION,
        });
      } else {
        if (brochureIdAndName.length !== 0 && wasLinkClicked === false) {
          setErrorModalMessage({
            title: errorMessages.MUST_CLICK_LINK_TITLE,
            description: (
              <div>
                <p>Please go back and read the following brochures:</p>
                <ul>
                  {brochureIdAndName.map((element) => (
                    <p key={element.id}>&#8226; {element.brochure_name}</p>
                  ))}
                </ul>
                <p>
                  Once read, click Register and acknowledge your reading of the
                  information guide.
                </p>
              </div>
            ),
          });
          setIsSubmitting(false);
          setIsOpenQuitError(true);
        } else if (!isInformationGuideRead) {
          setOpenGuideRead(true);
        } else {
          await submitForm();
        }
      }
    }
  }
  const registerAPI = (isFormReadyToBeSubmitted, type) => {
    if (type.includes(registrationCategory.PRESCRIBER)) {
      return isFormReadyToBeSubmitted
        ? APIVariables.USERS_SIGNUP_PRESCRIBER
        : APIVariables.USERS_SIGNUP_VALIDATE_PRESCRIBER;
    } else {
      return isFormReadyToBeSubmitted
        ? APIVariables.USERS_SIGNUP_PHARMACY
        : APIVariables.USERS_SIGNUP_VALIDATE_PHARMACY;
    }
  };

  const [productConfirmations, setProductConfirmations] = useState([]);
  // Get confirmations product from BE
  const getProductConfirmations = async () => {
    try {
      const promises = selectedProducts.map((product) =>
        Axios.get(APIVariables.SEND_ID_OF_PRODUCTS + `/${user_type}/${product}`)
      );

      const responses = await Promise.all(promises);
      // Extract the data from responses and set in state
      const data = responses.map((res) => res.data);
      setProductConfirmations(data);
    } catch (error) {
      handleCatch(error);
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    return new Promise(async (response) => {
      setErrorMessage("");

      let copyOfRegisterForm = JSON.parse(JSON.stringify(registerForm));
      if (stateRegister !== 3) {
        delete copyOfRegisterForm.confirmation_auth;
      }
      let { type } = copyOfRegisterForm;

      if (type.includes(registrationCategory.PRESCRIBER)) {
        copyOfRegisterForm = {
          ...copyOfRegisterForm,
          prescriber_type: 1,
        };
        delete copyOfRegisterForm.type;
        copyOfRegisterForm.hospital_id =
          copyOfRegisterForm.hospital_id.valueForBE;
      } else {
        copyOfRegisterForm.institution_type =
          copyOfRegisterForm.institution_type.value;
        copyOfRegisterForm.institution_id =
          copyOfRegisterForm.institution_id.valueForBE;
      }
      if (type !== registrationCategory.PRESCRIBER_PRESCRIBER) {
        copyOfRegisterForm.phone_number = copyOfRegisterForm.phone_number
          .replace("+", "")
          .replace(/ /g, "");
      }
      copyOfRegisterForm.brochure_downloads = downloadedBrochures;
      const isFormReadyToBeSubmitted = stateRegister === 3;

      const res = await Axios.post(
        registerAPI(isFormReadyToBeSubmitted, type),
        copyOfRegisterForm
      ).catch(function (error) {
        setIsSubmitting(false);
        setIsLoading(false);

        handleCatch(error);

        const errors = error.response.data;
        const errorsArray = [];

        if (typeof errors === "string") {
          errorsArray.push(errorMessages.INTERNAL_SERVER_ERROR);
        } else {
          Object.keys(errors).forEach((key) => {
            errorsArray.push(key + " - " + errors[key]);
          });
        }
        setErrorMessage(errorsArray);
        return response(errorsArray);
      });

      getProductConfirmations();

      if (res?.status === 200) {
        setIsSubmitting(false);
        setIsLoading(false);
        if (isFormReadyToBeSubmitted) {
          setIsOpenRegister(true);
        } else {
          setStateRegister(stateRegister + 1);
          window.scrollTo(0, 0);
        }
      }
    });
  };

  const previous = () => {
    if (stateRegister === 1) {
      return;
    }

    if (stateRegister === 3) {
      setCheckboxeslMedications([]);
      setWasLinkClicked(false);
      setStateRegister(stateRegister - 1);
    }
    setStateRegister(stateRegister - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let filterValue = value;

    if (
      name === "hospital_id" ||
      name === "institution_type" ||
      name === "institution_id"
    ) {
      setRegisterForm((prevState) => ({
        ...prevState,
        [name]: parseInt(filterValue),
      }));
    } else if (name === "phone_number")
      filterValue = formatPhoneNumber(filterValue);

    if (
      name === "email" &&
      (errorMessage || []).find((element) => element.includes("email"))
    ) {
      setErrorMessage(
        (errorMessage || []).filter((element) => !element.includes("email"))
      );
    }

    if (
      name === "registration_number" &&
      (errorMessage || []).find((element) => element.includes("Registration"))
    ) {
      setErrorMessage(
        (errorMessage || []).filter(
          (element) => !element.includes("Registration")
        )
      );
    }

    setRegisterForm((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  // Checked what product is selected from Medication section
  const handleCheckboxClick = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  useEffect(() => {
    setRegisterForm((registerForm) => ({
      ...registerForm,
      products: selectedProducts,
    }));
  }, [selectedProducts]);

  const [checkboxesMedications, setCheckboxeslMedications] = useState([]);

  const handleCheckboxes = (key) => {
    if (checkboxesMedications.includes(key)) {
      setCheckboxeslMedications(
        checkboxesMedications.filter((id) => id !== key)
      );
    } else {
      setCheckboxeslMedications([...checkboxesMedications, key]);
    }
  };
  // The checkboxesMedications includes some id (index) and I split them
  const modifiedList = checkboxesMedications.map((item) =>
    parseInt(item.split("-")[1])
  );

  useEffect(() => {
    setRegisterForm((registerForm) => ({
      ...registerForm,
      confirmations: modifiedList,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxesMedications]);

  const handleChangeWholeSaler = (event, saler) => {
    const { name, value } = event.target;

    const checkIfItemExists = !!registerForm.wholesalers_data.find(
      (element) => element.id === +event.target.name
    );
    if (checkIfItemExists) {
      const updateWholeSaler = registerForm.wholesalers_data.map((element) => {
        if (element.id === +name) {
          return { ...element, account_number: value };
        } else {
          return element;
        }
      });

      setRegisterForm((prevState) => ({
        ...prevState,
        wholesalers_data: updateWholeSaler,
      }));
    } else {
      const copyWholeSalers = [
        ...registerForm.wholesalers_data,
        { ...saler, account_number: value },
      ];

      setRegisterForm((prevState) => ({
        ...prevState,
        wholesalers_data: copyWholeSalers,
      }));
    }
  };

  const handleChangeSelect = (selectedElement, name) => {
    if (name === "institution_type") {
      const newRegisterFormValues = {
        ...registerForm,
        [name]: selectedElement,
        institution_id: "",
        delivery_address: "",
        ordering_address: "",
      };

      delete newRegisterFormValues.wholesalers_data;

      setRegisterForm(newRegisterFormValues);
    } else if (name === "institution_id") {
      setRegisterForm((prevState) => ({
        ...prevState,
        [name]: selectedElement,
        delivery_address: !!selectedElement.delivery_address
          ? selectedElement.delivery_address
          : "",
        ordering_address: !!selectedElement.ordering_address
          ? selectedElement.ordering_address
          : "",
        wholesalers_data: selectedElement.wholesalers_data,
      }));
    } else {
      setRegisterForm((prevState) => ({
        ...prevState,
        [name]: selectedElement,
      }));
    }
  };

  const disableNextButton = () => {
    if (stateRegister === 1) {
      return Object.keys(registerForm).length === 0;
    }
  };

  const disablePrevButton = () => {
    return stateRegister === 1;
  };

  const atLeastOneWhoSalertContainsValue = (wholesalers_data) => {
    return (wholesalers_data || []).some(
      (element) => element.account_number.length
    );
  };

  const validateScreen2 = ({
    first_name = "",
    last_name = "",
    email = "",
    phone_number = "",
    job_title = "",
    registration_number = "",
    hospital_id = "",
    institution_type = "",
    institution_id = "",
    password1 = "",
    password2 = "",
    wholesalers_data = "",
  }) => ({
    first_name: {
      isValid: first_name.trim().length > 0,
    },
    last_name: {
      isValid: last_name.trim().length > 0,
    },
    email: {
      isValid: email.trim().length > 0,
    },
    emailValid: {
      isValid: validateEmail(email),
    },
    phone_number: {
      isValid: registerForm.type?.includes(registrationCategory.PHARMACY)
        ? phone_number.trim().length > 0
        : true,
    },
    job_title: {
      isValid: registerForm.type?.includes(registrationCategory.PRESCRIBER)
        ? job_title.trim().length > 0
        : true,
    },
    registration_number: {
      isValid:
        registration_number.trim().length > 0 &&
        registration_number.trim().length <= 30,
    },
    hospital_id: {
      isValid: registerForm.type?.includes(registrationCategory.PRESCRIBER)
        ? !!hospital_id?.value
        : true,
    },
    institution_type: {
      isValid: registerForm.type?.includes(registrationCategory.PHARMACY)
        ? institution_type?.label?.trim().length > 0
        : true,
    },
    institution_id: {
      isValid: registerForm.type?.includes(registrationCategory.PHARMACY)
        ? !!institution_id?.value
        : true,
    },
    password1: {
      isValid: validatePassword(password1),
    },
    password2: {
      isValid: validatePassword(password2),
    },
    passwordMatch: {
      isValid: password1 === password2,
    },
    wholesalers_data: {
      isValid: registerForm.type?.includes(registrationCategory.PHARMACY)
        ? atLeastOneWhoSalertContainsValue(wholesalers_data)
        : true,
    },
    products: {
      isValid: selectedProducts.length > 0,
    },
  });

  // I counted every product confirmations I received from BE to compare length for validations
  function getObjectLength(obj) {
    return Object.keys(obj).length;
  }
  const lengths = Object.values(productConfirmations).map((obj) =>
    getObjectLength(obj)
  );
  const sum = lengths.reduce((acc, cur) => acc + cur, 0);

  const validateScreen3 = () => ({
    confirmations: {
      isValid: checkboxesMedications.length === sum ? true : false,
    },
  });

  let areAllFieldsValidScreen2;
  let areAllFieldsValidScreen3;

  const {
    first_name,
    last_name,
    email,
    phone_number,
    job_title,
    registration_number,
    hospital_id,
    institution_type,
    institution_id,
    password1,
    password2,
    wholesalers_data,
    confirmations,
  } = registerForm;

  const validateScreen2Fields = validateScreen2({
    first_name,
    last_name,
    email,
    phone_number,
    job_title,
    registration_number,
    hospital_id,
    institution_type,
    institution_id,
    password1,
    password2,
    wholesalers_data,
  });

  const validateScreen3Fields = validateScreen3({
    confirmations,
  });

  areAllFieldsValidScreen2 = Object.values(validateScreen2Fields).every(
    (field) => {
      return field.isValid;
    }
  );

  areAllFieldsValidScreen3 = Object.values(validateScreen3Fields).every(
    (field) => {
      return field.isValid;
    }
  );

  const closeModalGuideRead = (event) => {
    if (event.key === "Escape") {
      return;
    }

    setInformationGuideRead(true);
    setOpenGuideRead(false);

    submitForm();
  };

  const handleCheckConfirmInformationGuideRead = (event) => {
    setConfirmInformationGuideRead(event.target.checked);
  };

  const [products, setProducts] = useState();
  const getProducts = async () => {
    try {
      let res = await Axios.get(APIVariables.GET_PRODUCTS).catch((error) => {
        handleCatch(error);
      });

      if (res?.status === 200) {
        setProducts(res.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (stateRegister === 1) {
      getProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [brochureIdAndName, setBrochureIdAndName] = useState([]);
  useEffect(() => {
    // Your data fetching or processing logic here
    const processedData = productConfirmations
      .flat()
      .filter((item) => item.brochure_name !== null)
      .reduce((acc, item) => {
        const existingItem = acc.find(
          (x) => x.brochure_id === item.brochure_id
        );

        if (!existingItem) {
          acc.push({
            brochure_id: item.brochure_id,
            brochure_name: item.brochure_name,
          });
        } else {
          // Check if brochure_name is not already included
          if (!existingItem.brochure_name.includes(item.brochure_name)) {
            existingItem.brochure_name += `, ${item.brochure_name}`;
          }
        }
        return acc;
      }, []);

    // Set the state with the processed data
    setBrochureIdAndName(processedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productConfirmations]);

  const getListOfBrochures = (brochure) => {
    setBrochureIdAndName(brochure);
  };

  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal">
          <div className="close-icon" onClick={closeRegisterForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>

          <React.Fragment>
            {stateRegister === 1 && (
              <React.Fragment>
                <h1 className="select-category-txt">
                  Please select your registration category
                </h1>

                <hr className="gray-light-hr" />
                <div className="min-height">
                  <div className="flex-justify">
                    <button
                      className={
                        "mt-4 " +
                        (registerForm.type ===
                        registrationCategory.PRESCRIBER_PRESCRIBER
                          ? "green-light-button"
                          : "selected-register-button")
                      }
                      onClick={() =>
                        selectTypeOfCategory(
                          registrationCategory.PRESCRIBER_PRESCRIBER
                        )
                      }
                    >
                      Prescriber
                    </button>

                    <button
                      className={
                        "mt-4 " +
                        (registerForm.type === registrationCategory.PHARMACY
                          ? "green-light-button"
                          : "selected-register-button")
                      }
                      onClick={() =>
                        selectTypeOfCategory(registrationCategory.PHARMACY)
                      }
                    >
                      Pharmacist
                    </button>
                  </div>
                  <div className="blur-img">
                    <img src={RegisterBlur} alt="blur_image" />
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>

          {stateRegister !== 1 && (
            <RegisterForm
              stateRegister={stateRegister}
              previous={previous}
              handleChange={handleChange}
              registerForm={registerForm}
              isSubmitting={isSubmitting}
              nextPage={nextPage}
              formFields={validateScreen2Fields}
              handleChangeSelect={handleChangeSelect}
              typeOfForm={registerForm.type}
              errorMessage={errorMessage}
              setWasLinkClicked={setWasLinkClicked}
              downloadedBrochures={downloadedBrochures}
              setDownloadedBrochures={setDownloadedBrochures}
              handleChangeWholeSaler={handleChangeWholeSaler}
              setIsLoading={setIsLoading}
              products={products}
              handleCheckboxClick={handleCheckboxClick}
              handleCheckboxes={handleCheckboxes}
              selectedProducts={selectedProducts}
              productConfirmations={productConfirmations}
              checkboxesMedications={checkboxesMedications}
              getListOfBrochures={getListOfBrochures}
              brochureIdAndName={brochureIdAndName}
            />
          )}

          <div className="wrapper-buttons-select-type">
            <button
              onClick={previous}
              className="prev"
              disabled={disablePrevButton()}
            >
              Previous
            </button>
            <div className="flex-between-center">
              {stateRegister !== 1 && (
                <div className="symbol-idication">
                  *symbol indicating a mandatory field
                </div>
              )}
              <button
                className="primary-button custom-login-button"
                disabled={disableNextButton() || isLoading}
                onClick={nextPage}
              >
                {stateRegister === 3 ? "Register" : "Next"}{" "}
                {isLoading && (
                  <i className="custom-login-loader">
                    <Loader />
                  </i>
                )}
              </button>
            </div>
          </div>
        </div>

        <Modal
          isOpen={registerModalIsOpen}
          onRequestClose={logIn}
          style={customStyles}
          contentLabel="Register Modal"
          overlayClassName="overlay-modal"
          shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
        >
          <div className="wrapper-modal">
            <div className="image">
              <CheckRegister />
            </div>
            <div className="main-text">Registration is in Progress</div>
            <div className="second-text">
              Thank you for registering for the Pathfinder. <br /> A
              representitive will validate your account and you will receive an
              email to validate within one working day
            </div>
          </div>
          <div className="wrapper-buttons">
            <button className="green-button" onClick={logIn}>
              Login
            </button>
          </div>
        </Modal>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={logIn}
            description="Are you sure you want to cancel filling out this registration form? All progress will be lost and you will need to start again."
          />
        )}

        {openGuideRead && (
          <InformationGuideRead
            openGuideRead={openGuideRead}
            closeModalGuideRead={closeModalGuideRead}
            confirmInformationGuideRead={confirmInformationGuideRead}
            handleCheckConfirmInformationGuideRead={
              handleCheckConfirmInformationGuideRead
            }
          />
        )}

        <Modal
          isOpen={quitModalIsOpenError}
          onRequestClose={closeModalQuitError}
          style={customStyles}
          contentLabel="Error Modal"
          overlayClassName="overlay-modal"
          shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
        >
          {/* <button onClick={closeModalQuitError} className="close-icon-button ">
            <img src={CloseIcon} alt="Close Icon" />
          </button> */}
          <div className="wrapper-modal">
            <div className="image">
              {modalMessage.title === errorMessages.MUST_CLICK_LINK_TITLE ? (
                <Error />
              ) : (
                <Warning />
              )}
            </div>
            <div className="main-text-error">{modalMessage.title}</div>
            <div className="second-text">{modalMessage.description}</div>
          </div>
          <div className="wrapper-buttons">
            {modalMessage.title === errorMessages.MUST_CLICK_LINK_TITLE ? (
              <button className="red-button" onClick={closeModalQuitError}>
                Back
              </button>
            ) : (
              <React.Fragment>
                <button className="cancel mr-6" onClick={closeModalQuitError}>
                  Cancel
                </button>
                <button className="red-button" onClick={closeModalQuitError}>
                  Continue
                </button>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </StyledRegister>
    </React.Fragment>
  );
};
export default Register;
