import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as CheckRegister } from "../../../../assets/icons/checkLime.svg";

import StyledModal from "../../../common/Modal/StyledModal";
import { useNavigate } from "react-router-dom";
import { App } from "../../../../routes/Constants";

const ConfirmHomecareModal = ({ closeModal, modalIsOpen }) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "32px",
    },
  };

  const navigate = useNavigate();
  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  const handleClose = () => {
    closeModal(false);
    navigate(App.HOMECARE);
  };

  return (
    <StyledModal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal(false)}
        style={customStyles}
        contentLabel="Confirm Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <CheckRegister />
          </div>
          <div className="main-text">Connected Homecare Added</div>
          <div className="second-text">
            The Connected Homecare has been added to the list. A connection
            email has been sent to the Homecare provider.
          </div>
        </div>

        <div className="wrapper-buttons">
          <button className="green-button" onClick={() => handleClose()}>
            Return
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

ConfirmHomecareModal.propTypes = {
  closeModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
};
export default ConfirmHomecareModal;
