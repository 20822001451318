import styled from "styled-components";
import Colors from "../../../../shared/colors";

export default styled.div`
  .table-container {
    padding: 0px 100px;
    .registration-valid {
      color: ${Colors.endeavour};
      text-align-last: center;
    }

    table tr {
      height: 60px;
      text-align: center;
      vertical-align: middle;
    }
    table tr td {
      border: 1px solid ${Colors.gray};
      width: 33%;
    }

    .td-expiry-date {
      color: ${Colors.black};
      font-size: medium;
      p {
        margin: 0px !important;
      }
    }
    .prod_product_name {
      color: ${Colors.white};
      font-size: medium;
    }
    .td-product-name-grey {
      background: ${Colors.gray};
      border: 1px solid ${Colors.white};
      cursor: not-allowed;
    }
    .td-product-name {
      background: ${Colors.endeavour};
      border: 1px solid ${Colors.white};
    }
    .td-product-name-green {
      background: ${Colors.greenHaze};
      border: 1px solid ${Colors.white};
    }
    .td-product-name-red {
      background: #d73131;
      border: 1px solid ${Colors.white};
    }
    .td-pending-activation {
      text-align: -webkit-center;
      p {
        margin: 0px !important;
      }
      .register-text {
        color: ${Colors.white};
        padding: 8px 0px 0px 0px;
      }
      .register-drug-button {
        width: 128px;
        height: 38px;
        background-color: ${Colors.endeavour};
        border-radius: 50px;
        color: ${Colors.white};
        cursor: pointer;
      }
    }
  }

  .span {
    width: 185px;
    margin: 0px auto;
    color: ${Colors.coralRed};
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;
