import styled from "styled-components";
import Colors from "../../../shared/colors";

const Layout = styled.div`
  .red-text {
    color: ${Colors.valencia};
    font-size: 16px;
    line-height: 19px;
    padding: 10px 0;
    font-weight: bold;
    min-height: 40px;
  }
`;
export default function ErrorText({ item }) {
  return (
    <Layout>
      <div className="red-text">{item}</div>
    </Layout>
  );
}
