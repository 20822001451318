import React from "react";
import styled from "styled-components";

import LoaderImage from "../../../assets/images/loader.gif";

const Layout = styled.div`
  img {
    margin-top: 20vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
  }
`;

const Loader = () => {
  return (
    <Layout>
      <img src={LoaderImage} alt="Loader" />
    </Layout>
  );
};
export default Loader;
