import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import StyledModal from "../../common/Modal/StyledModal";

const OverdueWarningModal = ({
  continuDispenseOverduePAF,
  closeOverduePAF,
  continueWithOverduePAF,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={continuDispenseOverduePAF.isMainModalDisplayed}
        onRequestClose={closeOverduePAF}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image"></div>
          <div className="main-text-error">
            Dispensing more than 7 days after the prescription date for this
            patient
          </div>
          <div className="second-text">
            I acknowledge that this PAF should be rejected as dispensing is
            required to take place within 7 days of prescription date for women
            of childbearing potential{" "}
          </div>
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeOverduePAF}>
            Cancel
          </button>
          <button
            className="red-button"
            onClick={() => {
              continueWithOverduePAF();
            }}
          >
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

OverdueWarningModal.propTypes = {
  continuDispenseOverduePAF: PropTypes.object,
  closeOverduePAF: PropTypes.func,
  continueWithOverduePAF: PropTypes.func,
};

export default OverdueWarningModal;
