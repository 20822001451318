export const clearCacheData = () => {
  Object.keys(localStorage).forEach((key) => {
    if (
      key !== "USER_NHSPORTAL" &&
      key !== "ARE_NEW_RELEASE_NOTES" &&
      key !== "NEXT_PAGE_NHSPORTAL"
    ) {
      localStorage.removeItem(key);
    }
  });

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
