import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .layout {
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .wrapper-homecare {
    text-align: center;
    padding: 10px 0px;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .wrapper-infinitescroll {
    .head-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px;
      border-bottom: 1px solid ${Colors.alto};
      background: rgba(0, 0, 0, 0.02);

      .primary-button {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .wrapper-input-search {
      position: relative;
    }
    .wrapper-input-search img {
      position: absolute;
      left: 20px;
      top: 18px;
    }
    .input-search {
      background: ${Colors.white};
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.gray};
      font-weight: normal;
      min-width: 489px;
      padding-left: 40px;
      padding-right: 20px;
    }
    .input-search::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.gray};
      font-weight: normal;
    }
    .wrapper-table-infinitescroll {
      .table-wrapper {
        display: flex;
        width: 100%;
        padding: 14px 20px;
        border-bottom: 1px solid ${Colors.alto};
        background: ${Colors.white};
        border-radius: 5px 5px 0px 0px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        div {
          color: ${Colors.doveGray};
          align-self: center;
        }

        .name,
        .position,
        .registered,
        .gender {
          width: 25%;
        }

        .position,
        .registered {
          height: 37px;
          line-height: 37px;
        }

        .remove {
          cursor: pointer;
          width: 22%;
        }
      }
      .head {
        margin-bottom: 10px;

        .position,
        .registered {
          border-right: 0px;
        }
      }

      .position-homecare {
        @media screen and (max-width: 850px) {
          line-height: initial !important;
          display: flex;
          align-items: center;
        }
      }
    }

    .wrapper-table-infinitescroll {
      .item {
        border-bottom: 1px solid ${Colors.alto};
        padding: 14px 20px;
      }
    }
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar {
    width: 6px;
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${Colors.white};
    border-radius: 10px;
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar-thumb {
    background: ${Colors.alto};
    border-radius: 500px;
  }

  .align-center {
    text-align: center;
  }
  .align-left {
    text-align: left;
  }
  .align-right {
    text-align: right;
  }
`;
