import React, { useState, useEffect, useRef } from "react";

import moment from "moment";
import ViewPharmacyModal from "./ViewPharmacyModal";
import { Virtuoso } from "react-virtuoso";
import StyledNotifications from "../Notifications/StyledNotifications";
import { ReactComponent as FilterPoligon } from "../../../assets/icons/filterPoligon.svg";
import APIVariables from "../../../services/api-variables";
import CalendarComponent from "../../common/Calendar/Calendar";
import Loader from "../../common/Loader/Loader";
import SearchGray from "../../../assets/icons/searchGray.svg";
import { ReactComponent as ArrowRightBlue } from "../../../assets/icons/arrowRightBlue.svg";
import Axios from "../../../services/Axios";
import FilterDataViewPatients from "../ViewPatients/FilterDataViewPatients";
import { handleCatch } from "../../../shared/helpers";

const PharmacyList = () => {
  const debounceTime = 500;
  const [filters, setFilters] = useState(new Map());
  const [showFilter, setShowFilter] = useState("");
  const pharmacyNameRef = useRef();
  const registeredProducts = useRef();
  const postCodeRef = useRef();
  const validDateRef = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [items, setItems] = useState([]);
  const [existMoreItems, setExistMoreItems] = useState(false);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState();

  function handleOutsideClick(event) {
    const { target } = event;

    const refs = [
      pharmacyNameRef,
      postCodeRef,
      validDateRef,
      registeredProducts,
    ];

    let contains = refs.some((ref) => {
      try {
        let svgClass = target?.getAttribute("class");
        if (svgClass === "rs-icon") {
          return true;
        }

        return (
          ref?.current?.contains(target) ||
          target?.className?.includes("wrapper-filter") ||
          target?.className?.includes("rs-calendar") ||
          target?.className?.includes("rs-calendar-header-month-toolbar") ||
          target?.className?.includes("rs-picker-toolbar") ||
          target?.className?.includes("rs-btn")
        );
      } catch (error) {
        return false;
      }
    });

    if (!contains) setShowFilter("");
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const onChangeShowFilter = (type) => {
    if (type === showFilter) return setShowFilter("");

    setShowFilter(type);
  };

  /* eslint-disable */

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getPharmacies(false);
    }, debounceTime);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [filters, searchValue]);

  async function getPharmacies(fetchMore) {
    setIsFetching(true);
    let filterString = !fetchMore ? "?" : "";
    if (searchValue.length > 0) {
      filterString += `search_lookup=${searchValue}`;
    }

    [...filters.keys()].forEach((key) => {
      const currentFilter = filters.get(key);
      currentFilter.forEach((filter, index, array) => {
        if (!Boolean(filter.dataType)) {
          filterString += `&${key}=${filter.id}`;
        } else {
          filterString += `&${key}=${filter.value}`;
        }
      });
    });

    let link = fetchMore
      ? existMoreItems
      : APIVariables.PHARMACY_LIST + filterString;

    try {
      const res = await Axios.get(link);

      setIsLoading(false);

      if (fetchMore) setItems([...items, ...res.data]);
      else setItems(res.data);

      const areMoreMoreItems = !!res.data.next;

      if (areMoreMoreItems) {
        setExistMoreItems(res.data.next);
      } else {
        setExistMoreItems(false);
      }
    } catch (error) {
      handleCatch(error);
    }

    setIsFetching(false);
  }

  const onChangeFilters = (type, obj, isRemoveFromLabel) => {
    const filtersMap = new Map(filters);
    const typesArrays = ["string", "date"];
    if (typesArrays.includes(obj.dataType)) {
      if (String(obj.value).length < 1) return deleteFilter(type);
      if (isRemoveFromLabel) return deleteFilter(type);
      filtersMap.set(type, [obj]);
    } else {
      if (filtersMap.has(type)) {
        const currentFilter = filtersMap.get(type);
        const isFilter = currentFilter.find((filter) => filter.id === obj.id);
        if (isFilter) {
          return deleteFilter(type, obj);
        } else {
          filtersMap.set(type, [...currentFilter, obj]);
        }
      } else {
        filtersMap.set(type, [obj]);
      }
    }

    setFilters(filtersMap);
  };

  const deleteFilter = (type, obj) => {
    const filtersMap = new Map(filters);
    if (!obj) {
      filtersMap.delete(type);
      return setFilters(filtersMap);
    }

    const currentFilter = filtersMap.get(type);
    const newArrayFilters = currentFilter.filter(
      (filter) => filter.id !== obj.id
    );
    if (newArrayFilters.length < 1) {
      filtersMap.delete(type);
    } else {
      filtersMap.set(type, newArrayFilters);
    }

    setFilters(filtersMap);
  };

  const getStringFilter = (key) => {
    if (filters.has(key)) return filters.get(key)[0].value;
    return "";
  };

  const getDateFilter = (key) => {
    if (filters?.has(key)) return filters?.get(key)[0].date;
    return false;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <StyledNotifications>
        <div className="layout" style={{ minWidth: 736 }}>
          <h1 className="h1 text-center bold">Registered Pharmacy Search</h1>
          <div className="all-filter-buttons">
            {filters &&
              [...filters.keys()].map((key) => {
                let currentFilter = filters.get(key);
                return currentFilter.map((filter) => {
                  return (
                    <FilterDataViewPatients
                      key={key + filter.value}
                      keyRefer={key}
                      filter={filter}
                      onChangeFilters={onChangeFilters}
                    />
                  );
                });
              })}
          </div>

          <div className="wrapper-infinitescroll">
            <div className="head-wrapper">
              <div className="wrapper-input-search">
                <input
                  type="search"
                  className="input-search"
                  placeholder="Search pharmacy name, postcode, registered product..."
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  value={searchValue}
                />

                <img src={SearchGray} alt="" />
              </div>
            </div>

            <div className="wrapper-table-infinitescroll">
              <div
                className="table-wrapper view-patients header-view-patients"
                style={{
                  padding: "1.8rem 2rem",
                }}
              >
                <div
                  className="wrapper-nhs-number"
                  style={{
                    width: "35%",
                  }}
                >
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16 bold"
                    onClick={() => {
                      onChangeShowFilter("name");
                    }}
                  >
                    Pharmacy Name <FilterPoligon />
                  </div>

                  {showFilter === "name" && (
                    <div
                      className="wrapper-notifications-modal"
                      ref={pharmacyNameRef}
                      style={{ marginLeft: -70 }}
                    >
                      <div className="wrapper-search-input">
                        <input
                          className="input-search"
                          type="search"
                          value={getStringFilter("name") || ""}
                          onChange={(e) => {
                            onChangeFilters("name", {
                              value: e.target.value,
                              label: "Pharmacy Name",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find pharmacy name..."
                        />

                        {filters?.name?.value.length === 0 && (
                          <img src={SearchGray} alt="" className="search" />
                        )}
                      </div>
                      {/* I'm leaving this piece of code here, I might need it later */}

                      {/* {filterSearchData(items, "name", getStringFilter("name"))
                        .length > 0 &&
                        getStringFilter("name").length > 0 &&
                        showFilter === "name" && (
                          <div className="wrapper-tooltip-patients registered-products">
                            {filterSearchData(
                              items,
                              "name",
                              getStringFilter("name")
                            ).map((item, index) => (
                              <div
                                key={index}
                                className="element"
                                onClick={(e) => {
                                  setShowFilter("");
                                  onChangeFilters("name", {
                                    value: item?.name,
                                    label: "Pharmacy Name",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">{item.name}</div>
                              </div>
                            ))}
                          </div>
                        )} */}
                    </div>
                  )}
                </div>

                <div
                  className="wrapper-nhs-number wrapper-filter"
                  style={{
                    width: "25%",
                  }}
                >
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16 bold"
                    onClick={() => {
                      onChangeShowFilter("postal_code");
                    }}
                  >
                    Postcode <FilterPoligon />
                  </div>

                  {showFilter === "postal_code" && (
                    <div
                      className="wrapper-notifications-modal"
                      ref={postCodeRef}
                    >
                      <div className="wrapper-search-input">
                        <input
                          className="input-search"
                          id="input-search-name"
                          type="search"
                          value={getStringFilter("postal_code") || ""}
                          onChange={(e) => {
                            onChangeFilters("postal_code", {
                              value: e.target.value,
                              label: "Postcode",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find postcode..."
                        />

                        {filters?.name?.value.length === 0 && (
                          <img src={SearchGray} alt="" className="search" />
                        )}
                      </div>
                      {/* I'm leaving this piece of code here, I might need it later */}

                      {/* {filterSearchData(
                        items,
                        "postal_code",
                        getStringFilter("postal_code")
                      ).length > 0 &&
                        getStringFilter("postal_code").length > 0 &&
                        showFilter === "postal_code" && (
                          <div className="wrapper-tooltip-patients">
                            {filterSearchData(
                              items,
                              "postal_code",
                              getStringFilter("postal_code")
                            ).map((item, index) => (
                              <div
                                key={index}
                                className="element"
                                onClick={(e) => {
                                  setShowFilter("");
                                  onChangeFilters("postal_code", {
                                    value: item?.postal_code,
                                    label: "Postcode",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">{item.postal_code}</div>
                              </div>
                            ))}
                          </div>
                        )} */}
                    </div>
                  )}
                </div>

                <div
                  className="wrapper-nhs-number"
                  style={{
                    width: "30%",
                  }}
                >
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16 bold"
                    onClick={() => {
                      onChangeShowFilter("products");
                    }}
                  >
                    Registered Product
                    <FilterPoligon />
                  </div>

                  {showFilter === "products" && (
                    <div
                      className="wrapper-notifications-modal"
                      ref={registeredProducts}
                      style={{ marginLeft: 30 }}
                    >
                      <div className="wrapper-search-input">
                        <input
                          className="input-search"
                          type="search"
                          value={getStringFilter("products") || ""}
                          onChange={(e) => {
                            onChangeFilters("products", {
                              value: e.target.value,
                              label: "Registered Product",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find product name..."
                        />
                        {filters?.products?.value.length === 0 && (
                          <img src={SearchGray} alt="" className="search" />
                        )}
                      </div>
                      {/* I'm leaving this piece of code here, I might need it later */}

                      {/* {filterSearchData(
                        items,
                        "products",
                        getStringFilter("products")
                      ).length > 0 &&
                        getStringFilter("products").length > 0 &&
                        showFilter === "products" && (
                          <div className="wrapper-tooltip-patients">
                            {filterSearchData(
                              items,
                              "products",
                              getStringFilter("products")
                            ).map((item, index) => (
                              <div
                                key={index}
                                className="element"
                                onClick={(e) => {
                                  setShowFilter("");
                                  onChangeFilters("products", {
                                    value:
                                      item.products.length > 23
                                        ? `${item.products.slice(0, 23)}...`
                                        : item.products,
                                    label: "Registered Products",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">{item.products}</div>
                              </div>
                            ))}
                          </div>
                        )} */}
                    </div>
                  )}
                </div>

                <div
                  style={{ width: "35%" }}
                  className="wrapper-nhs-number wrapper-filter"
                >
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16 bold"
                    onClick={() => onChangeShowFilter("reg_valid_until")}
                  >
                    Reg Valid Until
                    <FilterPoligon />
                  </div>

                  {showFilter === "reg_valid_until" && (
                    <div
                      className="container--div"
                      ref={validDateRef}
                      style={{ marginTop: 50 }}
                    >
                      <div className="react-arrow-div" />
                      <CalendarComponent
                        close={() => {
                          setShowFilter("");
                        }}
                        date={getDateFilter("reg_valid_until") || null}
                        setDateRaw={(date) => {
                          date &&
                            onChangeFilters("reg_valid_until", {
                              value: moment(date).format("YYYY-MM-DD"),
                              label: "Reg Valid Until",
                              dataType: "date",
                              date,
                            });
                        }}
                        selectRange={false}
                        onResetCalendar={() => {
                          deleteFilter("reg_valid_until");
                        }}
                        type="Reg Valid Until"
                      />
                    </div>
                  )}
                </div>

                {/* <div style={{ width: "10%" }} /> */}
              </div>

              <Virtuoso
                endReached={() => {
                  if (existMoreItems && !isFetching) {
                    getPharmacies(true);
                  }
                }}
                style={{ height: "400px" }}
                data={items}
                itemContent={(index) => (
                  <div
                    key={index}
                    style={{
                      padding: "1.8rem 2rem",
                      cursor: "pointer",
                      borderBottom: "1px solid #DCDCDC",
                    }}
                    className="d-flex"
                    onClick={() => {
                      setIsOpenViewModal({
                        state: true,

                        id: items[index].id,
                      });
                      setProduct(items[index].products);
                    }}
                  >
                    <div
                      className="font-size-16"
                      style={{ width: "35%", textAlign: "center" }}
                    >
                      {items[index]?.name?.toUpperCase()}
                    </div>

                    <div
                      className="text-align-center font-size-16"
                      style={{ width: "25%" }}
                    >
                      {items[index]?.postal_code}
                    </div>

                    <div
                      className="text-align-center font-size-16"
                      style={{ width: "35%" }}
                    >
                      {items[index]?.products}
                    </div>

                    <div
                      className="text-align-center font-size-16"
                      style={{ width: "30%" }}
                    >
                      {items[index]?.reg_valid_until &&
                        moment(items[index]?.reg_valid_until).format(
                          "DD / MM / YYYY"
                        )}
                    </div>

                    <div className="text-align-right pdf">
                      <ArrowRightBlue />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </StyledNotifications>

      <ViewPharmacyModal
        isOpen={isOpenViewModal?.state}
        onRequestClose={() => setIsOpenViewModal(null)}
        ID={isOpenViewModal?.id}
        product={product}
      />
    </>
  );
};

export default PharmacyList;
