import React from "react";
import InfoBlue from "../../../assets/icons/infoBlue.svg";
import Tooltip from "../../common/ViewPAF/Tooltip";

const ToolTipWeeksPerCycle = ({ TotalSupply }) => {
  return (
    <Tooltip>
      <div className="wrraper">
        <div className="wrapper-div-patients">
          <div className="status">
            <div>
              <img src={InfoBlue} alt="info" className="info-icon" />
            </div>
            <div className="tooltiptext">
              {TotalSupply
                ? "Auto-calculated by multiplication of “Supply weeks per cycle” and “Number of cycles” "
                : "Number of weeks in the treatment cycle that the patient receives medication. Example: For a 28 day cycle with a 7 day break, enter  '3' in this field to cover the 21 days of medication  "}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default ToolTipWeeksPerCycle;
