import React from "react";
import Modal from "react-modal";
import ErrorText from "../../common/ErrorText/ErrorText";
import StyledModal from "../../common/Modal/StyledModal";
import BrochureWithText from "../Brochure/BrochureWithText";

const NonCompliantPAF = ({
  modalNonCompliantPAFIsOpen,
  closemodalNonCompliantPAF,
  modalNonCompliantPAF,
  continueButtonNonComplainPAF,
  handleChangeValidPassword,
  errorMessageFromServer,
  setValidPassword,
  hcp,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
      maxHeight: "90%",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={modalNonCompliantPAFIsOpen}
        onRequestClose={closemodalNonCompliantPAF}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="main-text-error">
            {modalNonCompliantPAF.screen === 3
              ? "Please confirm your approval"
              : "Non-Compliant PAF"}
          </div>
          {modalNonCompliantPAF.screen === 1 && (
            <React.Fragment>
              <div className="second-text">
                <BrochureWithText type={"NON_COMPLIANT_PAF"} hcp={hcp} />
              </div>
              <div className="second-text">
                The following issues were identified:
              </div>

              {(modalNonCompliantPAF?.description || []).map((item, index) => (
                <span className="required-text-reset bold" key={index}>
                  - {item}
                </span>
              ))}
            </React.Fragment>
          )}
          {modalNonCompliantPAF.screen === 2 && (
            <React.Fragment>
              <div className="second-text">
                I acknowledge that this PAF contains incomplete/non-compliant
                data. I wish to proceed outside the terms of the {hcp?.name} PPP
                based on my clinical judgement and will re-enter my password to
                document this decision.
              </div>
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  continueButtonNonComplainPAF(modalNonCompliantPAF.screen);
                }}
              >
                <input
                  className="input-rejection"
                  type="password"
                  onChange={(e) => handleChangeValidPassword(e)}
                  name="validate_password"
                  placeholder="Password"
                  maxLength={64}
                />
              </form>

              <div className="min-width-error-message">
                {(errorMessageFromServer || []).map((item, index) => (
                  <ErrorText key={index} item={item} />
                ))}
              </div>
            </React.Fragment>
          )}
          {modalNonCompliantPAF.screen === 3 && (
            <div className="second-text">
              <BrochureWithText type={"NON_COMPLIANT_PAF_SCREEN_3"} hcp={hcp} />
            </div>
          )}
        </div>
        <div className="wrapper-buttons">
          <button
            className="cancel mr-6"
            onClick={() => {
              closemodalNonCompliantPAF();
              setValidPassword("");
            }}
          >
            Cancel
          </button>
          <button
            className="red-button"
            onClick={() =>
              continueButtonNonComplainPAF(modalNonCompliantPAF.screen)
            }
          >
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};
export default NonCompliantPAF;
