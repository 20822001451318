import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StyledNurses from "./StyledNurses";
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import { App } from "../../../routes/Constants";
import { Virtuoso } from "react-virtuoso";
import RemoveNurseModal from "./removeNurseModal";
import APIVariables from "../../../services/api-variables";
import Loader from "../../common/Loader/Loader";
import moment from "moment";
import { handleCatch } from "../../../shared/helpers";
import { errorMessages } from "../../../constants/errorMessages.constants";
import Axios from "../../../services/Axios";

const Nurses = () => {
  const [items, setItems] = useState([]);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [selectedNurse, setSelectedNurse] = useState("");
  const [existMoreItems, setExistMoreItems] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getNurses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNurses = async () => {
    const res = await Axios.get(APIVariables.HCP_CONNECTED_NURSES).catch(
      (error) => {
        handleCatch(error);
      }
    );

    if (res.status === 200) {
      setItems(res.data);
      setIsLoading(false);

      const areMoreMoreItems = !!res.data.next;
      if (areMoreMoreItems) {
        setExistMoreItems(res.data.next);
      }
    }
  };

  const addNurse = () => {
    navigate(App.ADD_NURSE);
  };

  const fetchMoreData = async () => {
    const res = await Axios.get(
      existMoreItems ? existMoreItems : APIVariables.HCP_CONNECTED_NURSES
    ).catch((error) => {
      handleCatch(error);
    });

    setItems(items.concat(res.data.results));

    const areMoreMoreItems = !!res.data.next;
    if (areMoreMoreItems) {
      setExistMoreItems(res.data.next);
    } else {
      setExistMoreItems(false);
    }
  };

  const openModalRemoveNurse = (e, id) => {
    e.stopPropagation();
    setSelectedNurse(id);
    setIsModalOpen(true);
  };

  const redirectToNurse = (e, index) => {
    navigate(App.DEFAULT_EDIT_NURSE + `${index}`);
  };

  const closeModal = () => {
    setSelectedNurse("");
    setErrorMessage("");
    setIsModalOpen(false);
  };

  const removeNurse = async () => {
    setErrorMessage("");

    const res = await Axios.delete(
      APIVariables.HCP_CONNECTED_NURSES_DELETE + selectedNurse
    ).catch(function (error) {
      const errors = error.response.data;
      if (typeof errors === "string") {
        setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
      } else {
        const errorsArray = [];
        Object.keys(errors).forEach((key) => {
          errorsArray.push(key + " - " + errors[key]);
        });
        setErrorMessage(errorsArray);
      }
    });
    if (res?.status === 200) {
      const newItems = items.filter((item) => {
        return item.id !== selectedNurse;
      });
      setItems(newItems);
      closeModal();
    }
  };

  if (isLoading) {
    <Loader />;
  }

  return (
    <StyledNurses>
      <React.Fragment>
        <div className="wrapper-nurses main-max-width">
          <h1 className="h1 mineShaft-color">Connected Nurses</h1>
          <button className="second-button" onClick={addNurse}>
            Add Nurse
          </button>
        </div>
        <div className="layout mt-4">
          <div className="wrapper-infinitescroll">
            <div className="wrapper-table-infinitescroll">
              <div className="table-wrapper head">
                <div className="name bold align-center">Name</div>
                <div className="position bold align-center">Position</div>
                <div className="registered bold align-center">Registered</div>
                <div className="remove bold align-right">Remove</div>
              </div>
              <Virtuoso
                endReached={existMoreItems && fetchMoreData}
                style={{ height: "400px" }}
                data={items}
                itemContent={(index) => (
                  <div
                    className="head-table table-wrapper"
                    key={index}
                    onClick={(e) => redirectToNurse(e, items[index].id)}
                  >
                    <div className="name align-center">{items[index].name}</div>
                    <div className="position align-center position-nurses">
                      {items[index].job_title}
                    </div>
                    <div className="registered align-center">
                      {moment(items[index].registered).format("DD / MM / YYYY")}
                    </div>
                    <div className="remove align-right">
                      <Remove
                        onClick={(e) =>
                          openModalRemoveNurse(e, items[index].id)
                        }
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        {modalIsOpen && (
          <RemoveNurseModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            removeNurse={removeNurse}
            errorMessage={errorMessage}
          />
        )}
      </React.Fragment>
    </StyledNurses>
  );
};
export default Nurses;
