import React from "react";
import Modal from "react-modal";

import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import { errorMessages } from "../../../constants/errorMessages.constants";
import ErrorText from "../../common/ErrorText/ErrorText";
import StyledModal from "../../common/Modal/StyledModal";

const PAFRenewModal = ({
  closeConfirm,
  confirmPrescriptionAuthorisationForm,
  errorMessage,
  validateVerify,
  handleChangeValidPassword,
  errorMessageFromServer,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={confirmPrescriptionAuthorisationForm}
        onRequestClose={closeConfirm}
        style={customStyles}
        contentLabel="Error Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            {errorMessage.type === "error" ? <Error /> : ""}
          </div>
          <div
            className={
              errorMessage.type === "error"
                ? "main-text-error"
                : "main-text color-orient"
            }
          >
            {errorMessage.title === errorMessages.OUTDATED_DATE_BIRTH_TITLE
              ? "Continue with off-label use"
              : errorMessage.title}
          </div>
          <div className="second-text">{errorMessage.description}</div>
          <div
            className="second-text"
            dangerouslySetInnerHTML={{
              __html: errorMessage.secondDescription,
            }}
          ></div>

          {errorMessage.modalState === 2 && errorMessage.hasSecondState && (
            <React.Fragment>
              <input
                type="password"
                placeholder="Password"
                className="input-password-modal"
                onChange={(e) => handleChangeValidPassword(e)}
              />
              <div className="min-width-error-message">
                {(errorMessageFromServer || []).map((item, index) => (
                  <ErrorText key={index} item={item} />
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeConfirm}>
            Cancel
          </button>
          <button
            className={
              errorMessage.type === "error" ? "red-button" : "approve-button"
            }
            onClick={validateVerify}
          >
            {errorMessage.buttonText}
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};
export default PAFRenewModal;
