import React from "react";
import PropTypes from "prop-types";

import ViewBrochures from "../PatientMaterial/ViewBrochures";
import StyledPatientMaterials from "./../PatientMaterial/StyledPatientMaterials";

const SelectTIF = ({
  registerPatient,
  handleCheckboxChange,
  showErrors,
  brochures,
  treatmentFormText,
}) => {
  return (
    <React.Fragment>
      <h1 className="select-category-txt">
        Please select the {treatmentFormText?.treatment_form_text_short} for
        patient category
      </h1>

      <hr className="gray-hr" />

      <div className="min-height">
        <ol className="tif-ol">
          <li className="font-size-16">
            Select the {treatmentFormText?.treatment_form_text} that you would
            like to complete.
          </li>
          <li className="pt-3 font-size-16">
            Print the selected form and input all of the required information
            before confirming that the {treatmentFormText?.treatment_form_text}{" "}
            has been completed via the checkbox and clicking 'Next' at the
            bottom of the page.
          </li>
          <li className="pt-3 font-size-16">
            After clicking 'Next', you can generate a Prescription Authorisation
            Form. The PAF will be sent to the pharmacy nominated by the patient.
          </li>
        </ol>

        <StyledPatientMaterials>
          <div className="text mt-5">
            <div className="row">
              {brochures.map((brochure, index) => (
                <div className="col-md-4" key={index}>
                  <ViewBrochures item={brochure} index={index} />
                </div>
              ))}
            </div>
          </div>
        </StyledPatientMaterials>

        <div
          className={
            "wrapper-confirm mt-3 " +
            (!!registerPatient.confirmation_other1 ? "" : "red-label")
          }
        >
          <label
            htmlFor="confirmation_other1"
            className="custom-checkbox custom-checkbox-tif doveGray-color font-size-16"
          >
            I confirm that the{" "}
            {treatmentFormText?.treatment_form_text.toLowerCase()} has been
            completed and signed for this patient*
            <input
              type="checkbox"
              id="confirmation_other1"
              name="confirmation_other1"
              checked={registerPatient.confirmation_other1 || false}
              onChange={handleCheckboxChange}
            />
            <span
              className={
                "checkmark mt-0 " + (showErrors ? "checkmark-error" : "")
              }
            />
          </label>
        </div>
        {showErrors && (
          <div className="error-text mt-2">
            <p>Required</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

SelectTIF.propTypes = {
  registerPatient: PropTypes.object,
  handleChangeCheckBox: PropTypes.func,
  showErrors: PropTypes.bool,
  brochures: PropTypes.array,
};

export default SelectTIF;
