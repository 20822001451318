import styled from "styled-components";

import Colors from "../../../shared/colors";

export default styled.div`
  background-color: white;
  text-align: unset;
  margin-top: 30px;

  form > div {
    display: grid;
  }

  form label {
    color: ${Colors.orient};
    width: "100%";
  }

  form input {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: ${Colors.white} !important;
    color: ${Colors.doveGray} !important;
    border: 0;
    border-bottom: 1px solid ${Colors.silver};
    padding: 0 0 5px 0;
    font-size: 18px;
    line-height: 22px;
    height: 30px;
    display: block;
    width: 100%;
  }

  form input:focus-visible {
    outline: 0;
  }

  form a {
    position: absolute;
    right: 75px;
    color: ${Colors.orient};
    text-decoration: none;
    margin-top: 10px;
  }

  form a:hover {
    text-decoration: underline;
  }

  .disabledText {
    color: ${Colors.gray} !important;
  }
`;
