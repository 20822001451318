import React from "react";
import Modal from "react-modal";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import StyledModal from "./../Modal/StyledModal";

const ReleaseNotesModal = ({
  displayExistReleaseNotes,
  quitModalExistNotes,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={!!displayExistReleaseNotes}
        onRequestClose={quitModalExistNotes}
        style={customStyles}
        ariaHideApp={false} // Added to clear the warning from tests
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div>
            <div className="main-text">Release notes updates</div>
            <div className="second-text scroll-on-release-notes">
              {(displayExistReleaseNotes || []).map((item, index) => (
                <div
                  key={index}
                  className="release-notes-wrapper"
                  style={{ maxHeight: "unset", overflowY: "unset" }}
                >
                  <div className="bold">{item.title}</div>
                  <div
                    style={{
                      maxHeight: "unset",
                      overflowY: "unset",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {item.text_note}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="wrapper-buttons">
          <button className="green-button" onClick={quitModalExistNotes}>
            Acknowledge
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};
export default ReleaseNotesModal;
