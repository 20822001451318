import React from "react";

const Wholesaler = ({
  registerForm,
  wholesaler,
  handleChangeWholeSaler,
  formFields,
  isSubmitting,
  isEditMode,
}) => {
  let matchDataWholeSeller;

  if (registerForm) {
    matchDataWholeSeller =
      registerForm?.wholesalers_data &&
      registerForm.wholesalers_data.find(
        (element) => element.id === wholesaler.id
      );
  } else {
    matchDataWholeSeller = wholesaler;
  }

  return (
    <>
      <label style={{ width: "100%" }}>
        {wholesaler.name} Account Number:*
      </label>
      <input
        type="text"
        style={{ width: "100%" }}
        onChange={(event) => handleChangeWholeSaler(event, wholesaler)}
        placeholder="Account Number / Depot Number / None "
        name={wholesaler.id}
        maxLength={30}
        value={matchDataWholeSeller?.account_number || ""}
        disabled={!isEditMode}
        className={
          isSubmitting && !formFields.wholesalers_data.isValid ? "error" : ""
        }
      />
    </>
  );
};

export default Wholesaler;
