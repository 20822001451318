import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  .main-wrapper-footer-login {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      ${Colors.white} -61.5%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(-180deg);
    z-index: 0;
    height: 150px;
  }

  .main-wrapper-footer-general {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: rotate(-180deg);
    z-index: 0;
  }

  .wrapper-footer {
    padding-left: 20px;
    padding-right: 20px;
    transform: rotate(-180deg);
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .left {
      color: ${Colors.black};
      @media screen and (max-width: 1200px) {
        text-align: center;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${Colors.doveGray};
      @media screen and (max-width: 1200px) {
        margin-top: 0px;
      }
    }

    .wrapper-footer-loggedin {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        margin-bottom: 12px;
        width: 180px;

        @media screen and (max-width: 718px) {
          margin-bottom: initial;
        }
      }
    }

    .logos {
      display: flex;
      justify-content: space-between;
      img {
        margin: 0px 30px 12px 30px;
        width: 180px;
      }
      @media screen and (max-width: 718px) {
        margin-bottom: initial;
      }
    }
    .text-below-logos {
      margin: 15px 0px;
      b {
        color: ${Colors.endeavour};
        font-size: 18px;
        text-align: center;
      }
    }
    span {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: -15px;
    }

    .support-login {
      color: ${Colors.endeavour};
      b {
        color: ${Colors.endeavour};
      }
    }

    .center-notLoggedIn {
      margin-top: -20px;
      @media screen and (max-width: 1200px) {
        margin-top: 0px;
      }
    }

    a {
      color: ${Colors.doveGray};
      text-decoration: initial;
    }
    b {
      margin-left: 3px;
    }
  }
`;
