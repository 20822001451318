import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";

const PharmacyCard = ({ item }) => {
  return (
    <>
      <div className="font-size-18 orient bold mt-5">{item?.full_name}</div>
      <p className="font-size-16 mt-2">For reporting adverse events:</p>
      <div className="wrapper-contact-report">
        <Phone />{" "}
        <span className="font-size-16 bold">
          Tel:{" "}
          <a className="font-size-16" href={`tel:${item?.phone_number}`}>
            {item?.phone_number}
          </a>
        </span>
      </div>
      <div className="wrapper-contact-report">
        <Email />{" "}
        <span className="font-size-16 bold">
          Email:{" "}
          <a className="font-size-16" href={`mailto: ${item?.email}`}>
            {item?.email}
          </a>
        </span>
      </div>
    </>
  );
};

PharmacyCard.propTypes = {
  item: PropTypes.object,
};
export default PharmacyCard;
