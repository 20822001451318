import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import CloseIcon from "../../../assets/icons/close.svg";
import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import ErrorText from "../../common/ErrorText/ErrorText";

import StyledModal from "../../common/Modal/StyledModal";

const RemoveNurseModal = ({
  modalIsOpen,
  closeModal,
  removeNurse,
  errorMessage,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "32px",
    },
  };

  const [isRemovingNurse, setIsRemovingNurse] = useState(false);

  const removeNurseWrapper = async (e) => {
    setIsRemovingNurse(true);
    await removeNurse(e);
    setIsRemovingNurse(false);
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Error Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button onClick={closeModal} className="close-icon-button ">
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="wrapper-modal">
          <div className="image">
            <Error />
          </div>
          <div className="main-text-error">Are you sure?</div>
          <div className="second-text">
            By confirming the removal of this nurse, their access to the system
            and patient records will be revoked.
          </div>
          {(errorMessage || []).map((item, index) => (
            <ErrorText key={index} item={item} />
          ))}
        </div>

        <div className="wrapper-buttons">
          <button className="cancel mr-6" disabled={isRemovingNurse}>
            Cancel
          </button>
          <button
            className="red-button"
            disabled={isRemovingNurse}
            onClick={removeNurseWrapper}
          >
            Remove
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

RemoveNurseModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  removeNurse: PropTypes.func,
  errorMessage: PropTypes.string,
};
export default RemoveNurseModal;
