import React, { useEffect, useState } from "react";

import moment from "moment";
import axios from "axios";

import Select, { components } from "react-select";

import AllowLeft from "../../assets/icons/arrowLeft.svg";
import Calendar from "../../assets/icons/biCalendar.svg";
import { ReactComponent as DropdownArrowDown } from "../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../assets/icons/dropdownArrowUp.svg";
import APIVariables from "../../services/api-variables";

//constants
import { registrationCategory } from "../../constants/register.constants";
import { errorMessages } from "../../constants/errorMessages.constants";
import Axios from "../../services/Axios";

import Wholesaler from "../common/Wholesaler/Wholesaler";
import TermsAndConditionsPrescriber from "../common/TermsAndConditions/TermsAndConditionsPrescriber";
import TermsAndConditionsPharmacy from "../common/TermsAndConditions/TermsAndConditionsPharmacy";

import { AsyncPaginate } from "react-select-async-paginate";
import StyledMedication from "./StyledMedication";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropdownArrowUp />
        ) : (
          <DropdownArrowDown />
        )}
      </components.DropdownIndicator>
    )
  );
};

const RegisterForm = ({
  stateRegister,
  previous,
  handleChange,
  registerForm,
  isSubmitting,
  formFields,
  handleChangeSelect,
  typeOfForm,
  errorMessage,
  setWasLinkClicked,
  downloadedBrochures,
  setDownloadedBrochures,
  handleChangeWholeSaler,
  setIsLoading,
  products,
  handleCheckboxClick,
  selectedProducts,
  productConfirmations,
  handleCheckboxes,
  checkboxesMedications,
  getListOfBrochures,
  brochureIdAndName,
}) => {
  const [institutionOptions, setInstitutionsOptions] = useState([]);
  const [wholeSalers, setWholeSalers] = useState([]);

  const invalidEmail = (errorMessage || []).find((element) =>
    element.includes("email")
  );
  const invalidRegistrationNumber = (errorMessage || []).find((element) =>
    element.includes("registration_number")
  );
  const invalidPhoneNumber = (errorMessage || []).find((element) =>
    element.includes("phone_number")
  );
  const invalidPassword = (errorMessage || []).find((element) =>
    element.includes("password")
  );
  const invalidWholeSaler = (errorMessage || []).find((element) =>
    element.includes("wholesalers_data")
  );

  const [nextURL, setNextPage] = useState(false);
  const [keyValue, setKeyValue] = useState(0);

  useEffect(() => {
    loadInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reset the AsyncPaginate dropdown to start from initial call
    setNextPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRegister]);

  const handleReset = () => {
    setNextPage(false);
    setKeyValue((prevKeyValue) => prevKeyValue + 1);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerForm?.institution_type?.value]);

  const isValidRegistrationFromFE =
    registerForm?.registration_number?.trim().length > 0 &&
    registerForm?.registration_number?.trim().length <= 30 &&
    isSubmitting;

  const isUndefinedRegistratioNumberFE =
    registerForm.registration_number === undefined && isSubmitting;

  const isNotRegistrationNumberTyped =
    isSubmitting &&
    !formFields.registration_number?.isValid &&
    (registerForm.registration_number?.length === 0 ||
      registerForm.registration_number === undefined);

  const isRegistrationNumberExceeded =
    isSubmitting &&
    !formFields.registration_number?.isValid &&
    registerForm?.registration_number?.length >= 30;

  const getBrochure = async (brochure, openInNewPage = false) => {
    const updatedArray = brochureIdAndName.filter(
      (item) => item.brochure_id !== brochure.brochure_id
    );

    updatedArray.length === 0 && setWasLinkClicked(true);
    getListOfBrochures(updatedArray);

    setIsLoading(true);
    await Axios.get(
      APIVariables.PATIENTS_BROCHURES_DOWNLOAD + brochure.brochure_id
    )
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          const { brochure_history_id } = data;
          setDownloadedBrochures([...downloadedBrochures, brochure_history_id]);
          return data?.report;
        }
        return null;
      })
      .then(async (brochureString) => {
        const base64 = await fetch(
          `data:application/pdf;base64,${brochureString}`
        );
        return base64.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        if (openInNewPage) {
          downloadLink.target = "_blank";
        } else {
          downloadLink.download = brochure.brochure_name.toString() + ".pdf";
        }
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const validateRegistrationNumber = () => {
    if (!isValidRegistrationFromFE || isUndefinedRegistratioNumberFE) {
      if (isNotRegistrationNumberTyped) {
        return <span className="required-text">Required</span>;
      } else if (isRegistrationNumberExceeded) {
        return (
          <span className="required-text">Registration Number too long</span>
        );
      } else if (invalidRegistrationNumber?.length) {
        return (
          <span className="required-text">
            {invalidRegistrationNumber?.split("-")[1]}
          </span>
        );
      } else {
        <span className="required-text"></span>;
      }
    } else {
      return <span></span>;
    }
  };

  const createMarkup = (name, postal_code) => {
    return {
      __html: `<div class="flex-justify custom-hover-select"><div>${name}</div><div>${postal_code}</div></div>`,
    };
  };

  async function loadInitial() {
    if (typeOfForm.includes(registrationCategory.PHARMACY)) {
      const { data: getInstitutionsOptions } = await axios.get(
        process.env.REACT_APP_BASE_URL + APIVariables.HCP_PHARMACY_INSTITUTIONS
      );
      const institutionsOptions = (getInstitutionsOptions || []).map(
        ({ id, value }) => ({
          value: id,
          label: value,
        })
      );

      setInstitutionsOptions(institutionsOptions);

      const { data: getWholesalers } = await axios.get(
        process.env.REACT_APP_BASE_URL + APIVariables.ORDERS_WHOLESALERS
      );
      setWholeSalers(getWholesalers);
    }
  }

  const updateParamsIntoAPI_URL = (search, propertyName) => {
    const fullURL = new URL(nextURL);
    const baseUrl = `${fullURL.origin}${fullURL.pathname}`;

    let urlParams = new URLSearchParams(window.location.search);
    const pageNumber = new URLSearchParams(nextURL.split("?")[1]).get("page");

    urlParams.set(propertyName, search);
    urlParams.set("page", pageNumber);

    let url = `${baseUrl}?${urlParams.toString()}`;
    return url;
  };

  const [uniqueTitles, setUniqueTitles] = useState();

  const uniqueTitlesJoin = uniqueTitles?.join(", ");

  const getTitles = (titles) => {
    // Check if the titles are different before updating state
    if (titles !== uniqueTitles) {
      setUniqueTitles(titles);
    }
  };

  async function loadOptionsPharmacy(search) {
    const selectedInstitutionType = registerForm?.institution_type?.value;

    const baseURLCall =
      process.env.REACT_APP_BASE_URL +
      APIVariables.HCP_PHARMACY_INSTITUTIONS +
      "/" +
      selectedInstitutionType +
      "/list" +
      "?pharmacy_name=" +
      (search || "");

    const apiURL = nextURL
      ? updateParamsIntoAPI_URL(search, "pharmacy_name")
      : baseURLCall;

    const getPharmacyOptions = await axios.get(apiURL).catch(() => {
      setNextPage(false);
    });

    if (getPharmacyOptions.status === 200) {
      const responseJSON = getPharmacyOptions.data;

      const finalData = responseJSON.results.map((element) => {
        return {
          value: element.pharmacy_name + " " + element.postal_code,
          valueForBE: element.id,
          label: (
            <div
              dangerouslySetInnerHTML={createMarkup(
                element.pharmacy_name,
                element.postal_code
              )}
            />
          ),
          labelToBeDisplayed: element.pharmacy_name,
          address: element.address,
          delivery_address: element.delivery_address,
          ordering_address: element.ordering_address,
          wholesalers_data: element.wholesalers_data,
        };
      });

      let hasMore;
      if (!!responseJSON.next) {
        const url = responseJSON.next;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const page = urlParams.get("page");
        hasMore = !!page;
      } else {
        hasMore = false;
      }

      if (hasMore) {
        setNextPage(responseJSON.next);
      }

      return {
        options: finalData,
        hasMore: hasMore,
      };
    }
  }

  const loadOptionsHospitals = async (search) => {
    const baseURLCall =
      process.env.REACT_APP_BASE_URL +
      APIVariables.HCP_HOSPITALS +
      "?hospital_name=" +
      (search || "");

    const apiURL = nextURL
      ? updateParamsIntoAPI_URL(search, "hospital_name")
      : baseURLCall;

    const getHospitalOptions = await axios.get(apiURL).catch(() => {
      setNextPage(false);
    });

    if (getHospitalOptions.status === 200) {
      const responseJSON = getHospitalOptions.data;

      const finalData = responseJSON.results.map((element) => {
        return {
          value: element.hospital_name + " " + element.postal_code,
          valueForBE: element.id,
          label: (
            <div
              dangerouslySetInnerHTML={createMarkup(
                element.hospital_name,
                element.postal_code
              )}
            />
          ),
          address: element.address,
          postal_code: element.postal_code,
        };
      });

      let hasMore;
      if (!!responseJSON.next) {
        const url = responseJSON.next;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const page = urlParams.get("page");
        hasMore = !!page;
      } else {
        hasMore = false;
      }

      if (hasMore) {
        setNextPage(responseJSON.next);
      }

      return {
        options: finalData,
        hasMore: hasMore,
      };
    }
  };

  return (
    <React.Fragment>
      {stateRegister === 2 && (
        <React.Fragment>
          <h1 className="select-category-txt">
            {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
              ? "Prescriber Registration Form"
              : "Pharmacist Registration Form"}
          </h1>

          <hr className="gray-light-hr" />

          <div className="group-back">
            <button className="back" onClick={previous}>
              <img src={AllowLeft} alt="Arrow left" />
            </button>
            <div>
              {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                ? "Prescriber Registration Form"
                : "Pharmacist Registration Form"}
            </div>
          </div>
          <div className="wrapper-brackground">
            <h2 className="title-details">
              {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                ? "Prescriber Details"
                : "Pharmacist Details"}
            </h2>
            <div className="container">
              <div className="row">
                <div className="col">
                  <label>
                    {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                      ? "Prescriber First Name*"
                      : "Pharmacist (or appointed deputy) First Name:*"}
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="first_name"
                    maxLength={30}
                    value={registerForm.first_name || ""}
                    className={
                      isSubmitting && !formFields.first_name.isValid
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !formFields.first_name?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
                <div className="col">
                  <label>
                    {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                      ? "Prescriber Last Name*"
                      : "Pharmacist (or appointed deputy) Last Name:*"}
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="last_name"
                    maxLength={30}
                    value={registerForm.last_name || ""}
                    className={
                      isSubmitting && !formFields.last_name.isValid
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !formFields.last_name?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
              </div>
              {typeOfForm.includes(registrationCategory.PRESCRIBER) && (
                <div className="row">
                  <div className="col">
                    <label>Registration Number:*</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="registration_number"
                      maxLength={30}
                      value={registerForm.registration_number || ""}
                      className={
                        isSubmitting && !formFields.registration_number.isValid
                          ? "error"
                          : ""
                      }
                    />
                    <React.Fragment>
                      {validateRegistrationNumber()}
                    </React.Fragment>
                  </div>
                  <div className="col">
                    <label>Job Title:*</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="job_title"
                      maxLength={30}
                      value={registerForm.job_title || ""}
                      className={
                        isSubmitting && !formFields.job_title.isValid
                          ? "error"
                          : ""
                      }
                    />
                    {isSubmitting && !formFields.job_title?.isValid && (
                      <span className="required-text">Required</span>
                    )}
                  </div>
                </div>
              )}
              {typeOfForm === registrationCategory.PHARMACY && (
                <div className="row">
                  <div className="col">
                    <label>GPhC / PSNI Registration Number:*</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="registration_number"
                      maxLength={30}
                      value={registerForm.registration_number || ""}
                      className={
                        isSubmitting && !formFields.registration_number.isValid
                          ? "error"
                          : ""
                      }
                    />
                    <React.Fragment>
                      {validateRegistrationNumber()}
                    </React.Fragment>
                  </div>
                  <div className="col custom-select-register">
                    <label>Institution type:*</label>
                    <Select
                      classNamePrefix="custom-select"
                      components={{
                        DropdownIndicator: DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      value={registerForm.institution_type}
                      onChange={(selectedOption) => {
                        handleChangeSelect(selectedOption, "institution_type");
                      }}
                      options={institutionOptions}
                      placeholder="Select"
                      className={
                        isSubmitting && !formFields.institution_type.isValid
                          ? "error-select"
                          : "select-border"
                      }
                    />
                    {isSubmitting && !formFields.institution_type?.isValid && (
                      <span className="required-text">Required</span>
                    )}
                  </div>
                </div>
              )}
              <div className="row">
                {typeOfForm === registrationCategory.PHARMACY && (
                  <React.Fragment>
                    <div className="col">
                      <label>Phone Number:*</label>
                      <input
                        onChange={handleChange}
                        name="phone_number"
                        value={registerForm.phone_number || ""}
                        maxLength="21" // Max Length for Phone Number is 20 Length but because we have a space in the format phone it is also considered part of the number
                        type="text"
                        className={
                          isSubmitting && !formFields.phone_number.isValid
                            ? "error"
                            : ""
                        }
                      />

                      {isSubmitting && !formFields.phone_number?.isValid ? (
                        <span className="required-text">Required</span>
                      ) : invalidPhoneNumber ? (
                        <span className="required-text">
                          {invalidPhoneNumber.split(",")[0]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col custom-select-register">
                      <label>Institution Name:*</label>
                      <AsyncPaginate
                        key={keyValue} // Use a unique key to reset the loadOptions function
                        debounceTimeout={300}
                        loadOptions={loadOptionsPharmacy}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, "institution_id");
                        }}
                        className={
                          "optimization-dropdown " +
                          (isSubmitting && !formFields.institution_id.isValid
                            ? "error-select"
                            : "select-border")
                        }
                        value={registerForm.institution_id}
                        isDisabled={!registerForm.institution_type}
                      />
                      {isSubmitting && !formFields.institution_id?.isValid && (
                        <span className="required-text">Required</span>
                      )}
                    </div>
                  </React.Fragment>
                )}
                <div className="row">
                  {typeOfForm.includes(registrationCategory.PRESCRIBER) && (
                    <div className="col custom-select-register">
                      <label className="mb-3">Hospital Name:*</label>
                      <AsyncPaginate
                        debounceTimeout={300}
                        loadOptions={loadOptionsHospitals}
                        onChange={(selectedOption) => {
                          handleChangeSelect(selectedOption, "hospital_id");
                        }}
                        className={
                          "optimization-dropdown mb-10-px " +
                          (isSubmitting && !formFields.hospital_id.isValid
                            ? "error-select"
                            : "select-border")
                        }
                        value={registerForm.hospital_id}
                      />
                      {isSubmitting && !formFields.hospital_id?.isValid && (
                        <span className="required-text">Required</span>
                      )}
                    </div>
                  )}

                  {typeOfForm.includes(registrationCategory.PRESCRIBER) && (
                    <div className="col"></div>
                  )}
                </div>
              </div>
              {typeOfForm !== registrationCategory.PHARMACY && (
                <div className="row">
                  <div className="col">
                    <label>Hospital Address:</label>
                    <input
                      type="text"
                      name="hospital_address"
                      value={registerForm?.hospital_id?.address || ""}
                      disabled
                    />
                  </div>
                </div>
              )}
              {typeOfForm === registrationCategory.PHARMACY && (
                <div className="row">
                  <div className="col">
                    <label>Dispensing Pharmacy Address:*</label>
                    <input
                      type="text"
                      name="address1"
                      value={registerForm?.institution_id?.address || ""}
                      disabled
                    />
                  </div>
                </div>
              )}
              {typeOfForm === registrationCategory.PHARMACY && (
                <div className="row">
                  <div className="col">
                    <label>Delivery Address (if different):</label>
                    <input
                      type="text"
                      name="delivery_address"
                      value={registerForm.delivery_address || ""}
                      maxLength="75"
                      onChange={handleChange}
                      placeholder="Only complete if different to the address listed above"
                    />
                  </div>
                </div>
              )}
              {typeOfForm === registrationCategory.PHARMACY && (
                <div className="row">
                  <div className="col">
                    <label>
                      Ordering Address (If different to delivery address):
                    </label>
                    <input
                      type="text"
                      name="ordering_address"
                      value={registerForm.ordering_address || ""}
                      maxLength="75"
                      onChange={handleChange}
                      placeholder="Only complete if different to the address listed above"
                    />
                  </div>
                </div>
              )}
            </div>

            {typeOfForm === registrationCategory.PHARMACY ? (
              <React.Fragment>
                <h2 className="title-details mt-2">Wholesaler Details</h2>
                <div className="row mb-3" style={{ position: "relative" }}>
                  {(wholeSalers || []).map((wholesaler) => (
                    <div className="col-md-6" key={wholesaler.id}>
                      <Wholesaler
                        registerForm={registerForm}
                        wholesaler={wholesaler}
                        handleChangeWholeSaler={handleChangeWholeSaler}
                        formFields={formFields}
                        isSubmitting={isSubmitting}
                        isEditMode={registerForm?.institution_id}
                      />
                    </div>
                  ))}
                  {isSubmitting && !formFields.wholesalers_data?.isValid ? (
                    <span className="required-text wholesaler-error">
                      At least one Wholesaler Detail is required
                    </span>
                  ) : invalidWholeSaler?.length ? (
                    <span className="required-text wholesaler-error">
                      {invalidWholeSaler?.split("-")[1]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <hr />
            <h2 className="title-details mt-2"> Medication*:</h2>
            <p>Please select the medication in which you want to prescribe.</p>

            <StyledMedication>
              {products?.map((medication) => {
                return (
                  <div
                    className="custom-checkbox medication-block"
                    key={medication.product_id}
                    onClick={() => handleCheckboxClick(medication.product_id)}
                  >
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(medication.product_id)}
                      readOnly
                    />
                    <span className="checkmark" />

                    <p className="product-name">{medication.name}</p>
                  </div>
                );
              })}
            </StyledMedication>
            <div className="container-medication">
              <p className="message">
                IMPORTANT: You will only be able to receive PAFs for molecules
                in which you have checked and registered with.
              </p>
              {isSubmitting && !formFields.products?.isValid && (
                <span className="red-label ">
                  At least one Medication is required
                </span>
              )}
            </div>

            <h2 className="title-details mt-2">Account Details</h2>

            <div className="container">
              <div className="row">
                <div className="col">
                  <label>
                    Email Address: (A link will be sent to verify your email
                    address)*
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="email"
                    maxLength={50}
                    value={registerForm.email || ""}
                    className={
                      (isSubmitting && !formFields.email.isValid) ||
                      (isSubmitting &&
                        !formFields.emailValid.isValid &&
                        formFields.email.isValid)
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !formFields.email.isValid && (
                    <span className="required-text">Required</span>
                  )}
                  {isSubmitting &&
                    !formFields.emailValid.isValid &&
                    formFields.email.isValid && (
                      <span className="required-text">Invalid Email</span>
                    )}
                  {invalidEmail?.length && (
                    <span className="required-text">
                      {invalidEmail?.split("-")[1]}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Create Password:*</label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="password1"
                    maxLength={64}
                    value={registerForm.password1 || ""}
                    className={
                      isSubmitting && !formFields.password1.isValid
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !formFields.password1?.isValid ? (
                    <div className="required-text-password">
                      <p>Minimum length of 12 characters</p>
                      <p> Minimum of 1 uppercase letter.</p>
                      <p> At least 1 non-alphanumeric character (~!@#$%)</p>
                      <p>At least 1 number</p>
                    </div>
                  ) : isSubmitting && !formFields.passwordMatch?.isValid ? (
                    <span className="required-text">
                      {errorMessages.PASSWORD_NOT_MATCH}
                    </span>
                  ) : invalidPassword?.length ? (
                    <span className="required-text">
                      {invalidPassword.split("-")[1]}
                    </span>
                  ) : isSubmitting &&
                    formFields.password1?.isValid &&
                    formFields.password2?.isValid ? (
                    <div className="required-text-password green-text">
                      <p>Minimum length of 12 characters</p>
                      <p> Minimum of 1 uppercase letter.</p>
                      <p> At least 1 non-alphanumeric character (~!@#$%)</p>
                      <p>At least 1 number</p>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="col">
                  <label>Confirm Password:*</label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="password2"
                    maxLength={64}
                    value={registerForm.password2 || ""}
                    className={
                      isSubmitting && !formFields.password2.isValid
                        ? "error"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <React.Fragment>
        {stateRegister === 3 && (
          <React.Fragment>
            <h1 className="select-category-txt">
              {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                ? "Prescriber Registration Form"
                : "Pharmacist Registration Form"}
            </h1>

            <hr className="gray-light-hr" />

            {typeOfForm === registrationCategory.PHARMACY && (
              <div className="confirm">
                By registering {registerForm.institution_id.labelToBeDisplayed}{" "}
                to order and dispense {uniqueTitlesJoin}, I agree to implement
                and ensure compliance with the risk minimisation measures
                associated with the Pregnancy Prevention Programme (PPP) for{" "}
                {uniqueTitlesJoin} and adhere to the following requirements:
              </div>
            )}

            {typeOfForm.includes(registrationCategory.PRESCRIBER) && (
              <h2 className="title-details orient">Prescriber Confirmation</h2>
            )}

            {typeOfForm === registrationCategory.PHARMACY && (
              <TermsAndConditionsPharmacy
                getTitles={getTitles}
                uniqueTitlesJoin={uniqueTitlesJoin}
                products={products}
                getBrochure={getBrochure}
                handleCheckboxClick={handleCheckboxClick}
                handleCheckboxes={handleCheckboxes}
                selectedProducts={selectedProducts}
                productConfirmations={productConfirmations}
                checkboxesMedications={checkboxesMedications}
              />
            )}

            {typeOfForm.includes(registrationCategory.PRESCRIBER) && (
              <TermsAndConditionsPrescriber
                products={products}
                selectedProducts={selectedProducts}
                getBrochure={getBrochure}
                productConfirmations={productConfirmations}
                handleCheckboxClick={handleCheckboxClick}
                handleCheckboxes={handleCheckboxes}
                checkboxesMedications={checkboxesMedications}
              />
            )}

            <div className="container sign">
              <div className="row">
                <div className="col">
                  <label>
                    {typeOfForm === registrationCategory.PRESCRIBER_PRESCRIBER
                      ? "Prescriber Name"
                      : "Pharmacist Name"}
                  </label>
                  <input
                    type="text"
                    className="inputNameDate"
                    name="pharmacistName"
                    value={
                      registerForm.first_name + " " + registerForm.last_name
                    }
                    disabled
                  />
                </div>
                <div className="col">
                  <label>Date:</label>
                  <input
                    className="transparent-background inputNameDate"
                    value={moment().format("DD / MM / YYYY")}
                    disabled
                  />
                  <img src={Calendar} alt="Calendar" className="calendar" />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};
export default RegisterForm;
