import styled from "styled-components";

export default styled.div`
  .checkboxes-block {
    margin: 20px 0px;
  }
  .margin {
    margin-top: 1px;
  }
`;
