import React from "react";
import PropTypes from "prop-types";

const ConfirmIdentity = ({ personalDetails }) => {
  return (
    <React.Fragment>
      <React.Fragment>
        <h1 className="select-category-txt">Please confirm your identity</h1>

        <hr className="border-slate" />

        <div className="min-height">
          <div className="dove-gray font-size-16 m-top-3 m-bot-3">
            Please ensure that the following details are correct before
            proceeding. If any details are incorrect, please navigate to your
            profile before clicking begin.​
          </div>

          <div className="wrapper-brackground">
            <h2 className="title-details font-size-18">Personal Details</h2>
            <div className="container">
              <div className="row">
                <div className="col">
                  <label>Prescriber First Name</label>
                  <input
                    type="text"
                    className="disabled"
                    name="first_name"
                    value={personalDetails?.first_name || ""}
                    disabled
                  />
                </div>
                <div className="col">
                  <label>Prescriber Last Name</label>
                  <input
                    type="text"
                    className="disabled"
                    name="last_name"
                    value={personalDetails?.last_name || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Registration Number</label>
                  <input
                    type="text"
                    className="disabled"
                    name="registration_number"
                    value={personalDetails?.registration_number || ""}
                    disabled
                  />
                </div>
                <div className="col">
                  <label>Job Title</label>
                  <input
                    type="text"
                    className="disabled"
                    name="job_title"
                    value={personalDetails?.job_title || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Hospital Name</label>
                  <input
                    type="text"
                    className="disabled"
                    name="hospital_name"
                    value={personalDetails?.hospital_name || ""}
                    disabled
                  />
                </div>
                <div className="col">
                  <label>Address</label>
                  <input
                    type="text"
                    className="disabled"
                    name="hospital_address"
                    value={personalDetails?.hospital_address || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label>Email</label>
                  <input
                    type="text"
                    className="disabled"
                    name="email"
                    value={personalDetails?.email || ""}
                    disabled
                  />
                </div>
                <div className="col" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

ConfirmIdentity.propTypes = {
  personalDetails: PropTypes.object,
};

export default ConfirmIdentity;
