import styled from "styled-components";
import Colors from "../../../shared/colors";
import Selected from "../../../assets/icons/selected.svg";
import Unselected from "../../../assets/icons/unselected.svg";
import SelectedBorder from "../../../assets/icons/borderButton.svg";

const StyledMahButton = styled.button`
  width: 175px;
  height: 56px;
  background: white;
  border: ${(props) => (props?.selected ? "2px" : "1px")} solid #00567a;
  box-shadow: ${(props) =>
    props?.selected ? " 0px 0px 25px rgba(0, 86, 122, 1)" : "0px"};
  border-radius: 25px;
  color: ${Colors.doveGray};
  font-size: 18px;
  font-weight: 700;
  margin: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${Colors.alabaster};
  }

  .mah-check {
    width: 20px;
  }

  span {
    min-width: 120px;
  }

  .selected-border {
    background-image: url(${SelectedBorder});
    height: 48px;
    width: 167px;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 10;
  }
`;

function MahButton(props) {
  return (
    <StyledMahButton {...props}>
      <div className={props?.selected ? "selected-border" : ""} />
      {props?.selected ? (
        <img src={Selected} alt="Selected MAH" className="mah-check" />
      ) : (
        <img src={Unselected} alt="Unselected MAH" className="mah-check" />
      )}
      <span>{props?.text}</span>
    </StyledMahButton>
  );
}

export default MahButton;
