import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { App } from "../../../../routes/Constants";

import Line from "../../../../assets/icons/line.svg";
import ReferPatientImageGif from "../../../../assets/images/refer-a-patient.gif";
import ReferPatientImagePng from "../../../../assets/images/refer-a-patient.png";

export const ReferAPatient = () => {
  const navigate = useNavigate();
  const [gifPaused, setGifPaused] = useState(false);

  const handleHover = () => {
    setGifPaused(true);
    setTimeout(() => {
      setGifPaused(false);
    }, 2000);
  };

  const handleLeave = () => {
    setGifPaused(false);
  };

  return (
    <div className="col gif-button">
      <img src={Line} alt="White Line" />
      <div className="main-text font-size-30">Refer a Patient</div>
      <div className="second-text font-size-16">For new patients</div>
      <button
        className="primary-button font-size-16 generate-paf-btn "
        onClick={() => {
          navigate(App.PATIENT_INITIATION);
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        Generate a PAF
      </button>
      <img
        src={gifPaused ? ReferPatientImageGif : ReferPatientImagePng}
        alt="Refer a Patient"
        className="card-image gif-on-card"
      />
    </div>
  );
};
