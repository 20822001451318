import React from "react";
import SyledCheckboxMedication from "./SyledCheckboxMedication";

const TermsAndConditionsPrescriber = ({
  getBrochure,
  products,
  selectedProducts,
  productConfirmations,
  handleCheckboxes,
  checkboxesMedications,
}) => {
  function compareArrayWithObjects(array, objects) {
    const matchingParameters = [];

    for (let i = 0; i < array.length; i++) {
      const currentNumber = array[i];

      for (let j = 0; j < objects.length; j++) {
        const currentObject = objects[j];

        if (currentNumber === currentObject.product_id) {
          matchingParameters.push(currentObject.name);
        }
      }
    }

    // Return the array of matching parameters
    return matchingParameters;
  }

  // Usage
  const result = compareArrayWithObjects(selectedProducts, products);

  const keysandvalues = productConfirmations?.reduce(
    (acc, innerArray, index) => {
      return acc.concat(
        innerArray?.map((element) => {
          return {
            title: result[index],
            id: `${index}-${element.confirmation_id}`,
            val: element.confirmation_name,
            brochure_name: element.brochure_name,
            brochure_id: element.brochure_id,
          };
        })
      );
    },
    []
  );

  const uniqueTitles = [
    ...new Set(keysandvalues.map((element) => element.title)),
  ];

  const handleGetBrochure = (event, brochureId) => {
    event.stopPropagation();
    event.preventDefault();

    getBrochure(brochureId);
  };

  return (
    <React.Fragment>
      <div className="prescriber-confirmation">
        <div>
          <SyledCheckboxMedication>
            {uniqueTitles.map((title, index) => (
              <div key={index}>
                <h3 className="title-details orient">
                  Prescriber {title} Confirmation
                </h3>
                <p>When prescribing {title}, I accept responsability to:</p>
                {keysandvalues
                  .filter((element) => element.title === title)
                  ?.map((element, index) => (
                    <div
                      onClick={() => handleCheckboxes(element.id)}
                      className="custom-checkbox checkboxes-block"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        checked={checkboxesMedications.includes(element.id)}
                        readOnly
                      />
                      <span className="checkmark margin" />
                      <p className="text-for-checkboxes">
                        {index + 1}.{" "}
                        {element.val?.split("\r\n")?.map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line.includes("<brochure>") ? (
                              <>
                                {line
                                  .split("<brochure>")
                                  .map((textPart, partIndex) => (
                                    <React.Fragment key={partIndex}>
                                      {partIndex > 0 && (
                                        <span
                                          className="brochure-link"
                                          onClick={(e) =>
                                            handleGetBrochure(e, element)
                                          }
                                        >
                                          {element.brochure_name}
                                        </span>
                                      )}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: textPart,
                                        }}
                                      />
                                    </React.Fragment>
                                  ))}
                              </>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{ __html: line }}
                              />
                            )}
                            {lineIndex !==
                              element?.val?.split("\r\n").length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  ))}
              </div>
            ))}
          </SyledCheckboxMedication>
        </div>
      </div>
      <div className="confirm">
        By clicking ‘Register’, I confirm that I am a prescriber appropriately
        experienced in managing immunomodulatory drugs and that I have read and
        understand the{" "}
        <span>
          <u> Healthcare Professional Brochure</u>
        </span>{" "}
        and agree to comply with all the elements of the Pregnancy Prevention
        Programme described within it.
      </div>
    </React.Fragment>
  );
};
export default TermsAndConditionsPrescriber;
