import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as Warning } from "../../../assets/icons/error.svg";
import ErrorText from "../../common/ErrorText/ErrorText";
import StyledModal from "../../common/Modal/StyledModal";
import { typeOfPAF } from "../../../constants/typeOfPAF";

import { errorMessages as errorMessagesConstants } from "../../../constants/errorMessages.constants";

const NonComplaintPAF = ({
  openModalNonComplaint,
  closeNonComplaint,
  submitNonComplaint,
  registerPatient,
  handleChangeValidPassword,
  errorMessageFromServer,
  hcp,
  displayWeeks,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  let errorMessages = [];

  if (registerPatient.continueOffLabel) {
    errorMessages.push("Continue with off-label use");
  }
  if (registerPatient.continueIndicationIncomplete) {
    errorMessages.push(errorMessagesConstants.INDICATION_INCOMPLETE_TITLE);
  }
  if (
    registerPatient.continueWithoutConfirmation &&
    registerPatient.type !== typeOfPAF.WOMANOFNCP
  ) {
    errorMessages.push("Patient counselling not confirmed");
  }
  if (
    registerPatient.continueWithPregnancyTestDateOutdated &&
    registerPatient.type === typeOfPAF.WOMANOFCP
  ) {
    errorMessages.push(
      "Pregnancy test date was outside the permitted date range"
    );
  }
  if (
    registerPatient.continueWithPregnancyTestDateIncomplete &&
    registerPatient.type === typeOfPAF.WOMANOFCP
  ) {
    errorMessages.push(
      errorMessagesConstants.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE
    );
  }
  if (registerPatient.continueWithDateOfBirthUnderEighteen) {
    errorMessages.push(
      "You selected 'Continue with off-label use for Patient Date Of Birth'"
    );
  }
  if (
    registerPatient.continueMaximumNumberOfCyclesExceeded &&
    registerPatient.type === typeOfPAF.WOMANOFCP
  ) {
    errorMessages.push("Maximum number of cycles exceeded for this patient");
  }

  if (
    displayWeeks === "weeks" &&
    registerPatient.continueMaximumNumberOfWeeksExceeded
  ) {
  }

  if (
    displayWeeks === "weeks_per_cycle" &&
    registerPatient.continueMaximumNumberOfWeeksExceededAgain
  ) {
    errorMessages.push("Maximum number of weeks exceeded for this patient");
  }

  if (
    (displayWeeks === "weeks" || displayWeeks === "weeks_per_cycle") &&
    registerPatient.continueMaximumNumberOfCapsulesExceeded
  ) {
    errorMessages.push("Capsules outside permitted Quantity");
  }

  if (registerPatient.continueCapsulePerCycleIncomplete) {
    errorMessages.push(errorMessagesConstants.CAPSULES_PER_CYCLE_INCOMPLETE);
  }
  if (
    displayWeeks === "cycle" &&
    registerPatient.continueNumberOfCyclesIncomplete
  ) {
    errorMessages.push(errorMessagesConstants.NUMBER_OF_CYCLES_INCOMPLETE);
  }
  if (
    registerPatient.continueNumberOfWeeksIncomplete &&
    displayWeeks === "weeks"
  ) {
    errorMessages.push(errorMessagesConstants.NUMBER_OF_WEEKS_INCOMPLETE);
  }
  if (
    displayWeeks === "weeks" &&
    registerPatient.continueCapsulePerWeekIncomplete
  ) {
    errorMessages.push("Capsule per Week Incomplete");
  }
  if (
    displayWeeks === "weeks_per_cycle" &&
    (registerPatient.continueNumberOfCyclesIncomplete ||
      registerPatient.continueNumberOfWeeksIncomplete)
  ) {
    errorMessages.push("Number of supply weeks Incomplete");
  }

  return (
    <StyledModal>
      <Modal
        isOpen={openModalNonComplaint.show}
        onRequestClose={closeNonComplaint}
        style={customStyles}
        contentLabel="Non-Complaint Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="main-text-error">Non-Compliant PAF</div>
          {openModalNonComplaint.step === 1 ? (
            <React.Fragment>
              <div>
                This PAF is non-compliant. Please amend the following details.{" "}
              </div>
              <div className="mt-4 mb-1">
                The following issues were identified:
              </div>
              <ul className="custom-ul">
                {(errorMessages || []).map((error, index) => (
                  <li className="bold" key={index}>
                    - {error}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>
                This PAF contains incomplete/non-compliant data. If you wish to
                proceed outside the terms of the {hcp?.name} PPP based on your
                clinical understanding of your patient's needs please re-enter
                your password to document this decision.
              </p>
              <input
                className="input-rejection"
                type="password"
                onChange={(e) => handleChangeValidPassword(e)}
                name="validate_password"
                placeholder="Password"
              />

              {(errorMessageFromServer || []).map((item, index) => (
                <ErrorText key={index} item={item} />
              ))}
            </React.Fragment>
          )}
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeNonComplaint}>
            Cancel
          </button>
          <button className="red-button" onClick={submitNonComplaint}>
            {openModalNonComplaint.step === 1 ? "Continue" : "Submit"}
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

NonComplaintPAF.propTypes = {
  openModalNonComplaint: PropTypes.object,
  closeNonComplaint: PropTypes.func,
  submitNonComplaint: PropTypes.func,
  registerPatient: PropTypes.object,
  handleChangeValidPassword: PropTypes.func,
  errorMessageFromServer: PropTypes.array,
};

export default NonComplaintPAF;
