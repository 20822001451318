import axios from "axios";
import useRoles from "../hooks/useRoles";

export default (() => {
  const { storageData } = useRoles();
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      common: {
        Authorization: storageData?.token,
      },
    },
  });
})();
