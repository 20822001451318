import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import HBIcon from "../../../assets/images/hblogo2.svg";
import { App, Auth } from "../../../routes/Constants";
import StyledFooter from "./StyledFooter";
import { SupportText } from "./SupportText";

const Footer = () => {
  const location = useLocation();
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [isDashboardPage, setIsDashboardPage] = useState(false);

  useEffect(() => {
    const newIsLoadingValue =
      window.location.pathname.includes(Auth.LOGIN) ||
      window.location.pathname.includes(Auth.RESET_PASSWORD_VALIDATE_DEFAULT) ||
      window.location.pathname.includes(Auth.RESET_PASSWORD) ||
      window.location.pathname.includes(Auth.VALIDATE_DEFAULT);

    if (newIsLoadingValue !== isLoginPage) setIsLoginPage(newIsLoadingValue);

    const newIsDashboardValue = window.location.pathname === App.DASHBOARD;

    if (newIsDashboardValue !== isDashboardPage)
      setIsDashboardPage(newIsDashboardValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <StyledFooter>
      <div
        className={
          isLoginPage
            ? "main-wrapper-footer-login"
            : "main-wrapper-footer-general"
        }
      >
        <div className="wrapper-footer">
          <div className="center">
            <div className="wrapper-footer-loggedin">
              {isLoginPage ? (
                <SupportText
                  className="logo-container "
                  className2="support-login"
                  className3="logos"
                  showLogos={true}
                />
              ) : (
                <div className="logo-container">
                  {isDashboardPage ? (
                    <React.Fragment>
                      <span>Powered by</span>
                      <img src={HBIcon} alt="HB Logo" />
                    </React.Fragment>
                  ) : (
                    <div />
                  )}

                  <SupportText />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};
export default Footer;
