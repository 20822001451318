import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .layout {
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .patients {
    max-width: 1300px;
    margin: 0 auto;
    @media screen and (max-width: 910px) {
      width: 100%;
      padding: 10px;
    }
    .col {
      display: table;
      background: ${Colors.porcelain};
      margin: 5px;
      padding: 27px 34px;
      min-height: 155px;
      cursor: pointer;
      height: 186px;
      width: 25%;
      border-radius: 10px;

      @media screen and (max-width: 1045px) {
        height: 223px;
      }
    }

    .center-content {
      display: flex;
      flex-flow: wrap column;
    }

    .main-text {
      color: ${Colors.orient};
      height: 20px;
      margin-bottom: 5px;
    }

    .wrapper-number {
      display: flex;
      justify-content: space-between;
      .number {
        color: ${Colors.orient};
      }
    }

    .details {
      color: transparent;
    }
  }

  .referTo {
    margin-top: 150px;
    margin-bottom: 75px;

    .col {
      margin: 25px;
      padding: 68px 62px;
      position: relative;
      z-index: 1;
      background: ${Colors.porcelain};
      box-shadow: 0px 28px 80px rgba(0, 0, 0, 0.07),
        0px 11.6977px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 6.25417px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 3.50603px 10.0172px rgba(0, 0, 0, 0.035),
        0px 1.86203px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 0.774832px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 10px;
    }

    .bullet-1 {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: -1;
    }

    .bullet-2 {
      position: absolute;
      top: 80px;
      right: 10%;
      z-index: -1;
    }

    .bullet-3 {
      position: absolute;
      top: 30px;
      right: 25%;
      z-index: -1;
    }

    .main-text {
      color: ${Colors.orient};
      height: 60px;
    }

    .second-text {
      color: ${Colors.orient};
      margin-top: 5px;
      margin-bottom: 30px;
      min-height: 38px;
      width: 80%;
      height: 57px;
    }

    img {
      margin-bottom: 15px;
    }

    .card-image {
      position: absolute;
      bottom: 0;
      right: 25px;
      margin-bottom: 0;
      width: 31%;
    }

    .gif-on-card {
      width: 262px;
      height: 285px;
      opacity: 60%;
      z-index: -1;
      -webkit-transform: rotate(7deg);
      -ms-transform: rotate(7deg);
      transform: rotate(7deg);
    }

    .view-patients {
      right: -20px;
      top: 80px;
      width: 50%;
      opacity: 60%;
      height: 300px;
    }

    .view-patients-pharmacy {
      right: 9%;
      width: 30%;
      height: 110%;
      opacity: 60%;
    }
  }

  .layout-cards {
    max-width: 1200px;
    margin: 0 auto;
  }

  .patients .col-sm-6-custom-full.hovered {
    transition: all 200ms ease-in-out;
    background: ${Colors.orient};

    .main-text,
    .wrapper-number .number,
    .details {
      transition: all 200ms ease-in-out;
      color: ${Colors.white};
    }
  }

  .generate-paf-btn {
    min-width: 233px;
    padding-left: 40px;
    text-align: left;

    @media screen and (max-width: 1045px) {
      min-width: 160px;
      padding-left: initial;
      text-align: center;
    }
  }
`;
