import styled from "styled-components";

import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .layout {
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .main-title,
  .title {
    color: ${Colors.orient};
  }

  .wrapper-contact {
    display: grid;

    span {
      margin-left: 15px;
    }

    a {
      color: ${Colors.doveGray};
      text-decoration: none;
    }
  }

  .wrapper-contact-report {
    span {
      margin-left: 15px;
    }

    a {
      color: ${Colors.doveGray};
      text-decoration: none;
    }
  }

  .wrapper-pharmacy {
    width: 90%;
    margin: 0 auto;
  }

  li {
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
  }

  .container {
    padding-top: 55px;
  }

  .wrapper-buttons {
    .hover-class,
    .hover-class span {
      text-decoration: initial;
    }
  }
  .view-card-container {
    margin-top: 50px;
  }
  .title-brochure {
    width: 100%;
    margin: 30px 0px;
  }
`;
