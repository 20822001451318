import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as Error } from "../../../../assets/icons/error.svg";
import ErrorText from "../../../common/ErrorText/ErrorText";

import StyledModal from "../../../common/Modal/StyledModal";

const RemoveHomecareModal = ({
  modalIsOpen,
  closeModal,
  removeHomecare,
  errorMessage,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "32px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Error Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Error />
          </div>
          <div className="main-text-error">Are you sure?</div>
          <div className="second-text">
            By confirming the removal of this Homecare provider, your ability to
            send PAFs to them will be revoked.
          </div>
          {(errorMessage || []).map((item, index) => (
            <ErrorText key={index} item={item} />
          ))}
        </div>

        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeModal}>
            Cancel
          </button>
          <button className="red-button" onClick={removeHomecare}>
            Remove
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

RemoveHomecareModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  removeHomecare: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
export default RemoveHomecareModal;
