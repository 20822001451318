import styled from "styled-components";
import Colors from "../../../../shared/colors";

export default styled.div`
  width: 100%;
  .container {
    text-align: left;
    .input-and-text {
      margin: 15px 0px;
    }
  }
  .title-details {
    margin-bottom: 15px;
  }
  .sub-title {
    margin-bottom: 30px;
  }
  .wrapper-register-button {
    display: flex;
    justify-content: end;
  }
  .initiation {
    .row {
      width: 100%;
      margin: 0px auto;
      .col {
        display: flex;
        flex-direction: column;

        .prescriber-input {
          border: 0px;
          border-bottom: 1px solid ${Colors.gray};
          background-color: ${Colors.white};
          margin-bottom: 15px;
          color: ${Colors.doveGray};
          font-size: 18px;
          line-height: 22px;
        }
        .date-input {
          border: 0px;
          border-bottom: 1px solid ${Colors.gray};
          background-color: ${Colors.white};
          margin-bottom: 15px;
          color: ${Colors.doveGray};
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    .previous {
      cursor: pointer;
      color: ${Colors.endeavour};
    }
    .close-icon {
      cursor: pointer;
    }
  }
  .wrapper-buttons-modal {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
  }
  .error-text {
    color: ${Colors.coralRed};
    height: 50px;
  }
  .error-list {
    ul {
      margin: 0px;
    }
    li {
      color: ${Colors.coralRed};
    }
  }

  .display-flex {
    display: flex;
  }
`;
