import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StyledAuthentification from "./StyledAuthentification";

import { ReactComponent as CheckRegister } from "../../assets/icons/checkRegister.svg";
import { ReactComponent as Error } from "../../assets/icons/error.svg";

import { Auth } from "../../routes/Constants";

import APIVariables from "../../services/api-variables";
import { errorMessages } from "../../constants/errorMessages.constants";
import Axios from "../../services/Axios";

const Validate = () => {
  const navigate = useNavigate();

  const [displayModal, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState({
    title: "",
    description: "",
    isValid: true,
  });

  useEffect(() => {
    checkActivateAccount();
    // eslint-disable-next-line
  }, []);

  async function checkActivateAccount() {
    const uid = window.location.pathname.split("/")[2];
    const token = window.location.pathname.split("/")[3];

    const responseRegister = await Axios.post(APIVariables.USERS_ACTIVATE, {
      uid,
      token,
    }).catch((error) => {
      if (error?.response?.data?.uid?.includes("Activation link is invalid!")) {
        setDisplayMessage(true);
        setMessage({
          title: "Confirmation Link is Invalid",
          description:
            "Please click/copy the link again to confirm your email.",
          isValid: false,
        });
      } else {
        setDisplayMessage(true);
        setMessage({
          title: error?.response?.statusText,
          description: errorMessages.SOMETHING_WENT_WRONG,
          isValid: false,
        });
      }
    });
    if (responseRegister?.status === 200) {
      setDisplayMessage(true);
      setMessage({
        title: "Success",
        description: responseRegister?.data?.message,
        isValid: true,
      });
    }
  }

  function logIn() {
    navigate(Auth.LOGIN);
  }

  return (
    <StyledAuthentification>
      {displayModal && (
        <div className="wrapper-login" style={{ minHeight: 450 }}>
          <div className="wrapper-modal">
            <div className="image">
              {message.isValid ? <CheckRegister /> : <Error />}
            </div>
            <div className={message.isValid ? "main-text" : "main-text-error"}>
              {message.title}
            </div>
            <div className="second-text">{message.description}</div>
          </div>
          {message.isValid && (
            <div className="wrapper-buttons">
              <button className="green-button" onClick={logIn}>
                Login
              </button>
            </div>
          )}
        </div>
      )}
    </StyledAuthentification>
  );
};
export default Validate;
