import React from "react";
import "./toggleSwitch.scss";

const ToggleSwitch = ({ id, name, checked, onChange, small, disabled }) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(id, !checked);
  }

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(id, e.target.checked)}
        disabled={disabled}
      />
      <label
        className={
          `toggle-switch-label ` + (disabled ? "cursor-not-allowed" : "")
        }
        tabIndex={disabled ? -1 : 1}
        onKeyDown={(e) => handleKeyPress(e)}
        htmlFor={id}
      >
        <span
          className={
            disabled
              ? "toggle-switch-inner toggle-switch-disabled"
              : "toggle-switch-inner"
          }
          tabIndex={-1}
        />
        <span
          className={
            checked
              ? "toggle-switch-switch toggle-switch-switch-after"
              : "toggle-switch-switch"
          }
          tabIndex={-1}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
