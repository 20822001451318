import React from "react";
import Modal from "react-modal";
import CloseIcon from "../../../assets/icons/close.svg";
import StyledModal from "../../common/Modal/StyledModal";
import { ReactComponent as Warning } from "../../../assets/icons/warning-red.svg";

const ContinueNonCompliantPAF = ({
  modalNonCompliantPAFWarningisOpen,
  closemodalNonCompliantPAFWarning,
  modalNonCompliantPAFWarning,
  continueButtonNonComplainPAFWarning,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={modalNonCompliantPAFWarningisOpen}
        onRequestClose={closemodalNonCompliantPAFWarning}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button
          onClick={closemodalNonCompliantPAFWarning}
          className="close-icon-button "
        >
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="wrapper-modal">
          {modalNonCompliantPAFWarning.svg === true && (
            <div>
              <Warning />
            </div>
          )}
          <div className="main-text-error">
            {modalNonCompliantPAFWarning.title}
          </div>
          {typeof modalNonCompliantPAFWarning.description === "object" ? (
            <div className="second-text">
              {modalNonCompliantPAFWarning.description}
            </div>
          ) : (
            <div
              className="second-text"
              dangerouslySetInnerHTML={{
                __html: modalNonCompliantPAFWarning.description,
              }}
            />
          )}
        </div>
        <div className="wrapper-buttons">
          <button
            className="cancel mr-6"
            onClick={closemodalNonCompliantPAFWarning}
          >
            Back
          </button>
          <button
            className="red-button"
            onClick={() =>
              continueButtonNonComplainPAFWarning(
                modalNonCompliantPAFWarning.title
              )
            }
          >
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};
export default ContinueNonCompliantPAF;
