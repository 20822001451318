import React from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/downloadBrochure.svg";
import { handleCatch } from "../../../shared/helpers";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";

const ViewBrochures = ({ item, index }) => {
  const getBrochure = async () => {
    await Axios.get(APIVariables.PATIENTS_BROCHURES_DOWNLOAD + item.id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          return data?.report;
        }
        return null;
      })
      .then(async (brochureString) => {
        const base64 = await fetch(
          `data:application/pdf;base64,${brochureString}`
        );
        return base64.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = `${item.name}.pdf`;
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  return (
    <div className="child" key={index}>
      <div>
        <img src={item.image} alt="Brochure" />
        <div className="wrapper-buttons">
          <button
            className="bg-transparent border-0 hover-class"
            onClick={() => getBrochure()}
            onMouseDown={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (event.button === 1 || event.button === 4) {
                getBrochure();
              }
            }}
            download
          >
            <span>Download</span>
            <ArrowDown />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewBrochures;
