import React, { useEffect, useState } from "react";

import StyledPatientMaterials from "./StyledPatientMaterials";

import ViewCard from "../../common/ViewCard/ViewCard";
import APIVariables from "../../../services/api-variables";
import { handleCatch, mapArraysWithTitle } from "../../../shared/helpers";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";

const PatientMaterials = () => {
  const [patientsBrochures, setPatientsBrochures] = useState([]);

  const { isPrescriber } = useRoles();

  useEffect(() => {
    getBrochures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBrochures = async () => {
    const res = await Axios.get(APIVariables.PATIENTS_BROCHURES).catch(
      (error) => {
        handleCatch(error);
      }
    );

    setPatientsBrochures(res.data);
  };

  const mappedData = mapArraysWithTitle(patientsBrochures);

  return (
    <StyledPatientMaterials>
      <div className="wrapper-patient-materials">
        <div className="layout">
          <h1 className="h1">Healthcare Professional & Patient Materials</h1>
          <div className="text">
            <div className="container">
              <div
                className={
                  "cardsContainer " +
                  (isPrescriber ? "" : "pharmacy-cards-container")
                }
              >
                {(mappedData || []).map((item, index) => (
                  <div key={index} className="view-card-container">
                    <div className="title-brochure h1">
                      {!!item?.items?.length && item.title}
                    </div>

                    <ViewCard
                      key={index}
                      brochure={item}
                      displayImage={true}
                      displayTitle={false}
                      isReportanAdverseEventPage={false}
                    />
                  </div>
                ))}
              </div>
              <div className="font-size-20 mt-4">
                Information regarding{" "}
                {(mappedData || []).map((item) => (
                  <span className="font-size-20">
                    {!!item?.items?.length && item.title},
                  </span>
                ))}
                that is being dispensed by MA holders can be found via the
                electonic medicines compendium (EMC), by clicking on the link:{" "}
                <a
                  href="https://www.medicines.org.uk"
                  target="_blank"
                  rel="noreferrer"
                  className="url-text"
                >
                  https://www.medicines.org.uk
                </a>{" "}
                and typing the molecule name in the search bar provided.
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledPatientMaterials>
  );
};
export default PatientMaterials;
