import React from "react";
import StyledReportEvent from "../ReportEvent/StyledReportEvent";
import { ReactComponent as Phone } from "../../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { useNavigate } from "react-router-dom";
import { App } from "../../../routes/Constants";
import infoConstants from "../../../shared/infoConstants";
import infoContactUs from "../../../shared/infoContactUs";

const ContactUs = () => {
  const navigate = useNavigate();

  const redirect = (URL) => {
    navigate(URL);
  };

  return (
    <StyledReportEvent>
      <div className="layout">
        <div className="font-size-30 orient">Contact Us</div>
        <div className="mt-5 mb-2">
          <div className="font-size-16 mt-5 justify doveGray-color">
            Adverse events can also be reported online via the Yellow Card
            website{" "}
            <a
              href="https://yellowcard.mhra.gov.uk/"
              className="font-size-16 doveGray-color"
              target="_blank"
              rel="noreferrer"
            >
              https://yellowcard.mhra.gov.uk/
            </a>{" "}
            or search for MHRA Yellow Card in the Google Play or Apple App
            Store. Alternatively, prepaid Yellow Cards for reporting are
            available:
          </div>
          <div className="font-size-16 mt-3 justify doveGray-color ml-10">
            <li>
              by writing to FREEPOST YELLOW CARD (no other address details
              necessary);
            </li>
            <li>by emailing yellowcard@mhra.gov.uk;</li>
            <li>
              by telephoning the Commission on Human Medicines (CHM) free phone
              line: 0800-731-6789;
            </li>
            <li>
              or by downloading and printing a form from the Yellow Card section
              of the MHRA website.
            </li>
          </div>
        </div>

        <div className="mt-5 mb-2">
          <div className="wrapper-contact mt-3">
            <div>
              <div className="font-size-18 orient">
                Prescription Authorisation Form Issues
              </div>
              <div className="font-size-16 mt-2 mb-4">
                To request assistance with prescription authorisation forms,
                please contact:
              </div>
              <Phone />{" "}
              <span className="font-size-16">
                Tel:{" "}
                <a
                  className="font-size-16"
                  href={`tel:${infoConstants.nhsPhoneNumber}`}
                >
                  {infoConstants.nhsPhoneNumber}
                </a>
              </span>
            </div>
            <div>
              <Email />{" "}
              <span className="font-size-16">
                Email:{" "}
                <a
                  className="font-size-16"
                  href={`mailto: ${infoConstants.nhsEmail}`}
                >
                  {infoConstants.nhsEmail}
                </a>
              </span>
            </div>
            <p className="font-size-16 mt-2" style={{ marginLeft: "35px" }}>
              Mon - Fri 09:00 - 17:00
            </p>
          </div>
          <div className="wrapper-contact mt-3">
            <div>
              <div className="font-size-18 orient">For Technical Issues:</div>
              <div className="font-size-16 mt-2 mb-4">
                To request assistance with any technical issues on the
                Pathfinder RMP, please contact:
              </div>
              <Phone />{" "}
              <span className="font-size-16">
                Tel:{" "}
                <a
                  className="font-size-16"
                  href={`tel:${infoContactUs.nhsPhoneNumber}`}
                >
                  {infoContactUs.nhsPhoneNumber}
                </a>
              </span>
            </div>
            <div>
              <Email />{" "}
              <span className="font-size-16">
                Email:{" "}
                <a
                  className="font-size-16"
                  href={`mailto: ${infoContactUs.nhsEmail}`}
                >
                  {infoContactUs.nhsEmail}
                </a>
              </span>
            </div>
            <p className="font-size-16 mt-2" style={{ marginLeft: "35px" }}>
              Mon - Fri 09:00 - 17:00
            </p>
          </div>
          <div className="mt-5 mb-2">
            <div className="font-size-18 orient">
              Medical Information and reporting Adverse Events and Pregnancies:
            </div>
            <div className="font-size-16 mt-1 justify">
              Adverse Events (and cases of suspected or confirmed pregnancy or
              foetal exposure) should be reported. Adverse Event Report forms
              and Pregnancy Reporting forms can be found by following the link
              below.
            </div>
          </div>
          <div className="mt-5 mb-2">
            <button
              className="primary-button"
              onClick={() => redirect(App.REPORT_EVENT)}
            >
              Click here to access Adverse Event/Pregnancy report forms
            </button>
          </div>
        </div>
      </div>
    </StyledReportEvent>
  );
};

export default ContactUs;
