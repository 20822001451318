export const registrationCategory = {
  PRESCRIBER_PRESCRIBER: "PRESCRIBER_PRESCRIBER",
  PRESCRIBER: "PRESCRIBER",
  PHARMACY: "PHARMACY",
  RE_REGISTER: "RE_REGISTER",
  CUSTOMER_SOLUTION: "CUSTOMER_SOLUTION",
  CONNECTED_NURSE: "CONNECTED_NURSE",
  CONNECTED_HOMECARE: "CONNECTED_HOMECARE",
  CONNECTED_OUTPATIENT: "CONNECTED_OUTPATIENT_PHARMACY",
};
