import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { App } from "../../../routes/Constants";

import SearchGray from "../../../assets/icons/searchGray.svg";
import Info from "../../../assets/icons/info.svg";
import { Virtuoso } from "react-virtuoso";
import StyledNotifications from "../Notifications/StyledNotifications";
import { ReactComponent as DownloadFile } from "../../../assets/icons/downloadBrochure.svg";
import { ReactComponent as FilterPoligon } from "../../../assets/icons/filterPoligon.svg";
import PatientsDetails from "../../common/PatientDetails/PatientDetails";
import APIVariables from "../../../services/api-variables";
import CalendarComponent from "../../common/Calendar/Calendar";
import Loader from "../../common/Loader/Loader";
import SearchIconFilter from "../../../assets/icons/searchIconFilter.svg";

import { useLocation } from "react-router-dom";
import { handleCatch } from "../../../shared/helpers";
import Axios from "../../../services/Axios";
import FilterDataViewPatients from "./FilterDataViewPatients";
import CheckboxOption from "./helper/CheckboxOption";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ViewPatients = () => {
  const debounceTime = 500;
  const location = useLocation();
  const [filters, setFilters] = useState(new Map());
  // const [isFetching, setIsFetching] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [isUrlFilterApplied, setIsUrlFilterApplied] = useState(false);
  const [showFilter, setShowFilter] = useState("");
  const nhsNoRef = useRef();
  const prescriberNameRef = useRef();
  const nameRef = useRef();
  const genderRef = useRef();
  const moleculeRef = useRef();
  const statusRef = useRef();
  const capsuleRef = useRef();
  const dobRef = useRef();
  const pafRef = useRef();
  const [items, setItems] = useState([]);

  // const [existMoreItems, setExistMoreItems] = useState(false);
  const existMoreItems = useRef(false);

  const navigate = useNavigate();

  const controller = useRef(new AbortController()); // Scope: cancel the current call if another call is called
  const isFetchingPatientsRef = useRef(true); // Scope: update variable faster than the state for updating calls for the filters Virtuoso

  const [isLoadingTable, setIsLoadingTable] = useState(true);

  function chooseStatusFromLink(type) {
    switch (type) {
      case "submitted": {
        const filtersMap = new Map(filters);
        filtersMap.set("status", [
          {
            id: 0,
            value: "Submitted",
            label: "Status",
          },
          {
            id: 3,
            value: "Expiring",
            label: "Status",
          },
        ]);
        return setFilters(filtersMap);
      }
      case "approved-paf": {
        const filtersMap = new Map(filters);
        filtersMap.set("status", [
          {
            id: 1,
            value: "Approved",
            label: "Status",
          },
          {
            id: 3,
            value: "Expiring",
            label: "Status",
          },
        ]);
        return setFilters(filtersMap);
      }

      case "overdue-paf": {
        return filterStatusFromLinks({ id: 4, value: "Overdue" });
      }
      case "rejected-paf": {
        return filterStatusFromLinks({ id: 5, value: "Rejected" });
      }
      case "dispensed-paf": {
        return filterStatusFromLinks({ id: 6, value: "Dispensed" });
      }
      default: {
        return;
      }
    }
  }

  const filterStatusFromLinks = (status) => {
    onChangeFilters("status", {
      ...status,
      label: "Status",
    });
  };

  const [patientsStatistics, setPatientsStatistics] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const container = React.useRef(null);

  const tableRef = React.useRef(null); // Scope: When apply filter, scroll to the top of the table

  const filterSearchData = (items, property, searchBy) => {
    return (
      items
        .filter((patientFilter) =>
          patientFilter[property]
            ?.toLowerCase()
            ?.includes(searchBy?.toLocaleLowerCase())
        )
        .slice(0, 5) || []
    );
  };

  function handleOutsideClick(event) {
    const { target } = event;
    const refs = [
      nhsNoRef,
      nameRef,
      genderRef,
      moleculeRef,
      statusRef,
      capsuleRef,
      dobRef,
      pafRef,
      prescriberNameRef,
    ];
    let contains = refs.some((ref) => {
      try {
        let svgClass = target?.getAttribute("class");
        if (svgClass === "rs-icon") {
          return true;
        }

        return (
          ref?.current?.contains(target) ||
          target?.className?.includes("wrapper-filter") ||
          target?.className?.includes("rs-calendar") ||
          target?.className?.includes("rs-calendar-header-month-toolbar") ||
          target?.className?.includes("rs-picker-toolbar") ||
          target?.className?.includes("rs-btn")
        );
      } catch (error) {
        return false;
      }
    });
    if (!contains) {
      setShowFilter("");
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const onChangeShowFilter = (type) => {
    if (type === showFilter) return setShowFilter("");
    setShowFilter(type);
    // existMoreItems.current = false;
  };

  /* eslint-disable */
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let isUrlCondition = !isUrlFilterApplied && Boolean(query); ///check if there is url status and filter from url wasn't apply
    const delayDebounce = setTimeout(() => {
      if (isUrlCondition) {
        chooseStatusFromLink(query.get("status"));
        setIsUrlFilterApplied(true);
      } else {
        getPatients(false);
      }
      tableRef?.current?.scrollToIndex({
        index: 0,
      });
    }, debounceTime * Boolean(!isUrlCondition)); /// multiply with bool => if !condition don't wait debounceTime, else wait mls = debounceTime
    return () => {
      clearTimeout(delayDebounce);
    };
  }, [filters, searchValue, isUrlFilterApplied]);

  useEffect(() => {
    getStatistics();

    return () => {
      setPatientsStatistics({});
    };
  }, []);

  const showSearchIcon = () => {
    if ((filters?.get("id") || filters?.get("prescriber_name")) === undefined) {
      return true;
    }

    return false;
  };

  async function getPatients(fetchMore) {
    setIsLoadingTable(true);
    if (isFetchingPatientsRef.current) {
      controller.current.abort();
      controller.current = new AbortController();
    }
    isFetchingPatientsRef.current = true;
    // setIsFetching(true);
    let filterString = !fetchMore ? "?" : "";
    if (searchValue.length > 0) {
      filterString += `search_lookup=${searchValue}`;
    }
    [...filters.keys()].forEach((key) => {
      const currentFilter = filters.get(key);
      currentFilter.forEach((filter, index, array) => {
        if (!Boolean(filter.dataType)) {
          filterString += `&${key}=${filter.id}`;
        }
        if (filter.dataType === "date") {
          filterString += `&${key}_after=${filter.after}`;
          filterString += `&${key}_before=${filter.before}`;
        }
        if (filter.dataType === "string") {
          filterString += `&${key}=${filter.value}`;
        }
      });
    });

    let URLLink = existMoreItems.current;
    let turl = URLLink && new URL(URLLink);
    if (URLLink) {
      if (filters.size === 0 && URLLink?.includes("?")) {
        const params = new URLSearchParams(turl?.search);
        params.delete("id");
        params.delete("patient_initials");
        params.delete("gender");
        params.delete("dob");
        params.delete("indication");
        params.delete("product");
        params.delete("status");
        params.delete("prescriber_name");
        turl = URLLink?.split("?")[0] + "?" + params.toString();
      }
    }

    let link = fetchMore ? turl : APIVariables.PRESCRIPTIONS + filterString;
    const res = await Axios.get(link, {
      signal: controller.current.signal,
    }).catch((error) => {
      setIsLoading(false);

      handleCatch(error);
    });

    if (res?.status === 200) {
      setIsLoading(false);

      if (fetchMore) setItems([...items, ...res.data.results]);
      else setItems(res.data.results);
      setFilterItems(res.data.filter_data);

      const areMoreMoreItems = !!res.data.next;
      if (areMoreMoreItems) {
        existMoreItems.current = res.data.next;
      } else {
        existMoreItems.current = false;
      }
      if (!fetchMore) {
        tableRef?.current?.scrollToIndex({
          index: 0,
        });
      }
    }

    setTimeout(() => {
      isFetchingPatientsRef.current = false;
      setIsLoadingTable(false);
    }, 500);
  }

  const getStatistics = async () => {
    const res = await Axios.get(APIVariables.PATIENT_STATISTICS).catch(
      (error) => {
        handleCatch(error);
      }
    );
    setPatientsStatistics(res.data);
  };

  const redirectToPatient = (formID, patientID) => {
    navigate(
      App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT +
        `${patientID}` +
        "/" +
        `${formID}`
    );
  };

  const exportPDFWithMethod = async (e, patientID) => {
    e.stopPropagation();

    Axios.get(APIVariables.DOWNLOAD_PAF + patientID)
      .then((response) => {
        return response.data.report;
      })
      .then(async function (base64) {
        const base64Pdf = await fetch(`data:application/pdf;base64,${base64}`);
        return base64Pdf.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = `PAF.pdf`;
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const onChangeFilters = (type, obj, isRemoveFromLabel) => {
    setIsLoadingTable(true);
    const filtersMap = new Map(filters);
    const typesArrays = ["string", "date"];
    if (typesArrays.includes(obj.dataType)) {
      if (String(obj.value).length < 1) return deleteFilter(type);
      if (isRemoveFromLabel) return deleteFilter(type);
      filtersMap.set(type, [obj]);
    } else {
      if (filtersMap.has(type)) {
        const currentFilter = filtersMap.get(type);
        const isFilter = currentFilter.find((filter) => filter.id === obj.id);
        if (isFilter) {
          return deleteFilter(type, obj);
        } else {
          filtersMap.set(type, [...currentFilter, obj]);
        }
      } else {
        filtersMap.set(type, [obj]);
      }
    }

    setFilters(filtersMap);
  };

  const deleteFilter = (type, obj) => {
    const filtersMap = new Map(filters);
    if (!obj) {
      filtersMap.delete(type);
      return setFilters(filtersMap);
    }

    const currentFilter = filtersMap.get(type);
    const newArrayFilters = currentFilter.filter(
      (filter) => filter.id !== obj.id
    );
    if (newArrayFilters.length < 1) {
      filtersMap.delete(type);
    } else {
      filtersMap.set(type, newArrayFilters);
    }

    setFilters(filtersMap);
  };
  // const resetFilters = () => {
  //   setFilters(new Map());
  // };

  const checkIsFiltering = (key, id) => {
    if (!filters.has(key)) return false;
    const currentFilter = filters.get(key);
    return currentFilter.find((filter) => filter.id === id);
  };
  const getStringFilter = (key) => {
    if (filters.has(key)) return filters.get(key)[0].value;
    return "";
  };

  const getDateFilter = (key) => {
    if (filters.has(key)) return filters.get(key)[0].date;
    return false;
  };

  return (
    <StyledNotifications>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="layout">
          <PatientsDetails patientsDetails={patientsStatistics} />

          <div className="all-filter-buttons">
            {filters &&
              [...filters.keys()].map((key) => {
                let currentFilter = filters.get(key);
                return currentFilter.map((filter) => {
                  return (
                    <FilterDataViewPatients
                      key={key + filter.value}
                      keyRefer={key}
                      filter={filter}
                      onChangeFilters={onChangeFilters}
                    />
                  );
                });
              })}
          </div>

          <div className="wrapper-infinitescroll">
            <div className="head-wrapper">
              <div className="wrapper-input-search">
                <input
                  type="search"
                  className="input-search"
                  placeholder="Search Patient ID,Prescriber name, Initials or Date of Birth..."
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  value={searchValue}
                />
                <img src={SearchGray} alt="" />
              </div>

              {/* {isPrescriber && (
                <button
                  className="primary-button custom"
                  onClick={(e) => {
                    resetFilters();
                  }}
                >
                  Reset Filters
                </button>
              )} */}
            </div>
            <div className="wrapper-table-infinitescroll">
              <div
                className="table-wrapper view-patients header-view-patients"
                style={{
                  padding: "1.8rem 1rem",
                }}
              >
                <div className="wrapper-nhs-number text-align-center">
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16"
                    onClick={() => {
                      onChangeShowFilter("id");
                    }}
                  >
                    Patient ID <FilterPoligon />
                  </div>

                  {showFilter === "id" && (
                    <div className="wrapper-notifications-modal" ref={nhsNoRef}>
                      <div className="wrapper-search-input">
                        <input
                          className="input-search"
                          id="input-search-nhs-no"
                          type="search"
                          value={getStringFilter("id") || ""}
                          onChange={(e) => {
                            onChangeFilters("id", {
                              value: e.target.value,
                              label: "Patient ID",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find a Patient..."
                        />
                        {showSearchIcon() && (
                          <img
                            src={SearchIconFilter}
                            alt=""
                            className="search"
                          />
                        )}
                      </div>
                      {filterSearchData(items, "id", getStringFilter("id"))
                        .length > 0 &&
                        getStringFilter("id").length > 0 &&
                        showFilter === "id" && (
                          <div className="wrapper-tooltip-patients">
                            {filterSearchData(
                              items,
                              "id",
                              getStringFilter("id")
                            ).map((item, indexPatient) => (
                              <div
                                key={indexPatient}
                                className="element"
                                onClick={() => {
                                  // close show filter
                                  setShowFilter("");
                                  onChangeFilters("id", {
                                    value: item?.id,
                                    label: "Patient ID",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">{item.id}</div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number text-align-center">
                  <div
                    className="wrapper-nhs-number-bold wrapper-filter font-size-16"
                    onClick={() => {
                      onChangeShowFilter("prescriber_name");
                    }}
                  >
                    Prescriber <FilterPoligon />
                  </div>

                  {showFilter === "prescriber_name" && (
                    <div
                      className="wrapper-notifications-modal"
                      ref={prescriberNameRef}
                    >
                      <div className="wrapper-search-input">
                        <input
                          className="input-search"
                          id="input-search-prescriber-name"
                          type="search"
                          value={getStringFilter("prescriber_name") || ""}
                          onChange={(e) => {
                            onChangeFilters("prescriber_name", {
                              value: e.target.value,
                              label: "Prescriber Name",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find a Prescriber..."
                        />
                        {showSearchIcon() && (
                          <img
                            src={SearchIconFilter}
                            alt=""
                            className="search"
                          />
                        )}
                      </div>
                      {filterSearchData(
                        items,
                        "prescriber_name",
                        getStringFilter("prescriber_name")
                      ).length > 0 &&
                        getStringFilter("prescriber_name").length > 0 &&
                        showFilter === "prescriber_name" && (
                          <div className="wrapper-tooltip-patients">
                            {filterSearchData(
                              items,
                              "prescriber_name",
                              getStringFilter("prescriber_name")
                            ).map((item, indexPrescriberName) => (
                              <div
                                key={indexPrescriberName}
                                className="element"
                                onClick={() => {
                                  // close show filter
                                  setShowFilter("");
                                  onChangeFilters("prescriber_name", {
                                    value: item?.prescriber?.full_name,
                                    label: "Prescriber Name",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">
                                  {item?.prescriber?.full_name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number wrapper-filter">
                  <div
                    className="text-align-center wrapper-name-bold wrapper-filter font-size-16"
                    onClick={(e) => {
                      onChangeShowFilter("patient_initials");
                    }}
                  >
                    Initials <FilterPoligon />
                  </div>

                  {showFilter === "patient_initials" && (
                    <div className="wrapper-notifications-modal">
                      <div className="wrapper-search-input" ref={nameRef}>
                        <input
                          className="input-search"
                          id="input-search-name"
                          type="search"
                          value={getStringFilter("patient_initials") || ""}
                          onChange={(e) => {
                            onChangeFilters("patient_initials", {
                              value: e.target.value,
                              label: "Initials",
                              dataType: "string",
                            });
                          }}
                          placeholder="Find a Patient..."
                        />
                        {filters?.patient_initials?.value.length === 0 && (
                          <img src={SearchGray} alt="" className="search" />
                        )}
                      </div>
                      {filterSearchData(
                        items,
                        "patient_initials",
                        getStringFilter("patient_initials")
                      ).length > 0 &&
                        getStringFilter("patient_initials").length > 0 &&
                        showFilter === "patient_initials" && (
                          <div className="wrapper-tooltip-patients">
                            {filterSearchData(
                              items,
                              "patient_initials",
                              getStringFilter("patient_initials")
                            ).map((item, indexPatient) => (
                              <div
                                key={indexPatient}
                                className="element"
                                onClick={(e) => {
                                  ///this stop close filter after select one from dropdown
                                  // e.stopPropagation();
                                  onChangeFilters("patient_initials", {
                                    value: item?.patient_initials,
                                    label: "Initials",
                                    dataType: "string",
                                  });
                                }}
                              >
                                <div className="value">
                                  {item.patient_initials}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number wrapper-filter">
                  <div
                    className="font-size-16 text-align-center wrapper-gender-bold wrapper-filter"
                    onClick={() => onChangeShowFilter("gender")}
                  >
                    Gender <FilterPoligon />
                  </div>
                  {showFilter === "gender" && (
                    <div className="wrapper-notifications-modal">
                      <div className="wrapper-search-input" ref={genderRef}>
                        {filterItems?.gender.map((filterGender, index) => {
                          return (
                            <CheckboxOption
                              key={index}
                              indexValue={index}
                              stat={filterGender}
                              checkIsFiltering={checkIsFiltering}
                              onChangeFilters={onChangeFilters}
                              keyType="gender"
                              labelType="Gender"
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="font-size-16 text-align-center wrapper-dob-bold wrapper-filter"
                    style={{ width: "100%" }}
                    onClick={() => onChangeShowFilter("dob")}
                  >
                    DOB
                    <FilterPoligon />
                  </div>
                  {showFilter === "dob" && (
                    <div className="container--div" ref={dobRef}>
                      <div className="react-arrow-div"> </div>
                      <CalendarComponent
                        close={() => {
                          setShowFilter("");
                        }}
                        date={getDateFilter("dob") || null}
                        setDateRaw={(date) => {
                          date &&
                            onChangeFilters("dob", {
                              after: moment(date[0]).format("YYYY-MM-DD"),
                              before: moment(date[1]).format("YYYY-MM-DD"),
                              label: "DOB",
                              dataType: "date",
                              date,
                            });
                        }}
                        selectRange={true}
                        onResetCalendar={() => {
                          deleteFilter("dob");
                        }}
                        type="DOB"
                      />
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number wrapper-filter">
                  <div
                    className="font-size-16 text-align-center wrapper-capsule-bold wrapper-filter"
                    onClick={() => onChangeShowFilter("capsule")}
                  >
                    Indication <FilterPoligon />
                  </div>
                  {showFilter === "capsule" && (
                    <div className="wrapper-notifications-modal">
                      <div
                        className="wrapper-search-input wrapper-search-indication"
                        ref={capsuleRef}
                      >
                        {filterItems.indication.map((indication, index) => {
                          return (
                            <CheckboxOption
                              key={index}
                              indexValue={index}
                              stat={indication}
                              checkIsFiltering={checkIsFiltering}
                              onChangeFilters={onChangeFilters}
                              keyType="indication"
                              labelType="Condition"
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number wrapper-filter">
                  <div
                    className="font-size-16 text-align-center wrapper-molecule-bold wrapper-filter"
                    onClick={() => onChangeShowFilter("molecule")}
                  >
                    Molecule <FilterPoligon />
                  </div>
                  {showFilter === "molecule" && (
                    <div className="wrapper-notifications-modal">
                      <div className="wrapper-search-input" ref={moleculeRef}>
                        {filterItems?.product.map((product, index) => {
                          return (
                            <CheckboxOption
                              key={index}
                              indexValue={index}
                              stat={product}
                              checkIsFiltering={checkIsFiltering}
                              onChangeFilters={onChangeFilters}
                              keyType="product"
                              labelType="Product"
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="wrapper-nhs-number wrapper-filter">
                  <div
                    className="font-size-16 text-align-center wrapper-status-bold wrapper-filter"
                    onClick={() => onChangeShowFilter("status")}
                  >
                    Status <FilterPoligon />
                  </div>
                  {showFilter === "status" && (
                    <div className="wrapper-notifications-modal">
                      <div className="wrapper-search-input" ref={statusRef}>
                        {filterItems?.status?.map((stat, index) => {
                          return (
                            <CheckboxOption
                              key={index}
                              indexValue={index}
                              stat={stat}
                              checkIsFiltering={checkIsFiltering}
                              onChangeFilters={onChangeFilters}
                              keyType="status"
                              labelType="Status"
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="font-size-16 text-align-right pdf">PDF</div>
              </div>
              <Virtuoso
                ref={tableRef}
                endReached={() => {
                  if (existMoreItems.current && isLoadingTable === false) {
                    setIsLoadingTable(true);
                    getPatients(true);
                  }
                }}
                style={
                  isLoadingTable
                    ? {
                        overflowY: "hidden",
                        height: "500px",
                      }
                    : { height: "500px", overflowX: "hidden" }
                }
                data={items}
                itemContent={(index, item) => (
                  <div
                    className={
                      "head-table table-wrapper view-patients " +
                      (isLoadingTable ? "noClick" : "")
                    }
                    key={index}
                    style={{
                      padding: "1.8rem 1rem",
                    }}
                    onClick={() =>
                      redirectToPatient(item?.prescription?.id, item?.id)
                    }
                  >
                    <div className=" text-align-left font-size-16">
                      {isLoadingTable ? <Skeleton width={120} /> : item?.id}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={120} />
                      ) : (
                        <div className="font-size-16">
                          <div className="tooltip-status ">
                            <div className="font-size-16">
                              {items[index]?.prescriber?.full_name.length > 30
                                ? items[index]?.prescriber?.full_name.substring(
                                    0,
                                    30
                                  ) + "..."
                                : items[index]?.prescriber?.full_name}
                            </div>
                            <span className="tooltiptext-status">
                              {items[index]?.prescriber?.full_name}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={50} />
                      ) : (
                        items[index]?.patient_initials
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={70} />
                      ) : (
                        items[index]?.gender
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={100} />
                      ) : (
                        moment(items[index]?.dob).format("DD / MM / YYYY")
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={100} />
                      ) : items[index]?.prescription?.indication?.includes(
                          "Other"
                        ) ? (
                        `Other (${items[index].prescription?.indication_other})`
                      ) : items[index]?.prescription?.indication?.includes(
                          "Clinical "
                        ) ? (
                        `Clinical (${items[index].prescription?.clinical_trial})`
                      ) : (
                        items[index]?.prescription?.indication || "—"
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={100} />
                      ) : (
                        items[index]?.prescription?.product || "—"
                      )}
                    </div>
                    <div className="text-align-center font-size-16">
                      {isLoadingTable ? (
                        <Skeleton width={80} />
                      ) : (
                        <React.Fragment>
                          <span
                            className={
                              items[index].prescription?.status ===
                                "Rejected" ||
                              items[index].prescription?.status === "Overdue" ||
                              items[index].prescription?.status === "Expiring"
                                ? "red font-size-16"
                                : "font-size-16 mine-shaft"
                            }
                          >
                            {items[index]?.prescription?.status || "—"}
                          </span>
                          {items[index].prescription?.status === "Rejected" && (
                            <div className="tooltip-status">
                              <div>
                                <img src={Info} alt="" className="info-icon" />
                              </div>
                              <span className="tooltiptext-status">
                                {items[
                                  index
                                ]?.prescription?.rejection_reason?.toLowerCase() ===
                                "Other (please state)".toLocaleLowerCase()
                                  ? items[index]?.prescription
                                      ?.rejection_reason_other
                                  : items[index]?.prescription
                                      ?.rejection_reason}
                              </span>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>

                    <div
                      className={
                        items[index]?.prescription
                          ? "text-align-right"
                          : "text-align-right pdf"
                      }
                      onClick={(e) => exportPDFWithMethod(e, items[index].id)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (event.button === 1 || event.button === 4) {
                          exportPDFWithMethod(event, items[index].id);
                        }
                      }}
                    >
                      {isLoadingTable ? (
                        <Skeleton width={50} />
                      ) : items[index]?.prescription ? (
                        <DownloadFile />
                      ) : (
                        "—"
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <div id="pdf-element" ref={container}></div>
        </div>
      )}
    </StyledNotifications>
  );
};
export default ViewPatients;
