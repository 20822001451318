import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as ArrowDown } from "../../../assets/icons/downloadBrochure.svg";
import APIVariables from "../../../services/api-variables";

import StyledViewCard from "./StyledViewCard";
import { handleCatch } from "../../../shared/helpers";
import Axios from "../../../services/Axios";
import PharmacyCard from "../../pages/ReportEvent/PharmacyCard";
import { App } from "../../../routes/Constants";

const ViewCard = ({
  brochure,
  displayImage,
  displayTitle,
  isReportanAdverseEventPage,
  getStatus200,
  setCheckbox,
  checkbox,
  isChangingMolecule,
  pharmacyList,
  treatmentFormText,
}) => {
  const result = {};
  for (const key in pharmacyList) {
    if (pharmacyList?.hasOwnProperty(key)) {
      result[key] = pharmacyList[key]?.map((item) => ({
        full_name: item.full_name,
        email: item.email,
        phone_number: item.phone_number,
      }));
    }
  }

  const downloadBrochure = async (id, name) => {
    Axios.get(
      (isReportanAdverseEventPage
        ? APIVariables.PATIENTS_ADVERSE_EVENTS_DOWNLOAD
        : APIVariables.PATIENTS_BROCHURES_DOWNLOAD) + id
    )
      .then((response) => {
        if (isChangingMolecule) {
          getStatus200(response);
        }
        return response.data.report;
      })
      .then(async function (base64) {
        const base64Pdf = await fetch(`data:application/pdf;base64,${base64}`);
        return base64Pdf.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = `${name || brochure.description}.pdf`;
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };
  return (
    <StyledViewCard>
      {isChangingMolecule ? (
        <div
          className={`${!!isChangingMolecule ? "wrapper-modal-molecule" : ""}`}
        >
          {displayImage && (
            <img src={brochure?.image} alt="" className="main-img" />
          )}
          {!!brochure && Object.keys(brochure)?.length !== 0 ? (
            <div className="wrapper-buttons">
              <button
                className="bg-transparent border-0 hover-class"
                onClick={() => {
                  downloadBrochure(brochure.id);
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (event.button === 1 || event.button === 4) {
                    downloadBrochure(brochure.id);
                  }
                }}
              >
                <span>Download</span> <ArrowDown />
              </button>
            </div>
          ) : (
            <h2>
              {" "}
              No {treatmentFormText?.treatment_form_text_short} available.
            </h2>
          )}

          {isChangingMolecule &&
            !!brochure &&
            Object.keys(brochure)?.length !== 0 && (
              <div className="display-flex">
                <div
                  onClick={() => setCheckbox(!checkbox)}
                  className="custom-checkbox checkboxes-block input-and-text"
                >
                  <input type="checkbox" checked={checkbox === true} readOnly />
                  <span className="checkmark margin" />
                  <span className="font-size-16">
                    I confirm that the{" "}
                    {treatmentFormText?.treatment_form_text.toLowerCase()} has
                    been completed and signed for this patient*{" "}
                  </span>
                </div>
              </div>
            )}
        </div>
      ) : (
        <>
          <div className="display-brochures">
            {brochure?.items?.map((element, index) => (
              <div key={index}>
                {displayTitle && <div className="title">{element.name}</div>}

                {displayImage && (
                  <img src={element?.image} alt="" className="main-img" />
                )}

                <div className="wrapper-buttons">
                  <button
                    className="bg-transparent border-0 hover-class"
                    onClick={() => {
                      downloadBrochure(element.id, element.description);
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (event.button === 1 || event.button === 4) {
                        downloadBrochure(element.id, element.description);
                      }
                    }}
                  >
                    <span>Download</span> <ArrowDown />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {!window.location.pathname.includes(App.PATIENT_MATERIALS) && (
            <div className="mb-5">
              <div className="font-size-18 orient bold">
                Medical Information and Adverse Event MAH contact:
              </div>
              <details className="details">
                <div>
                  <div>
                    {Object.keys(result).map(
                      (key) =>
                        key === brochure.title && (
                          <div key={key} className="container">
                            {result[key].map((item, index) => (
                              <div key={`${key}-${index}`} className="element">
                                <PharmacyCard item={item} />
                              </div>
                            ))}
                          </div>
                        )
                    )}
                  </div>
                </div>
              </details>
            </div>
          )}
        </>
      )}
    </StyledViewCard>
  );
};

ViewCard.propTypes = {
  brochure: PropTypes.object,
  displayImage: PropTypes.bool,
  displayTitle: PropTypes.bool,
  isReportanAdverseEventPage: PropTypes.bool,
};
export default ViewCard;
