const LocalStorage = {
  USER_NHSPORTAL: "USER_NHSPORTAL",
  RESET_PASSWORD_EMAIL: "RESET_PASSWORD_EMAIL",
  NEXT_PAGE_NHSPORTAL: "NEXT_PAGE_NHSPORTAL",
  ABOUT_TO_EXPIRE_NHSPORTAL: "ABOUT_TO_EXPIRE_NHSPORTAL",
  EXPIRED_NHSPORTAL: "EXPIRED_NHSPORTAL",
  EXPIRED_SESSION_MESSAGE_NHSPORTAL: "EXPIRED_SESSION_MESSAGE_NHSPORTAL",
  HAS_ACCESS_TO_THE_RE_REGISTER_NHSPORTAL:
    "HAS_ACCESS_TO_THE_RE_REGISTER_NHSPORTAL",
  IS_EDIT_MODE_NHSPORTAL: "IS_EDIT_MODE_NHSPORTAL",
  ARE_NEW_RELEASE_NOTES: "ARE_NEW_RELEASE_NOTES",
};

export default LocalStorage;
