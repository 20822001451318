import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as Warning } from "../../../assets/icons/warning-red.svg";
import StyledModal from "../../common/Modal/StyledModal";
import { errorMessages } from "../../../constants/errorMessages.constants";

const ContinueConfirmationProvidedModal = ({
  errorMessage,
  openModal,
  closeConfirmationProvided,
  continueConfirmationNotProvided,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={openModal}
        onRequestClose={closeConfirmationProvided}
        style={customStyles}
        contentLabel="Confirmation Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            {errorMessage.type === "error" ? <Warning /> : ""}
          </div>
          <div className="main-text-error">
            {errorMessage.title === errorMessages.OUTDATED_DATE_BIRTH_TITLE
              ? "Continue with off-label use"
              : errorMessage.title}
          </div>
          {typeof errorMessage.description === "object" ? (
            <div className="second-text">{errorMessage.description}</div>
          ) : (
            <div
              className="second-text"
              dangerouslySetInnerHTML={{
                __html: errorMessage.description,
              }}
            />
          )}
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeConfirmationProvided}>
            Cancel
          </button>
          <button
            className="red-button"
            onClick={continueConfirmationNotProvided}
          >
            {errorMessage.buttonText}
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

ContinueConfirmationProvidedModal.propTypes = {
  errorMessage: PropTypes.any, // TODO: to be changed
  openModal: PropTypes.bool,
  closeConfirmationProvided: PropTypes.func,
  continueConfirmationNotProvided: PropTypes.func,
};

export default ContinueConfirmationProvidedModal;
