import React, { useEffect, useState } from "react";

import StyledReportEvent from "./StyledReportEvent";

import ViewCard from "../../common/ViewCard/ViewCard";
import { handleCatch, mapArraysWithTitle } from "../../../shared/helpers";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";

const ReportEvent = () => {
  const [patientsBrochures, setPatientsBrochures] = useState([]);
  const [pharmacyList, setPharmacyList] = useState([]);

  useEffect(() => {
    getBrochures();
    getPharmacyList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBrochures = async () => {
    const res = await Axios.get(APIVariables.PATIENTS_ADVERSE_EVENTS).catch(
      (error) => {
        handleCatch(error);
      }
    );

    setPatientsBrochures(res.data);
  };

  const getPharmacyList = async () => {
    const res = await Axios.get(
      APIVariables.ORDERS_MAH_REPORT_ADVERSE_EVENTS
    ).catch((error) => {
      handleCatch(error);
    });

    setPharmacyList(res.data);
  };

  const mappedData = mapArraysWithTitle(patientsBrochures);

  return (
    <StyledReportEvent>
      <div className="layout">
        <div className="font-size-30 main-title">
          Report an Adverse Event and/or Pregnancy
        </div>
        <div className="mt-5 mb-2">
          <div className="font-size-18 mt-1 justify">
            Adverse Events and cases of suspected or confirmed pregnancy or
            foetal exposure should be reported. Adverse Event Report forms and
            Pregnancy Reporting forms can be found below and should be forwarded
            to the relevant Marketing Authorisation Holder.
          </div>
        </div>

        <div className="container mb-2">
          <div className="row">
            {(mappedData || []).map((item, index) => (
              <div key={index} className="view-card-container">
                <hr />
                <div className="title-brochure h1">{item.title}</div>
                <ViewCard
                  pharmacyList={pharmacyList}
                  className="row wrapper-pharmacy"
                  brochure={item}
                  displayImage={false}
                  displayTitle={true}
                  isReportanAdverseEventPage={true}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mr-5 mb-2 mt-5 font-size-18 justify">
          Adverse events can also be reported online via the Yellow Card website{" "}
          <a
            href="https://yellowcard.mhra.gov.uk/"
            className="font-size-18 doveGray-color"
            target="_blank"
            rel="noreferrer"
          >
            https://yellowcard.mhra.gov.uk/
          </a>{" "}
          or search for MHRA Yellow Card in the Google Play or Apple App Store.
          Alternatively, prepaid Yellow Cards for reporting are available:
        </div>

        <ul className="view-card-ul">
          <li>
            by writing to FREEPOST YELLOW CARD (no other address details
            necessary);
          </li>
          <li>by emailing yellowcard@mhra.gov.uk;</li>
          <li>
            by telephoning the Commission on Human Medicines (CHM) free phone
            line: 0800-731-6789;
          </li>
          <li>
            or by downloading and printing a form from the Yellow Card section
            of the MHRA website.
          </li>
        </ul>
      </div>
    </StyledReportEvent>
  );
};
export default ReportEvent;
