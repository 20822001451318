import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { App } from "../../../routes/Constants";
import useRoles from "../../../hooks/useRoles";

const DrugToPrescribe = ({
  registerPatient,
  selectTypeOfDrug,
  drugToPrescribe,
}) => {
  const navigate = useNavigate();
  const { storageData } = useRoles();

  const goToProfile = () => {
    navigate(`${App.PROFILE_DEFAULT}` + storageData.registration_number);
  };
  return (
    <React.Fragment>
      <React.Fragment>
        <h1 className="select-category-txt">
          Please select the drug to prescribe
        </h1>

        <hr className="gray-hr" />
        <div className="m-top-3 m-bot-3 min-height">
          {!!drugToPrescribe && (
            <>
              {drugToPrescribe.map(
                (drug) =>
                  drug?.can_renew_registration === true &&
                  drug?.product_active === true && (
                    //  Active buttons go here
                    <div key={drug.product_id}>
                      <button
                        className={
                          "full " +
                          (registerPatient?.drug === drug?.product_name
                            ? "green-light-button"
                            : "second-button patient-category-button")
                        }
                        onClick={() =>
                          selectTypeOfDrug(drug?.product_name, drug?.product_id)
                        }
                      >
                        {drug?.product_name}
                      </button>
                    </div>
                  )
              )}
              {drugToPrescribe.map(
                (drug) =>
                  drug?.product_active === true &&
                  !drug?.can_renew_registration && (
                    // Disabled buttons go here
                    <div key={drug.product_id}>
                      <button
                        className={
                          "full second-button patient-category-button-grey"
                        }
                        onClick={() => goToProfile()}
                      >
                        <p className="product-name">{drug?.product_name}</p>
                        <p className="required-registration">
                          {drug.can_register || drug.can_re_register
                            ? "Requires registration, click the drug to register"
                            : drug.pending_activation === true
                            ? "Medication disabled for you"
                            : ""}
                        </p>
                      </button>
                    </div>
                  )
              )}
            </>
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

DrugToPrescribe.propTypes = {
  registerPatient: PropTypes.object,
  selectTypeOfDrug: PropTypes.func,
};

export default DrugToPrescribe;
