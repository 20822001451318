import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Virtuoso } from "react-virtuoso";
import RemoveConnectedPharmacistModal from "./modals/RemoveConPharmModal";
import Loader from "../../common/Loader/Loader";
import APIVariables from "../../../services/api-variables";
import { handleCatch, isValidTimestamp } from "../../../shared/helpers";
import { errorMessages } from "../../../constants/errorMessages.constants";
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import StyledHomecare from "../Homecare/StyledHomecare";
import { App } from "../../../routes/Constants";
import Axios from "../../../services/Axios";

const ConnectedPharmacistsList = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [existMoreItems, setExistMoreItems] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPharmacist, setSelectePharmacist] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getConnectedPharmacists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPharmacist = () => {
    navigate(App.ADD_CONNECTED_PHARMACIST);
  };

  const getConnectedPharmacists = async () => {
    await Axios.get(APIVariables.HCP_CON_PHARMACISTS)
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setItems(data);
          setIsLoading(false);
          if (!!data.next) {
            setExistMoreItems(res.data.next);
          }
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const fetchMoreData = async () => {
    await Axios.get(
      existMoreItems ? existMoreItems : APIVariables.HCP_CON_PHARMACISTS
    )
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setItems(items.concat(data.results));
          const areMoreMoreItems = !!data.next;
          if (areMoreMoreItems) {
            setExistMoreItems(data.next);
          } else {
            setExistMoreItems(false);
          }
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const openModalRemovePharmacist = (e, id) => {
    e.stopPropagation();
    setSelectePharmacist(id);
    setIsModalOpen(true);
  };

  const redirectToEditPharmacist = (_, id) => {
    navigate(App.VIEW_CP_DEFAULT + `${id}`);
  };

  const closeModal = () => {
    setSelectePharmacist("");
    setErrorMessage("");
    setIsModalOpen(false);
  };

  const removePharmacist = async () => {
    setErrorMessage("");
    await Axios.delete(APIVariables.HCP_CON_PHARMACIST + selectedPharmacist)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          const newItems = items.filter((item) => {
            return item.id !== selectedPharmacist;
          });
          setItems(newItems);
          closeModal();
        }
      })
      .catch(function (error) {
        const errors = error.response.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          Object.keys(errors).forEach((key) => {
            errorsArray.push(key + " - " + errors[key]);
          });
          setErrorMessage(errorsArray);
        }
      });
  };
  return (
    <StyledHomecare>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="wrapper-homecare main-max-width">
            <h1 className="h1 mineShaft-color">Connected Pharmacists</h1>
            <button className="second-button" onClick={addPharmacist}>
              Add Pharmacist
            </button>
          </div>
          <div className="layout mt-4">
            <div
              className="wrapper-infinitescroll"
              style={{ marginBottom: "20px" }}
            >
              <div className="wrapper-table-infinitescroll">
                <div className="table-wrapper head">
                  <div className="name bold align-center">Name</div>
                  <div className="position bold align-center">Position</div>
                  <div className="registered bold align-center">
                    Reg Valid Until
                  </div>
                  <div className="remove bold align-right">Remove</div>
                </div>
                <Virtuoso
                  endReached={existMoreItems && fetchMoreData}
                  style={{ height: "400px" }}
                  data={items}
                  itemContent={(index) => (
                    <div
                      className="head-table table-wrapper"
                      key={index}
                      onClick={(e) =>
                        redirectToEditPharmacist(e, items[index].id)
                      }
                    >
                      <div className="name align-center">
                        {items[index].name}
                      </div>
                      <div className="position align-center position-homecare">
                        {items[index].job_title}
                      </div>
                      <div className="registered align-center">
                        {isValidTimestamp(items[index].reg_valid_until)
                          ? moment(items[index].reg_valid_until).format(
                              "DD / MM / YYYY"
                            )
                          : items[index].reg_valid_until}
                      </div>
                      <div className="remove align-right">
                        <Remove
                          onClick={(e) =>
                            openModalRemovePharmacist(e, items[index].id)
                          }
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          {modalIsOpen && (
            <RemoveConnectedPharmacistModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              removePharmacist={removePharmacist}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      )}
    </StyledHomecare>
  );
};
export default ConnectedPharmacistsList;
