import styled from "styled-components";
import Colors from "../../shared/colors";

export default styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;

  .medication-block {
    cursor: pointer;
    margin-right: 35px;
    .product-name {
      color: ${Colors.orient};
      margin: 0px 20px 20px 0px;
    }
  }
`;
