import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .layout {
    max-width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 1000px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .h1 {
    margin-bottom: 40px;
  }
  .general-notification {
    padding-top: 120px;
  }

  .wrapper-infinitescroll {
    border-radius: 5px 5px 0px 0px;
    background: ${Colors.white};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    .wrapper-filter {
      justify-content: center;
    }

    .head-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px;
      border-bottom: 1px solid ${Colors.alto};
      background: rgba(0, 0, 0, 0.05);

      .primary-button {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .wrapper-input-search {
      position: relative;
    }
    .wrapper-input-search img {
      position: absolute;
      left: 20px;
      top: 18px;
    }
    .input-search {
      background: ${Colors.white};
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.gray};
      font-weight: normal;
      min-width: 489px;
      padding-left: 40px;
      padding-right: 20px;
      border: 1px solid #cccccc;

      @media screen and (max-width: 850px) {
        min-width: 400px;
      }
    }
    .input-search::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.gray};
      font-weight: normal;
    }
    .wrapper-table-infinitescroll {
      .table-wrapper {
        display: flex;
        width: 100%;
        padding: 18px 20px;
        border-bottom: 1px solid ${Colors.alto};
        min-height: 60px;

        .text-align-center {
          justify-content: center;
          display: block;
        }

        div {
          color: ${Colors.mineShaft};
          align-self: center;
        }

        .description {
          width: 40%;
          padding-right: 5px;
        }

        .patient,
        .date,
        .status,
        .title {
          width: 20%;
        }
        .admin-description {
          width: 60%;
        }
        .description-admin-table {
          padding-left: 15px;
        }
      }
    }

    .wrapper-table-infinitescroll {
      .item {
        border-bottom: 1px solid ${Colors.alto};
        padding: 14px 20px;
      }
    }
  }

  .head-table {
    cursor: pointer;

    .status {
      color: ${Colors.valencia} !important;
      font-weight: bold;
    }

    .pdf {
      cursor: pointer;
    }
  }
  .tooltip-status {
    position: relative;
    display: inline-block;
    margin-left: -17px;
  }

  .tooltip-status .tooltiptext-status {
    visibility: hidden;
    width: fit-content;
    background-color: ${Colors.white};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    color: ${Colors.emperor};
    text-align: center;
    border-radius: 6px;
    padding: 10px 21px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -89px;
    font-weight: normal;
  }

  .tooltip-status .tooltiptext-status::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .tooltip-status:hover .tooltiptext-status {
    visibility: visible;
  }
  .info-icon {
    margin-left: 20px;
    cursor: pointer;
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar {
    width: 6px;
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${Colors.white};
    border-radius: 10px;
  }

  .wrapper-table-infinitescroll > div::-webkit-scrollbar-thumb {
    background: ${Colors.alto};
    border-radius: 500px;
  }

  .custom {
    padding-left: 40px;
    padding-right: 40px;
  }

  .view-patients > div {
    align-self: center;
    display: flex;
    align-items: center;
    white-space: nowrap;
    :first-child {
      width: 20%;
      justify-content: center;
    }
    :nth-child(2) {
      width: 20%;
      white-space: normal;
      > div {
        padding-left: 20px;
      }
    }
    :nth-child(3) {
      width: 10%;
      > div {
        padding-left: 60px;
      }
    }
    :nth-child(4) {
      width: 10%;
    }
    :nth-child(5) {
      width: 10%;
      padding-left: 20px;
    }
    :nth-child(6) {
      width: 20%;
      padding-left: 20px;
      white-space: normal;
      .wrapper-filter {
        padding-left: 20px;
      }
    }
    :nth-child(7) {
      width: 15%;
      padding-left: 20px;
      > div {
        padding-left: 20px;
      }
    }
    :nth-child(8) {
      width: 10%;
      .wrapper-filter {
        padding-left: 20px;
      }
    }
    :nth-child(9) {
      width: 5%;
    }
    svg {
      margin-left: 5px;
    }
  }

  .view-patients {
    .text-align-right {
      justify-content: right;
      width: 5%;
    }

    .pdf {
      margin-right: 10px;
    }
  }

  .red {
    color: ${Colors.valencia};
  }

  .mine-shaft {
    color: ${Colors.mineShaft};
  }

  .header-view-patients {
    div {
      cursor: pointer;
    }
  }

  .wrapper-nhs-number {
    .bold {
      width: auto;
    }
    .input-search {
      min-width: auto;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 3%);
      height: 29px;
      border-radius: 1px;
      width: 195px;
      padding: 5px 10px;
    }
  }

  span {
    margin-top: 0px !important;
  }

  .wrapper-notifications-modal {
    padding-left: 0px !important;
    position: absolute;
    background: ${Colors.alabaster};
    width: 224px;
    z-index: 2;
    top: 37px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);

    hr {
      color: ${Colors.alabaster};
      opacity: 1;
    }

    .element {
      display: flex;
      justify-content: space-between;

      .notification-text {
        margin-left: 10px;

        .text {
          font-size: 16px;
          line-height: 19px;
          color: ${Colors.emperor};
          font-weight: normal;
        }

        .date {
          font-size: 14px;
          line-height: 17px;
          color: ${Colors.gray};
          font-weight: normal;
        }
      }
    }

    .view-notifications {
      div {
        display: flex;
        justify-content: center;
        font-size: 16px;
        line-height: 19px;
        color: ${Colors.orient};
        cursor: pointer;
      }
    }
  }

  .wrapper-notifications-modal:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .wrapper-notifications-modal:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${Colors.white};
    border-width: 10px;
    margin-left: -10px;
  }

  .wrapper-nhs-number {
    position: relative;
  }

  .wrapper-search-input {
    position: relative;
    background: ${Colors.alabaster};
    padding: 9px 15px;

    img {
      position: absolute;
      right: 25px;
      top: 18px;
    }
  }

  .wrapper-search-indication {
    .custom-checkbox {
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }

  .wrapper-search-indication:first-child {
    margin-top: 1rem;
  }

  .wrapper-tooltip-patients {
    .value {
      margin: 5px 25px;
    }
  }

  .wrapper-tooltip-patients .element {
    background: ${Colors.white};
    .value {
      color: ${Colors.emperor};
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .wrapper-tooltip-patients .element:hover {
    background: ${Colors.slateGray};

    .value {
      color: ${Colors.white};
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .all-filter-buttons {
    display: flex;
    margin-bottom: 10px;
    min-height: 46px;
    flex-wrap: wrap;
  }

  .notifications-filter {
    text-align: right;
    display: flex;
    justify-content: flex-end;

    label {
      color: ${Colors.mineShaft}!important;
      font-size: 30px;
      line-height: 36.5px;
      font-weight: 700;
    }

    img {
      width: 30px;
    }
  }

  .modal-absolute {
    position: absolute;
    margin-top: 55px;
    z-index: 99999;
  }

  .filters-modal {
    width: 260px;
    background: ${Colors.white};
    float: right;
    padding: 20px;
    margin-right: -20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
      color: ${Colors.mineShaft};
    }

    hr {
      margin: 0px;
      width: 260px;
      margin-left: -19px;
    }

    .filter-modal-bottom-buttons {
      display: flex;
      justify-content: space-around;

      span {
        cursor: pointer;
      }
    }

    .mini-div {
      display: flex;
      justify-content: space-between;
    }
  }

  .filter-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .filter-label {
    font-size: 30px;
    color: #3c3c3c;
    margin-right: 5px;
  }

  .view-patients-btn {
    width: 209px;
    min-width: auto;
  }
`;
