import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StyledDashboard from "./StyledDashboard";
import { App } from "../../../routes/Constants";
import APIVariables from "../../../services/api-variables";
import LocalStorage from "../../../shared/localStorage";
import Loader from "../../common/Loader/Loader";

// INTERN COMPONENTS
import { ViewPatientsBox, ReferAPatient } from "./components";
import { handleCatch } from "../../../shared/helpers";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";
import ReleaseNotesModal from "../../common/ReleaseNotesModal/ReleaseNotesModal";
import GuideButtons from "../../authentication/guideButtons";

const Dashboard = () => {
  const navigate = useNavigate();

  const [hover, setHover] = useState();
  const [patientsStatistics, setPatientsStatistics] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [displayExistReleaseNotes, setDisplayExistReleaseNotes] =
    useState(false);
  const {
    isPrescriber,
    isPharmacy,
    isConnectedNurse,
    isHomecare,
    isOutpatient,
  } = useRoles();

  useEffect(() => {
    getStatistics();

    displayExistReleaseNotesInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const displayExistReleaseNotesInitial = () => {
    const existReleaseNotes = localStorage.getItem(
      LocalStorage.ARE_NEW_RELEASE_NOTES
    );

    if (existReleaseNotes) {
      setDisplayExistReleaseNotes(JSON.parse(existReleaseNotes));
    }
  };

  const getStatistics = async () => {
    const res = await Axios.get(APIVariables.PATIENT_STATISTICS).catch(
      (error) => {
        handleCatch(error);
      }
    );

    if (res?.status === 200) {
      setIsLoading(false);
      setPatientsStatistics(res.data);
    }
  };

  const onHover = (element) => {
    setHover(element);
  };

  const onLeave = () => {
    setHover();
  };

  const quitModalExistNotes = async (event) => {
    if (event.key === "Escape") {
      return;
    }
    const res = await Axios.get(APIVariables.RELEASE_NOTES_READ).catch(
      (error) => {
        handleCatch(error);
      }
    );

    if (res?.status === 200) {
      setDisplayExistReleaseNotes(false);
      localStorage.removeItem(LocalStorage.ARE_NEW_RELEASE_NOTES);
    }
  };
  if (isLoading) return <Loader />;

  return (
    <StyledDashboard>
      <div className={"row patients " + (isPrescriber ? "" : "layout-cards")}>
        <div
          className={
            "col col-sm-6-custom-full " +
            (hover === "All Patients" ? "hovered" : "")
          }
          onMouseEnter={() => onHover("All Patients")}
          onMouseLeave={onLeave}
          onClick={() => {
            navigate(App.VIEW_PATIENTS);
          }}
        >
          <div className="center-content">
            <div className="main-text font-size-18">All Patients</div>
            <div className="wrapper-number">
              <span className="number font-size-64">
                {patientsStatistics?.all_patients}
              </span>
            </div>
            <div className="details">
              View a list of all patients that have been prescribed
            </div>
          </div>
        </div>
        <div
          className={
            "col col-sm-6-custom-full " +
            (hover === "Active Patients" ? "hovered" : "")
          }
          onMouseEnter={() => onHover("Active Patients")}
          onMouseLeave={onLeave}
          onClick={() => {
            navigate({
              pathname: "/view-patients",
              search:
                isOutpatient || isHomecare
                  ? "status=approved-paf"
                  : "?status=submitted",
            });
          }}
        >
          <div className="center-content">
            <div className="main-text font-size-18">Active Patients</div>
            <div className="wrapper-number">
              <span className="number font-size-64">
                {patientsStatistics?.active_patients}
              </span>
            </div>
            <div className="details">
              View a list of all currently prescribed patients
            </div>
          </div>
        </div>
        <div
          className={
            "col col-sm-6-custom-full " +
            (hover === "Overdue PAF" ? "hovered" : "")
          }
          onMouseEnter={() => onHover("Overdue PAF")}
          onMouseLeave={onLeave}
          onClick={() => {
            navigate({
              pathname: "/view-patients",
              search: "?status=overdue-paf",
            });
          }}
        >
          <div className="center-content">
            <div className="main-text font-size-18">Overdue PAF</div>
            <div className="wrapper-number">
              <span className="number font-size-64">
                {patientsStatistics?.overdue_paf}
              </span>
            </div>
            <div className="details">
              View a list of all PAF's that are overdue
            </div>
          </div>
        </div>
        <div
          className={
            "col col-sm-6-custom-full " +
            (hover === "Rejected PAF" ? "hovered" : "")
          }
          onMouseEnter={() => onHover("Rejected PAF")}
          onMouseLeave={onLeave}
          onClick={() => {
            navigate({
              pathname: "/view-patients",
              search:
                isOutpatient || isHomecare
                  ? "?status=dispensed-paf"
                  : "?status=rejected-paf",
            });
          }}
        >
          <div className="center-content">
            <div className="main-text font-size-18">
              {isOutpatient || isHomecare ? "Dispensed PAF" : "Rejected PAF"}
            </div>
            <div className="wrapper-number">
              <span className="number font-size-64">
                {isOutpatient || isHomecare
                  ? patientsStatistics?.dispensed_paf
                  : patientsStatistics?.rejected_paf}
              </span>
            </div>
            <div className="details">
              {isOutpatient || isHomecare
                ? "View a list of all scripts that have been dispensed"
                : "View a list of all scripts that have been rejected"}
            </div>
          </div>
        </div>
      </div>
      <div className="layout-cards">
        <div>
          {!isPharmacy && !isConnectedNurse && !isHomecare && !isOutpatient ? (
            <div className="row referTo">
              <ReferAPatient />
              <ViewPatientsBox />
            </div>
          ) : (
            <div className="row referTo">
              <ViewPatientsBox />
            </div>
          )}
        </div>
      </div>
      {displayExistReleaseNotes && (
        <ReleaseNotesModal
          displayExistReleaseNotes={displayExistReleaseNotes}
          quitModalExistNotes={quitModalExistNotes}
        />
      )}
      <GuideButtons />
    </StyledDashboard>
  );
};
export default Dashboard;
