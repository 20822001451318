import React, { useState } from "react";

import Select, { components } from "react-select";

import { ReactComponent as DropdownArrowDown } from "../../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../../assets/icons/dropdownArrowUp.svg";

import Calendar from "../../../assets/icons/biCalendar.svg";

import moment from "moment";
import CalendarComponent from "../../common/Calendar/Calendar";
import { typeOfPAF } from "../../../constants/typeOfPAF";
import useRoles from "../../../hooks/useRoles";
import { getTotalCapsulesLabel, prescriberName } from "../../../shared/helpers";
import ToolTipWeeksPerCycle from "./ToolTipWeeksPerCycle";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropdownArrowUp />
        ) : (
          <DropdownArrowDown />
        )}
      </components.DropdownIndicator>
    )
  );
};

const DigitalPAF = (props) => {
  const {
    handleChange,
    registerPatient,
    handleChangeCheckBox,
    validateFields,
    updateRegisterPatient,
    showErrors,
    handleChangeSelect,
    isRenewal,
    deletePropertyValue,
    handleCapsules,
    prescriptionField,
    dropdownValue,
    checkedItems,
    checkedItemsPrescriber,
    handleCheckboxChange,
    prescriptionConfirmationRisks,
    prescriberConfirmations,
  } = props;

  const [isDobOpen, setIsDobOpen] = useState(false);
  const [isPregnancyOpen, setIsPregnancyOpen] = useState(false);

  const isMale = registerPatient.type === "male";
  const isWCBP = registerPatient.type === "womanOfCP";

  const parseTitle = isMale ? "Male" : isWCBP ? "WCBP" : "WNCBP";
  const changeSource = isRenewal === true ? dropdownValue : prescriptionField;

  var today = new Date();

  const setDateComponentOpen = (event, setToBeOpened, toBeOpened) => {
    event.preventDefault();
    if (toBeOpened) {
      setToBeOpened(false);
    } else {
      setIsDobOpen(false);
      setIsPregnancyOpen(false);
      setToBeOpened(true);
    }
  };

  const { storageData } = useRoles();

  const deleteProperty = (propName) => {
    deletePropertyValue(propName);
  };

  const renamedMolecule =
    isRenewal === true &&
    changeSource?.product?.map((option) => ({
      value: option.id,
      label: option.value,
      brocures: option.brochure,
    }));
  const filteredOptions =
    Array?.isArray(renamedMolecule) &&
    renamedMolecule?.filter((option) => {
      return option?.value !== registerPatient?.molecule?.value;
    });

  const renamedCapsuleCycle =
    !!changeSource &&
    changeSource?.capsule_cycle?.map((option) => ({
      value: option.id === -1 ? null : option.id,
      label:
        option.id === -1
          ? "None"
          : option.id === 0
          ? "Other (please state)"
          : option.value,
    }));

  const scheduleDisplayType = changeSource?.schedule_display_type;

  const noneOption = { id: null, label: "None" };
  const getOptionsWithNone = (options) => {
    return [noneOption, ...(options || [])];
  };

  const renamedOptions = (() => {
    switch (scheduleDisplayType) {
      case "weeks":
        return {
          weeksOptions: getOptionsWithNone(
            changeSource?.n_weeks?.map((option) => ({
              id: option.id,
              label: option.value,
            }))
          ),
          cyclesOptions: [],
        };
      case "weeks_per_cycle":
        return {
          weeksOptions: getOptionsWithNone(
            changeSource?.weeks_per_cycle?.map((option) => ({
              id: option.id,
              label: option.value,
            }))
          ),
          cyclesOptions: getOptionsWithNone(
            changeSource?.n_cycles?.map((option) => ({
              id: option.id,
              label: option.value,
            }))
          ),
        };
      default:
        return {
          weeksOptions: [],
          cyclesOptions: getOptionsWithNone(
            changeSource?.n_cycles?.map((option) => ({
              id: option.id,
              label: option.value,
            }))
          ),
        };
    }
  })();

  const renamedCapsules = registerPatient?.capsules?.map((option) => option);

  const myRename = renamedCapsules?.map((element) => ({
    label:
      element.label === null
        ? "None"
        : element.label === 0
        ? "Other capsules"
        : element.label + " capsules",
    id: element.id,
    other: !!element.other && element.other,
  }));

  const renamedIndication = changeSource?.indication?.map((option) => ({
    is_clinical_trial: option.is_clinical_trial,
    is_indication_other: option.is_indication_other,
    id: option.id,
    label: option.id === -1 ? "None" : option.value,
  }));

  const placeholder = "Select"; // Replace with your desired placeholder value
  const populatedRename = Array.from(
    { length: changeSource?.strengths?.length },
    () => placeholder
  );
  !!myRename &&
    myRename?.forEach((item) => {
      const index = changeSource?.strengths?.findIndex(
        (element) => element.id === item.id || element.id === item.index
      );
      if (index !== -1) {
        populatedRename[index] = item;
      }
    });

  return (
    <div>
      <h1 className="select-category-txt">
        {`Prescription Authorisation Form - ${
          !isRenewal ? parseTitle : `Renewal  ${parseTitle} `
        }`}
      </h1>

      <hr className="gray-lightC8-hr" />

      <div className="m-top-3 m-bot-3 min-height">
        {registerPatient.type === typeOfPAF.WOMANOFCP ? (
          <ol>
            <li className="font-size-16">
              Enter the full hospital name and address where the patient is
              treated
            </li>
            <li className="pt-3 font-size-16">
              Enter the indication and drug name
            </li>
            <li className="pt-3 font-size-16">
              Select the appropriate strength and quantity of individual
              capsules required for this prescription. The maximum quantity
              allowable for this patient is a 4 week supply
            </li>
            <li className="pt-3 font-size-16">
              Enter the most recent negative pregnancy test date.
            </li>
          </ol>
        ) : (
          <ol>
            <li className="font-size-16">
              Enter the full hospital name and address where the patient is
              treated
            </li>
            <li className="pt-3 font-size-16">
              Enter the indication and drug name
            </li>
            <li className="pt-3 font-size-16">
              Select the appropriate strength and quantity of individual
              capsules required for this prescription. The maximum quantity
              allowable for this patient is a 12 week supply
            </li>
          </ol>
        )}

        <h2 className="title-details">Patient Details</h2>
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="silver">Patient Category</label>
              <input
                type="text"
                name="patient_category"
                value={
                  isMale
                    ? "Male"
                    : isWCBP
                    ? "Woman of Childbearing Potential"
                    : "Woman of Non-Childbearing Potential"
                }
                className="silver"
                disabled
              />
            </div>

            <div className="col">
              <label
                className={
                  showErrors &&
                  !isRenewal &&
                  !validateFields?.patient_dob?.isValid
                    ? "red-label"
                    : ""
                }
              >
                {!isRenewal
                  ? "Patient Date of Birth:*"
                  : "Patient Date of Birth:"}
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {!isRenewal ? (
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      id="patient_dob"
                      name="patient_dob"
                      className={
                        "transparent-background" +
                        (showErrors && !validateFields?.patient_dob?.isValid
                          ? " error"
                          : "")
                      }
                      value={
                        registerPatient.patient_dob
                          ? moment(registerPatient.patient_dob).format(
                              "DD / MM / YYYY"
                            )
                          : ""
                      }
                      placeholder="DD / MM / YYYY"
                      onClick={(e) => {
                        setDateComponentOpen(e, setIsDobOpen, isDobOpen);
                      }}
                      readOnly
                    />
                    <img src={Calendar} alt="Calendar" className="calendar" />
                  </div>
                ) : (
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      value={
                        registerPatient.patient_dob || registerPatient.dob
                          ? moment(
                              registerPatient.patient_dob || registerPatient.dob
                            ).format("DD / MM / YYYY")
                          : ""
                      }
                      readOnly
                    />
                  </div>
                )}
                {isDobOpen && (
                  <div
                    className="container--div"
                    style={{
                      alignItems: "center",
                      right: "41px",
                    }}
                  >
                    <div className="react-arrow-div" />
                    <CalendarComponent
                      close={setIsDobOpen}
                      setDateRaw={(date) =>
                        updateRegisterPatient("patient_dob", date)
                      }
                      date={registerPatient.patient_dob}
                      type="DOB"
                      selectRange={false}
                      maxDate={today}
                    />
                  </div>
                )}
                {showErrors &&
                  !isRenewal &&
                  !validateFields?.patient_dob?.isValid && (
                    <span className="required-text">Required</span>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label
                className={
                  showErrors &&
                  !isRenewal &&
                  !validateFields?.patient_initials?.isValid
                    ? "red-label"
                    : ""
                }
              >
                {!isRenewal ? "Patient Initials:*" : "Patient Initials:"}
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="patient_initials"
                value={registerPatient.patient_initials || ""}
                placeholder="Initials"
                className={
                  showErrors &&
                  !isRenewal &&
                  !validateFields?.patient_initials?.isValid
                    ? "error"
                    : ""
                }
                maxLength="3"
                readOnly={isRenewal}
              />

              {showErrors &&
                !isRenewal &&
                !validateFields?.patient_initials?.isValid && (
                  <span className="required-text">Required</span>
                )}
            </div>

            {!isWCBP ? (
              <div className="col"></div>
            ) : (
              <div className="col">
                <label>Date of Last Negative Pregnancy Test:*</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      id="pregnancy_test"
                      name="pregnancy_test"
                      className="transparent-background"
                      value={
                        registerPatient.pregnancy_test
                          ? moment(registerPatient.pregnancy_test).format(
                              "DD / MM / YYYY"
                            )
                          : ""
                      }
                      placeholder="DD / MM / YYYY"
                      onClick={(e) => {
                        setDateComponentOpen(
                          e,
                          setIsPregnancyOpen,
                          isPregnancyOpen
                        );
                      }}
                      readOnly
                    />
                    <img src={Calendar} alt="Calendar" className="calendar" />
                  </div>
                  {isPregnancyOpen && (
                    <div
                      className="container--div"
                      style={{
                        alignItems: "center",
                        right: "41px",
                      }}
                    >
                      <div className="react-arrow-div" />
                      <CalendarComponent
                        close={setIsPregnancyOpen}
                        setDateRaw={(date) =>
                          updateRegisterPatient("pregnancy_test", date)
                        }
                        date={registerPatient.pregnancy_test}
                        type="pregnancy_test"
                        maxDate={today}
                        selectRange={false}
                        onResetCalendar={() => {
                          deleteProperty("pregnancy_test");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <h2 className="title-details">Prescription Details</h2>

        <div className="container">
          <div className="row">
            <div className="col">
              <label
                className={
                  showErrors &&
                  !isRenewal &&
                  !validateFields?.hospital_name_and_address?.isValid
                    ? "red-label"
                    : ""
                }
              >
                Name and Address of treating Hospital:
              </label>
              {!isRenewal ? (
                <input
                  type="text"
                  name="hospital_name_and_address"
                  value={
                    storageData?.hospital?.value +
                    ", " +
                    storageData?.hospital?.address
                  }
                  placeholder="Hospital Name and Address"
                  className={"silver"}
                  disabled
                />
              ) : (
                <input
                  type="text"
                  name="hospital_name_and_address"
                  value={
                    registerPatient?.prescriber?.hospital?.name +
                    " " +
                    registerPatient?.prescriber?.hospital?.address?.full_address
                  }
                  placeholder="Hospital Name and Address"
                  className={"silver"}
                  disabled
                />
              )}
            </div>
          </div>

          <div className="row mb-3 paf-renew-custom">
            <div className="col">
              <label>Indication:*</label>
              {!isRenewal ? (
                <Select
                  classNamePrefix="custom-select"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  value={registerPatient.indication || ""}
                  onChange={(selectedOption) => {
                    handleCapsules(selectedOption, "indication");
                  }}
                  options={renamedIndication}
                  placeholder="Select"
                  className={
                    showErrors && !validateFields?.indication?.isValid
                      ? "error-border"
                      : ""
                  }
                />
              ) : (
                <div className="col">
                  <Select
                    classNamePrefix="custom-select"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    value={registerPatient.indication || ""}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, "indication");
                    }}
                    options={renamedIndication}
                    placeholder="Select"
                  />
                </div>
              )}
            </div>
            <div className="col">
              <label>Molecule:</label>
              {!isRenewal ? (
                <input
                  type="text"
                  value={registerPatient.drug || ""}
                  className="gray"
                  disabled
                />
              ) : (
                <div className="col">
                  <Select
                    classNamePrefix="custom-select"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    value={registerPatient.molecule || ""}
                    onChange={(selectedOption) => {
                      handleChangeSelect(selectedOption, "Molecule");
                    }}
                    options={filteredOptions}
                    placeholder="Select"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {(registerPatient.indication?.is_indication_other === true ||
              registerPatient?.indication?.label.includes(
                "Other (Please State)"
              )) && (
              <div className="row mb-3">
                <div className="col col-sm-6-custom">
                  <label>Other indication:*</label>

                  <input
                    type="text"
                    onChange={(e) => {
                      isRenewal === true
                        ? handleChangeSelect(e.target.value, "indication_other")
                        : handleCapsules(e.target.value, "indication_other");
                    }}
                    name="indication_other"
                    value={registerPatient?.indication_other || ""}
                    placeholder="Other indication"
                    maxLength={75}
                  />
                  {showErrors && !validateFields?.indication_other?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
                <div className="col col-sm-6-custom"></div>
              </div>
            )}
            {(registerPatient.indication?.is_clinical_trial === true ||
              registerPatient?.indication?.label.includes(
                "Clinical trial"
              )) && (
              <div className="row mb-3">
                <div className="col col-sm-6-custom">
                  <label>Clinical Trial</label>

                  <input
                    type="text"
                    onChange={(e) => {
                      isRenewal === true
                        ? handleChangeSelect(e.target.value, "clinical_trial")
                        : handleCapsules(e.target.value, "clinical_trial");
                    }}
                    name="clinical_trial"
                    value={registerPatient?.clinical_trial || ""}
                    placeholder="Clinical indication"
                    maxLength={75}
                  />
                  {showErrors && !validateFields?.clinical_trial?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
                <div className="col col-sm-6-custom"></div>
              </div>
            )}
          </div>

          <div className="row mb-3 paf-renew-custom">
            <div className="col">
              <label>
                {scheduleDisplayType === "weeks_per_cycle"
                  ? "Dosage Strength*"
                  : "Capsule Strength:*"}
              </label>
              {changeSource?.strengths?.map((element, key) => (
                <div
                  key={element.id}
                  style={{ marginTop: key === 0 ? "15px" : "0" }}
                >
                  <input
                    type="text"
                    onChange={handleChange}
                    value={element.value}
                    className="mb-5"
                    disabled
                  />
                  {populatedRename[key]?.label === "Other capsules" && (
                    <div
                      className="mb-5"
                      style={{ paddingBottom: "80px" }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
            <div className="col noWrap">
              <label>
                {scheduleDisplayType === "weeks"
                  ? "Number of capsules per 4 week supply:*"
                  : "Capsules per Cycle:*"}
              </label>
              <i className="italic-min">(Not pack size)</i>

              {changeSource?.strengths?.map((element, key) => (
                <div key={key}>
                  <Select
                    placeholder="Select"
                    classNamePrefix="margin-custom-select custom-select"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      populatedRename !== undefined ? populatedRename[key] : ""
                    }
                    onChange={(selectedOption) => {
                      isRenewal === true
                        ? handleChangeSelect(
                            selectedOption,
                            "capsule_per_cycle",
                            element,
                            element.id
                          )
                        : handleCapsules(
                            selectedOption,
                            "capsule_per_cycle",
                            element,
                            element.id
                          );
                    }}
                    options={renamedCapsuleCycle}
                  />
                  <div className="col"></div>
                  <div className="col"></div>

                  {!!populatedRename &&
                    populatedRename[key]?.label === "Other capsules" && (
                      <div className="row mb-3">
                        <div className="col">
                          <label>
                            {scheduleDisplayType
                              ? "Quantity:*"
                              : "Other Capsules per Cycle:*"}
                          </label>
                          <input
                            type="number"
                            onChange={(e) => {
                              isRenewal === true
                                ? handleChangeSelect(
                                    e.target.value,
                                    "capsule_per_cycle",
                                    element
                                  )
                                : handleCapsules(
                                    e.target.value,
                                    "capsule_per_cycle",
                                    element
                                  );
                            }}
                            value={
                              populatedRename[key].other !== undefined &&
                              populatedRename[key].other !== false
                                ? populatedRename[key].other
                                : 1
                            }
                            name={`capsule_cycle_other_${element.id}`}
                            placeholder="Other Capsules per Cycle"
                            min={1}
                          />
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>

            {scheduleDisplayType === "weeks_per_cycle" ? (
              <div className="col col2">
                <label style={{ paddingBottom: "25px", display: "flex" }}>
                  {"Supply weeks per cycle:*"}
                  <ToolTipWeeksPerCycle />
                </label>
                <Select
                  classNamePrefix="margin-custom-select custom-select"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    !!registerPatient.weeks_per_cycle
                      ? registerPatient.weeks_per_cycle
                      : { id: null, label: "Select" }
                  }
                  onChange={(selectedOption) => {
                    isRenewal === true
                      ? handleChangeSelect(selectedOption, "weeks_per_cycle")
                      : handleCapsules(selectedOption, "weeks_per_cycle");
                  }}
                  options={renamedOptions.weeksOptions}
                  placeholder="Select"
                />

                {registerPatient?.weeks_per_cycle &&
                  registerPatient?.weeks_per_cycle?.label ===
                    "Other (please state)" && (
                    <div className="row mb-3">
                      <div className="col">
                        <label>Other Weeks:*</label>
                        <input
                          type="number"
                          name={`capsule_cycle_other_$`}
                          value={
                            registerPatient.other
                              ? registerPatient.other
                              : 1 || ""
                          }
                          onChange={(e) => {
                            isRenewal === true
                              ? handleChangeSelect(
                                  e.target.value,
                                  "number_weeks_other"
                                )
                              : handleCapsules(
                                  e.target.value,
                                  "number_weeks_other"
                                );
                          }}
                          placeholder="Other Weeks"
                          min={1}
                        />
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div className="col col2">
                <label style={{ paddingBottom: "25px" }}>
                  {scheduleDisplayType === "weeks"
                    ? "Number of supply weeks:*"
                    : " Number of Cycles:*"}
                </label>
                <Select
                  classNamePrefix="margin-custom-select custom-select"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    scheduleDisplayType && !!registerPatient.number_weeks
                      ? registerPatient.number_weeks
                      : !!registerPatient.number_cycles
                      ? registerPatient.number_cycles
                      : { id: null, label: "Select" }
                  }
                  onChange={(selectedOption) => {
                    isRenewal === true
                      ? handleChangeSelect(
                          selectedOption,
                          scheduleDisplayType === "cycles"
                            ? "number_cycles"
                            : "number_weeks"
                        )
                      : handleCapsules(
                          selectedOption,
                          scheduleDisplayType === "cycles"
                            ? "number_cycles"
                            : "number_weeks"
                        );
                  }}
                  options={
                    scheduleDisplayType === "cycles"
                      ? renamedOptions.cyclesOptions
                      : renamedOptions.weeksOptions
                  }
                  placeholder="Select"
                />

                {registerPatient.number_weeks &&
                  registerPatient.number_weeks.label ===
                    "Other (Please State)" && (
                    <div className="row mb-3">
                      <div className="col">
                        <label>Other Weeks:*</label>
                        <input
                          type="number"
                          name={`capsule_cycle_other_$`}
                          value={
                            registerPatient.other
                              ? registerPatient.other
                              : 1 || ""
                          }
                          onChange={(e) => {
                            isRenewal === true
                              ? handleChangeSelect(
                                  e.target.value,
                                  "number_weeks_other"
                                )
                              : handleCapsules(
                                  e.target.value,
                                  "number_weeks_other"
                                );
                          }}
                          placeholder="Other Weeks"
                          min={1}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

            <div className="col col2">
              {scheduleDisplayType === "weeks_per_cycle" && (
                <>
                  <label style={{ paddingBottom: "25px" }}>
                    {scheduleDisplayType === "weeks"
                      ? "Number of supply weeks:*"
                      : " Number of Cycles:*"}
                  </label>
                  <Select
                    classNamePrefix="margin-custom-select custom-select"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      scheduleDisplayType && !!registerPatient.number_weeks
                        ? registerPatient.number_weeks
                        : !!registerPatient.number_cycles
                        ? registerPatient.number_cycles
                        : { id: null, label: "Select" }
                    }
                    onChange={(selectedOption) => {
                      isRenewal === true
                        ? handleChangeSelect(selectedOption, "number_cycles")
                        : handleCapsules(selectedOption, "number_cycles");
                    }}
                    options={renamedOptions.cyclesOptions}
                    placeholder="Select"
                  />
                </>
              )}

              <div
                className={`col col2 ${
                  scheduleDisplayType === "weeks_per_cycle" && "mt-3"
                }`}
              >
                <label className="flex">
                  {getTotalCapsulesLabel(scheduleDisplayType)}{" "}
                  {scheduleDisplayType === "weeks_per_cycle" && (
                    <ToolTipWeeksPerCycle TotalSupply />
                  )}
                </label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="total_capsules"
                  value={
                    scheduleDisplayType === "weeks_per_cycle"
                      ? !!registerPatient.total_weeks_supply
                        ? registerPatient.total_weeks_supply + " weeks"
                        : ""
                      : registerPatient.total_capsules || ""
                  }
                  className="mb-5"
                  placeholder="No Total"
                  disabled
                  style={{
                    paddingTop:
                      scheduleDisplayType === "weeks_per_cycle"
                        ? "5px"
                        : "25px",
                  }}
                />
              </div>
            </div>
          </div>

          {isRenewal &&
            !!prescriptionConfirmationRisks &&
            prescriptionConfirmationRisks?.map((item) => (
              <div key={item.id} className="mt-2 mb-2">
                <label
                  className={"custom-checkbox font-size-16 dove-gray"}
                  htmlFor={`confirmation-${item.id}`}
                >
                  {item.confirmation_text}{" "}
                  <input
                    type="checkbox"
                    id={`confirmation-${item.id}`}
                    name={`confirmation-${item.id}`}
                    checked={item.confirmation_risk_patient}
                    onChange={(e) => handleChangeCheckBox(e, item.id)}
                  />
                  <span className="checkmark mt-0" />
                </label>
              </div>
            ))}

          {
            <div>
              {prescriptionField?.prescription_confirmation_risks?.map(
                (item) => (
                  <div key={item.id} className="mt-2 mb-2">
                    <label
                      className={`custom-checkbox font-size-16 dove-gray ${
                        registerPatient?.confirmation_checked_by_pharmacist ===
                        true
                          ? "green-haze"
                          : ""
                      }`}
                      htmlFor={`risk-checkbox-${item.id}`}
                    >
                      {item.confirmation_text}
                      <input
                        type="checkbox"
                        id={`risk-checkbox-${item.id}`}
                        name={`risk-checkbox-${item.id}`}
                        checked={
                          checkedItems[`risk-checkbox-${item.id}`] || false
                        }
                        onChange={handleCheckboxChange}
                      />
                      <span className="checkmark mt-0" />
                    </label>
                  </div>
                )
              )}
            </div>
          }
        </div>

        <h2 className="title-details mt-5 silver">Prescriber Declaration:</h2>

        <div className="container initiation">
          <div className="row">
            <div className="col">
              <label className="silver">Prescriber Name</label>
              <input
                type="text"
                className="disabled"
                name="prescriberName"
                value={prescriberName() || ""}
                disabled
              />
            </div>
            <div className="col">
              <label className="silver">Date of PAF</label>
              <input
                className="disabled"
                value={moment().format("DD / MM / YYYY")}
                disabled
              />
            </div>
          </div>
        </div>
        <div>
          {
            <div>
              {prescriptionField?.prescriber_confirmations?.map((item) => (
                <div key={item.id} className="mt-2 mb-2">
                  <label
                    className={
                      "custom-checkbox dove-gray font-size-18 bold " +
                      (showErrors &&
                      !validateFields?.confirmation_risk_prescriber?.isValid &&
                      !checkedItemsPrescriber[`checkbox-prescriber-${item.id}`]
                        ? "red-label"
                        : "")
                    }
                    htmlFor={`checkbox-prescriber-${item.id}`}
                  >
                    {item.confirmation_text}
                    <input
                      type="checkbox"
                      id={`checkbox-prescriber-${item.id}`}
                      name={`checkbox-prescriber-${item.id}`}
                      checked={
                        checkedItemsPrescriber[
                          `checkbox-prescriber-${item.id}`
                        ] || false
                      }
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark mt-0" />
                  </label>
                </div>
              ))}
            </div>
          }
          {isRenewal &&
            !!prescriberConfirmations &&
            prescriberConfirmations?.map((item, index) => (
              <div key={item.id} className="mt-2 mb-2">
                <label
                  className={
                    "custom-checkbox dove-gray font-size-18 bold " +
                    (showErrors &&
                    !validateFields?.confirmation_risk_prescriber?.isValid &&
                    !item.prescriber_confirmations
                      ? "red-label"
                      : "")
                  }
                  htmlFor={`checkbox-prescriber-${item.id}`}
                >
                  {item.confirmation_text}
                  <input
                    type="checkbox"
                    id={`checkbox-prescriber-${item.id}`}
                    name={`checkbox-prescriber-${item.id}`}
                    checked={item.prescriber_confirmations}
                    onChange={(e) => handleChangeCheckBox(e, item.id)}
                  />
                  <span className="checkmark mt-0" />
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default DigitalPAF;
