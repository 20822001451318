import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as Mail } from "../../../src/assets/icons/iconMail.svg";
import StyledModal from "./../common/Modal/StyledModal";
import ErrorText from "../common/ErrorText/ErrorText";

const MFAModal = ({
  MAFModalIsOpen,
  confirmMFAModal,
  handleChangeCodeAuthentication,
  errorMessage,
  mfa_code,
  resendMFA,
  resendEmail,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "32%",
      borderRadius: "30px",
      boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "60%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "32%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={MAFModalIsOpen}
        onRequestClose={confirmMFAModal}
        style={customStyles}
        contentLabel="Password Expired Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal wrapper-modal-mfa">
          <div className="image">
            <Mail />
          </div>
          <div className="green-haze-color main-text">
            Email Code Authentication
          </div>
          <div className="second-text">
            Please check your email for an authentication code and enter it into
            the box below
          </div>
          <input
            className="input-rejection"
            type="text"
            onChange={handleChangeCodeAuthentication}
            name="mfa_code"
            placeholder=""
            maxLength={6}
            value={mfa_code}
          />
          <div className={!resendEmail && "resend-email"}>
            {!resendEmail ? (
              <p className="resend-email-p" onClick={resendMFA}>
                Resend Email
              </p>
            ) : (
              <p style={{ marginTop: "10px" }}> {resendEmail}</p>
            )}
          </div>
          <div className="min-width-error-message">
            <ErrorText item={errorMessage} />
          </div>
        </div>
        <div className="wrapper-buttons wrapper-buttons-mfa">
          <button
            className="green-button green-haze-background"
            onClick={confirmMFAModal}
          >
            Confirm
          </button>
        </div>
        <div className="wrapper-buttons wrapper-buttons-mfa"></div>
      </Modal>
    </StyledModal>
  );
};

MFAModal.propTypes = {
  MAFModalIsOpen: PropTypes.bool,
  confirmMFAModal: PropTypes.func,
  handleChangeCodeAuthentication: PropTypes.func,
  errorMessage: PropTypes.string,
  mfa_code: PropTypes.string,
};

export default MFAModal;
