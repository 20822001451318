import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .layout {
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .undo-changes {
      margin-right: 10px;
      border: none;
      background: none;
      color: ${Colors.mineShaft};
    }

    .undo-changes:hover {
      color: ${Colors.gray};
    }
  }
  .second-button {
    display: flex;
    align-items: center;
    width: 166px;
  }

  label {
    color: ${Colors.orient};
  }

  .col {
    display: grid;
    position: relative;
  }

  .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  input {
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: ${Colors.alabaster} !important;
    border: 0;
    border-bottom: 1px solid ${Colors.silver};
    font-size: 18px;
    line-height: 22px;
    color: ${Colors.gray};
  }

  .inputEditing {
    color: ${Colors.doveGray};
  }

  input:focus-visible {
    outline: 0;
  }

  .container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sign {
    label {
      color: ${Colors.gray} !important;
    }
  }

  .disable-label {
    color: ${Colors.gray} !important;
  }

  .error {
    border-bottom: 1px solid ${Colors.valencia};
  }

  .error-txt {
    color: ${Colors.valencia};
    font-size: 16px;
    line-height: 19px;
    font-style: italic;
    font-weight: normal;
  }

  .title-details {
    color: ${Colors.mineShaft};
  }

  .white-background input {
    background: white !important;
  }

  .email-management {
    span {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;
