import styled from "styled-components";

export default styled.div`
  .display-none {
    display: none;
  }
  .container {
    max-height: 150px;
    width: 50%;
    background-color: white;
    position: absolute;
    left: 25%;
    top: 12%;
    flex-wrap: wrap;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
