import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Virtuoso } from "react-virtuoso";
import RemoveOutpatientModal from "./modals/RemoveOutpatient";
import Loader from "../../common/Loader/Loader";
import APIVariables from "../../../services/api-variables";
import { handleCatch, isValidTimestamp } from "../../../shared/helpers";
import { errorMessages } from "../../../constants/errorMessages.constants";
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import StyledHomecare from "../Homecare/StyledHomecare";
import { App } from "../../../routes/Constants";
import Axios from "../../../services/Axios";

const ConnectedOutpatients = () => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [existMoreItems, setExistMoreItems] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOutpatient, setSelectedOutpatient] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getOutpatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOutpatient = () => {
    navigate(App.CONNECTED_OUTPATIENT_ADD);
  };

  const getOutpatients = async () => {
    await Axios.get(APIVariables.HCP_OUTPATIENTS)
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setItems(data);
          setIsLoading(false);
          if (!!data.next) {
            setExistMoreItems(res.data.next);
          }
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const fetchMoreData = async () => {
    await Axios.get(
      existMoreItems ? existMoreItems : APIVariables.HCP_OUTPATIENTS
    )
      .then((res) => {
        const { status, data } = res;

        if (status === 200) {
          setItems(items.concat(data.results));
          const areMoreMoreItems = !!data.next;
          if (areMoreMoreItems) {
            setExistMoreItems(data.next);
          } else {
            setExistMoreItems(false);
          }
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const openModalRemoveOutpatient = (e, id) => {
    e.stopPropagation();
    setSelectedOutpatient(id);
    setIsModalOpen(true);
  };

  const redirectToEditOutpatient = (_, id) => {
    navigate(App.CONNECTED_OUTPATIENT_EDIT_DEFAULT + `${id}`);
  };

  const closeModal = () => {
    setSelectedOutpatient("");
    setErrorMessage("");
    setIsModalOpen(false);
  };

  const removeOutpatient = async () => {
    setErrorMessage("");
    await Axios.delete(APIVariables.HCP_OUTPATIENT_DELETE + selectedOutpatient)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          const newItems = items.filter((item) => {
            return item.connected_user_id !== selectedOutpatient;
          });
          setItems(newItems);
          closeModal();
        }
      })
      .catch(function (error) {
        const errors = error.response.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          Object.keys(errors).forEach((key) => {
            errorsArray.push(key + " - " + errors[key]);
          });
          setErrorMessage(errorsArray);
        }
      });
  };
  return (
    <StyledHomecare>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="wrapper-homecare main-max-width">
            <h1 className="h1 mineShaft-color">
              Connected Outpatient Pharmacy
            </h1>
            <button className="second-button" onClick={addOutpatient}>
              Add Outpatient
            </button>
          </div>
          <div className="layout mt-4">
            <div className="wrapper-infinitescroll">
              <div className="wrapper-table-infinitescroll">
                <div className="table-wrapper head">
                  <div className="name bold align-center">Outpatient Name</div>
                  <div className="position bold align-center">Postcode</div>
                  <div className="registered bold align-center">
                    Reg Valid Until
                  </div>
                  <div className="remove bold align-right">Remove</div>
                </div>
                <Virtuoso
                  endReached={existMoreItems && fetchMoreData}
                  style={{ height: "400px" }}
                  data={items}
                  itemContent={(index) => (
                    <div
                      className="head-table table-wrapper"
                      key={index}
                      onClick={(e) =>
                        redirectToEditOutpatient(
                          e,
                          items[index].connected_user_id
                        )
                      }
                    >
                      <div className="name align-center">
                        {items[index].name}
                      </div>
                      <div className="position align-center position-homecare">
                        {items[index].postcode}
                      </div>
                      <div className="registered align-center">
                        {isValidTimestamp(items[index].reg_valid_until)
                          ? moment(items[index].reg_valid_until).format(
                              "DD / MM / YYYY"
                            )
                          : items[index].reg_valid_until}
                      </div>
                      <div className="remove align-right">
                        <Remove
                          onClick={(e) =>
                            openModalRemoveOutpatient(
                              e,
                              items[index].connected_user_id
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          {modalIsOpen && (
            <RemoveOutpatientModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              removeOutpatient={removeOutpatient}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      )}
    </StyledHomecare>
  );
};
export default ConnectedOutpatients;
