import React from "react";
import PropTypes from "prop-types";

import useRoles from "../../../hooks/useRoles";

import StyledPatientsDetails from "./StyledPatientsDetails";

const PatientsDetails = ({ patientsDetails }) => {
  const { isHomecare, isOutpatient } = useRoles();

  return (
    <StyledPatientsDetails>
      <div className="container total-patients">
        <div className="row">
          <div className="col">
            <div className="font-size-18 orient">Total Patients</div>
            <div className="font-size-30 orient">
              {patientsDetails?.all_patients}
            </div>
          </div>
          <div className="col">
            <div className="font-size-18 orient">Active Patients</div>
            <div className="font-size-30 orient">
              {patientsDetails?.active_patients}
            </div>
          </div>
          <div className="col">
            <div className="font-size-18 orient">Overdue PAF</div>
            <div className="font-size-30 orient">
              {patientsDetails?.overdue_paf}
            </div>
          </div>
          <div className="col">
            <div className="font-size-18 orient">
              {isOutpatient || isHomecare ? "Dispensed PAF" : "Rejected PAF"}
            </div>
            <div className="font-size-30 orient">
              {isOutpatient || isHomecare
                ? patientsDetails?.dispensed_paf
                : patientsDetails?.rejected_paf}
            </div>
          </div>
        </div>
      </div>
    </StyledPatientsDetails>
  );
};

PatientsDetails.propTypes = {
  patientsDetails: PropTypes.object,
};

export default PatientsDetails;
