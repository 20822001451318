import React from "react";
import PropTypes from "prop-types";

const SearchPatientMap = ({
  patient,
  isPrescriber,
  redirectToPatient,
  displayDetails,
}) => {
  return (
    <div
      className="element"
      onClick={() => {
        let id =
          patient?.prescriptions &&
          patient?.prescriptions[(patient?.prescriptions?.length ?? 1) - 1]?.id;

        isPrescriber
          ? redirectToPatient(patient?.id)
          : redirectToPatient(patient?.id, id);
      }}
    >
      <div className="name">{patient.patient_initials}</div>
      <div className="details">{displayDetails(patient)}</div>
    </div>
  );
};
SearchPatientMap.propTypes = {
  patient: PropTypes.object,
  isPrescriber: PropTypes.bool,
  redirectToPatient: PropTypes.func,
  displayDetails: PropTypes.func,
};
export default SearchPatientMap;
