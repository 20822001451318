import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StyledRegister from "../../authentication/StyledRegister";
import CloseIcon from "../../../assets/icons/close.svg";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import PAFRenewModal from "./PAFRenewModal";
import ContinueConfirmationProvidedModal from "./ContinueConfirmationProvidedModal";
import InfoPAF from "../../common/InfoPAF/InfoPAF";
import ViewPAF from "../../common/ViewPAF/ViewPAF";
import APIVariables from "../../../services/api-variables";
import LocalStorage from "../../../shared/localStorage";
import { typeOfPAF } from "../../../constants/typeOfPAF";
import {
  getAge,
  handleCatch,
  isDispensingMoreThan3DaysBetween2Dates,
  isObject,
  removeEmptyValueAndSelectLabel,
} from "../../../shared/helpers";
import Loader from "../../common/Loader/Loader";
import { errorMessages } from "../../../constants/errorMessages.constants";
import DigitalPAF from "../PatientInitiation/DigitalPAF";
import RenewalModal from "./renewalModal";
import moment from "moment";
import BrochureWithText from "../Brochure/BrochureWithText";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";
import ChangeMoleculeModal from "./ChangeMoleculeModal";

const PAFRenew = () => {
  const typeOfForm =
    window.location.pathname.split("/")[3] === "womanOfCPRenew2"
      ? "womanOfCP"
      : window.location.pathname.split("/")[3];

  const [form, setForm] = useState({
    confirmation: false,
    confirmation_risk_prescriber: false,
    confirmPAF: false,
    confirmation_risk_patient: false,
    continueOffLabel: false,
    continueIndicationIncomplete: false,
    continueWithoutConfirmation: false,
    continueWithMaximumNumberOfCyclesExceeded: false,
    continueWithMaximumNumberOfWeeksExceeded: false,
    continueWithMaximumNumberOfWeeksExceededAgain: false,
    continueMaximumNumberOfCapsulesExceeded: false,
    continueWithPregnancyTestDateOutdated: false,
    continueWithPregnancyTestDateIncomplete: false,
    continueWithDateOfBirthUnderEighteen: false,
    continueNumberOfCyclesIncomplete: false,
    continueNumberOfWeeksIncomplete: false,
    continueCapsulePerCycleIncomplete: false,
  });

  const [initialFormCopy, setInitialFormCopy] = useState(null);
  const [unchangedForm, setUnchangedForm] = useState({});
  const { storageData } = useRoles();
  const patient_id = window.location.pathname.split("/")[4];
  const navigate = useNavigate();
  const [state, setState] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [
    confirmPrescriptionAuthorisationForm,
    setConfirmPrescriptionAuthorisationForm,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    type: "",
    modalState: 1,
    title: "",
    description: "",
    buttonText: "",
  });
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [validPassword, setValidPassword] = useState("");
  const [errorMessageFromServer, setErrorMessageFromServer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [indications, setIndications] = useState({});
  const [moleculeOptions, setMoleculeOptions] = useState({});
  const [capsulePerCycle, setCapsulePerCycle] = useState({});
  const [numberOfCycles, setNumberOfCycles] = useState({});
  const [numberOfWeeks, setNumberOfWeeks] = useState({});
  const [prescriptionConfirmationRisks, setPrescriptionConfirmationRisks] =
    useState({});
  const [prescriberConfirmations, setPrescriberConfirmations] = useState({});
  const [prescriptionToRenew, setPresctiptionToRenew] = useState({});
  const patientId = window.location.pathname.split("/")[4];
  const prescriptionId = window.location.pathname.split("/")[5];
  const isRenewal = !!patientDetails?.prescriptions?.length;
  const [isDisplayRenewal, setDisplayRenewal] = useState(false);
  const [isChangedMolecule, setIsChangedMolecule] = useState(false);
  const [isConfirmedRenewal, setConfirmedRenewal] = useState(false);
  const [isApprovedPAFCompleted, setIsApprovedPAFCompleted] = useState(false);
  const [moleculeOption, setMoleculeOption] = useState();
  const [selectedMolecule, setSelectedMolecule] = useState();
  const [moleculeid, setMoleculeid] = useState();

  useEffect(() => {
    fetchData();
    getProfile();
    localStorage.setItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL, true);

    // After referring a patient, you will be redirected to this page, and because when you submit, you are at the bottom of the page, this code force you to come to the top of the page
    window.scrollTo(0, 0);

    return () => {
      localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const displayWeeks = form?.schedule_display_type === "weeks";

  const treatmentFormText = patientDetails?.prescriber?.products.find(
    (element) => element.product_id === moleculeid?.value
  );

  useEffect(() => {
    setTotalCapsules({ ...prescriptionToRenew, ...form });
  }, [JSON.stringify(form), JSON.stringify(prescriptionToRenew)]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPatientDetails = async (newFormData) => {
    const patienthasAtLeastOnPAFFormInOrderToPrepopulate =
      !!window.location.pathname.split("/")[5];
    const res = await Axios.get(
      APIVariables.PATIENTS_DETAILS + patientId
    ).catch((error) => {
      handleCatch(error);
    });

    // Go back after creating/renewing a PAF, redirect the user to View PAF instead of the PAF Form
    if (
      res?.data?.prescriptions?.length &&
      res.data.prescriptions[0].status_name === "Submitted"
    ) {
      navigate(
        App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT +
          res.data.id +
          "/" +
          res.data.prescriptions[0].id
      );
    }

    if (patienthasAtLeastOnPAFFormInOrderToPrepopulate) {
      let pfa_issued =
        res.data?.prescriptions && res.data?.prescriptions[0].pfa_issued;

      setPatientDetails({ ...(res.data.patient || res.data), pfa_issued });
      setIsLoading(false);
    } else {
      setPatientDetails(res.data);
      const formCopy = {
        ...newFormData,
        pregnancy_test: res.data.pregnancy_test,
        type: res.data.type,
        patient_dob: res.data.dob,
        patient_initials: res.data.patient_initials,
      };

      setForm(formCopy);
      setIsLoading(false);
    }
  };

  const setTotalCapsules = (formCopy) => {
    if (form?.schedule_display_type === "weeks_per_cycle") {
      const TotalweeksPerCycle =
        form?.schedule_display_type === "weeks_per_cycle" &&
        formCopy?.number_cycles?.id *
          (formCopy.weeks_per_cycle?.id !== 0
            ? formCopy.weeks_per_cycle?.id
            : formCopy?.other);
      setForm((prevState) => ({
        ...prevState,
        total_weeks_supply:
          form.schedule_display_type === "weeks_per_cycle" &&
          (TotalweeksPerCycle || ""),
      }));
    }

    if (!!formCopy.capsules) {
      // Calculate the sum of the values using the reduce method
      const capsuleValues = Object.values(formCopy.capsules);

      const sumOfValues = capsuleValues
        ?.map((element) =>
          element.label === 0 ? element.other : element.label
        )
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const numberOfCyles =
        formCopy.schedule_display_type === "weeks"
          ? formCopy.number_weeks?.id === 0
            ? formCopy?.other
            : formCopy.number_weeks?.id
          : formCopy.number_cycles?.id;
      const totalCapsules = +sumOfValues * (numberOfCyles || 0);

      setForm((prevState) => ({
        ...prevState,
        capsule_strength: sumOfValues,
        total_capsules:
          formCopy.schedule_display_type === "weeks"
            ? Math.ceil(totalCapsules / 4)
            : totalCapsules,
      }));
    }
  };

  const getProfile = async () => {
    const profile = await Axios.get(APIVariables.USERS_PROFILE).catch(
      (error) => {
        handleCatch(error);
      }
    );

    const newFormData = {
      ...form,
      hospital_address:
        profile?.data?.hospital.value + ", " + profile?.data?.hospital?.address,
      pharmacy_address: profile?.data?.pharmacy_address,
    };

    setForm(newFormData);

    getPatientDetails(newFormData);
  };

  const [dropdownValue, setDropdownValue] = useState();
  async function changeDropdowns(values) {
    try {
      const response = await Axios.get(
        APIVariables.PRESCRIPTION_FIELDS + values?.value + `/${typeOfForm}`
      );
      const dropDownValuesChange = response.data; // This is the actual response data
      setDropdownValue(dropDownValuesChange);
      setIsChangedMolecule(false);
      const updatedForm = { ...initialFormCopy };
      updatedForm.indication = { value: undefined, label: "Select" };
      updatedForm.molecule = {
        ...values,
        isChanged: true,
      };
      updatedForm.schedule_display_type =
        dropDownValuesChange.schedule_display_type;
      updatedForm.max_capsules_m_or_wncbp =
        dropDownValuesChange.max_capsules_m_or_wncbp;
      updatedForm.max_capsules_wcbp = dropDownValuesChange.max_capsules_wcbp;
      updatedForm.max_weeks_m_or_wncbp =
        dropDownValuesChange.max_weeks_m_or_wncbp;
      updatedForm.max_weeks_wcbp = dropDownValuesChange.max_weeks_wcbp;
      setPrescriptionConfirmationRisks(
        dropDownValuesChange.prescription_confirmation_risks
      );
      setPrescriberConfirmations(dropDownValuesChange.prescriber_confirmations);

      setForm(updatedForm);
      getHCP(values?.value);
    } catch (error) {
      handleCatch(error);
    }
  }
  const closeIsChangedMoleculeModal = () => {
    setIsChangedMolecule(false);
  };

  async function fetchData() {
    let productID;
    let res;
    if (patientId) {
      productID = await Axios.get(
        APIVariables.PRESCRIPTIONS +
          patientId +
          APIVariables.PRESCRIPTIONS_RENEW
      ).catch((error) => {
        handleCatch(error);
      });
    }

    let dropDownValues;

    if (productID.data.prescription.product_id) {
      dropDownValues = await Axios.get(
        APIVariables.PRESCRIPTION_FIELDS +
          productID.data.prescription.product_id +
          `/${productID.data.prescription.patient.type}`
      ).catch((error) => {
        handleCatch(error);
      });
    }
    setDropdownValue(dropDownValues.data);

    if (!dropDownValues) return;

    const {
      indication,
      product,
      capsule_cycle,
      n_cycles,
      n_weeks,
      prescription_confirmation_risks,
      prescriber_confirmations,
    } = await dropDownValues.data;

    const { prescription } = await productID.data;

    setIndications(indication);
    setMoleculeOptions(product);
    setCapsulePerCycle(capsule_cycle);
    setNumberOfCycles(n_cycles);
    setNumberOfWeeks(n_weeks);
    setPrescriptionConfirmationRisks(prescription_confirmation_risks);
    setPrescriberConfirmations(prescriber_confirmations);
    if (!!prescription) {
      let patientDet = prescription?.patient;
      delete prescription?.patient;
      delete prescription.total_capsules;
      delete prescription?.pregnancy_test;
      delete prescription?.total_weeks_supply;
      delete prescription?.weeks_per_cycle;

      Object.keys(prescription).forEach((key) => {
        if (key?.includes("capsule_cycle_") || key?.includes("n_cycles")) {
          delete prescription[key];
        }
      });
      let indicationRenew = indication.find(
        (indication) => indication?.label === prescription.indication
      );
      setPresctiptionToRenew({
        ...prescription,
        ...patientDet,
        indication: indicationRenew,
      });
    }

    if (prescriptionId) {
      res = await Axios.get(
        APIVariables.PRESCRIPTIONS_GET + prescriptionId
      ).catch((error) => {
        handleCatch(error);
      });

      const newForm = {
        ...form,
        indication: {
          value: res?.data?.indication_id,
          label: res?.data?.indication || "Select",
        },
        indication_other:
          !!res?.data?.indication_other && res?.data?.indication_other,
        molecule: {
          value: res?.data?.product_id,
          label: res?.data?.product,
        },
        clinical_trial:
          !!res?.data?.clinical_trial && res?.data?.clinical_trial,
        status: res.data.status,
        patient_dob: res.data.patient.dob,
        schedule_display_type: res?.data?.schedule_display_type,
        max_capsules_m_or_wncbp: res?.data?.max_capsules_m_or_wncbp,
        max_capsules_wcbp: res?.data?.max_capsules_wcbp,
        max_weeks_m_or_wncbp: res?.data?.max_weeks_m_or_wncbp,
        max_weeks_wcbp: res?.data?.max_weeks_wcbp,
        prescription_confirmation_risks: prescription_confirmation_risks?.map(
          (risk) => ({
            ...risk,
            confirmation_risk_patient: false,
          })
        ),
      };
      if (initialFormCopy === null) {
        setInitialFormCopy(newForm);
      }
      setForm(newForm);
      setUnchangedForm(newForm);
      getHCP(newForm?.molecule?.value);

      if (getAge(res.data.patient.dob) < 18) {
        setDateOfirthUnderEigteen();
      }

      checkEarlyRenewal(res?.data?.patient?.type, res?.data?.pfa_issued);
    }
  }

  const setDateOfirthUnderEigteen = () => {
    setForm((prevState) => ({
      ...prevState,
      continueWithDateOfBirthUnderEighteen: true,
    }));
  };

  const checkEarlyRenewal = (patientType, lastPAFDate) => {
    const currentDate = moment().format("l").split("/");
    const formatCurrentDate =
      currentDate[2] + "/" + currentDate[0] + "/" + currentDate[1];

    const date1 = new Date(formatCurrentDate);
    const date2 = new Date(lastPAFDate);

    if (patientType === "womanOfCP") {
      // 3 weeks
      if (Math.abs(moment(date1).diff(moment(date2), "days")) <= 21) {
        setDisplayRenewal(true);
      } else {
        setConfirmedRenewal(true);
      }
    } else {
      //  11 weeks
      if (Math.abs(moment(date1).diff(moment(date2), "days")) <= 77) {
        setDisplayRenewal(true);
      } else {
        setConfirmedRenewal(true);
      }
    }
  };

  function closeRegisterForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.DASHBOARD);
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name?.includes("capsule_cycle_other")) {
      let parsedValue = parseInt(value);
      value = parsedValue ? parsedValue.toString() : value;
      if (+value <= 0) {
        value = "1";
      } else if (+value > 200) {
        value = "200";
      }
    }

    const formCopy = { ...form, [name]: value };
    setForm(formCopy);

    if (name === "pregnancy_test") {
      setForm((prevState) => ({
        ...prevState,
        continueWithPregnancyTestDateOutdated: false,
        continueWithPregnancyTestDateIncomplete: false,
      }));
    }
  };

  const getPatientTif = async (molecule) => {
    const res = await Axios.get(
      APIVariables.PRESCRIPTION_PATIENT_TIF +
        molecule.value +
        "/" +
        patientDetails.patient_type_id
    ).catch((error) => {
      handleCatch(error);
    });
    setMoleculeOption(molecule);
    setSelectedMolecule(res?.data);
    setIsChangedMolecule(true);
  };

  const handleChangeSelect = (selectedElement, name, values) => {
    if (selectedElement === "pregnancy_test") {
      setForm({
        ...form,
        pregnancy_test: new Date(name),
        continueWithPregnancyTestDateOutdated: false,
        continueWithPregnancyTestDateIncomplete: false,
      });
      return;
    }

    if (typeof selectedElement === "string" && name === "capsule_per_cycle") {
      let parsedValue = parseInt(selectedElement);
      selectedElement = parsedValue ? parsedValue.toString() : selectedElement;
      if (+selectedElement <= 0) {
        selectedElement = "1";
      } else if (+selectedElement > 200) {
        selectedElement = "200";
      }
      let maxLength = -1;
      const shouldSetValue = +values.length <= maxLength;

      const formCopy = {
        ...form,
        [selectedElement]: selectedElement,
      };

      if (maxLength === -1) {
        setForm(formCopy);
      } else {
        if (shouldSetValue) {
          setForm(formCopy);
        }
      }
    }

    if (name === "capsule_per_cycle") {
      const copiedRegisterPatient = { ...form };
      const valuee = values.id;

      if (!Array.isArray(copiedRegisterPatient.capsules)) {
        copiedRegisterPatient.capsules = [];
      }
      const existingIndex = copiedRegisterPatient.capsules.findIndex(
        (capsule) => capsule.id === valuee
      );

      if (existingIndex !== -1) {
        copiedRegisterPatient.capsules[existingIndex] = {
          id: valuee,
          label:
            selectedElement.value === undefined ? 0 : selectedElement.value,
          other:
            selectedElement.value === 0
              ? 1
              : typeof selectedElement === "string" && +selectedElement,
          capsuleStrength: values,

          strengths: {
            [valuee]:
              selectedElement.value === undefined
                ? +selectedElement
                : selectedElement.value,
          },
        };
      } else {
        copiedRegisterPatient.capsules.push({
          id: valuee,
          label: selectedElement.value,
          capsuleStrength: values,
          other: selectedElement.value === 0 ? 1 : false,

          strengths: {
            [valuee]:
              selectedElement.value === undefined
                ? +selectedElement
                : selectedElement.value === 0
                ? 1
                : selectedElement.value,
          },
        });
      }

      copiedRegisterPatient.strengths = {};

      // Iterate through capsules and merge strengths objects
      copiedRegisterPatient.capsules.forEach((capsule) => {
        const { strengths } = capsule;
        Object.assign(copiedRegisterPatient.strengths, strengths);
      });
      for (const key in copiedRegisterPatient.strengths) {
        if (copiedRegisterPatient.strengths[key] === null) {
          delete copiedRegisterPatient.strengths[key];
        }
      }

      copiedRegisterPatient.capsules = copiedRegisterPatient.capsules.filter(
        (obj) => obj.label !== null
      );

      copiedRegisterPatient.continueCapsulePerCycleIncomplete =
        selectedElement.value === null && false;

      const updatedForm = {
        ...form, // Copy all fields from the original form
        ...copiedRegisterPatient, // Overwrite with fields from the copied form
      };

      setForm(updatedForm);
    }

    if (name === "number_cycles") {
      const formcopy = {
        ...form,
        [name]: selectedElement.id === 0 ? null : selectedElement,
        continueNumberOfCyclesIncomplete: selectedElement.id === 0 && false,
        continueWithMaximumNumberOfWeeksExceeded: false,
      };
      setForm(formcopy);
    }
    if (name === "number_weeks") {
      const formcopy = {
        ...form,
        [name]: selectedElement,
        continueNumberOfWeeksIncomplete:
          selectedElement.id === 0 && selectedElement.label === "None" && false,
        n_weeks_other:
          selectedElement.label === "Other (Please State)" ? 1 : null,
        other: selectedElement.label === "Other (Please State)" ? 1 : null,
      };
      setForm(formcopy);
    }

    if (name === "number_weeks_other") {
      let parsedValue = parseInt(selectedElement);

      selectedElement = parsedValue ? parsedValue.toString() : selectedElement;
      if (+selectedElement <= 0) {
        selectedElement = "1";
      } else if (+selectedElement > 12) {
        selectedElement = "12";
      }
      let maxLength = -1;
      const shouldSetValue = +selectedElement.length <= maxLength;

      const formCopy = {
        ...form,
        n_weeks_other: +selectedElement,
        other: +selectedElement,
        continueWithMaximumNumberOfWeeksExceeded: false,
      };

      if (maxLength === -1) {
        setForm(formCopy);
      } else {
        if (shouldSetValue) {
          setForm(formCopy);
        }
      }

      setTotalCapsules(formCopy);
    }

    if (name === "weeks_per_cycle") {
      const formcopy = {
        ...form,
        [name]: selectedElement,
        continueWithMaximumNumberOfWeeksExceeded: false,
        continueNumberOfWeeksIncomplete:
          selectedElement.id === 0 && selectedElement.label === "None" && false,
      };

      setForm(formcopy);
      setTotalCapsules(formcopy);
    }

    if (name === "clinical_trial" || name === "indication_other") {
      const formCopy = {
        ...form,

        [name]: selectedElement,
      };
      setForm(formCopy);
    }
    if (name === "indication") {
      if (selectedElement.id === -1) {
        setForm(() => ({
          ...form,
          indication: { value: undefined, label: "Select" },
          other_indication: null,
          clinical_trial: null,
        }));
      } else {
        setForm(() => ({
          ...form,
          indication: selectedElement,
          clinical_trial: null, // Set clinical_trial to empty string
          other_indication: null,
        }));
      }
    }

    if (name === "Molecule") {
      getPatientTif(selectedElement);
      setMoleculeid(selectedElement);
    }

    if (name === "indication" && +selectedElement.value !== "other") {
      setForm((prevState) => ({
        ...prevState,
        continueOffLabel: false,
        continueIndicationIncomplete: false,
      }));
    }
  };

  const [confirmationRisks, setConfirmationRisks] = useState(
    form?.prescription_confirmation_risks || []
  );
  const [confirmationRisksPrescriber, setConfirmationRisksPrescriber] =
    useState(form?.prescriber_confirmations || []);

  // prescriptionConfirmationRisks and prescriberConfirmations come without confirmation_risk_patient and prescriber_confirmations, so here I initialize them.
  useEffect(() => {
    if (
      prescriptionConfirmationRisks &&
      Object.keys(prescriptionConfirmationRisks).length !== 0
    ) {
      const updatedConfirmationRisks = prescriptionConfirmationRisks?.map(
        (risk) => ({
          ...risk,
          confirmation_risk_patient: false,
        })
      );
      setConfirmationRisks(updatedConfirmationRisks);
    }
    if (
      prescriberConfirmations &&
      Object.keys(prescriberConfirmations).length !== 0
    ) {
      const updatedprescriberConfirmations = prescriberConfirmations?.map(
        (risk) => ({
          ...risk,
          prescriber_confirmations: false,
        })
      );

      setConfirmationRisksPrescriber(updatedprescriberConfirmations);
    }
  }, [prescriptionConfirmationRisks, prescriberConfirmations]);

  // Prepare confirmationRisksPrescriber and confirmationRisks for the BE
  const confirmationRisksPrescriberFormatted =
    confirmationRisksPrescriber.reduce((acc, item) => {
      acc[item.id] = item.prescriber_confirmations;
      return acc;
    }, {});

  const confirmationRisksFormatted = confirmationRisks.reduce((acc, item) => {
    acc[item.id] = item.confirmation_risk_patient;
    return acc;
  }, {});

  const [allChecked, setAllChecked] = useState(false);
  const [allCheckedPrescriber, setAllCheckedPrescriber] = useState(false);

  useEffect(() => {
    // Calculate allChecked based on checkedItems
    const calculatedAllChecked =
      confirmationRisks.length > 0 &&
      confirmationRisks.every((risk) => risk.confirmation_risk_patient);

    const calculatedAllCheckedPrescriber =
      confirmationRisksPrescriber.length > 0 &&
      confirmationRisksPrescriber.every(
        (risk) => risk.prescriber_confirmations
      );

    // Update the allChecked state variable
    setAllChecked(calculatedAllChecked);
    setAllCheckedPrescriber(calculatedAllCheckedPrescriber);
  }, [confirmationRisks, confirmationRisksPrescriber]);

  const handleChangeCheckBox = (event, confirmationRiskId) => {
    const { name, checked } = event.target;

    if (name.includes("confirmation-")) {
      setConfirmationRisks((prevRisks) =>
        prevRisks.map((risk) =>
          risk.id === confirmationRiskId
            ? { ...risk, confirmation_risk_patient: checked }
            : risk
        )
      );
    } else if (name.includes("checkbox-prescriber-")) {
      setConfirmationRisksPrescriber((prevRisks) =>
        prevRisks.map((risk) =>
          risk.id === confirmationRiskId
            ? { ...risk, prescriber_confirmations: checked }
            : risk
        )
      );
    }
  };

  useEffect(() => {
    if (confirmationRisksPrescriber && allCheckedPrescriber) {
      setForm((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: false,
        confirmation_risk_prescriber: allCheckedPrescriber,
      }));
    } else if (confirmationRisksPrescriber && !allCheckedPrescriber) {
      setForm((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: false,
        confirmation_risk_prescriber: allCheckedPrescriber,
      }));
    }

    if (confirmationRisks && allChecked) {
      setForm((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: false,
        confirmation_risk_patient: allChecked,
      }));
    } else if (confirmationRisks && !allChecked) {
      setForm((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: false,
        confirmation_risk_patient: allChecked,
      }));
    }
  }, [
    confirmationRisksPrescriber,
    allCheckedPrescriber,
    allChecked,
    confirmationRisks,
  ]);

  useEffect(() => {
    setForm({
      ...form,
      prescription_confirmation_risks: confirmationRisks,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationRisks]);

  const previous = () => {
    navigate(-1);
  };

  const previousBackState = () => {
    setState(state - 1);
  };

  const closeConfirmationProvided = () => {
    setOpenModal(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      type: "",
      modalState: 1,
      hasSecondState: false,
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  const closeConfirm = () => {
    setConfirmPrescriptionAuthorisationForm(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      type: "",
      modalState: 1,
      hasSecondState: false,
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  const continueConfirmationNotProvided = () => {
    if (
      errorMessage.title === errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_TITLE
    ) {
      closeConfirm();
      setForm((prevState) => ({
        ...prevState,
        continueWithMaximumNumberOfCyclesExceeded: true,
      }));
    }

    if (errorMessage.title === "Maximum number of weeks exceeded") {
      closeConfirm();
      setForm((prevState) => ({
        ...prevState,
        continueWithMaximumNumberOfWeeksExceeded: true,
      }));
    }

    if (errorMessage.title === " Capsules outside permitted Quantity") {
      closeConfirm();
      setForm((prevState) => ({
        ...prevState,
        continueWithMaximumNumberOfWeeksExceededAgain: true,
      }));
    }

    if (errorMessage.title === "Capsules outside permitted Quantity") {
      closeConfirm();
      setForm((prevState) => ({
        ...prevState,
        continueMaximumNumberOfCapsulesExceeded: true,
      }));
    }

    if (errorMessage.title === "Continue with off-label use") {
      setForm((prevState) => ({
        ...prevState,
        continueOffLabel: true,
      }));
    }

    if (errorMessage.title === errorMessages.INDICATION_INCOMPLETE_TITLE) {
      setForm((prevState) => ({
        ...prevState,
        continueIndicationIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE) {
      setForm((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: true,
      }));
    }

    if (errorMessage.title === errorMessages.OUTDATED_PREGANACY_TEST_TITLE) {
      setForm((prevState) => ({
        ...prevState,
        continueWithPregnancyTestDateOutdated: true,
      }));
    }

    if (
      errorMessage.title === errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE
    ) {
      setForm((prevState) => ({
        ...prevState,
        continueWithPregnancyTestDateIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.OUTDATED_DATE_BIRTH_TITLE) {
      setForm((prevState) => ({
        ...prevState,
        continueWithDateOfBirthUnderEighteen: true,
      }));
    }

    if (
      errorMessage.title === errorMessages.CAPSULES_PER_CYCLE_INCOMPLETE ||
      errorMessage.title === errorMessages.CAPSULES_PER_WEEKS_INCOMPLETE
    ) {
      setForm((prevState) => ({
        ...prevState,
        continueCapsulePerCycleIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.NUMBER_OF_CYCLES_INCOMPLETE) {
      setForm((prevState) => ({
        ...prevState,
        continueNumberOfCyclesIncomplete: true,
      }));
    }
    if (errorMessage.title === errorMessages.NUMBER_OF_WEEKS_INCOMPLETE) {
      setForm((prevState) => ({
        ...prevState,
        continueNumberOfWeeksIncomplete: true,
      }));
    }
    setOpenModal(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      type: "",
      modalState: 1,
      hasSecondState: false,
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  const handleChangeValidPassword = (event) => {
    setValidPassword(event.target.value);
  };

  const validateVerify = async () => {
    if (errorMessage.type === "error") {
      if (errorMessage.modalState === 1) {
        setErrorMessage((prevState) => ({
          ...prevState,
          modalState: 2,
          description: `This PAF contains incomplete/non-compliant data. If you wish to proceed outside the terms of the  ${hcp?.name} PPP based on your clinical understanding of your patient's needs please re-enter your password to document this decision.`,
          secondDescription: "",
          buttonText: "Submit",
        }));
      }
      if (errorMessage.modalState === 2) {
        const res = await Axios.post(APIVariables.USERS_PASSWORD_VALIDATE, {
          password: validPassword,
        }).catch(function (error) {
          handleCatch(error);

          const errors = error?.response?.data;
          if (typeof errors === "string") {
            setErrorMessageFromServer([errorMessages.INTERNAL_SERVER_ERROR]);
          } else {
            const errorsArray = [];
            if (!!errors) {
              Object.keys(errors).forEach((key) => {
                if (isObject(errors[key])) {
                  Object.keys(errors[key]).forEach((secondKey) => {
                    errorsArray.push(errors[key][secondKey][0]);
                  });
                } else {
                  errorsArray.push(errors[key]);
                }
              });
              setErrorMessageFromServer(errorsArray);
            } else {
              setErrorMessageFromServer([error.message]);
            }
          }
        });

        if (res?.status === 200) {
          closeConfirm();
          setErrorMessageFromServer([]);
          setForm((prevState) => ({
            ...prevState,
            confirmPAF: true,
          }));
        }
      }
    } else {
      // Form is Valid
      finishForm();
    }

    // Form is not valid
    if (isApprovedPAFCompleted) {
      finishForm();
    }
  };

  const verifyPAF = () => {
    setConfirmPrescriptionAuthorisationForm(true);

    if (
      !form.confirmPAF &&
      (form.continueWithoutConfirmation ||
        form.continueWithMaximumNumberOfCyclesExceeded ||
        form.continueWithMaximumNumberOfWeeksExceeded ||
        form.continueWithMaximumNumberOfWeeksExceededAgain ||
        form.continueMaximumNumberOfCapsulesExceeded ||
        form.continueIndicationIncomplete ||
        form.continueWithPregnancyTestDateIncomplete ||
        form.continueOffLabel ||
        form.continueWithPregnancyTestDateOutdated ||
        form.continueWithDateOfBirthUnderEighteen ||
        form.continueNumberOfCyclesIncomplete ||
        form.continueNumberOfWeeksIncomplete ||
        form.continueCapsulePerCycleIncomplete)
    ) {
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: true,
        title: "Non-Compliant PAF",
        description:
          "This PAF is non-compliant. Please amend the following details.",
        secondDescription: `The following issues were identified: <ul class="custom-ul"> ${
          form.continueWithoutConfirmation
            ? "<li class='bold'>- Patient counselling not confirmed</li>"
            : ""
        }
        ${
          form.continueWithMaximumNumberOfCyclesExceeded
            ? "<li class='bold'>- Maximum number of cycles exceeded for this patient</li>"
            : ""
        }
        ${
          form.continueWithMaximumNumberOfWeeksExceeded
            ? "<li class='bold'>- Maximum number of weeks exceeded for this patient</li>"
            : ""
        }
        ${
          form.continueMaximumNumberOfCapsulesExceeded
            ? "<li class='bold'>- Capsules outside permitted Quantity</li>"
            : ""
        }
        ${
          form.continueWithDateOfBirthUnderEighteen
            ? "<li class='bold'>- You selected 'Continue with off-label use for Patient Date Of Birth'</li>"
            : ""
        }
        ${
          form.continueOffLabel
            ? "<li class='bold'>- You selected 'Continue with off-label use for Indication'</li>"
            : ""
        }
        ${
          form.continueIndicationIncomplete
            ? "<li class='bold'>- You selected 'Continue with Indication Incomplete'</li>"
            : ""
        }
        ${
          form.continueNumberOfCyclesIncomplete
            ? "<li class='bold'>- You selected 'Number of Cycles Incomplete'</li>"
            : ""
        }
        ${
          form.continueNumberOfWeeksIncomplete
            ? "<li class='bold'>- You selected 'Number of Weeks Incomplete'</li>"
            : ""
        }
        ${
          form.continueCapsulePerCycleIncomplete
            ? `<li class='bold'>- You selected 'Capsules per ${
                displayWeeks ? "Weeks" : "Cycles"
              }  Incomplete'</li>`
            : ""
        }
        ${
          form.continueWithPregnancyTestDateOutdated
            ? "<li class='bold'>- Pregnancy test date was outside the permitted date range</li>"
            : ""
        }
        ${
          form.continueWithPregnancyTestDateIncomplete
            ? "<li class='bold'>- You selected 'Continue with Pregnancy Test Date Incomplete'</li>"
            : ""
        }
   
          </ul> `,
        buttonText: "Continue",
      }));
    } else {
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "valid",
        title: "Prescription Authorisation Form Completed",
        description:
          "A copy of the prescription authorisation form will be available to the hospital inpatient pharmacy. ",
        secondDescription: "",
        buttonText: "Complete",
      }));
    }
  };
  const finishForm = async () => {
    let PAFRenewed = {
      product: form.molecule.value,
      strengths: form.strengths,
      indication: form?.indication?.id
        ? form?.indication?.id
        : form?.indication?.value,
      indication_other: form?.indication_other ? form?.indication_other : null,
      clinical_trial: form?.clinical_trial ? form?.clinical_trial : null,
      total_capsules: fullForm?.total_capsules,
      prescription_confirmation_risks:
        typeOfForm === "womanOfNCP" ? true : confirmationRisksFormatted,
      hospital_name_and_address:
        storageData?.hospital?.value + ", " + storageData?.hospital?.address,
      n_cycles: form?.number_cycles?.id,
      n_weeks: form?.number_weeks?.id,
      n_weeks_other: form?.number_weeks?.id === 0 ? form?.n_weeks_other : 0,
      weeks_per_cycle:
        form?.weeks_per_cycle?.id !== 0
          ? +form?.weeks_per_cycle?.id
          : form?.n_weeks_other,
      prescriber_confirmations: confirmationRisksPrescriberFormatted,
      ...(form.pregnancy_test && {
        pregnancy_test: moment(form.pregnancy_test).format("YYYY-MM-DD"),
      }),
    };

    if (typeOfForm === typeOfPAF.WOMANOFNCP) {
      delete PAFRenewed.confirmation_risk;
    }

    const res = await Axios.post(
      APIVariables.PRESCRIPTIONS +
        patient_id +
        APIVariables.PRESCRIPTIONS_RENEW,
      PAFRenewed
    ).catch(function (error) {
      handleCatch(error);

      const errors = error?.response?.data;
      if (typeof errors === "string") {
        setErrorMessageFromServer([errorMessages.INTERNAL_SERVER_ERROR]);
      } else {
        const errorsArray = [];
        if (!!errors) {
          Object.keys(errors).forEach((key) => {
            if (isObject(errors[key])) {
              Object.keys(errors[key]).forEach((secondKey) => {
                errorsArray.push(key + " - " + errors[key][secondKey][0]);
              });
            } else {
              errorsArray.push(key + " - " + errors[key]);
            }
          });
          setErrorMessageFromServer(errorsArray);
        } else {
          setErrorMessageFromServer([error.message]);
        }
      }
    });
    if (res?.status === 200) {
      closeConfirm();
      setForm((prevState) => ({
        ...prevState,
        confirmPAF: true,
      }));
      setIsApprovedPAFCompleted(true);
    }
  };

  const onClickFinishButton = () => {
    setErrorMessageFromServer([]);
    navigate(App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT + patient_id);
  };

  const checkIfNumberOfWeeksExceeded = (form) => {
    const isWOMANOFCP = typeOfForm === typeOfPAF.WOMANOFCP;
    const isMALE = typeOfForm === typeOfPAF.MALE;
    const isWOMANOFNCP = typeOfForm === typeOfPAF.WOMANOFNCP;

    const maxWeeksWCBP = form?.max_weeks_wcbp || form?.max_weeks_wcbp;
    const maxWeeksMOrWNCBP =
      form?.max_weeks_m_or_wncbp || form?.max_weeks_m_or_wncbp;

    const nrCycles = !!form?.number_weeks?.id
      ? form?.number_weeks?.id
      : !!form?.n_weeks_other
      ? form?.n_weeks_other
      : !!form?.other
      ? parseFloat(form?.other)
      : parseFloat(form?.n_weeks);

    return isWOMANOFCP
      ? maxWeeksWCBP && maxWeeksWCBP > 0
        ? nrCycles > maxWeeksWCBP
        : false
      : isMALE || isWOMANOFNCP
      ? maxWeeksMOrWNCBP && maxWeeksMOrWNCBP > 0
        ? nrCycles > maxWeeksMOrWNCBP
        : false
      : false;
  };

  const isChangedWeeksPerCycle = () => {
    const checkNewNCycles =
      form?.weeks_per_cycle?.id === 0
        ? form?.n_weeks_other
        : form?.weeks_per_cycle?.id;

    if (checkNewNCycles) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfTotalSupplyWeeksExceeded = (form) => {
    const isWOMANOFCP = typeOfForm === typeOfPAF.WOMANOFCP;
    const isMALE = typeOfForm === typeOfPAF.MALE;
    const isWOMANOFNCP = typeOfForm === typeOfPAF.WOMANOFNCP;

    const maxWeeksWCBP = form?.max_weeks_wcbp;
    const maxWeeksMOrWNCBP = form?.max_weeks_m_or_wncbp;

    return isWOMANOFCP
      ? maxWeeksWCBP && maxWeeksWCBP > 0
        ? isChangedWeeksPerCycle() && form?.total_weeks_supply > maxWeeksWCBP
        : false
      : isMALE || isWOMANOFNCP
      ? maxWeeksMOrWNCBP && maxWeeksMOrWNCBP > 0
        ? isChangedWeeksPerCycle() &&
          form?.total_weeks_supply > maxWeeksMOrWNCBP
        : false
      : false;
  };

  const checkIfNumberOfCyclesExceeded = (form) => {
    if (typeOfForm === typeOfPAF.MALE || typeOfForm === typeOfPAF.WOMANOFNCP) {
      const getNumberOfCyles = form?.number_cycles?.id;
      if (getNumberOfCyles > 3) {
        return true;
      } else {
        return false;
      }
    } else {
      const getNumberOfCyles = form?.number_cycles?.id;
      if (getNumberOfCyles > 1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const printPAF = async () => {
    Axios.get(APIVariables.DOWNLOAD_PAF + patient_id)
      .then((response) => {
        return response.data.report;
      })
      .then(async function (base64) {
        const base64Pdf = await fetch(`data:application/pdf;base64,${base64}`);
        return base64Pdf.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = `PAF.pdf`;
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const [hcp, setHcp] = useState();

  const getHCP = async (productid) => {
    try {
      const config = {
        headers: {
          Authorization: storageData?.token,
        },
      };

      const data = await Axios.get(
        APIVariables.HCP_BROCHURE_INFO + productid,
        config
      );
      setHcp(data.data);
    } catch (error) {
      handleCatch(error);
    }
  };

  async function nextPage() {
    setIsSubmitting(true);

    if (!isConfirmedRenewal && isRenewal) {
      checkEarlyRenewal(patientDetails?.type, patientDetails?.pfa_issued);
      return;
    }

    let confirmarmation_risk_prescriber_local =
      form.confirmation_risk_prescriber;

    // When Renew a form and the previous form was approve or rejected and the prescriber did not change the checkbox
    if (form.status === "Approved" || form.status === "Rejected") {
      if (
        form.confirmation_risk_prescriber ===
        unchangedForm.confirmation_risk_prescriber
      ) {
        confirmarmation_risk_prescriber_local = form.confirmation_risk_pharmacy;
        setForm((prevState) => ({
          ...prevState,
          confirmation_risk_prescriber: form.confirmation_risk_pharmacy,
        }));
      }
    }

    if (!areAllFieldsPrescriberValidFirst) {
      setShowErrors(true);
      return;
    }

    const isNotSelectedAnyCapsulePerCycle = () => {
      if (form?.capsules?.length > 0) {
        const result = form?.capsules?.some(
          (capsule) => Boolean(capsule.label) || Boolean(capsule.other)
        );
        return result;
      } else {
        return false;
      }
    };

    if (
      form?.schedule_display_type !== "weeks_per_cycle" &&
      checkIfNumberOfCyclesExceeded(form) &&
      form.continueWithMaximumNumberOfCyclesExceeded === false &&
      !displayWeeks
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_TITLE,
        description:
          typeOfForm === typeOfPAF.MALE ? (
            errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_MALE
          ) : typeOfForm === typeOfPAF.WOMANOFNCP ? (
            errorMessages.MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_WOMANOFNCP
          ) : (
            <BrochureWithText type="CYCLES_EXCEEDED_WCBP" hcp={hcp} />
          ),
        buttonText: "Continue",
      }));
    } else if (
      checkIfNumberOfWeeksExceeded(form) &&
      form.continueWithMaximumNumberOfWeeksExceeded === false &&
      displayWeeks
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: "Maximum number of weeks exceeded",
        description: (
          <BrochureWithText
            type={"WEEKS_EXCEEDED"}
            typeOfPAF={typeOfPAF}
            registerPatientType={typeOfForm}
            maxCapsulesOrWeeks={form}
            hcp={hcp}
          />
        ),
        buttonText: "Continue",
      }));
    } else if (
      checkIfTotalSupplyWeeksExceeded(form) &&
      form.continueWithMaximumNumberOfWeeksExceeded === false &&
      form?.schedule_display_type === "weeks_per_cycle"
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: "Maximum number of weeks exceeded",
        description: (
          <BrochureWithText
            type={"WEEKS_EXCEEDED"}
            typeOfPAF={typeOfPAF}
            registerPatientType={typeOfForm}
            maxCapsulesOrWeeks={form}
            hcp={hcp}
          />
        ),
        buttonText: "Continue",
      }));
    } else if (
      checkIfNumberOfWeeksExceeded(form) &&
      form.continueWithMaximumNumberOfWeeksExceededAgain === false &&
      displayWeeks
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: " Capsules outside permitted Quantity",
        description: (
          <BrochureWithText
            type={"WEEKS_EXCEEDED_WCBP"}
            typeOfPAF={typeOfPAF}
            registerPatientType={typeOfForm}
            maxCapsulesOrWeeks={form}
            hcp={hcp}
          />
        ),
        buttonText: "Continue",
      }));
    } else if (
      displayWeeks &&
      form.continueMaximumNumberOfCapsulesExceeded !== true &&
      (typeOfPAF.WOMANOFCP === typeOfForm
        ? !!form.max_capsules_wcbp
        : !!form.max_capsules_m_or_wncbp) &&
      form.total_capsules >
        (typeOfPAF.WOMANOFCP === typeOfForm
          ? form.max_capsules_wcbp
          : form.max_capsules_m_or_wncbp)
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: "Capsules outside permitted Quantity",
        description: (
          <BrochureWithText
            type={"WEEKS_EXCEEDED_WCBP"}
            typeOfPAF={typeOfPAF}
            registerPatientType={typeOfForm}
            maxCapsulesOrWeeks={form}
            hcp={hcp}
          />
        ),

        buttonText: "Continue",
      }));
    } else if (
      getAge(form.patient_dob) < 18 &&
      form.continueWithDateOfBirthUnderEighteen !== true
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: errorMessages.OUTDATED_DATE_BIRTH_TITLE,
        description: (
          <BrochureWithText type="NEED_VALID_DATE_BIRTH" hcp={hcp} />
        ),
        buttonText: "Continue",
      }));
    } else if (
      typeOfForm === typeOfPAF.WOMANOFCPRENEW2 &&
      form.continueWithPregnancyTestDateIncomplete !== true &&
      form.pregnancy_test === undefined
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE,
        description: (
          <BrochureWithText
            type="NEED_VALID_NEGATIVE_TEST_INCOMPLETE"
            hcp={hcp}
          />
        ),
        buttonText: "Continue",
      }));
    } else if (
      typeOfForm === typeOfPAF.WOMANOFCPRENEW2 &&
      isDispensingMoreThan3DaysBetween2Dates(new Date(), form.pregnancy_test) &&
      form.continueWithPregnancyTestDateOutdated === false
    ) {
      setOpenModal(true);
      setErrorMessage((prevState) => ({
        ...prevState,
        type: "error",
        modalState: 1,
        hasSecondState: false,
        title: errorMessages.OUTDATED_PREGANACY_TEST_TITLE,
        description: (
          <BrochureWithText type="NEED_VALID_NEGATIVE_TEST" hcp={hcp} />
        ),
        buttonText: "Continue",
      }));
    } else if (areAllFieldsPrescriberValidFirst) {
      setIsSubmitting(false);
      if (
        confirmarmation_risk_prescriber_local !== true &&
        form.continueWithoutConfirmation !== true &&
        typeOfForm !== typeOfPAF.WOMANOFNCP &&
        Boolean(form.prescription_confirmation_risks.length)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE,
          description: (
            <BrochureWithText
              type={"CONFIRMATION_NOT_PROVIDED_DESCRIPTION"}
              hcp={hcp}
              confirmationRiskLength={
                form?.prescription_confirmation_risks?.length
              }
            />
          ),
          buttonText: "Continue",
        }));
      } else if (
        form?.indication?.is_indication_other &&
        form?.continueOffLabel === false
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: "Continue with off-label use",
          description: `You have selected an indication that is not officially approved for use with ${hcp?.name}. Are you sure you want to proceed?`,
          buttonText: "Continue",
        }));
      } else if (
        form?.continueIndicationIncomplete !== true &&
        form?.indication.label === "Select"
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.INDICATION_INCOMPLETE_TITLE,
          description: (
            <BrochureWithText
              type={"CONTINUE_INDICATION_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else if (
        form?.continueCapsulePerCycleIncomplete !== true &&
        !isNotSelectedAnyCapsulePerCycle()
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: displayWeeks
            ? errorMessages.CAPSULES_PER_WEEKS_INCOMPLETE
            : errorMessages.CAPSULES_PER_CYCLE_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_CAPSULE_PER_CYCLE_INCOMPLETE"}
              hcp={hcp}
              scheduleDisplayType={form?.schedule_display_type}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else if (
        form?.continueNumberOfCyclesIncomplete !== true &&
        (!displayWeeks || form?.schedule_display_type === "weeks_per_cycle") &&
        (form?.number_cycles?.value === 0 ||
          form?.number_cycles?.label === "None" ||
          form?.number_cycles === null ||
          !form?.number_cycles)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.NUMBER_OF_CYCLES_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_NUMBER_OF_CYCLES_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else if (
        form?.schedule_display_type !== "weeks_per_cycle" &&
        form?.continueNumberOfWeeksIncomplete !== true &&
        displayWeeks &&
        (form?.number_weeks === null ||
          form?.number_weeks?.label === "None" ||
          !form?.number_weeks)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.NUMBER_OF_WEEKS_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_NUMBER_OF_WEEKS_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else if (
        form?.schedule_display_type === "weeks_per_cycle" &&
        form?.continueNumberOfWeeksIncomplete !== true &&
        (form?.weeks_per_cycle?.id === null ||
          form?.weeks_per_cycle?.label === "None" ||
          !form?.weeks_per_cycle)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.NUMBER_OF_WEEKS_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_NUMBER_OF_WEEKS_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else if (
        form.confirmation_risk_patient !== true &&
        form.continueWithoutConfirmation !== true &&
        typeOfForm !== typeOfPAF.WOMANOFNCP &&
        Boolean(form.prescription_confirmation_risks.length)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE,
          description: (
            <BrochureWithText
              type={"CONFIRMATION_NOT_PROVIDED_DESCRIPTION"}
              hcp={hcp}
              confirmationRiskLength={
                form?.prescription_confirmation_risks?.length
              }
            />
          ),
          buttonText: "Continue",
        }));
        return;
      } else {
        setState(state + 1);
      }
    }
  }

  const validatePrescriberScreen1 = (fields) => {
    const {
      indication = "",
      indication_other = "",
      molecule = "",
      clinical_trial = "",
    } = fields;

    return {
      indication_other: {
        isValid: !indication?.label?.includes("Other")
          ? true
          : indication_other && Boolean(indication_other?.trim())
          ? true
          : false,
      },
      clinical_trial: {
        isValid: !indication?.label?.includes("Clinical")
          ? true
          : Boolean(clinical_trial?.trim())
          ? true
          : false,
      },
      molecule: {
        isValid: molecule?.label?.trim().length >= 0,
      },
      confirmation_risk_prescriber: {
        isValid: !!allCheckedPrescriber,
      },
    };
  };

  let fullForm = { ...prescriptionToRenew, ...form };

  const {
    indication,
    indication_other,
    molecule,
    clinical_trial,
    confirmation_risk_prescriber,
  } = fullForm;

  let areAllFieldsPrescriberValidFirst;
  const prescriberFields = validatePrescriberScreen1({
    indication,
    indication_other,
    molecule,
    clinical_trial,
    confirmation_risk_prescriber,
  });

  areAllFieldsPrescriberValidFirst = Object.values(prescriberFields).every(
    (field) => {
      return field.isValid;
    }
  );

  const closeModalRenewal = () => {
    setDisplayRenewal(false);
  };

  const continueIsRenewal = () => {
    setDisplayRenewal(false);

    setConfirmedRenewal(true);
  };

  const deletePropertyValue = (propName) => {
    const copyForm = { ...form };
    delete copyForm[propName];

    setForm(copyForm);
  };

  const ActionButtons = ({ auxButtons = false }) => {
    return (
      <>
        {auxButtons &&
          (isApprovedPAFCompleted ? (
            <button onClick={() => printPAF()} className="prev mr-4">
              Print <Print />
            </button>
          ) : (
            <button onClick={previousBackState} className="prev mr-4">
              Cancel
            </button>
          ))}

        <button
          className="second-button"
          style={{ width: "160px" }}
          onClick={isApprovedPAFCompleted ? onClickFinishButton : verifyPAF}
        >
          {isApprovedPAFCompleted ? "Finish" : "Complete"}
        </button>
      </>
    );
  };

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal">
          <div className="close-icon" onClick={closeRegisterForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          {state === 1 && (
            <React.Fragment>
              <div className="step font-size-18">Step 1/1</div>

              <DigitalPAF
                handleChange={handleChange}
                registerPatient={{ ...prescriptionToRenew, ...form }}
                form={form}
                isSubmitting={isSubmitting}
                prescriberFields={prescriberFields}
                handleChangeSelect={handleChangeSelect}
                handleChangeCheckBox={handleChangeCheckBox}
                prescriptionConfirmationRisks={confirmationRisks}
                prescriberConfirmations={confirmationRisksPrescriber}
                typeOfForm={typeOfForm}
                indications={indications}
                moleculeOptions={moleculeOptions}
                capsulePerCycle={capsulePerCycle}
                numberOfCycles={displayWeeks ? numberOfWeeks : numberOfCycles}
                showErrors={showErrors}
                validateFields={prescriberFields}
                updateRegisterPatient={handleChangeSelect}
                isRenewal
                deletePropertyValue={deletePropertyValue}
                dropdownValue={dropdownValue}
              />

              <div className="wrapper-buttons-select-type">
                {state === 1 ? (
                  <span></span>
                ) : (
                  <button onClick={previous} className="prev">
                    Previous
                  </button>
                )}

                <button className="primary-button" onClick={() => nextPage()}>
                  Next
                </button>
              </div>
            </React.Fragment>
          )}
          {state === 2 && (
            <div className="min-height">
              <div className="wrapper-flex">
                <h1 className="select-category-txt">
                  Prescription Authorisation Form
                </h1>
                <div>
                  <ActionButtons auxButtons />
                </div>
              </div>
              <InfoPAF patientDetails={patientDetails} />
              <div className="paf-view-background">
                <ViewPAF
                  receivedForm={removeEmptyValueAndSelectLabel({
                    // ...prescriptionToRenew,
                    ...form,
                  })}
                  isCreateNewPAF={true}
                />
              </div>
              <div className="footer-button-holder">
                <div className="footer-button">
                  <ActionButtons />
                </div>
              </div>
            </div>
          )}
        </div>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={quitModal}
            description={`Are you sure you want to cancel filling out this ${treatmentFormText?.treatment_form_text}. All progress will be lost and you will need to start again.`}
          />
        )}
        {isDisplayRenewal && (
          <RenewalModal
            hcp={hcp}
            renewalModalIsOpen={isDisplayRenewal}
            closeModalRenewal={closeModalRenewal}
            continueIsRenewal={continueIsRenewal}
            patientType={patientDetails?.type}
          />
        )}

        <ContinueConfirmationProvidedModal
          errorMessage={errorMessage}
          openModal={openModal}
          closeConfirmationProvided={closeConfirmationProvided}
          continueConfirmationNotProvided={continueConfirmationNotProvided}
        />
        <ChangeMoleculeModal
          isOpen={isChangedMolecule}
          closeModal={closeIsChangedMoleculeModal}
          changeDropdowns={changeDropdowns}
          selectedMolecule={selectedMolecule}
          moleculeOption={moleculeOption}
          treatmentFormText={treatmentFormText}
        />

        <PAFRenewModal
          closeConfirm={closeConfirm}
          confirmPrescriptionAuthorisationForm={
            confirmPrescriptionAuthorisationForm
          }
          errorMessage={errorMessage}
          validateVerify={validateVerify}
          handleChangeValidPassword={handleChangeValidPassword}
          errorMessageFromServer={errorMessageFromServer}
        />
      </StyledRegister>
    </React.Fragment>
  );
};

export default PAFRenew;
