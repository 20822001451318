import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import StyledModal from "../../common/Modal/StyledModal";
import StyledViewPharmacy from "./StyledViewPharmacy";
import CloseIcon from "../../../assets/icons/close.svg";
import APIVariables from "../../../services/api-variables";
import LoaderImage from "../../../assets/images/loader.gif";
import { handleCatch } from "../../../shared/helpers";
import Axios from "../../../services/Axios";

const ViewPharmacyModal = ({ isOpen, onRequestClose, ID, product }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pharmacy, setPharmacy] = useState({});

  const getPharmacy = async () => {
    try {
      const res = await Axios.get(APIVariables.PHARMACY_ID + ID).catch(
        (error) => {
          handleCatch(error);
        }
      );

      setPharmacy(res?.data);

      setIsLoading(false);
    } catch (error) {
      handleCatch(error);
    }
  };

  useEffect(() => {
    if (ID) getPharmacy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ID]);

  const onClose = () => {
    onRequestClose();
    setPharmacy(null);
    setIsLoading(true);
  };

  return (
    <StyledModal>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button
          onClick={onClose}
          className="close-icon-button"
          style={{ top: 20 }}
        >
          <img src={CloseIcon} alt="Close Icon" />
        </button>

        <h1 className="select-category-txt my-0 color-orient">
          {isOpen && pharmacy?.pharmacy_name?.toUpperCase()}
        </h1>

        <StyledViewPharmacy className="wrapper-modal py-5 px-3">
          {isLoading ? (
            <div className="d-flex justify-content-center h-100 align-items-center">
              <img src={LoaderImage} alt="Loader" />
            </div>
          ) : (
            <form>
              <div className="wrapper-brackground">
                <div className="container mt-2">
                  <h2 className="title-details color-orient mb-4 bold">
                    Pharmacist Details
                  </h2>

                  <div className="row">
                    <div className="col">
                      <label>
                        Pharmacist (or appointed deputy) First Name:
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.first_name || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Pharmacist (or appointed deputy) Last Name:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.last_name || ""}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>GPhC / PSNI Registration Number:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.registration_number || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Institution Name:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.institution_type || ""}
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-6">
                      <label>Mobile Phone Number:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.phone_number || ""}
                      />
                    </div>

                    <div className="col-6 tooltip-container">
                      <label>Registered Product:</label>
                      <input type="text" readOnly value={product || ""} />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col">
                      <label>Pharmacy Name:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.pharmacy_name || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Pharmacy Address Line 1:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.address_data?.address || ""}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>Pharmacy Address Line 2:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.address_data?.address2 || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Pharmacy Address Line 3:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.address_data?.city || ""}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>Pharmacy Address Line 4:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.address_data?.state || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Pharmacy Postcode:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.address_data?.postal_code || ""}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label>Delivery Address:</label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.delivery_address || ""}
                      />
                    </div>

                    <div className="col">
                      <label>Ordering Address: </label>
                      <input
                        type="text"
                        readOnly
                        value={pharmacy?.ordering_address || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </StyledViewPharmacy>
      </Modal>
    </StyledModal>
  );
};

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: "#F8F8F8",
    padding: "50px 80px",
    maxHeight: "80vh",
  },
};

ViewPharmacyModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  ID: PropTypes.number,
};

export default ViewPharmacyModal;
