import React, { useEffect, useState } from "react";
import StyledDrugRegistration from "./StyledDrugRegistration";
import renew from "../../../../assets/icons/Renew.svg";
import Reregister from "../../../../assets/icons/Re-Register.svg";
import Axios from "../../../../services/Axios";
import APIVariables from "../../../../services/api-variables";
import { handleCatch } from "../../../../shared/helpers";
import DrugRegistrationModal from "./DrugRegistrationModal";
import moment from "moment";
import { registrationCategory } from "../../../../constants/register.constants";

export const DrugRegistration = ({ profileDetails, getProfileData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [productID, setProductID] = useState([]);
  const [productConfirmations, setProductConfirmations] = useState([]);
  const [checkboxesMedications, setCheckboxeslMedications] = useState([]);
  const [isregistering, setIsregistering] = useState(false);
  const [wasLinkClicked, setWasLinkClicked] = useState(false);
  const [confirmInformationGuideRead, setConfirmInformationGuideRead] =
    useState(false);
  const [openGuideRead, setOpenGuideRead] = useState(false);

  const products = profileDetails.products;
  const user_type =
    profileDetails.user_type === registrationCategory.PRESCRIBER
      ? 1
      : (profileDetails.user_type === registrationCategory.PHARMACY ||
          profileDetails.user_type ===
            registrationCategory.CONNECTED_HOMECARE ||
          profileDetails.user_type ===
            registrationCategory.CONNECTED_OUTPATIENT) &&
        2;

  const getProductConfirmation = async (productID) => {
    await Axios.get(APIVariables.GETCONF + `/${user_type}/${productID}`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setProductConfirmations(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const handleCheckboxes = (key) => {
    const numericKey = Number(key); // Convert key to a number dynamically
    if (checkboxesMedications.includes(numericKey)) {
      // Key exists, remove it from the array
      setCheckboxeslMedications(
        checkboxesMedications.filter((id) => id !== numericKey)
      );
    } else {
      // Key does not exist, add it to the array
      setCheckboxeslMedications([...checkboxesMedications, numericKey]);
    }
  };
  // Check if the number of checkboxes is equal to the number of checked checkboxes
  const lengths = Object.keys(productConfirmations).length;
  const isValid = checkboxesMedications?.length === lengths;

  const brochureNull = productConfirmations.filter((element) => {
    return (
      element.brochure_id !== null &&
      element.confirmation_name.includes("<brochure>")
    );
  });
  const registerProduct = async () => {
    setIsregistering(true);
    if (
      isValid === true &&
      (brochureNull.length !== 0 ? wasLinkClicked : true)
    ) {
      setOpenGuideRead(true);
    }
    if (openGuideRead === true) {
      // Check if confirmInformationGuideRead is true when OpenGuideRead is true
      if (confirmInformationGuideRead === true) {
        const res = await Axios.post(APIVariables.REGISTER_PRODUCT, {
          user_type: user_type,
          product: productID,
          confirmations: checkboxesMedications,
        }).catch((error) => {
          handleCatch(error);
        });

        if (res?.status === 200 && res?.data?.detail === "success") {
          setOpenGuideRead(false);
          setIsOpen(false);
          getProfileData();
          setCheckboxeslMedications([]);
          setIsregistering(false);
          setConfirmInformationGuideRead(false);
          setProductConfirmations([]);
          setWasLinkClicked(false);
        }
      }
    }
  };

  const handleCheckConfirmInformationGuideRead = (event) => {
    setConfirmInformationGuideRead(event.target.checked);
  };

  const closeModalGuideRead = (event) => {
    if (event.key === "Escape") {
      return;
    }
    setOpenGuideRead(false);
    registerProduct();
  };

  const clearData = () => {
    setCheckboxeslMedications([]);
    setProductConfirmations([]);
    setIsregistering(false);
    setWasLinkClicked(false);
  };

  // When you are registered/renew for a product localstorage is updated
  const existingData = JSON.parse(localStorage.getItem("USER_NHSPORTAL")) || {};

  existingData.products = profileDetails?.products;

  useEffect(() => {
    localStorage.setItem("USER_NHSPORTAL", JSON.stringify(existingData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData.products]);

  return (
    <StyledDrugRegistration>
      <div className="layout-modal mt-3">
        <div className="table-container">
          <h4> Drug Registration Details</h4>
          <table className="table">
            <tbody>
              <tr>
                <th></th>
                <th className="registration-valid">Registration Valid Until</th>
                <th></th>
              </tr>
              {products?.map((prod) => (
                <tr key={prod.product_id}>
                  <td
                    className={
                      prod.product_active === false
                        ? "td-product-name-grey"
                        : "td-product-name"
                    }
                  >
                    <span className="prod_product_name">
                      {prod.product_name}
                    </span>
                  </td>
                  <td className="td-expiry-date">
                    <p>
                      {prod.expiry_date === null
                        ? " - "
                        : moment(prod.expiry_date).format("DD / MM / YYYY")}
                    </p>
                  </td>

                  <td className="td-pending-activation">
                    {prod.product_active === false ? (
                      <span className="span">
                        This drug is currently disabled
                      </span>
                    ) : prod.pending_activation === true &&
                      prod.product_active === true ? (
                      <p className="span"> Medication disabled for you </p>
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setProductID(prod.product_id);
                          getProductConfirmation(prod.product_id);
                          setIsOpen(true);
                        }}
                      >
                        {prod.can_renew_registration === true ? (
                          <img src={renew} alt="renew" />
                        ) : prod.can_register === true &&
                          prod.product_active === true ? (
                          <div className="register-drug-button">
                            <p className="register-text">Register</p>
                          </div>
                        ) : (
                          prod.can_re_register === true && (
                            <img src={Reregister} alt="reregister" />
                          )
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <DrugRegistrationModal
          isOpen={isOpen}
          handleCheckboxes={handleCheckboxes}
          registerProduct={registerProduct}
          profileDetails={profileDetails}
          products={products}
          checkboxesMedications={checkboxesMedications}
          user_type={user_type}
          productID={productID}
          productConfirmations={productConfirmations}
          setCheckboxeslMedications={setCheckboxeslMedications}
          setProductConfirmations={setProductConfirmations}
          isValid={isValid}
          isregistering={isregistering}
          setIsregistering={setIsregistering}
          setWasLinkClicked={setWasLinkClicked}
          wasLinkClicked={wasLinkClicked}
          onRequestClose={() => setIsOpen(false)}
          overlayClassName="overlay-modal"
          shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
          //For the Information guide mdoal
          clearData={clearData}
          brochureNull={brochureNull}
          openGuideRead={openGuideRead}
          closeModalGuideRead={closeModalGuideRead}
          confirmInformationGuideRead={confirmInformationGuideRead}
          handleCheckConfirmInformationGuideRead={
            handleCheckConfirmInformationGuideRead
          }
        />
      </div>
    </StyledDrugRegistration>
  );
};
