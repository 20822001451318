import styled from "styled-components";

import Colors from "../../shared/colors";
import LoginBackground from "./../../assets/images/login.png";

export default styled.div`
  background: url("${LoginBackground}") no-repeat center center fixed;
  height: 100vh;
  width: 100%;
  background-size: cover;
  @media screen and (max-height: 900px) {
    height: 130vh;
  }
  @media screen and (max-height: 700px) {
    height: 150vh;
  }
  @media screen and (max-height: 600px) {
    height: 180vh;
  }
  @media screen and (max-height: 500px) {
    height: 220vh;
  }
  hr {
    border: 1px solid ${Colors.silver};
  }

  .wrapper-auth {
    padding: 40px 47px 20px 47px;
    max-width: 747px;
  }
  @media screen and (max-width: 1536px) {
    .wrapper-auth img {
      width: 80%;
    }
  }

  .wrapper-register {
    display: flex;
    align-items: center;
    padding: 10px 47px 25px 47px;
    cursor: pointer;
    text-decoration: none;

    div {
      margin-right: 10px;
      color: ${Colors.doveGray};
    }
  }

  .wrapper-register:hover {
    div {
      color: ${Colors.gray};
    }

    svg path {
      fill: ${Colors.gray};
    }
  }

  .wrapper-login {
    position: absolute;
    top: 57%;
    left: 50%;
    width: 38%;
    min-width: fit-content;
    height: auto;
    background: ${Colors.white};
    opacity: 0.95;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    max-width: 740px;
    min-width: 600px;
    border-radius: 5px;

    .wrapper-title {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;

      .text {
        color: #3c3c3c;
      }
    }

    .resetPasswordInputDiv {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .newPassword {
        margin-right: 9px;
      }

      .confirmPassword {
        margin-left: 9px;
      }

      .error-text {
        height: 20px;
      }
    }
  }

  .margin-form {
    margin: 10px 0px -10px 0px;
  }

  form {
    margin: 10px 0px 20px 0px;
  }

  form > div {
    display: grid;
  }

  form label {
    color: ${Colors.orient};
  }

  form input {
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: ${Colors.white} !important;
    color: ${Colors.doveGray} !important;
    border: 0;
    border-bottom: 1px solid ${Colors.silver};
    font-size: 18px;
    line-height: 22px;
    height: 30px;
  }
  form input:focus-visible {
    outline: 0;
  }

  form a {
    position: absolute;
    right: 45px;
    color: ${Colors.orient};
    text-decoration: none;
    margin-top: 10px;
  }

  form a:hover {
    text-decoration: underline;
  }

  .error-txt {
    color: ${Colors.valencia};
    font-size: 16px;
    line-height: 19px;
    font-style: italic;
    font-weight: normal;
  }

  .successMessage {
    padding: 20px;
    text-align: center;

    p {
      font-size: 18px;
    }
  }

  .disabledText {
    color: ${Colors.gray} !important;
  }

  .required-text-reset {
    color: ${Colors.valencia};
    font-size: 14px;
    line-height: 17px;
    max-width: fit-content;
  }

  .errorMessageResetPassword {
    color: ${Colors.valencia};
    font-size: 14px;
    max-width: fit-content;
    height: 20px;
    line-height: 17px;
  }

  .custom-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;

    .custom-login-arrow {
      margin-left: 15px;
    }
  }

  .training-video {
    margin: 0px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 92%;
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: 1536px) {
      top: 89%;
    }
  }

  .guide-button {
    width: 20%;
    min-width: 150px;
    height: inherit;
    min-height: 50px;

    @media screen and (max-width: 1200px) {
      width: 31%;
    }
  }

  .ml-14 {
    margin-left: 14px;
  }

  .error-text-reset {
    font-size: 14px;
    line-height: 13px;
  }
`;
