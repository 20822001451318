import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import CloseIcon from "../../../assets/icons/close.svg";
import StyledRegister from "../../authentication/StyledRegister";
import ConfirmIdentity from "./ConfirmationIdentity";
import PatientCategory from "./PatientCategory";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import InfoPAF from "../../common/InfoPAF/InfoPAF";
import ViewPAF from "../../common/ViewPAF/ViewPAF";
import APIVariables from "../../../services/api-variables";
import { errorMessages } from "../../../constants/errorMessages.constants";
import { typeOfPAF } from "../../../constants/typeOfPAF";
import Loader from "../../common/Loader/newLoader";
import {
  getAge,
  handleCatch,
  isDispensingMoreThan3DaysBetween2Dates,
  isObject,
  removeEmptyValueAndSelectLabel,
} from "../../../shared/helpers";
import SelectTIF from "./SelectTIF";
import DrugToPrescribe from "./DrugToPrescribe";
import DigitalPAF from "./DigitalPAF";
import ContinueConfirmationProvidedModal from "../PAFRenew/ContinueConfirmationProvidedModal";
import NonComplaintPAF from "./NonComplaintPAF";
import useRoles from "../../../hooks/useRoles";
import BrochureWithText from "../Brochure/BrochureWithText";
import Axios from "../../../services/Axios";

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "37%",
    borderRadius: "30px",
  },
};

const mediaMatch = window.matchMedia("(max-width: 850px)");
mediaMatch.matches === true
  ? (customStyles.content = { ...customStyles.content, width: "70%" })
  : (customStyles.content = {
      ...customStyles.content,
      width: "37%",
    });

const PatientInitiation = () => {
  const [resId, setResId] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const [isSubmittedPresciberPAF, setIsSubmittedPrescriberPAF] =
    useState(false);
  const [disableCompleteButton, setDisableCompleteButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalNonComplaint, setOpenModalNonComplaint] = useState({
    show: false,
    step: 1,
  });
  const [validPassword, setValidPassword] = useState("");
  const [errorMessageFromServer, setErrorMessageFromServer] = useState([]);
  const navigate = useNavigate();
  const [registerModalIsOpen, setIsOpenRegister] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState(1);
  const [prescriptionField, setPrescriptionField] = useState([]);
  const [registerPatient, setRegisterPatient] = useState({
    confirmation_risk_patient: false,
    confirmation_risk_prescriber: false,
    continueOffLabel: false,
    continueIndicationIncomplete: false,
    continueWithoutConfirmation: false,
    continueWithPregnancyTestDateOutdated: false,
    continueWithPregnancyTestDateIncomplete: false,
    continueMaximumNumberOfCyclesExceeded: false,
    continueMaximumNumberOfWeeksExceeded: false,
    continueMaximumNumberOfWeeksExceededAgain: false,
    continueMaximumNumberOfCapsulesExceeded: false,
    nonComplaintValidationPassed: false,
    continueWithDateOfBirthUnderEighteen: false,
    continueCapsulePerCycleIncomplete: false,
    continueCapsulePerWeekIncomplete: false,
    continueNumberOfCyclesIncomplete: false,
    continueNumberOfWeeksIncomplete: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isPrescriber, isPharmacy, isConnectedRegistrar, storageData } =
    useRoles();
  const [brochures, setBrochures] = useState([]);
  const [profileData, setProfileData] = useState();
  const drugToPrescribe = !!profileData && profileData?.products;

  const treatmentFormText =
    !!drugToPrescribe &&
    drugToPrescribe?.find(
      (element) => element.product_id === registerPatient?.product
    );

  const scheduleDisplayType = prescriptionField?.schedule_display_type;

  const getProfileData = async () => {
    try {
      const response = await Axios.get(APIVariables.USERS_PROFILE, {
        headers: {
          Authorization: storageData?.token,
        },
      });

      const { status, data } = response;

      if (status === 200) {
        setProfileData(data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // Get confirmations product from BE
  const getPrescriptionField = async () => {
    try {
      const config = {
        headers: {
          Authorization: storageData?.token,
        },
      };

      const data = await Axios.get(
        APIVariables.PRESCRIPTION_FIELDS +
          registerPatient.product +
          `/${registerPatient.type}`,
        config
      );
      setPrescriptionField(data.data);
    } catch (error) {
      handleCatch(error);
    }
  };

  const [hcp, setHcp] = useState();

  const getHCP = async () => {
    try {
      const config = {
        headers: {
          Authorization: storageData?.token,
        },
      };

      const data = await Axios.get(
        APIVariables.HCP_BROCHURE_INFO + registerPatient.product,
        config
      );

      setHcp(data.data);
    } catch (error) {
      handleCatch(error);
    }
  };

  useEffect(() => {
    state === 5 && getPrescriptionField() && getHCP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    state === 2 && getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    loadInitial();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerPatient?.pregnancy_test) {
      setRegisterPatient({
        ...registerPatient,
        continueWithPregnancyTestDateOutdated: false,
        continueWithPregnancyTestDateIncomplete: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.pregnancy_test]);

  useEffect(() => {
    if (registerPatient?.indication) {
      setRegisterPatient({
        ...registerPatient,
        continueOffLabel: false,
        continueIndicationIncomplete: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.indication]);

  useEffect(() => {
    if (registerPatient.number_cycles) {
      setRegisterPatient({
        ...registerPatient,
        continueNumberOfCyclesIncomplete: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient.number_cycles]);

  useEffect(() => {
    if (registerPatient?.number_cycles) {
      setRegisterPatient({
        ...registerPatient,
        continueMaximumNumberOfCyclesExceeded: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.number_cycles]);

  useEffect(() => {
    if (registerPatient?.number_weeks || registerPatient?.other) {
      setRegisterPatient({
        ...registerPatient,
        continueMaximumNumberOfWeeksExceeded: false,
        continueMaximumNumberOfWeeksExceededAgain: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.number_weeks, registerPatient?.other]);

  useEffect(() => {
    if (registerPatient?.total_capsules) {
      setRegisterPatient({
        ...registerPatient,
        continueMaximumNumberOfCapsulesExceeded: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.total_capsules]);

  useEffect(() => {
    if (registerPatient.number_weeks) {
      setRegisterPatient({
        ...registerPatient,
        continueNumberOfWeeksIncomplete: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient.number_weeks]);

  useEffect(() => {
    if (registerPatient?.patient_dob) {
      setRegisterPatient({
        ...registerPatient,
        continueWithDateOfBirthUnderEighteen: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerPatient?.patient_dob]);

  useEffect(() => {
    if (state === 3) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state === 3]);

  async function fetchData() {
    try {
      const config = {
        headers: {
          Authorization: storageData?.token,
        },
      };

      const brochures = await Axios.get(
        APIVariables.PRESCRIPTIONS_TIF + registerPatient?.product,
        config
      );

      setBrochures(brochures.data.tifs);
    } catch (error) {
      handleCatch(error);
    }
  }

  function loadInitial() {
    const composedUserDetails = {
      ...storageData,
      hospital_name: storageData?.hospital?.value,
      hospital_address: storageData?.hospital?.address,
    };

    setPersonalDetails(composedUserDetails);
  }

  const goToProfile = () => {
    navigate(`${App.PROFILE_DEFAULT}` + personalDetails.registration_number);
  };

  const isNotSelectedAnyCapsulePerCycle = () => {
    if (registerPatient?.capsules?.length > 0) {
      const result = registerPatient?.capsules?.some(
        (capsule) => Boolean(capsule.label) || Boolean(capsule.other)
      );
      return result;
    } else {
      return false;
    }
  };

  // const isNotSelectedAnyWeeksOrCycles = () => {
  //   const isNullOrEmpty = (item) => item === null || item === undefined;

  //   const isNone = (item) => item?.id === null && item?.label === "None";

  //   const checkNumberWeeks = () => {
  //     if (
  //       isNullOrEmpty(registerPatient?.number_weeks) ||
  //       isNone(registerPatient?.number_weeks)
  //     ) {
  //       return true;
  //     }
  //     if (
  //       registerPatient?.number_weeks?.id === 0 &&
  //       registerPatient?.number_weeks?.label === "Other (Please State)"
  //     ) {
  //       return isNullOrEmpty(registerPatient?.other);
  //     }
  //     return false;
  //   };

  //   const checkWeeksPerCycle = () =>
  //     isNullOrEmpty(registerPatient?.weeks_per_cycle) ||
  //     isNone(registerPatient?.weeks_per_cycle);

  //   return checkNumberWeeks() || checkWeeksPerCycle();
  // };

  const submitPAFToServer = async () => {
    const {
      continueOffLabel,
      continueIndicationIncomplete,
      continueWithoutConfirmation,
      continueWithPregnancyTestDateOutdated,
      continueWithPregnancyTestDateIncomplete,
      continueMaximumNumberOfCyclesExceeded,
      continueMaximumNumberOfWeeksExceeded,
      continueMaximumNumberOfWeeksExceededAgain,
      continueMaximumNumberOfCapsulesExceeded,
      nonComplaintValidationPassed,
      continueWithDateOfBirthUnderEighteen,
      continueCapsulePerCycleIncomplete,
      continueCapsulePerWeekIncomplete,
      continueNumberOfCyclesIncomplete,
      continueNumberOfWeeksIncomplete,
    } = registerPatient;
    if (
      continueOffLabel ||
      continueIndicationIncomplete ||
      continueWithoutConfirmation ||
      continueWithPregnancyTestDateOutdated ||
      continueWithPregnancyTestDateIncomplete ||
      continueMaximumNumberOfCyclesExceeded ||
      continueMaximumNumberOfWeeksExceeded ||
      continueMaximumNumberOfWeeksExceededAgain ||
      continueMaximumNumberOfCapsulesExceeded ||
      continueWithDateOfBirthUnderEighteen ||
      continueCapsulePerCycleIncomplete ||
      continueCapsulePerWeekIncomplete ||
      continueNumberOfCyclesIncomplete ||
      continueNumberOfWeeksIncomplete
    ) {
      if (!nonComplaintValidationPassed) {
        setOpenModalNonComplaint({ show: true, step: 1 });
      } else {
        setIsOpenRegister(true);
      }
    } else {
      setIsOpenRegister(true);
    }

    setDisableCompleteButton(false);
  };

  const nextPage = async () => {
    setShowErrors(false);

    if (state === 6) {
      setIsSubmittedPrescriberPAF(false);
      if (!areAllFieldsValid) {
        setShowErrors(true);
        return;
      } else {
        setIsOpenRegister(true);
      }
    }

    if (state === 5) {
      if (!areAllFieldsValid) {
        setShowErrors(true);
        return;
      }

      if (typeOfPAF.WOMANOFCP === registerPatient.type) {
        var today = new Date();
        if (
          registerPatient.continueWithPregnancyTestDateOutdated !== true &&
          isDispensingMoreThan3DaysBetween2Dates(
            today,
            registerPatient.pregnancy_test
          )
        ) {
          setOpenModal(true);
          setErrorMessage((prevState) => ({
            ...prevState,
            type: "error",
            modalState: 1,
            hasSecondState: false,
            title: errorMessages.OUTDATED_PREGANACY_TEST_TITLE,
            description: (
              <BrochureWithText type="NEED_VALID_NEGATIVE_TEST" hcp={hcp} />
            ),
            buttonText: "Continue",
          }));
          return;
        }
      }

      if (typeOfPAF.WOMANOFCP === registerPatient.type) {
        if (
          registerPatient.continueWithPregnancyTestDateIncomplete !== true &&
          registerPatient.pregnancy_test === undefined
        ) {
          setOpenModal(true);
          setErrorMessage((prevState) => ({
            ...prevState,
            type: "error",
            modalState: 1,
            hasSecondState: false,
            title: errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE,
            description: (
              <BrochureWithText
                type="NEED_VALID_NEGATIVE_TEST_INCOMPLETE"
                hcp={hcp}
              />
            ),
            buttonText: "Continue",
          }));
          return;
        }
      }

      if (
        registerPatient.continueWithDateOfBirthUnderEighteen !== true &&
        getAge(registerPatient.patient_dob) < 18
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.OUTDATED_DATE_BIRTH_TITLE,
          description: (
            <BrochureWithText type="NEED_VALID_DATE_BIRTH" hcp={hcp} />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        confirmation_risk_patient !== true &&
        registerPatient.continueWithoutConfirmation !== true &&
        registerPatient.type !== typeOfPAF.WOMANOFNCP &&
        Boolean(prescriptionField.prescription_confirmation_risks.length)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE,
          description: (
            <BrochureWithText
              type={"CONFIRMATION_NOT_PROVIDED_DESCRIPTION"}
              hcp={hcp}
              confirmationRiskLength={
                prescriptionField?.prescription_confirmation_risks?.length
              }
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        registerPatient?.continueOffLabel !== true &&
        registerPatient?.indication?.is_indication_other === true
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: "Continue with off-label use",
          description: (
            <BrochureWithText type={"CONTINUE_OFF_LABEL"} hcp={hcp} />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        registerPatient?.continueIndicationIncomplete !== true &&
        (registerPatient?.indication?.label === undefined ||
          registerPatient?.indication?.label === null ||
          registerPatient?.indication?.label === "None")
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.INDICATION_INCOMPLETE_TITLE,
          description: (
            <BrochureWithText
              type={"CONTINUE_INDICATION_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        (scheduleDisplayType === "cycle" ||
          scheduleDisplayType === "weeks_per_cycle") &&
        registerPatient?.continueCapsulePerCycleIncomplete !== true &&
        !isNotSelectedAnyCapsulePerCycle()
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.CAPSULES_PER_CYCLE_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_CAPSULE_PER_CYCLE_INCOMPLETE"}
              hcp={hcp}
              scheduleDisplayType={prescriptionField?.schedule_display_type}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        scheduleDisplayType === "weeks" &&
        registerPatient?.continueCapsulePerWeekIncomplete !== true &&
        !isNotSelectedAnyCapsulePerCycle()
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.CAPSULES_PER_WEEKS_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_CAPSULE_PER_CYCLE_INCOMPLETE"}
              hcp={hcp}
              scheduleDisplayType={prescriptionField?.schedule_display_type}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        (scheduleDisplayType === "cycle" ||
          scheduleDisplayType === "weeks_per_cycle") &&
        registerPatient?.continueNumberOfCyclesIncomplete !== true &&
        (registerPatient?.number_cycles?.value === 0 ||
          !registerPatient?.number_cycles ||
          !registerPatient?.number_cycles.id)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.NUMBER_OF_CYCLES_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_NUMBER_OF_CYCLES_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        registerPatient?.continueNumberOfWeeksIncomplete !== true &&
        ((scheduleDisplayType === "weeks_per_cycle" &&
          (!registerPatient?.weeks_per_cycle ||
            registerPatient.weeks_per_cycle.label === "None")) ||
          (scheduleDisplayType === "weeks" &&
            (!registerPatient?.number_weeks ||
              registerPatient?.number_weeks.label === "None")))
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: errorMessages.NUMBER_OF_WEEKS_INCOMPLETE,
          description: (
            <BrochureWithText
              type={"CONTINUE_NUMBER_OF_WEEKS_INCOMPLETE"}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      const isNumberOfCylesExceeded =
        checkIfNumberOfCyclesExceeded(registerPatient);

      if (
        scheduleDisplayType === "cycle" &&
        registerPatient.continueMaximumNumberOfCyclesExceeded !== true &&
        typeOfPAF.WOMANOFCP === registerPatient.type &&
        isNumberOfCylesExceeded
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: "Maximum Number of Cycles Exceeded",
          description: (
            <BrochureWithText type={"CYCLES_EXCEEDED_WCBP"} hcp={hcp} />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        scheduleDisplayType === "weeks" &&
        registerPatient.continueMaximumNumberOfCapsulesExceeded !== true &&
        (typeOfPAF.WOMANOFCP === registerPatient.type
          ? !!prescriptionField.max_capsules_wcbp
          : !!prescriptionField.max_capsules_m_or_wncbp) &&
        (registerPatient.total_capsules !== 0
          ? registerPatient.total_capsules
          : registerPatient.capsule_strength) >
          (typeOfPAF.WOMANOFCP === registerPatient.type
            ? prescriptionField.max_capsules_wcbp
            : prescriptionField.max_capsules_m_or_wncbp)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: "Capsules outside permitted Quantity",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED_WCBP"}
              typeOfPAF={typeOfPAF}
              registerPatientType={registerPatient.type}
              maxCapsulesOrWeeks={prescriptionField}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        scheduleDisplayType === "weeks" &&
        (typeOfPAF.WOMANOFCP === registerPatient.type
          ? !!prescriptionField.max_capsules_wcbp
          : !!prescriptionField.max_capsules_m_or_wncbp) &&
        registerPatient.continueMaximumNumberOfWeeksExceeded !== true &&
        (registerPatient?.other
          ? registerPatient?.other
          : registerPatient?.number_weeks?.id) >
          (typeOfPAF.WOMANOFCP === registerPatient.type
            ? prescriptionField.max_weeks_wcbp
            : prescriptionField.max_weeks_m_or_wncbp)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: " Capsules outside permitted Quantity",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED_WCBP"}
              typeOfPAF={typeOfPAF}
              registerPatientType={registerPatient.type}
              maxCapsulesOrWeeks={prescriptionField}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      if (
        (scheduleDisplayType === "weeks" ||
          scheduleDisplayType === "weeks_per_cycle") &&
        registerPatient.continueMaximumNumberOfWeeksExceededAgain !== true &&
        prescriptionField?.max_weeks_wcbp !== 0 &&
        prescriptionField?.max_weeks_m_or_wncbp !== 0 &&
        (scheduleDisplayType === "weeks_per_cycle"
          ? registerPatient?.total_weeks_supply
          : registerPatient?.other
          ? registerPatient?.other
          : registerPatient?.number_weeks?.id) >
          (typeOfPAF.WOMANOFCP === registerPatient.type
            ? prescriptionField.max_weeks_wcbp
            : prescriptionField.max_weeks_m_or_wncbp)
      ) {
        setOpenModal(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          type: "error",
          modalState: 1,
          hasSecondState: false,
          title: "Maximum number of weeks exceeded",
          description: (
            <BrochureWithText
              type={"WEEKS_EXCEEDED"}
              typeOfPAF={typeOfPAF}
              registerPatientType={registerPatient.type}
              maxCapsulesOrWeeks={prescriptionField}
              hcp={hcp}
            />
          ),
          buttonText: "Continue",
        }));
        return;
      }

      setState(state + 1);
    }

    if (state === 1 || state === 4 || state === 2) {
      setState(state + 1);
    }

    if (state === 3 && (isPrescriber || isConnectedRegistrar)) {
      if (!!registerPatient.confirmation_other1) {
        setShowErrors(false);
        setState(state + 1);
      } else {
        setShowErrors(true);
        return;
      }
    }
  };

  const previous = () => {
    setState(state - 1);

    if (state === 5) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        number_cycles: null,
        number_weeks: null,
        total_capsules: null,
        capsules: null,
        strengths: null,
        capsule_strength: null,
        other: null,
        total_weeks_supply: null,
        indication: null,
        other_indication: null,
        weeks_per_cycle: null,
      }));
    }

    if (state === 6) {
      setCheckedItemsPrescriber(checkedItemsPrescriber);
      setCheckedItems(checkedItems);

      setRegisterPatient((prevState) => ({
        ...prevState,
        confirmation_risk_patient: registerPatient.confirmation_risk_patient,
        continueWithoutConfirmation:
          registerPatient.continueWithoutConfirmation,
        confirmation_risk_prescriber:
          registerPatient.confirmation_risk_prescriber,
      }));
    }
  };

  const updateRegisterPatient = (field, value) => {
    setRegisterPatient((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const selectTypeOfPatient = (type) => {
    if (type === typeOfPAF.WOMANOFNCP) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        type: type,
        continueWithoutConfirmation: false,
      }));
    } else {
      setRegisterPatient((prevState) => ({
        ...prevState,
        type: type,
      }));
    }
  };

  const selectTypeOfDrug = (type, id) => {
    setRegisterPatient((prevState) => ({
      ...prevState,
      drug: type,
      product: id,
    }));
  };

  const handleChange = (e) => {
    let { name, value, maxLength } = e.target;
    if (name?.toLowerCase()?.includes("capsule_cycle_other")) {
      let parsedValue = parseInt(value);
      value = parsedValue ? parsedValue.toString() : value;
      if (+value <= 0) {
        value = "1";
      } else if (+value > 200) {
        value = "200";
      }
    }

    const shouldSetValue = +value.length <= maxLength;

    let filterValue = value;

    const nameRegex = /[^a-zA-Z]/g;

    if (name === "patient_initials") {
      if (value === "" || nameRegex.test(e.target.value)) {
        filterValue = filterValue.replace(nameRegex, "");
      }
    }

    if (name === "patient_initials") {
      filterValue = filterValue?.toUpperCase();
    }

    const formCopy = {
      ...registerPatient,
      [name]: filterValue,
    };

    if (maxLength === -1) {
      setRegisterPatient(formCopy);
    } else {
      if (shouldSetValue) {
        setRegisterPatient(formCopy);
      }
    }

    setTotalCapsules(formCopy);
  };

  // Initialized prescription_confirmation_risks and prescriber_confirmations with checkbox-prescriber-${item.id} and risk-checkbox-${item.id}

  const initialValue = prescriptionField?.prescriber_confirmations?.reduce(
    (acc, item) => {
      acc[`checkbox-prescriber-${item.id}`] = false;
      return acc;
    },
    {}
  );

  const initialValueRisk =
    prescriptionField?.prescription_confirmation_risks?.reduce((acc, item) => {
      acc[`risk-checkbox-${item.id}`] = false;
      return acc;
    }, {});

  // Set the checkedItems and checkedItemsPrescriber with initial values (initialValue and initialValueRisk)
  useEffect(() => {
    if (
      !!prescriptionField?.prescriber_confirmations &&
      !!initialValue &&
      Object.keys(checkedItemsPrescriber).length === 0
    ) {
      setCheckedItemsPrescriber(initialValue);
    }
    if (
      !!prescriptionField?.prescription_confirmation_risks &&
      !!initialValueRisk &&
      Object.keys(checkedItems).length === 0
    ) {
      setCheckedItems(initialValueRisk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prescriptionField?.prescriber_confirmations,
    prescriptionField?.prescription_confirmation_risks,
  ]);

  const [checkedItems, setCheckedItems] = useState(initialValueRisk || {});
  const [checkedItemsPrescriber, setCheckedItemsPrescriber] = useState(
    initialValue || {}
  );

  const [allChecked, setAllChecked] = useState(false);
  const [allCheckedPrescriber, setAllCheckedPrescriber] = useState(false);

  useEffect(() => {
    // Calculate allChecked based on checkedItems
    const calculatedAllChecked =
      Object.keys(checkedItems).length > 0 &&
      Object.values(checkedItems).every(Boolean);

    const calculatedAllCheckedPrescriber =
      Object.keys(checkedItemsPrescriber).length > 0 &&
      Object.values(checkedItemsPrescriber).every(Boolean);

    // Update the allChecked state variable
    setAllChecked(calculatedAllChecked);
    setAllCheckedPrescriber(calculatedAllCheckedPrescriber);
  }, [checkedItems, checkedItemsPrescriber]);

  useEffect(() => {
    if (checkedItems && allChecked) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: false,
        confirmation_risk_patient: allChecked,
        confirmation_risk_prescriber: allCheckedPrescriber,
      }));
    }
    if (checkedItems) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        confirmation_risk_patient: allChecked,
        confirmation_risk_prescriber: allCheckedPrescriber,
      }));
    }
  }, [allChecked, allCheckedPrescriber, checkedItems]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "confirmation_other1" && checked) {
      setShowErrors(false);
      setRegisterPatient((prevState) => ({
        ...prevState,
        confirmation_other1: checked,
      }));
    }

    if (name.includes("checkbox-prescriber")) {
      setCheckedItemsPrescriber({ ...checkedItemsPrescriber, [name]: checked });
    } else if (name.includes("risk-checkbox")) {
      setCheckedItems({ ...checkedItems, [name]: checked });
    }
  };

  const printPAF = async () => {
    Axios.get(APIVariables.DOWNLOAD_PAF + resId)
      .then((response) => {
        return response.data.report;
      })
      .then(async function (base64) {
        const base64Pdf = await fetch(`data:application/pdf;base64,${base64}`);
        return base64Pdf.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = `PAF.pdf`;
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const redirectToOrderPage = () => {
    if (isPharmacy) {
      navigate(App.ORDER_DEFAULT + resId);
    } else {
      navigate(App.VIEW_PATIENTS);
    }
  };

  const closeModalRegisterModal = () => {
    setIsOpenRegister(false);
  };

  const closeNonComplaint = () => {
    setOpenModalNonComplaint({ show: false, step: 1 });
  };

  const submitNonComplaint = async () => {
    if (openModalNonComplaint.step === 1) {
      setOpenModalNonComplaint({ show: true, step: 2 });
    }

    if (openModalNonComplaint.step === 2) {
      const res = await Axios.post(APIVariables.USERS_PASSWORD_VALIDATE, {
        password: validPassword,
      }).catch(function (error) {
        handleCatch(error);

        const errors = error?.response?.data;
        if (typeof errors === "string") {
          setErrorMessageFromServer([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          if (!!errors) {
            Object.keys(errors).forEach((key) => {
              if (isObject(errors[key])) {
                Object.keys(errors[key]).forEach((secondKey) => {
                  errorsArray.push(errors[key][secondKey][0]);
                });
              } else {
                errorsArray.push(errors[key]);
              }
            });
            setErrorMessageFromServer(errorsArray);
          } else {
            setErrorMessageFromServer([error.message]);
          }
        }
      });

      if (res?.status === 200) {
        closeNonComplaint();
        setRegisterPatient({
          ...registerPatient,
          nonComplaintValidationPassed: true,
        });
      }
    }
  };

  const disableNextButton = () => {
    if (isPharmacy) {
    } else {
      if (state === 4) {
        return !registerPatient.type;
      }
      if (state === 2) {
        return !registerPatient.drug;
      }
    }
  };

  const [quitModalIsOpen, setIsOpenQuit] = useState(false);

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.DASHBOARD);
  }

  function closeForm() {
    setIsOpenQuit(true);
  }

  const handleChangeValidPassword = (event) => {
    setValidPassword(event.target.value);
  };

  const checkIfNumberOfCyclesExceeded = (form) => {
    const typeOfFormVar = registerPatient.type;

    if (typeOfFormVar === typeOfPAF.WOMANOFCP) {
      const nrCycles = form?.number_cycles?.id;

      return nrCycles > 1;
    }
    return false;
  };

  const submitForm = async () => {
    return new Promise(async (response) => {
      setErrorMessage("");
      setIsSubmitting(true);

      const API = APIVariables.PRESCRIPTIONS_CREATE_POST;
      let copyOfRegisterPatient = Object.assign({}, registerPatient);

      copyOfRegisterPatient.patient_dob = moment(
        copyOfRegisterPatient.patient_dob
      ).format("YYYY-MM-DD");

      copyOfRegisterPatient.pregnancy_test =
        copyOfRegisterPatient?.pregnancy_test === undefined ||
        copyOfRegisterPatient?.pregnancy_test === null
          ? undefined
          : moment(copyOfRegisterPatient?.pregnancy_test).format("YYYY-MM-DD");

      copyOfRegisterPatient.indication =
        copyOfRegisterPatient?.indication?.id === undefined ||
        copyOfRegisterPatient?.indication?.id === null
          ? undefined
          : copyOfRegisterPatient?.indication?.id;

      if (
        copyOfRegisterPatient.type === typeOfPAF.MALE ||
        copyOfRegisterPatient.type === typeOfPAF.WOMANOFNCP
      ) {
        delete copyOfRegisterPatient.pregnancy_test;
      }

      const handedHospitalName =
        storageData?.hospital?.value + ", " + storageData?.hospital?.address;

      copyOfRegisterPatient.hospital_name_and_address =
        copyOfRegisterPatient.hospital_name_and_address ?? handedHospitalName;

      copyOfRegisterPatient.prescription_confirmation_risks = Object.keys(
        checkedItems
      )
        .filter((key) => key.startsWith("risk-checkbox-"))
        .reduce((obj, key) => {
          const id = key.split("-")[2];
          obj[id] = checkedItems[key];
          return obj;
        }, {});

      copyOfRegisterPatient.prescriber_confirmations = Object.keys(
        checkedItemsPrescriber
      )
        .filter((key) => key.startsWith("checkbox-prescriber-"))
        .reduce((obj, key) => {
          const id = key.split("-")[2];
          obj[id] = checkedItemsPrescriber[key];
          return obj;
        }, {});

      delete copyOfRegisterPatient.confirmation_other1;

      const patient = {
        patient_initials: copyOfRegisterPatient.patient_initials,
        dob: copyOfRegisterPatient.patient_dob,
        patient_type:
          copyOfRegisterPatient?.type === "male"
            ? 0
            : copyOfRegisterPatient?.type === "womanOfCP"
            ? 1
            : 2,
        counselling_date: moment().format("YYYY-MM-DD"),
      };

      copyOfRegisterPatient = {
        ...copyOfRegisterPatient,
        patient,
      };

      if (copyOfRegisterPatient.type === "womanOfNCP") {
        copyOfRegisterPatient.confirmation_risk_patient = true;
      }

      if (copyOfRegisterPatient?.number_cycles?.id) {
        copyOfRegisterPatient = {
          ...copyOfRegisterPatient,
          n_cycles: copyOfRegisterPatient?.number_cycles?.id,
        };
      }

      if (copyOfRegisterPatient?.number_weeks?.id) {
        copyOfRegisterPatient = {
          ...copyOfRegisterPatient,
          n_weeks: copyOfRegisterPatient?.number_weeks?.id,
        };
      }

      if (copyOfRegisterPatient?.other) {
        copyOfRegisterPatient = {
          ...copyOfRegisterPatient,
          n_weeks_other: +copyOfRegisterPatient?.other,
          n_weeks: +copyOfRegisterPatient?.number_weeks?.id,
        };
      }

      if (copyOfRegisterPatient?.weeks_per_cycle) {
        copyOfRegisterPatient = {
          ...copyOfRegisterPatient,
          weeks_per_cycle:
            copyOfRegisterPatient?.weeks_per_cycle?.id !== 0
              ? +copyOfRegisterPatient?.weeks_per_cycle?.id
              : copyOfRegisterPatient?.n_weeks_other,
        };
      }

      !!copyOfRegisterPatient?.capsules &&
        copyOfRegisterPatient?.capsules?.map((element) => {
          delete element.strengths;
          return element;
        });
      delete copyOfRegisterPatient.type;
      delete copyOfRegisterPatient.number_cycles;
      delete copyOfRegisterPatient.number_weeks;
      delete copyOfRegisterPatient.other;
      delete copyOfRegisterPatient.total_capsules;
      // delete copyOfRegisterPatient.capsule_strength;
      delete copyOfRegisterPatient.patient_initials;
      delete copyOfRegisterPatient.patient_dob;
      delete copyOfRegisterPatient.nonComplaintValidationPassed;
      delete copyOfRegisterPatient.drug;
      delete copyOfRegisterPatient.drugId;
      delete copyOfRegisterPatient.continueWithoutConfirmation;
      delete copyOfRegisterPatient.continueWithPregnancyTestDateOutdated;
      delete copyOfRegisterPatient.continueWithPregnancyTestDateIncomplete;
      delete copyOfRegisterPatient.continueOffLabel;
      delete copyOfRegisterPatient.continueIndicationIncomplete;
      delete copyOfRegisterPatient.continueMaximumNumberOfCyclesExceeded;
      delete copyOfRegisterPatient.continueMaximumNumberOfWeeksExceeded;
      delete copyOfRegisterPatient.continueMaximumNumberOfWeeksExceededAgain;
      delete copyOfRegisterPatient.continueMaximumNumberOfCapsulesExceeded;
      delete copyOfRegisterPatient.continueWithDateOfBirthUnderEighteen;
      delete copyOfRegisterPatient.continueCapsulePerCycleIncomplete;
      delete copyOfRegisterPatient.continueCapsulePerWeekIncomplete;
      delete copyOfRegisterPatient.continueNumberOfCyclesIncomplete;
      delete copyOfRegisterPatient.continueNumberOfWeeksIncomplete;

      const res = await Axios.post(API, copyOfRegisterPatient).catch(function (
        error
      ) {
        setIsSubmitting(false);
        handleCatch(error);

        const errors = error?.response?.data;
        if (!!errors) {
          const errorsArray = [];
          if (typeof errors === "string") {
            errorsArray.push(errorMessages.INTERNAL_SERVER_ERROR);
          } else {
            Object?.keys(errors).forEach((key) => {
              errorsArray.push(key + " - " + errors[key]);
            });
          }

          setErrorMessage(errorsArray);
          return response(errorsArray);
        }
      });

      if (res?.status === 200) {
        setResId(res.data.patient_id);
        setIsSubmitting(false);
        setIsSubmittedPrescriberPAF(true);
        setIsOpenRegister(true);
        closeModalRegisterModal();

        setRegisterPatient({ ...registerPatient, id: res.data.patient_id });
        return response(true);
      }
      setIsSubmitting(false);
    });
  };

  const setTotalCapsules = (copiedRegisterPatient) => {
    if (prescriptionField?.schedule_display_type === "weeks_per_cycle") {
      const TotalweeksPerCycle =
        prescriptionField?.schedule_display_type === "weeks_per_cycle" &&
        copiedRegisterPatient?.number_cycles?.id *
          (copiedRegisterPatient.weeks_per_cycle?.id !== 0
            ? copiedRegisterPatient.weeks_per_cycle?.id
            : copiedRegisterPatient?.other);

      setRegisterPatient((prevState) => ({
        ...prevState,

        total_weeks_supply:
          prescriptionField.schedule_display_type === "weeks_per_cycle" &&
          (TotalweeksPerCycle || ""),
      }));
    }

    if (!!copiedRegisterPatient.capsules) {
      // Calculate the sum of the values using the reduce method
      const capsuleValues = Object.values(copiedRegisterPatient.capsules);

      const sumOfValues = capsuleValues
        ?.map((element) =>
          element.label === 0 ? element.other : element.label
        )
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const numberOfCyles =
        copiedRegisterPatient?.number_weeks?.id === 0
          ? copiedRegisterPatient?.other
          : copiedRegisterPatient?.number_weeks?.id ||
            copiedRegisterPatient?.number_cycles?.id;
      const totalCapsules = +sumOfValues * (numberOfCyles || 0);

      setRegisterPatient((prevState) => ({
        ...prevState,
        capsule_strength: sumOfValues,
        total_capsules: !!copiedRegisterPatient?.number_weeks
          ? Math.ceil(totalCapsules / 4)
          : totalCapsules,
      }));
    }
  };

  const {
    patient_initials,
    patient_dob,
    indication,
    indication_other,
    clinical_trial,
    number_cycles,
    // Pharmacy
    prescriber_name,
    hospital_name_and_address,
    date_paper_paf,
    confirmation_other1,
    confirmation_other2,
    confirmation_risk_prescriber,
    confirmation_risk_patient,
  } = registerPatient;

  const validateFields = ({
    patient_initials = "",
    patient_dob = "",
    indication = "",
    indication_other = "",
    clinical_trial = "",
    number_cycles = "",
    // Pharmacy
    prescriber_name = "",
    hospital_name_and_address = "",
    date_paper_paf = "",
    confirmation_other1 = "",
    confirmation_other2 = "",
    confirmation_risk_prescriber = "",
  }) => ({
    patient_initials: {
      isValid: patient_initials.trim().length > 0,
    },
    patient_dob: {
      isValid: !!patient_dob,
    },
    indication_other: {
      isValid: !indication?.label?.includes("Other")
        ? true
        : indication_other?.trim().length > 0,
    },
    clinical_trial: {
      isValid: !indication?.label?.includes("Clinical")
        ? true
        : clinical_trial?.trim().length > 0,
    },
    prescriber_name: {
      isValid:
        isPrescriber || isConnectedRegistrar
          ? true
          : prescriber_name.trim().length > 0,
    },
    hospital_name_and_address: {
      isValid: isPharmacy ? !!hospital_name_and_address : true,
    },
    date_paper_paf: {
      isValid: isPharmacy ? !!date_paper_paf : true,
    },
    confirmation_other1: {
      isValid: isPharmacy ? !!confirmation_other1 : true,
    },
    confirmation_other2: {
      isValid: isPharmacy ? !!confirmation_other2 : true,
    },
    confirmation_risk_prescriber: {
      isValid: !!allCheckedPrescriber,
    },
    confirmation_risk_patient: {
      isValid: isPharmacy
        ? true
        : registerPatient.type === typeOfPAF.WOMANOFNCP
        ? true
        : true,
    },
  });

  let areAllFieldsValid;

  const fields = validateFields({
    patient_initials,
    patient_dob,
    indication,
    indication_other,
    clinical_trial,
    number_cycles,
    // Pharmacy
    prescriber_name,
    hospital_name_and_address,
    date_paper_paf,
    confirmation_other1,
    confirmation_other2,
    confirmation_risk_prescriber,
    confirmation_risk_patient,
  });

  areAllFieldsValid = Object.values(fields).every((field) => {
    return field.isValid;
  });

  const getUserInfo = () => {
    const {
      id,
      patient_initials,
      type,
      patient_dob,
      hospital_name_and_address,
    } = registerPatient;

    const userInfo = {
      id: id || "Awaiting Completion",
      patient_initials,
      gender: type === typeOfPAF.MALE ? "Male" : "Female",
      dob: patient_dob,
      hospital: isPharmacy
        ? hospital_name_and_address
        : storageData?.hospital?.value,
    };
    return userInfo;
  };

  const deletePropertyValue = (propName) => {
    const copyRegisterPatient = { ...registerPatient };
    delete copyRegisterPatient[propName];

    setRegisterPatient(copyRegisterPatient);
  };

  const handleCapsules = (capsuleCycleSelected, name, capsuleStrength) => {
    if (name === "clinical_trial" || name === "indication_other") {
      const formCopy = {
        ...registerPatient,

        [name]: capsuleCycleSelected,
      };
      setRegisterPatient(formCopy);
    }

    if (
      typeof capsuleCycleSelected === "string" &&
      name === "capsule_per_cycle"
    ) {
      let parsedValue = parseInt(capsuleCycleSelected);
      capsuleCycleSelected = parsedValue
        ? parsedValue.toString()
        : capsuleCycleSelected;
      if (+capsuleCycleSelected <= 0) {
        capsuleCycleSelected = "1";
      } else if (+capsuleCycleSelected > 200) {
        capsuleCycleSelected = "200";
      }
      let maxLength = -1;
      const shouldSetValue = +capsuleStrength.length <= maxLength;

      const formCopy = {
        ...registerPatient,
        [capsuleCycleSelected]: capsuleCycleSelected,
      };

      if (maxLength === -1) {
        setRegisterPatient(formCopy);
      } else {
        if (shouldSetValue) {
          setRegisterPatient(formCopy);
        }
      }

      setTotalCapsules(formCopy);
    }
    if (name === "capsule_per_cycle") {
      const copiedRegisterPatient = { ...registerPatient };
      const valuee = capsuleStrength.id;

      if (!Array.isArray(copiedRegisterPatient.capsules)) {
        copiedRegisterPatient.capsules = [];
      }
      const existingIndex = copiedRegisterPatient.capsules.findIndex(
        (capsule) => capsule.id === valuee
      );

      if (existingIndex !== -1) {
        copiedRegisterPatient.capsules[existingIndex] = {
          id: valuee,
          label:
            capsuleCycleSelected.value === undefined
              ? 0
              : capsuleCycleSelected.value,
          other:
            capsuleCycleSelected.value === 0
              ? 1
              : typeof capsuleCycleSelected === "string" &&
                +capsuleCycleSelected,
          //   typeof capsuleCycleSelected === "string" && +capsuleCycleSelected,
          capsuleStrength: capsuleStrength,

          strengths: {
            [valuee]:
              capsuleCycleSelected.value === undefined
                ? +capsuleCycleSelected
                : capsuleCycleSelected.value,
          },
        };
      } else {
        copiedRegisterPatient.capsules.push({
          id: valuee,
          label: capsuleCycleSelected.value,
          capsuleStrength: capsuleStrength,
          other: capsuleCycleSelected.value === 0 ? 1 : false,

          strengths: {
            [valuee]:
              capsuleCycleSelected.value === undefined
                ? +capsuleCycleSelected
                : capsuleCycleSelected.value === 0
                ? 1
                : capsuleCycleSelected.value,
          },
        });
      }

      copiedRegisterPatient.strengths = {};

      // Iterate through capsules and merge strengths objects
      copiedRegisterPatient.capsules.forEach((capsule) => {
        const { strengths } = capsule;
        Object.assign(copiedRegisterPatient.strengths, strengths);
      });

      for (const key in copiedRegisterPatient.strengths) {
        if (copiedRegisterPatient.strengths[key] === null) {
          delete copiedRegisterPatient.strengths[key];
        }
      }

      copiedRegisterPatient.capsules = copiedRegisterPatient.capsules.filter(
        (obj) => obj.label !== null
      );

      copiedRegisterPatient.continueCapsulePerCycleIncomplete =
        capsuleCycleSelected.value === null && false;

      copiedRegisterPatient.continueCapsulePerWeekIncomplete =
        capsuleCycleSelected.value === null && false;

      setRegisterPatient(copiedRegisterPatient);
      setTotalCapsules(copiedRegisterPatient);
    }

    if (name === "number_cycles") {
      const formcopy = {
        ...registerPatient,
        [name]:
          capsuleCycleSelected?.id === 0 &&
          capsuleCycleSelected?.label === "None"
            ? null
            : capsuleCycleSelected,
        continueNumberOfCyclesIncomplete:
          capsuleCycleSelected.id === 0 && false,
        continueMaximumNumberOfWeeksExceededAgain:
          registerPatient.schedule_display_type === "weeeks_per_cycle" && false,
        // TODO: de vazut la scheduleDisplayType === cycle sa nu fie probleme
        // other: null,
        // number_weeks: null,
      };

      setRegisterPatient(formcopy);
      setTotalCapsules(formcopy);
    }

    if (name === "number_weeks") {
      const formcopy = {
        ...registerPatient,
        [name]:
          capsuleCycleSelected.id === 0 && capsuleCycleSelected.label === "None"
            ? null
            : capsuleCycleSelected,
        continueNumberOfWeeksIncomplete: capsuleCycleSelected.id === 0 && false,
        continueMaximumNumberOfWeeksExceeded: false,
        continueMaximumNumberOfWeeksExceededAgain: false,
        other:
          capsuleCycleSelected.id === 0 &&
          capsuleCycleSelected.label === "Other (Please State)"
            ? 1
            : null,
        number_cycles: null,
      };

      setRegisterPatient(formcopy);
      setTotalCapsules(formcopy);
    }

    if (name === "number_weeks_other") {
      let parsedValue = parseInt(capsuleCycleSelected);

      capsuleCycleSelected = parsedValue
        ? parsedValue.toString()
        : capsuleCycleSelected;
      if (+capsuleCycleSelected <= 0) {
        capsuleCycleSelected = "1";
      } else if (+capsuleCycleSelected > 12) {
        capsuleCycleSelected = "12";
      }
      let maxLength = -1;
      const shouldSetValue = +capsuleCycleSelected.length <= maxLength;

      const formCopy = {
        ...registerPatient,
        other: capsuleCycleSelected,
      };

      if (maxLength === -1) {
        setRegisterPatient(formCopy);
      } else {
        if (shouldSetValue) {
          setRegisterPatient(formCopy);
        }
      }

      setTotalCapsules(formCopy);
    }

    if (name === "weeks_per_cycle") {
      const formcopy = {
        ...registerPatient,
        weeks_per_cycle:
          capsuleCycleSelected?.id === 0 &&
          capsuleCycleSelected?.label === "None"
            ? null
            : capsuleCycleSelected,

        other: capsuleCycleSelected?.id === 0 ? 1 : null,
        continueMaximumNumberOfWeeksExceededAgain:
          registerPatient.schedule_display_type === "weeeks_per_cycle" && false,
        continueNumberOfWeeksIncomplete: capsuleCycleSelected.id === 0 && false,
      };

      setRegisterPatient(formcopy);
      setTotalCapsules(formcopy);
    }

    if (name === "indication") {
      if (capsuleCycleSelected.id === -1) {
        setRegisterPatient(() => ({
          ...registerPatient,
          indication: { value: undefined, label: "Select" },
          other_indication: null,
          clinical_trial: null,
        }));
      } else {
        setRegisterPatient(() => ({
          ...registerPatient,
          indication: capsuleCycleSelected,
          clinical_trial: null, // Set clinical_trial to empty string
          other_indication: null,
        }));
      }
    }
  };

  const commonFormProps = {
    state,
    previous,
    handleChange,
    registerPatient,
    personalDetails,
    validateFields: fields,
    updateRegisterPatient,
    errorMessage,
    showErrors,
    deletePropertyValue,
    prescriptionField,
    handleCapsules,
    checkedItems,
    checkedItemsPrescriber,
    handleCheckboxChange,
    allCheckedPrescriber,
  };

  const closeConfirmationProvided = () => {
    setOpenModal(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      type: "",
      modalState: 1,
      hasSecondState: false,
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  const continueConfirmationNotProvided = () => {
    if (errorMessage.title === "Continue with off-label use") {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueOffLabel: true,
      }));
    }

    if (errorMessage.title === errorMessages.INDICATION_INCOMPLETE_TITLE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueIndicationIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.CONFIRMATION_NOT_PROVIDED_TITLE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueWithoutConfirmation: true,
      }));
    }

    if (errorMessage.title === errorMessages.OUTDATED_PREGANACY_TEST_TITLE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueWithPregnancyTestDateOutdated: true,
      }));
    }

    if (
      errorMessage.title === errorMessages.PREGNANACY_TEST_DATE_INCOMPLETE_TITLE
    ) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueWithPregnancyTestDateIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.OUTDATED_DATE_BIRTH_TITLE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueWithDateOfBirthUnderEighteen: true,
      }));
    }

    if (errorMessage.title === "Maximum Number of Cycles Exceeded") {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueMaximumNumberOfCyclesExceeded: true,
      }));
    }
    if (errorMessage.title === " Capsules outside permitted Quantity") {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueMaximumNumberOfWeeksExceeded: true,
      }));
    }

    if (errorMessage.title === "Maximum number of weeks exceeded") {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueMaximumNumberOfWeeksExceededAgain: true,
      }));
    }
    if (errorMessage.title === "Capsules outside permitted Quantity") {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueMaximumNumberOfCapsulesExceeded: true,
      }));
    }

    if (errorMessage.title === errorMessages.CAPSULES_PER_CYCLE_INCOMPLETE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueCapsulePerCycleIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.CAPSULES_PER_WEEKS_INCOMPLETE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueCapsulePerWeekIncomplete: true,
      }));
    }

    if (errorMessage.title === errorMessages.NUMBER_OF_CYCLES_INCOMPLETE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueNumberOfCyclesIncomplete: true,
      }));
    }
    if (errorMessage.title === errorMessages.NUMBER_OF_WEEKS_INCOMPLETE) {
      setRegisterPatient((prevState) => ({
        ...prevState,
        continueNumberOfWeeksIncomplete: true,
      }));
    }

    setOpenModal(false);
    setErrorMessage((prevState) => ({
      ...prevState,
      type: "",
      modalState: 1,
      hasSecondState: false,
      title: "",
      description: "",
      buttonText: "",
    }));
  };

  const TopButtonsComponent = ({ auxButtons = false }) => (
    <>
      {!isSubmittedPresciberPAF ? (
        <div className="approve-reject-paf">
          {auxButtons && (
            <button className="mr-4 cancel-btn font-size-16" onClick={previous}>
              Cancel
            </button>
          )}
          <button
            className="green-button"
            onClick={() => {
              submitPAFToServer();
            }}
            disabled={disableCompleteButton}
          >
            Complete
          </button>
        </div>
      ) : (
        <div className="approve-reject-paf">
          {auxButtons && (
            <button className="mr-4 cancel-btn" onClick={() => printPAF()}>
              Print <Print />
            </button>
          )}
          <button className="green-button" onClick={redirectToOrderPage}>
            Finish
          </button>
        </div>
      )}
    </>
  );

  // END VALIDED WOMAN OF CHILDBEARING POTENTIAL FORM
  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal create-paf-wrapper">
          <div className="close-icon" onClick={closeForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          {state <= 5 && (
            <div className="step font-size-18">
              Step {state}/
              {registerPatient.type === typeOfPAF.WOMANOFCP ? "6" : "5"}
            </div>
          )}
          {state === 1 && <ConfirmIdentity personalDetails={personalDetails} />}
          {state === 3 && (isPrescriber || isConnectedRegistrar) && (
            <SelectTIF
              registerPatient={registerPatient}
              handleCheckboxChange={handleCheckboxChange}
              showErrors={showErrors}
              brochures={brochures}
              treatmentFormText={treatmentFormText}
            />
          )}
          {state === 4 && (
            <PatientCategory
              registerPatient={registerPatient}
              selectTypeOfPatient={selectTypeOfPatient}
            />
          )}
          {state === 2 && (
            <DrugToPrescribe
              registerPatient={registerPatient}
              selectTypeOfDrug={selectTypeOfDrug}
              drugToPrescribe={drugToPrescribe}
            />
          )}
          {state === 5 && (isPrescriber || isConnectedRegistrar) && (
            <DigitalPAF {...commonFormProps} storageData={storageData} />
          )}
          {state === 6 && (
            <React.Fragment>
              <div className="wrapper-flex">
                <h1>Prescription Authorisation Form</h1>
                {<TopButtonsComponent auxButtons />}
              </div>

              <InfoPAF patientDetails={getUserInfo()} />
              <ViewPAF
                receivedForm={removeEmptyValueAndSelectLabel(registerPatient)}
                isPrescriber={isPrescriber || isConnectedRegistrar}
                personalDetails={personalDetails}
                prescriptionField={prescriptionField}
                isCreateNewPAF={true}
                checkIfNumberOfCyclesExceeded={checkIfNumberOfCyclesExceeded}
                checkedItems={checkedItems}
                checkedItemsPrescriber={checkedItemsPrescriber}
                handleCheckboxChange={handleCheckboxChange}
              />

              <div className="footer-button-holder">
                <div className="footer-button">
                  <TopButtonsComponent />
                </div>
              </div>
            </React.Fragment>
          )}
          {state <= 5 && (
            <div className={state === 1 ? "wrapper-flex" : ""}>
              {state === 1 && (
                <div className="wrapper-flex editable-from-profile">
                  {/* Editable from profile */}
                </div>
              )}
              <div
                className={
                  state === 1
                    ? "wrapper-buttons-right-align"
                    : "wrapper-buttons-select-type"
                }
              >
                {state === 1 ? (
                  <button onClick={goToProfile} className="prev">
                    Go to Profile
                  </button>
                ) : (
                  <button onClick={previous} className="prev">
                    Previous
                  </button>
                )}

                <div className="flex-between-center">
                  {(state === 3 || state === 5) &&
                    (isPrescriber || isConnectedRegistrar) && (
                      <div className="symbol-idication">
                        *symbol indicating a mandatory field
                      </div>
                    )}
                  {(state === 1 || state === 3) && isPharmacy && (
                    <div className="symbol-idication">
                      *symbol indicating a mandatory field
                    </div>
                  )}

                  <button
                    className="primary-button custom-login-button"
                    onClick={nextPage}
                    disabled={isSubmitting || disableNextButton()}
                  >
                    {state === 1 ? "Begin" : "Next"}
                    {isSubmitting && (
                      <i className="custom-login-loader">
                        <Loader />
                      </i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          isOpen={registerModalIsOpen}
          style={customStyles}
          contentLabel="Register Modal"
          overlayClassName="overlay-modal"
          shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
        >
          <div className="wrapper-modal">
            <div className="main-text color-orient">
              Prescription Authorisation Form Completed
            </div>
            <div className="second-text">
              A copy of the prescription authorisation form will be available to
              the hospital inpatient pharmacy.
            </div>
          </div>
          <div className="wrapper-buttons">
            <button className="cancel mr-6" onClick={closeModalRegisterModal}>
              Cancel
            </button>
            <button
              className="approve-button"
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Complete
            </button>
          </div>
        </Modal>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={quitModal}
            description={`Are you sure you want to cancel filling out this ${treatmentFormText.treatment_form_text}. All progress will be lost and you will need to start again.`}
          />
        )}

        <ContinueConfirmationProvidedModal
          errorMessage={errorMessage}
          openModal={openModal}
          closeConfirmationProvided={closeConfirmationProvided}
          continueConfirmationNotProvided={continueConfirmationNotProvided}
        />

        <NonComplaintPAF
          openModalNonComplaint={openModalNonComplaint}
          closeNonComplaint={closeNonComplaint}
          submitNonComplaint={submitNonComplaint}
          handleChangeValidPassword={handleChangeValidPassword}
          registerPatient={registerPatient}
          displayWeeks={scheduleDisplayType}
          errorMessageFromServer={errorMessageFromServer}
          hcp={hcp}
        />
      </StyledRegister>
    </React.Fragment>
  );
};
export default PatientInitiation;
