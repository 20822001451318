import React, { useEffect } from "react";
import SyledCheckboxMedication from "./SyledCheckboxMedication";

const TermsAndConditionsPharmacy = ({
  getBrochure,
  products,
  selectedProducts,
  productConfirmations,
  handleCheckboxes,
  checkboxesMedications,
  getTitles,
  uniqueTitlesJoin,
}) => {
  function compareArrayWithObjects(array, objects) {
    const matchingParameters = [];

    for (let i = 0; i < array.length; i++) {
      const currentNumber = array[i];

      for (let j = 0; j < objects.length; j++) {
        const currentObject = objects[j];

        if (currentNumber === currentObject.product_id) {
          matchingParameters.push(currentObject.name);
        }
      }
    }

    // Return the array of matching parameters
    return matchingParameters;
  }

  // Usage
  const result = compareArrayWithObjects(selectedProducts, products);

  const keysandvalues = productConfirmations.reduce(
    (acc, innerArray, index) => {
      return acc.concat(
        innerArray?.map((element) => {
          return {
            title: result[index],
            id: `${index}-${element.confirmation_id}`,
            val: element.confirmation_name,
            brochure_name: element.brochure_name,
            brochure_id: element.brochure_id,
          };
        })
      );
    },
    []
  );

  const uniqueTitles = [
    ...new Set(keysandvalues.map((element) => element.title)),
  ];

  const handleGetBrochure = (event, brochureId) => {
    event.stopPropagation();
    event.preventDefault();
    getBrochure(brochureId);
  };

  const title = uniqueTitles.map((title) => title);
  useEffect(() => {
    getTitles(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title?.length]);

  return (
    <React.Fragment>
      <div className="prescriber-confirmation">
        <div>
          <SyledCheckboxMedication>
            {uniqueTitles.map((title) => (
              <div>
                <h3 className="title-details orient">
                  Pharmacy {title} Confirmation
                </h3>
                <p>When prescribing {title}, I accept responsability to:</p>
                {keysandvalues
                  .filter((element) => element.title === title)
                  ?.map((element, index) => (
                    <div
                      onClick={() => handleCheckboxes(element.id)}
                      className="custom-checkbox checkboxes-block"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        checked={checkboxesMedications.includes(element.id)}
                        readOnly
                      />
                      <span className="checkmark margin" />
                      <p className="text-for-checkboxes">
                        {index + 1}.{" "}
                        {element.val?.split("\r\n")?.map((line, lineIndex) => (
                          <React.Fragment key={lineIndex}>
                            {line.includes("<brochure>") ? (
                              <>
                                {line
                                  .split("<brochure>")
                                  .map((textPart, partIndex) => (
                                    <React.Fragment key={partIndex}>
                                      {partIndex > 0 && (
                                        <span
                                          className="brochure-link"
                                          onClick={(e) =>
                                            handleGetBrochure(e, element)
                                          }
                                        >
                                          {element.brochure_name}
                                        </span>
                                      )}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: textPart,
                                        }}
                                      />
                                    </React.Fragment>
                                  ))}
                              </>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{ __html: line }}
                              />
                            )}
                            {lineIndex !==
                              element?.val?.split("\r\n").length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  ))}
              </div>
            ))}
          </SyledCheckboxMedication>
        </div>
      </div>

      <div className="confirm">
        By clicking register, I understand that if during the period of
        registration I am unable to fulfil requirements 1 - 10, the above
        named-pharmacy will be de-registered by Pharmacare and I will be unable
        to order any further {uniqueTitlesJoin} and required to go through the
        registration process again, following any necessary remedial action(s).
        I acknowledge this registration to order and dispense {uniqueTitlesJoin}{" "}
        is valid for 2 years only, after which I am required to re-register the
        above-named pharmacy should I wish to continue to order and dispense{" "}
        {uniqueTitlesJoin}. I understand that my personal data will be processed
        by Pharmacare, for the purpose of administering the PPP for{" "}
        {uniqueTitlesJoin} and that the information supplied to Pharmacare on
        PAFs will be used to provide anonymised aggregate annual reports to the
        Medicines and Healthcare products Regulatory Agency (MHRA) to assess the
        implementation of the PPP.
      </div>
    </React.Fragment>
  );
};
export default TermsAndConditionsPharmacy;
