import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import StyledModal from "./../../../common/Modal/StyledModal";
import CloseIcon from "../../../../assets/icons/close.svg";
import StyledDrugRegisterModal from "./StyledDrugRegisterModal";
import moment from "moment";
import Axios from "../../../../services/Axios";
import APIVariables from "../../../../services/api-variables";
import InformationGuideRead from "../../../authentication/informationGuideRead";

const DrugRegistrationModal = ({
  isOpen,
  productConfirmations,
  profileDetails,
  handleCheckboxes,
  registerProduct,
  checkboxesMedications,
  user_type,
  productID,
  products,
  onRequestClose,
  isValid,
  isregistering,
  setWasLinkClicked,
  wasLinkClicked,
  openGuideRead,
  closeModalGuideRead,
  confirmInformationGuideRead,
  handleCheckConfirmInformationGuideRead,
  clearData,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      minHeight: "50%",
      maxHeight: "80%",
      boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
    },
  };

  const nameProduct = products.map(
    (element) => element?.product_id === productID && element?.product_name
  );
  const [brochureIdAndName, setBrochureIdAndName] = useState([]);
  useEffect(() => {
    // Your data fetching or processing logic here
    const processedData = productConfirmations
      .flat()
      .filter(
        (item) =>
          item.brochure_name !== null &&
          item.confirmation_name.includes("<brochure>")
      )
      .reduce((acc, item) => {
        const existingItem = acc.find(
          (x) => x.brochure_id === item.brochure_id
        );

        if (!existingItem) {
          acc.push({
            brochure_id: item.brochure_id,
            brochure_name: item.brochure_name,
          });
        } else {
          // Check if brochure_name is not already included
          if (!existingItem.brochure_name.includes(item.brochure_name)) {
            existingItem.brochure_name += `, ${item.brochure_name}`;
          }
        }

        return acc;
      }, []);

    // Set the state with the processed data
    setBrochureIdAndName(processedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productConfirmations]);

  const getBrochure = async (confirmation, openInNewPage = false) => {
    const updatedArray = brochureIdAndName.filter(
      (item) => item.brochure_id !== confirmation.brochure_id
    );

    updatedArray.length === 0 && setWasLinkClicked(true);
    setBrochureIdAndName(updatedArray);

    await Axios.get(
      APIVariables.PATIENTS_BROCHURES_DOWNLOAD + confirmation.brochure_id
    )
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          return data?.report;
        }
        return null;
      })
      .then(async (brochureString) => {
        const base64 = await fetch(
          `data:application/pdf;base64,${brochureString}`
        );
        return base64.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        if (openInNewPage) {
          downloadLink.target = "_blank";
        } else {
          downloadLink.download = confirmation.brochure_name + ".pdf";
        }
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(() => {});
  };
  const handleBrochureClick = (brochureId) => {
    getBrochure(brochureId);
  };

  return (
    <StyledModal>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <StyledDrugRegisterModal>
          <div className="wrapper-modal wrapper-modal-mfa">
            <div className="wrapper-buttons ">
              <div className="container initiation">
                <div className="row">
                  <div className="col"></div>
                  <div className="col">
                    <div className="wrapper-register-button">
                      <div className="close-icon" onClick={onRequestClose}>
                        <img
                          src={CloseIcon}
                          className="close-img"
                          alt="Close Icon"
                          onClick={() => {
                            clearData();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <h3 className="title-details orient">
                {" "}
                {user_type === 1 ? "Prescriber" : "Pharmacy"} {nameProduct}{" "}
                Confirmation
              </h3>
              <p className="sub-title">
                When prescribing {nameProduct}, I accept responsibility to:
              </p>

              {Object.keys(productConfirmations).map((key, index) => {
                const confirmation = productConfirmations[key];
                let displayName = confirmation.confirmation_name;
                let clickId = confirmation.confirmation_id;

                // Replace '\r\n' with line breaks
                displayName = displayName.replace(/\r\n/g, "<br />");

                // Replace '<brochure>' with a placeholder for now
                displayName = displayName.replace(/<brochure>/g, "{brochure}");

                // Split the displayName into an array of parts
                const parts = displayName.split("{brochure}");

                return (
                  <div
                    key={clickId}
                    onClick={() => {
                      handleCheckboxes(clickId);
                    }}
                    className="custom-checkbox checkboxes-block input-and-text display-flex"
                  >
                    <input
                      type="checkbox"
                      checked={checkboxesMedications.includes(clickId)}
                      readOnly
                    />
                    <span className="checkmark margin" />
                    <strong>{index + 1}.</strong>
                    <div>
                      {parts.map((part, i) => {
                        return (
                          <React.Fragment key={`part_${i}`}>
                            <span
                              dangerouslySetInnerHTML={{ __html: part }}
                            ></span>
                            {i < parts.length - 1 && (
                              <span
                                key={`brochure_${clickId}`}
                                className="brochure-link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleBrochureClick(confirmation);
                                }}
                              >
                                {confirmation.brochure_name}
                              </span>
                            )}
                            {/* <br /> Add line break between parts */}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* TODO: Check brochures  */}
            <div className="container mb-5">
              By clicking ‘Register’, I confirm that I am a{" "}
              {user_type === 1 ? "prescriber" : "pharmacy"} appropriately
              experienced in managing immunomodulatory drugs and that I have
              read and understand the {nameProduct} Healthcare Professional
              Brochure and agree to comply with all the elements of the
              Pregnancy Prevention Programme described within it.
            </div>

            <div className="wrapper-buttons-modal">
              <div className="container initiation">
                <div className="row">
                  <div className="col">
                    <label className="orient mb-1">Prescriber Name</label>
                    <input
                      type="text"
                      className="disabled prescriber-input"
                      name="prescriberName"
                      value={
                        profileDetails?.first_name +
                        " " +
                        profileDetails?.last_name
                      }
                      disabled
                    />
                  </div>
                  <div className="col">
                    <label className="orient mb-1">Date</label>
                    <input
                      className="disabled date-input"
                      value={moment().format("DD / MM / YYYY")}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper-buttons-modal">
              <div className="container initiation">
                <div className="row">
                  <div className="col" onClick={onRequestClose}>
                    <div
                      className="previous"
                      onClick={() => {
                        clearData();
                      }}
                    >
                      Previous
                    </div>
                  </div>
                  <div className="col">
                    <div className="wrapper-register-button">
                      <button
                        className="primary-button custom-login-button "
                        disabled={
                          productConfirmations.length !== 0
                            ? isregistering === true &&
                              isValid === true &&
                              Boolean(brochureIdAndName.length)
                            : true
                        }
                        onClick={() => registerProduct()}
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TODO: Check brochures */}
            <div className="container initiation">
              <div className="row">
                <div className="col" onClick={onRequestClose}></div>
                <div className="col">
                  <div className="wrapper-register-button">
                    <div className="error-text">
                      {isregistering === true && isValid === false ? (
                        <div className="error-text">
                          The {user_type === 1 ? "prescriber" : "pharmacy"} has
                          not agreed to 1 or more statements on this page.
                          <br />
                          Please tick the boxes and confirm that the patient has
                          agreed before continuing
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {isregistering === true &&
                      isValid === true &&
                      Boolean(brochureIdAndName.length) &&
                      wasLinkClicked === false && (
                        <div className="error-text error-list">
                          Please read{" "}
                          {brochureIdAndName.map((element) => (
                            <ul key={element.brochure_id}>
                              <li>{element.brochure_name}</li>
                            </ul>
                          ))}
                          Once read, click Register to continue
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <InformationGuideRead
              openGuideRead={openGuideRead}
              closeModalGuideRead={closeModalGuideRead}
              confirmInformationGuideRead={confirmInformationGuideRead}
              handleCheckConfirmInformationGuideRead={
                handleCheckConfirmInformationGuideRead
              }
            />
          </div>
        </StyledDrugRegisterModal>
      </Modal>
    </StyledModal>
  );
};

export default DrugRegistrationModal;
