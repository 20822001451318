export const errorMessages = {
  INVALID_EMAIL: "Email is invalid",
  INVALID_PASSWORD: "Password is invalid",
  PASSWORD_LENGTH: "Password length must be between 8 and 20 characters",
  PASSWORD_CHARACTERS:
    "Password must contain at least one capital letter, one number and one special character",
  PASSWORD_MATCH: "Passwords must match",
  PATIENT_NOT_AGREED_REQUIRMENTS:
    "Patient has not agreed to the required statements",
  PATIENT_NOT_AGREED_REQUIRMENTS_DESCRIPTION:
    "The patient has not agreed to 1 or more statements on this page. please tick the boxes and confirm that the patient has agreed before continuing.",
  PRESCRIBER_NOT_AGREED_REQUIRMENTS:
    "Prescriber has not agreed to the required statements",
  PRESCRIBER_NOT_AGREED_REQUIRMENTS_DESCRIPTION:
    "The prescriber has not agreed to 1 or more statements on this page. Please tick the boxes and confirm that the patient has agreed before continuing.",
  PHARMACY_NOT_AGREED_REQUIRMENTS:
    "Pharmacist (or appointed Deputy) has not agreed to the required statements",
  PHARMACY_NOT_AGREED_REQUIRMENTS_DESCRIPTION:
    "1 or more statements on this page have not been confirmed. Please tick the appropriate box(es) to complete the registration process.",
  NEED_VALID_NEGATIVE_TEST: `<div>A valid negative pregnancy test must have been conducted a maximum of 3 days prior to the prescription date. Please refer to the SmPC or <a
    rel="noreferrer"
    href="/"
    target="_blank"
  >
    Lenalidomide Healthcare Professional Brochure
  </a> for further information on pregnancy prevention measures.</div>`,
  OUTDATED_PREGANACY_TEST_TITLE:
    "Pregnancy test date outside permitted date range",
  PREGNANACY_TEST_DATE_INCOMPLETE_TITLE: "Pregnancy Test Date Incomplete",
  INDICATION_INCOMPLETE_TITLE: "Indication Incomplete",
  CAPSULES_PER_CYCLE_INCOMPLETE: "Capsules per Cycle Incomplete",
  CAPSULES_PER_WEEKS_INCOMPLETE: "Capsules per Weeks Incomplete",
  NUMBER_OF_CYCLES_INCOMPLETE: "Number of Cycles Incomplete",
  NUMBER_OF_WEEKS_INCOMPLETE: "Number of Weeks Incomplete",
  OUTDATED_DATE_BIRTH_TITLE: "Date birth under 18 years old",
  SOMETHING_WENT_WRONG: "Something went wrong",
  PASSWORD_NOT_MATCH: "Password does not match",
  MUST_CLICK_LINK_TITLE: "Information Guide not read",
  MUST_CLICK_LINK_DESCRIPTION:
    "Please go back and read the Healthcare Professionals Information Guide. Once read, click Register and acknowledge your reading of the information guide",
  MAXIM_NUMBER_OF_CYLES_EXCEEDED_TITLE: "Maximum Number of Cycles Exceeded",
  MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_MALE: `<div>A Prescription Authorisation Form for a male may not exceed more than 3 cycles. Please refer to the SmPC and     <a
  rel="noreferrer"
  href="/"
  target="_blank"
>
  Lenalidomide Healthcare Professional Brochure
</a> for further information on pregnancy prevention measures.</div>`,
  MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_WOMANOFNCP: `A Prescription Authorisation Form for a woman of non-childbearing potential may not exceed more than 3 cycles. Please refer to the SmPC and <a
rel="noreferrer"
href="/"
target="_blank"
>
Lenalidomide Healthcare Professional Brochure
</a> for further information on pregnancy prevention measures.`,
  MAXIM_NUMBER_OF_CYLES_EXCEEDED_DESCRIPTION_WOMANOFCP: `<div>A Prescription Authorisation Form for a woman of childbearing potential may not exceed more than 1 cycle. Please refer to the SmPC and   <a
rel="noreferrer"
href="/"
target="_blank"
>
Lenalidomide Healthcare Professional Brochure
</a> for further information on pregnancy prevention measures.</div>`,
  CONFIRMATION_NOT_PROVIDED_TITLE: "Confirmation not provided",
  CONFIRMATION_NOT_PROVIDED_DESCRIPTION: `<div>Please check the confirmation box that the patient has been counselled on the terms of the Pregnancy Prevention Programme. Please refer to the SmPC and <a
rel="noreferrer"
href="/"
target="_blank"
>
Lenalidomide Healthcare Professional Brochure
</a> for further information on pregnancy prevention measures.</div>`,
  INTERNAL_SERVER_ERROR: "Internal Server Error.",
  DISPENSING_MORE_THAN_7_DAYS_TITLE:
    "Dispensing more than 7 days after the prescription date for this patient",
  DISPENSING_MORE_THAN_7_DAYS_DESCRIPTION:
    "I acknowledge that this PAF should be rejected as dispensing is required to take place within 7 days of prescription date for women of childbearing potential",
  CHANGE_OF_MOLECULE: "Change of Molecule",
};
