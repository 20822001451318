import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close.svg";
import APIVariables from "../../../services/api-variables";
import { ReactComponent as Pen } from "../../../assets/icons/pen.svg";
import StyledRegister from "../../authentication/StyledRegister";
import {
  handleCatch,
  isEmptyObject,
  isObject,
  validateEmail,
} from "../../../shared/helpers";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import RemoveNurseModal from "../Nurses/removeNurseModal";
import LocalStorage from "../../../shared/localStorage";
import Loader from "../../common/Loader/Loader";
import { errorMessages } from "../../../constants/errorMessages.constants";
import { ReactComponent as Undo } from "../../../assets/icons/undo.svg";
import Axios from "../../../services/Axios";

const NurseEdit = () => {
  const navigate = useNavigate();
  const selectedNurse = window.location.pathname.split("/")[2];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nurseForm, setNurseForm] = useState({});
  const [hospitalDetails, setHospitalDetails] = useState();
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [unchangedFields, setUnchangedFields] = useState({});

  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);

  useEffect(() => {
    getData();

    // When destroy profile component, remove IS_EDIT_MODE_NHSPORTAL  from localstorage
    return () => {
      localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    await Axios.get(
      APIVariables.HCP_CONNECTED_NURSE + window.location.pathname.split("/")[2]
    )
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          let nurseForm = {
            ...data,
            ...data.user,
          };
          delete nurseForm.user;
          delete nurseForm.hospital;
          setUnchangedFields(nurseForm);
          setNurseForm(nurseForm);
          setHospitalDetails({ ...data.hospital });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        handleCatch(error);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNurseForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function closeForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.DASHBOARD);
  }

  const openModalRemoveNurse = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const removeNurse = async () => {
    setErrorMessage("");

    await Axios.delete(APIVariables.HCP_CONNECTED_NURSES_DELETE + selectedNurse)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          closeModal();
          navigate(App.NURSES);
        }
      })
      .catch(function (error) {
        const errors = error.response.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          Object.keys(errors).forEach((key) => {
            if (isObject(errors[key])) {
              Object.keys(errors[key]).forEach((secondKey) => {
                errorsArray.push(key + " - " + errors[key][secondKey][0]);
              });
            } else {
              errorsArray.push(key + " - " + errors[key]);
            }
          });
          setErrorMessage(errorsArray);
        }
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveButtonClick = async () => {
    setIsLoadingSaveButton(true);
    await onClickEditSave();
    setIsLoadingSaveButton(false);
  };

  const onClickEditSave = async () => {
    setErrorMessage("");
    setIsSubmitting(false);
    if (isEditMode) {
      localStorage.setItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL, true);
      setIsSubmitting(true);

      if (areAllFields) {
        let buildEditObject = {
          job_title: nurseForm?.job_title,
          user_data: {
            ...nurseForm,
          },
        };

        if (!isEmptyObject(buildEditObject)) {
          const currentNurseID = window.location.pathname.split("/")[2];

          await Axios.put(
            APIVariables.HCP_CONNECTED_NURSES_EDIT + currentNurseID,
            buildEditObject
          )
            .then((res) => {
              const { status } = res;
              if (status === 200) {
                setUnchangedFields(nurseForm);
                setIsEditMode(false);
                localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
              }
            })
            .catch(function (error) {
              handleCatch(error);
              const errors = error?.response?.data;
              const errorsArray = [];
              errorsArray.push(errors);
              setErrorMessage(errorsArray);
            });
        } else {
          setIsEditMode(false);
          localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
        }
      } else {
        localStorage.setItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL, true);
        setIsSubmitting(true);
      }
    } else {
      if (!isEditMode) {
        localStorage.setItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL, true);
      } else {
        localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
      }
      setIsEditMode(!isEditMode);
    }
  };

  const validateForm = ({
    first_name = "",
    last_name = "",
    job_title = "",
    registration_number = "",
    email = "",
  }) => ({
    first_name: {
      isValid: first_name.trim().length > 0,
    },
    last_name: {
      isValid: last_name.trim().length > 0,
    },
    job_title: {
      isValid: job_title.trim().length > 0,
    },
    registration_number: {
      isValid: registration_number.trim().length > 0,
    },
    email: {
      isValid: email.trim().length > 0,
    },
    emailValid: {
      isValid: validateEmail(email),
    },
  });

  const { first_name, last_name, job_title, email, registration_number } =
    nurseForm;

  const validateScreenFields = validateForm({
    first_name,
    last_name,
    job_title,
    email,
    registration_number,
  });

  let areAllFields = Object.values(validateScreenFields).every((field) => {
    return field.isValid;
  });

  const isEmailInvalid = errorMessage && errorMessage[0]?.email;

  const setUndoChanges = () => {
    setNurseForm(unchangedFields);
    setIsEditMode(false);
  };

  const areChanges = () => {
    return JSON.stringify(nurseForm) !== JSON.stringify(unchangedFields);
  };

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <StyledRegister>
        <React.Fragment>
          <div className="layout-modal">
            <div className="close-icon" onClick={closeForm}>
              <img src={CloseIcon} alt="Close Icon" />
            </div>
            <div className="title-flex">
              <h1 className="select-category-txt mineShaft-color">
                {nurseForm.first_name} {nurseForm.last_name} - Registered Nurse
              </h1>
              <div className="title-flex">
                <button
                  className="second-button margin-right"
                  disabled={isLoadingSaveButton}
                  onClick={handleSaveButtonClick}
                >
                  {!isEditMode ? "Edit" : "Save"} Profile <Pen />
                </button>
                <button
                  className="second-button red-button"
                  onClick={(e) => openModalRemoveNurse(e)}
                >
                  Remove
                </button>
              </div>
            </div>
            <hr className="gray-hr mineShaft-color" />

            <h2 className={"title-details"}>Nurse Details</h2>

            <div className="container mb-5">
              <div className="row">
                <div className="col">
                  <label>Nurse First Name:</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="first_name"
                    value={nurseForm.first_name}
                    maxLength={30}
                    className={"disabled"}
                    disabled
                  />
                  {isSubmitting &&
                    !validateScreenFields.first_name?.isValid && (
                      <span className="required-text">Required</span>
                    )}
                </div>
                <div className="col">
                  <label>Nurse Last Name:*</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="last_name"
                    value={nurseForm.last_name}
                    maxLength={30}
                    className={
                      isEditMode
                        ? ""
                        : isSubmitting &&
                          !validateScreenFields.last_name.isValid
                        ? "error"
                        : "disabled"
                    }
                    disabled={!isEditMode}
                  />
                  {isSubmitting && !validateScreenFields.last_name?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Registration number:</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="registration_number"
                    value={nurseForm.registration_number || ""}
                    maxLength={30}
                    disabled
                    className="disabled"
                  />
                </div>
                <div className="col">
                  <label>Job Title:*</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="job_title"
                    value={nurseForm.job_title}
                    maxLength={30}
                    className={
                      isEditMode
                        ? ""
                        : isSubmitting &&
                          !validateScreenFields.job_title.isValid
                        ? "error"
                        : "disabled"
                    }
                    disabled={!isEditMode}
                  />
                  {isSubmitting && !validateScreenFields.job_title?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col custom-select-register">
                  <label>Hospital Name:</label>

                  <input
                    type="text"
                    name="hospital"
                    value={hospitalDetails?.name}
                    disabled
                    className="disabled"
                  />
                </div>
                <div className="col">
                  <label>Email Address:</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="email"
                    value={nurseForm.email}
                    maxLength={50}
                    className="disabled"
                    disabled
                  />
                  {isSubmitting && !validateScreenFields.email?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                  {isSubmitting &&
                    !validateScreenFields.emailValid.isValid &&
                    validateScreenFields.email.isValid && (
                      <span className="required-text">Invalid Email</span>
                    )}
                  {isEmailInvalid && (
                    <span className="required-text">{isEmailInvalid}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Hospital Address:</label>
                  <input
                    type="text"
                    name="hospital"
                    value={hospitalDetails?.address.full_address}
                    disabled
                    className="disabled"
                  />
                </div>
              </div>
            </div>
            <div className="wrapper-buttons-select-type nurse-undo">
              <div>
                {areChanges() ? (
                  <button
                    className="undo-changes font-size-18 mineShaft-color"
                    onClick={() => setUndoChanges()}
                  >
                    <Undo /> Undo Changes
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {quitModalIsOpen && (
            <QuitModal
              quitModalIsOpen={quitModalIsOpen}
              closeModalQuit={closeModalQuit}
              quitModal={quitModal}
              description="Are you sure you want to cancel filling out this form? All progress will be lost and you will need to start again."
            />
          )}

          {modalIsOpen && (
            <RemoveNurseModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              removeNurse={removeNurse}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      </StyledRegister>
    </React.Fragment>
  );
};
export default NurseEdit;
