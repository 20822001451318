import Select, { components } from "react-select";
import { ReactComponent as DropdownArrowDown } from "../../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../../assets/icons/dropdownArrowUp.svg";
import Colors from "../../../shared/colors";
import moment from "moment";
import { useEffect, useState } from "react";
import APIVariables from "../../../services/api-variables";
import { handleCatch } from "../../../shared/helpers";
import Axios from "../../../services/Axios";
import useRoles from "../../../hooks/useRoles";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropdownArrowUp />
        ) : (
          <DropdownArrowDown />
        )}
      </components.DropdownIndicator>
    )
  );
};

function NominateDispenser({
  openFinishModal,
  onPrevious,
  setFinishModalContent,
  completePAF,
  pafID,
  dispensingForm,
  setDispensingForm,
}) {
  const [dispenserPoint, setDispenserPoint] = useState([]);
  const [locations, setLocations] = useState([]);
  const { storageData } = useRoles();
  const [fieldsValidation, setFieldsValidation] = useState({
    showErrors: false,
    validation: {
      type: {
        isValid: false,
      },
      location: {
        isValid: false,
      },
      confirmation_risk: {
        isValid: false,
      },
    },
    areErrors: true,
  });

  useEffect(() => {
    getDispensersType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pafID]);

  useEffect(() => {
    validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dispensingForm)]);

  useEffect(() => {
    getLocationOptions();
    setDispensingForm({ ...dispensingForm, location: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispensingForm.type]);

  const validateFields = () => {
    const { confirmation_risk, location, type } = dispensingForm;
    let validation = {
      confirmation_risk: {
        isValid: confirmation_risk === true,
      },
      type: {
        isValid: type !== null,
      },
      location: {
        isValid: location !== null,
      },
    };
    let areErrors = Object.values(validation).some((field) => {
      return !field.isValid;
    });
    setFieldsValidation((prevState) => ({
      ...prevState,
      validation: validation,
      areErrors: areErrors,
    }));
  };

  const getLocationOptions = async () => {
    if (!dispensingForm.type) {
      return;
    }
    await Axios.get(
      APIVariables.PRESCRIPTIONS +
        APIVariables.DISPENSER_TYPE_LOCATIONS +
        `${dispensingForm.type?.value}`
    )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setLocations(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const getDispensersType = async () => {
    await Axios.get(
      APIVariables.PRESCRIPTIONS + `${pafID}` + APIVariables.DISPENSER_TYPE
    )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const { dispenser_types } = data;
          setDispenserPoint(dispenser_types);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const handleChangeSelect = (e, selectInfo) => {
    setDispensingForm({ ...dispensingForm, [selectInfo.name]: e });
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setDispensingForm({ ...dispensingForm, [name]: checked });
  };

  const clickComplete = () => {
    if (fieldsValidation.areErrors) {
      setFieldsValidation({ ...fieldsValidation, showErrors: true });
      return;
    }
    let nominate = {
      dispenser_type: dispensingForm.type?.value,
      dispensing_location: dispensingForm.location?.value,
      confirmation_risk: dispensingForm.confirmation_risk,
    };
    setFinishModalContent({
      title: "Prescription Authorisation Form Completed",
      content: "The PAF has been sent to the nominated dispensing location.",
      onFinish: () => {
        completePAF(nominate);
        onPrevious();
      },
    });
    openFinishModal();
  };

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: `${Colors.orient} !important`,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: `${Colors.orient} !important`,
      background: isFocused
        ? `${Colors.porcelain} !important`
        : "white !important",
    }),
  };

  return (
    <div className="layout-modal nominate-dispenser">
      <div className="nominate-dispenser-container">
        <div className="step font-size-18">Step 3/3</div>
        <div className="title font-size-18">PAF to Nominated Dispenser*:</div>
        <div className="subtitle">Please select the nominated dispenser*:</div>
        <div className="row mt-1">
          <div className="col">
            <Select
              classNamePrefix="custom-select"
              components={{
                DropdownIndicator: DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              styles={customStyles}
              options={dispenserPoint.map((dispenser) => ({
                value: dispenser?.id,
                label: dispenser?.value,
              }))}
              name="type"
              value={dispensingForm.type}
              onChange={handleChangeSelect}
              className="select-border"
              placeholder="Select the dispensing point"
            />
          </div>
        </div>
        <div className="row">
          {fieldsValidation.showErrors &&
            !fieldsValidation.validation.type?.isValid && (
              <span className="required-text unset-bottom-left">Required</span>
            )}
        </div>
        <div className="row mt-5">
          <div className="col">
            <Select
              classNamePrefix="custom-select"
              components={{
                DropdownIndicator: DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              value={dispensingForm.location}
              onChange={handleChangeSelect}
              styles={customStyles}
              name="location"
              options={locations.map((location) => ({
                value: location?.id,
                label: location?.name,
                address: location?.address,
              }))}
              className="select-border"
              placeholder="Select a dispensing location"
            />
          </div>
        </div>
        <div className="row">
          {fieldsValidation.showErrors &&
            !fieldsValidation.validation.location?.isValid && (
              <span className="required-text unset-bottom-left">Required</span>
            )}
        </div>
        <div className="row mt-5">
          <div className="col">
            <label
              htmlFor="confirmation_risk"
              className={
                "custom-checkbox font-size-16 mt-4 mb-4 " +
                (fieldsValidation.showErrors &&
                !fieldsValidation.validation.confirmation_risk?.isValid
                  ? "red-label"
                  : "")
              }
            >
              I confirm that treatment lengths will be limited to a maximum of 4
              weeks supply for women of childbearing potential and a maximum of
              12 weeks for males and women of non-childbearing potential
              patients. For women of child bearing potential dispensing will be
              within 7 days of the date of prescription
              <input
                type="checkbox"
                id="confirmation_risk"
                name="confirmation_risk"
                onChange={handleCheckBoxChange}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="info-container">
          <div className="pharma-name">
            <label>Pharmacist name:</label>
            <input
              type="text"
              value={
                storageData?.first_name + " " + storageData?.last_name || ""
              }
              disabled
            />
          </div>
          <div className="date-container">
            <label>Date:</label>
            <div className="date">{moment().format("DD / MM / YYYY")}</div>
          </div>
        </div>
        <div className="wrapper-buttons-select-type">
          <button className="prev" onClick={() => onPrevious(true)}>
            Previous
          </button>
          <div className="flex-between-center">
            <div className="symbol-idication orient">
              *symbol indicating a mandatory field
            </div>
            <button
              className="primary-button custom-login-button"
              onClick={() => clickComplete()}
            >
              Complete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NominateDispenser;
