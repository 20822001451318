import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  background: ${Colors.alabaster};
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .paf-status {
    margin-left: auto;
    margin-right: 110px;
  }

  .layout {
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .custom-style {
    @media screen and (max-width: 1025px) {
      display: block !important;
    }
  }

  .color-orient {
    color: #00567a;
  }

  h2 {
    color: ${Colors.orient};
  }

  .paf-info {
    margin-right: 50px;

    &-date {
      span {
        color: ${Colors.mineShaft};
      }
    }
  }

  .wrapper-flex {
    display: flex;
    justify-content: space-between;
    align-self: center;

    div {
      align-self: center;
      margin-bottom: 5px;
      min-height: 20px;
    }

    .left-button {
      margin-right: 30px;
      color: ${Colors.orient};
      text-decoration-line: underline;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
    }

    .left-button:hover {
      color: ${Colors.blumine};
    }
  }

  .renew-button {
    margin-left: 30px;
  }

  .print-tif {
    cursor: pointer;

    span {
      text-decoration-line: underline;
      color: ${Colors.orient};
      margin-right: 10px;
    }
  }

  .print-tif:hover {
    span {
      color: ${Colors.blumine};
    }

    svg path {
      fill: ${Colors.blumine};
    }
  }

  .extra-text-button {
    color: ${Colors.orient};
    text-align: center;
  }

  .custom-checkbox {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: justify;
  }

  .accordion {
    border: 0;
    margin: 20px 0;
  }
  .accordion__panel {
    background: ${Colors.white};
    padding: 0px 20px 20px 20px;
    animation: none;
  }

  .accordion__button {
    position: relative;
    background-color: ${Colors.white};
  }

  .accordion__item {
    margin-bottom: 10px;
  }

  .accordion__item + .accordion__item {
    border-top: 0;
  }

  .accordion__button:before {
    content: "▼";
    border: 0;
    transform: initial;
    color: ${Colors.orient};
    right: 20px;
    position: absolute;
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    content: "▲";
    z-index: 1;
    width: 20px;
    height: 25px;
    text-align: right;
    font-size: initial;
  }

  [aria-expanded="true"] {
    h2 {
      color: ${Colors.white};
      height: 0;
      font-size: 0 !important;
    }
  }

  .wrapper-pannel {
    position: relative;
    margin-top: -19px;
  }

  .paf-header {
    min-height: 49px;
    margin-bottom: -25px;
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;

    h2 {
      margin-bottom: 0px;
    }
  }

  .nominate-dispenser {
    background-color: ${Colors.alabaster};
  }
  .nominate-dispenser-container {
    min-height: 500px;

    a {
      color: ${Colors.doveGray};
      font-size: 16px;
    }

    .title {
      color: ${Colors.orient};
      margin-top: 50px;
    }

    .subtitle {
      color: ${Colors.orient};
      margin-top: 15px;
    }

    .custom-select__placeholder {
      color: ${Colors.gray} !important;
    }

    .custom-select__control {
      background-color: white !important;
    }
    .custom-select__menu > div > div:hover {
      background-color: ${Colors.slateGray} !important;
      color: white !important;
    }

    .custom-select__menu > div > div {
      background-color: white !important;
      color: ${Colors.mineShaft} !important;
    }

    .custom-select__single-value {
      color: ${Colors.doveGray} !important;
    }

    .pharma-name {
      display: grid;
      width: 25%;
      input {
        border: 0px;
        border-bottom: 1px solid ${Colors.gray};
        color: ${Colors.gray};
        margin-top: 10px;
        font-size: 18px;
        background-color: ${Colors.alabaster};
      }
      label {
        color: ${Colors.gray};
        font-size: 14px;
      }
    }

    .date-container {
      width: 25%;
      label {
        color: ${Colors.gray};
        font-size: 14px;
      }
      .date {
        border-bottom: 1px solid ${Colors.gray};
        color: ${Colors.gray};
        font-size: 18px;
        margin-top: 10px;
      }
    }

    .info-container {
      display: flex;
      justify-content: space-around;
      margin-top: 75px;
    }

    .wrapper-buttons-select-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      .prev {
        color: ${Colors.orient};
      }
    }

    .flex-between-center {
      display: flex;
      align-items: center;

      button {
        margin-left: 10px;
      }
    }
    .buttons_container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 15px;
    }
  }

  .renewal-available {
    color: ${Colors.orient};
    font-size: 10px;
    line-height: 15px;
    text-align: center;
  }
`;
