import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import CloseIcon from "../../../assets/icons/close.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning-red.svg";
import StyledModal from "../../common/Modal/StyledModal";

const ChangeValuesModal = ({
  changeValuesModalIsOpen,
  closeModalChangeValues,
  changeValuesModal,
  continueButton,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={changeValuesModalIsOpen}
        onRequestClose={closeModalChangeValues}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button onClick={closeModalChangeValues} className="close-icon-button ">
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="main-text-error">{changeValuesModal?.title}</div>
          {typeof changeValuesModal.description === "object" ? (
            <div className="second-text">{changeValuesModal.description}</div>
          ) : (
            <div
              className="second-text"
              dangerouslySetInnerHTML={{
                __html: changeValuesModal.description,
              }}
            />
          )}
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeModalChangeValues}>
            Cancel
          </button>
          <button
            className="red-button"
            onClick={() => continueButton(changeValuesModal.typeOfError)}
          >
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

ChangeValuesModal.propTypes = {
  changeValuesModalIsOpen: PropTypes.bool,
  closeModalChangeValues: PropTypes.func,
  changeValuesModal: PropTypes.object,
  continueButton: PropTypes.func,
};

export default ChangeValuesModal;
