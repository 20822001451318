import React from "react";
import Modal from "react-modal";
import { ReactComponent as CheckRegister } from "../../assets/icons/checkLime.svg";
import StyledModal from "./../common/Modal/StyledModal";

const InformationGuideRead = ({
  openGuideRead,
  closeModalGuideRead,
  confirmInformationGuideRead,
  handleCheckConfirmInformationGuideRead,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={openGuideRead}
        onRequestClose={closeModalGuideRead}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <CheckRegister />
          </div>
          <div className="main-text">Information Guide Read</div>
          <div className="second-text bold">
            Please confirm below that you have read and understood the
            Healthcare Professionals Information Guide
          </div>
          <label
            htmlFor="confirmation_read"
            className="custom-checkbox font-size-16 mt-2"
            style={{ textAlign: "left" }}
          >
            I confirm that I have read and understood the Healthcare
            Professionals information guide and understand the PPP materials.
            <input
              type="checkbox"
              id="confirmation_read"
              name="confirmation_read"
              checked={confirmInformationGuideRead || false}
              onChange={(event) =>
                handleCheckConfirmInformationGuideRead(event)
              }
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="wrapper-buttons">
          <button
            className="primary-button"
            onClick={closeModalGuideRead}
            disabled={!confirmInformationGuideRead}
          >
            Next
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};
export default InformationGuideRead;
