import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "../../assets/icons/close.svg";
import {
  getPharmacyTrainingVideo,
  getPrescriberTrainingVideo,
  getUserGuidePdf,
} from "../../shared/helpers";
import styled from "styled-components";
import useRoles from "../../hooks/useRoles";

const GuideButtons = () => {
  const [showTrainingVideo, setShowTrainingVideo] = useState({
    status: false,
    url: "",
  });

  const handleOpenTrainingVideo = (url) => {
    if (typeof url === "string") setShowTrainingVideo({ status: true, url });
    else setShowTrainingVideo({ status: false, url: "" });
  };

  const {
    isHomecare,
    isOutpatient,
    isPrescriber,
    isPharmacy,
    isConnectedNurse,
  } = useRoles();

  return (
    <Layout>
      <div className="training-video">
        <button
          className="primary-button guide-button"
          onClick={() => window.open(getUserGuidePdf(), "_blank")}
        >
          User Guide
        </button>
        {isPrescriber && (
          <button
            className="primary-button ml-14 guide-button"
            onClick={() =>
              handleOpenTrainingVideo(getPrescriberTrainingVideo())
            }
          >
            Prescriber Training Video
          </button>
        )}
        {(isHomecare || isOutpatient || isPharmacy || isConnectedNurse) && (
          <button
            className="primary-button ml-14 guide-button"
            onClick={() => handleOpenTrainingVideo(getPharmacyTrainingVideo())}
          >
            Pharmacy Training Video
          </button>
        )}
      </div>

      <Modal
        isOpen={showTrainingVideo.status}
        style={customStyles}
        contentLabel="Session Expired Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false} // Added to clear the warning from tests
      >
        <button
          onClick={handleOpenTrainingVideo}
          className="close-icon-button "
        >
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="training-modal">
          <video width="500" height="300" autoPlay controls>
            <source src={showTrainingVideo.url} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </Layout>
  );
};
export default GuideButtons;

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 630,
  },
};

const Layout = styled.div`
  margin: 0px;
  position: absolute;
  left: 51%;
  transform: translate(-50%, -50%);
  top: 88%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  width: 80%;
  z-index: 1;

  @media screen and (max-width: 850px) {
    top: 93%;
  }
  .guide-button {
    margin: 0px 30px;
  }
`;
