import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/quitModal.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import StyledModal from "./StyledModal";

const QuitModal = ({
  quitModalIsOpen,
  closeModalQuit,
  quitModal,
  description,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
      boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={quitModalIsOpen}
        onRequestClose={closeModalQuit}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="orient main-text">Are you sure you want to quit?</div>
          <div className="second-text">{description}</div>
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={quitModal}>
            Quit <CloseIcon />
          </button>
          <button className="green-button" onClick={closeModalQuit}>
            Stay
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

QuitModal.propTypes = {
  quitModalIsOpen: PropTypes.bool,
  closeModalQuit: PropTypes.func,
  quitModal: PropTypes.func,
  description: PropTypes.string,
};
export default QuitModal;
