import { registrationCategory } from "../constants/register.constants";
import {
  verifyUserType,
  verifyUserTypeConnectedRegistrar,
} from "../shared/helpers";
import LocalStorage from "../shared/localStorage";

const useRoles = () => {
  const isPharmacy = verifyUserType(registrationCategory.PHARMACY);
  const isConnectedNurse = verifyUserType(registrationCategory.CONNECTED_NURSE);
  const isPrescriber = verifyUserType(registrationCategory.PRESCRIBER);
  const isHomecare = verifyUserType(registrationCategory.CONNECTED_HOMECARE);
  const isOutpatient = verifyUserType(
    registrationCategory.CONNECTED_OUTPATIENT
  );
  const isCustomerSolution = verifyUserType(
    registrationCategory.CUSTOMER_SOLUTION
  );
  const isConnectedRegistrar = verifyUserTypeConnectedRegistrar(
    registrationCategory.CONNECTED_REGISTRAR
  );

  const storageData =
    localStorage.getItem(LocalStorage.USER_NHSPORTAL) &&
    JSON.parse(localStorage.getItem(LocalStorage.USER_NHSPORTAL));
  //to know the difference between a connected pharmacist and a regular pharmacist
  const isPrimaryPharmacist = storageData?.primary_user || null;

  return {
    isConnectedNurse,
    isConnectedRegistrar,
    isPharmacy,
    isPrescriber,
    isHomecare,
    isCustomerSolution,
    isOutpatient,
    storageData,
    isPrimaryPharmacist,
  };
};

export default useRoles;
