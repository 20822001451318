import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close.svg";
import APIVariables from "../../../services/api-variables";
import StyledRegister from "../../authentication/StyledRegister";
import { handleCatch, isObject } from "../../../shared/helpers";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import Loader from "../../common/Loader/Loader";
import { errorMessages } from "../../../constants/errorMessages.constants";
import RemoveOutpatientModal from "./modals/RemoveOutpatient";
import Axios from "../../../services/Axios";

const OutpatientEdit = () => {
  const navigate = useNavigate();

  const selectedOutpatient = window.location.pathname.split("/")[2];
  const [form, setForm] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [modalIsOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    await Axios.get(
      APIVariables.HCP_OUTPATIENT + window.location.pathname.split("/")[2]
    )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setForm(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });

    setIsLoading(false);
  };

  function closeForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.CONNECTED_OUTPATIENT);
  }

  const openModalRemoveOutpatient = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const removeOutpatient = async () => {
    setErrorMessage("");

    await Axios.delete(APIVariables.HCP_OUTPATIENT_DELETE + selectedOutpatient)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          closeModal();
          navigate(App.CONNECTED_OUTPATIENT);
        }
      })
      .catch(function (error) {
        const errors = error.response.data;
        if (typeof errors === "string") {
          setErrorMessage([errorMessages.INTERNAL_SERVER_ERROR]);
        } else {
          const errorsArray = [];
          Object.keys(errors).forEach((key) => {
            if (isObject(errors[key])) {
              Object.keys(errors[key]).forEach((secondKey) => {
                errorsArray.push(key + " - " + errors[key][secondKey][0]);
              });
            } else {
              errorsArray.push(key + " - " + errors[key]);
            }
          });
          setErrorMessage(errorsArray);
        }
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <StyledRegister>
        <React.Fragment>
          <div className="layout-modal">
            <div className="close-icon" onClick={closeForm}>
              <img src={CloseIcon} alt="Close Icon" />
            </div>
            <div className="title-flex">
              <h1 className="select-category-txt mineShaft-color">
                {form?.name} - Outpatient
              </h1>
              <div className="title-flex">
                <button
                  className="second-button red-button"
                  onClick={(e) => openModalRemoveOutpatient(e)}
                >
                  Remove
                </button>
              </div>
            </div>
            <hr className="gray-hr" />

            <h2 className={"title-details"}>Outpatient Pharmacy Details</h2>

            <div className="container mb-5 min-height">
              <div className="row">
                <div className="col">
                  <label>Outpatient Pharmacy Name:</label>
                  <input
                    type="text"
                    name="first_name"
                    value={form?.name || ""}
                    maxLength={30}
                    className={"disabled"}
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <label>Postcode:</label>
                  <input
                    type="text"
                    name="job_title"
                    value={form?.postcode || ""}
                    maxLength={30}
                    className={"disabled"}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col custom-select-register">
                  <label>Outpatient Address:</label>

                  <input
                    type="text"
                    name="hospital"
                    value={form?.address || ""}
                    disabled
                    className="disabled"
                  />
                </div>
                <div className="col" />
              </div>
              <div className="row">
                <p className="info_nurse font-size-16">
                  <b className="bold info_nurse font-size-16">IMPORTANT</b>:
                  Connected Outpatient Pharmacy will only receive an email of
                  any PAF that has been sent.
                </p>
              </div>
            </div>
          </div>

          {quitModalIsOpen && (
            <QuitModal
              quitModalIsOpen={quitModalIsOpen}
              closeModalQuit={closeModalQuit}
              quitModal={quitModal}
              description="Are you sure you want to cancel filling out this Form. All progress will be lost and you will need to start again."
            />
          )}

          {modalIsOpen && (
            <RemoveOutpatientModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              removeOutpatient={removeOutpatient}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      </StyledRegister>
    </React.Fragment>
  );
};
export default OutpatientEdit;
