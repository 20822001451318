import React from "react";
import StyledRegister from "../components/authentication/StyledRegister";

const PageNotFound = () => {
  return (
    <StyledRegister>
      <div className="layout-modal">
        <div>Sorry! The page you're looking for doesn't exists!</div>
      </div>
    </StyledRegister>
  );
};
export default PageNotFound;
