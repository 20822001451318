import React from "react";
import Modal from "react-modal";
import { ReactComponent as Warning } from "../../../assets/icons/warning-red.svg";
import StyledModal from "./../../common/Modal/StyledModal";
import ViewCard from "../../common/ViewCard/ViewCard";
import { useState } from "react";

const ChangeMoleculeModal = ({
  isOpen,
  closeModal,
  changeDropdowns,
  selectedMolecule,
  moleculeOption,
  treatmentFormText,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  const [statusOk, setStatusOk] = useState(false);
  const getStatus200 = (status) => {
    if (status.status === 200) {
      setStatusOk(true);
    }
  };

  const [checkbox, setCheckbox] = useState(false);
  const isDisabled =
    !!selectedMolecule && Object.keys(selectedMolecule)?.length === 0
      ? true
      : checkbox && statusOk;

  return (
    <StyledModal>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Renewal Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>

          <div className="main-text-error">Change of Molecule</div>

          <div className="second-text">
            You have selected a molecule that is different from what was
            previous prescribed, if you want to continue, please confirm that
            the {treatmentFormText?.treatment_form_text_short} has been
            downloaded and completed by the Patient
          </div>
        </div>
        <ViewCard
          brochure={selectedMolecule}
          displayImage={true}
          displayTitle={false}
          isReportanAdverseEventPage={false}
          getStatus200={getStatus200}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          isChangingMolecule={true}
          treatmentFormText={treatmentFormText}
        />

        <div className="wrapper-buttons">
          <button
            className="cancel mr-6"
            onClick={() => {
              closeModal();
              setCheckbox(false);
            }}
          >
            Cancel
          </button>

          <button
            className="red-button"
            disabled={!isDisabled}
            onClick={() => {
              changeDropdowns(moleculeOption);
              setStatusOk(false);
              setCheckbox(false);
            }}
          >
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

export default ChangeMoleculeModal;
