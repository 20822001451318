import { Navigate } from "react-router-dom";
import useRoles from "../hooks/useRoles";

import TopMenu from "../components/common/TopMenu/TopMenu";
import { Auth } from "./Constants";

import styled from "styled-components";
import Footer from "../components/common/Footer/Footer";

const ProtectedRoute = ({
  component: Component,
  condition = true,
  loggedInInitials,
  componentProps,
  ...restOfProps
}) => {
  const { storageData } = useRoles();

  let result = <Navigate replace to={Auth.LOGIN} />;

  if (storageData?.isLoggedIn && condition)
    result = (
      <Component
        setLoggedInInitials={restOfProps?.setLoggedInInitials}
        {...componentProps}
      />
    );

  return (
    <Layout>
      <TopMenu
        isLoggedIn={storageData?.isLoggedIn}
        loggedInInitials={loggedInInitials}
      />
      {result}
      <Footer />
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  min-height: 100vh;
`;

export default ProtectedRoute;
