import React from "react";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import moment from "moment";
import { DateRangePicker, DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const CalendarComponent = ({
  close,
  date,
  selectRange,
  setDateRaw,
  maxDate,
  onResetCalendar = () => true,
  onClose = () => true,
}) => {
  const { after } = DateRangePicker;
  const [currentDate, setCurrentDate] = React.useState(date);
  const ranges = [
    {
      label: "Reset",
      value: selectRange ? [1, 1] : null,
    },
    {
      label: "Today",
      value: selectRange ? [new Date(), new Date()] : new Date(),
    },
  ];

  const onReset = () => {
    setCurrentDate(selectRange ? [] : null);
    onResetCalendar();
  };

  const onDone = (date) => {
    setCurrentDate(date);
    setDateRaw(date);
    close(false);
  };

  const onDoneNoClose = (date) => {
    setCurrentDate(date);
    setDateRaw(date);
  };

  const handleChange = (vals, event) => {
    const { code } = event;
    if (selectRange) {
      if (vals[0] === 1 && vals[0] === vals[1]) {
        setCurrentDate([]);
        onReset();
      } else {
        setCurrentDate(vals);
        if (code === "Enter") {
          onDone(vals);
        } else {
          onDoneNoClose(vals);
        }
      }
    } else {
      if (vals === null) {
        setCurrentDate([]);
        onReset();
      } else {
        setCurrentDate(vals);
        if (code === "Enter") {
          onDone(vals);
        } else {
          onDoneNoClose(vals);
        }
      }
    }
  };

  return (
    <div className="react-calendar-div">
      {selectRange ? (
        <DateRangePicker
          value={currentDate}
          onExited={onClose}
          ranges={ranges}
          cleanable={false}
          onChange={(vals, e) => handleChange(vals, e)}
          showOneCalendar
          format="dd/MM/yyyy"
          placeholder="DD / MM / YYYY ~ DD / MM / YYYY"
          locale="en-US"
          disabledDate={maxDate ? after(maxDate) : null}
          defaultOpen
          onOk={onDone}
        />
      ) : (
        <DatePicker
          value={currentDate}
          onExited={onClose}
          ranges={ranges}
          cleanable={false}
          onChange={(value, e) => handleChange(value, e)}
          format="dd/MM/yyyy"
          placeholder="DD / MM / YYYY"
          locale="en-US"
          disabledDate={(date) =>
            maxDate ? moment(date).isAfter(maxDate, "day") : false
          }
          defaultOpen
          onOk={onDone}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
