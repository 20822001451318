import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { ReactComponent as Warning } from "../../../src/assets/icons/warning.svg";
import StyledModal from "./../common/Modal/StyledModal";

const PasswordExpiredModal = ({
  passwordExpiredModalisOpen,
  continueModalQuit,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
      boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={passwordExpiredModalisOpen}
        onRequestClose={continueModalQuit}
        style={customStyles}
        contentLabel="Password Expired Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="orient main-text">Password has expired </div>
          <div className="second-text">
            Your password has expired, please select continue to reset your
            password
          </div>
        </div>
        <div className="wrapper-buttons">
          <button className="green-button" onClick={continueModalQuit}>
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

PasswordExpiredModal.propTypes = {
  passwordExpiredModalisOpen: PropTypes.bool,
  continueModalQuit: PropTypes.func,
};

export default PasswordExpiredModal;
