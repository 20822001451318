import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { ReactComponent as Warning } from "../../../assets/icons/error.svg";
import StyledModal from "./../../common/Modal/StyledModal";

const RenewalModal = ({
  renewalModalIsOpen,
  closeModalRenewal,
  continueIsRenewal,
  patientType,
  hcp,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={renewalModalIsOpen}
        onRequestClose={closeModalRenewal}
        style={customStyles}
        contentLabel="Renewal Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>

          <div className="main-text-error">
            {patientType === "womanOfCP"
              ? "WCBP PAF Renewal within four Weeks of previous prescription"
              : patientType === "male"
              ? "Male PAF Renewal within twelve weeks of previous prescription"
              : "WNCBP PAF Renewal within twelve weeks of previous prescription"}
          </div>

          <div className="second-text">
            Please check that this patient is being prescribed{" "}
            {!!hcp && hcp?.name} according to the required dosing schedule
          </div>
        </div>

        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeModalRenewal}>
            Cancel
          </button>

          <button className="red-button" onClick={continueIsRenewal}>
            Continue
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

RenewalModal.propTypes = {
  renewalModalIsOpen: PropTypes.bool,
  closeModalRenewal: PropTypes.func,
  continueIsRenewal: PropTypes.func,
  patientType: PropTypes.string,
};

export default RenewalModal;
