import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

import Colors from "../../../shared/colors";
import useRoles from "../../../hooks/useRoles";
import { getAge } from "../../../shared/helpers";

const Layout = styled.div`
  padding: 0px 10px;
  .patient-details {
    margin-top: 25px;
    margin-bottom: 10px;

    .label {
      color: ${Colors.orient};
    }
  }

  .align-center {
    text-align: center;
  }
`;

const InfoPAF = ({ patientDetails }) => {
  const { isPrescriber, isConnectedNurse } = useRoles();
  return (
    <Layout>
      <div className="wrapper-flex patient-details row">
        <div className="col col-sm-6-custom">
          <div className="label">Patient ID:</div>
          <div className="font-size-16">{patientDetails?.id}</div>
        </div>
        <div className="col col-sm-6-custom align-center">
          <div className="label">Patient Initials:</div>
          <div className="font-size-16">{patientDetails?.patient_initials}</div>
        </div>
        <div className="col col-sm-6-custom align-center">
          <div className="label">Gender:</div>
          <div className="font-size-16">{patientDetails?.gender}</div>
        </div>
        <div className="col col-sm-6-custom align-center">
          <div className="label">Date of Birth:</div>
          <div
            className={
              "font-size-16 " +
              ((isPrescriber || isConnectedNurse) &&
              getAge(patientDetails.dob) < 18
                ? "red-label"
                : "")
            }
          >
            {patientDetails?.dob
              ? moment(patientDetails?.dob).format("DD / MM / YYYY")
              : "-"}
          </div>
        </div>
        <div className="col col-sm-6-custom">
          <div className="label">Treating Hospital:</div>
          <div className="font-size-16">
            {patientDetails?.treatingHospital || patientDetails?.hospital}
          </div>
        </div>
      </div>
    </Layout>
  );
};

InfoPAF.propTypes = {
  patientDetails: PropTypes.object,
};
export default InfoPAF;
