import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close.svg";
import APIVariables from "../../../services/api-variables";
import StyledRegister from "../../authentication/StyledRegister";
import { handleCatch, validateEmail } from "../../../shared/helpers";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import ConfirmNurseModal from "./ConfirmNurseModal";
import { ReactComponent as Undo } from "../../../assets/icons/undo.svg";
import Axios from "../../../services/Axios";

const NurseAdd = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nurseForm, setNurseForm] = useState({});
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [confirmModalIsOpen, setConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hospitalDets, setHospitalDets] = useState({
    hospital_address: "",
    hospital_name: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noChangesOnForm = Object.values(nurseForm).every(
    (item) => item.length === 0
  );

  const getData = async () => {
    await Axios.get(APIVariables.USERS_PROFILE)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setHospitalDets({
            hospital_address: data.hospital.address,
            hospital_name: data.hospital.value,
          });
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNurseForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function closeForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.DASHBOARD);
  }

  const goToNursePage = () => {
    navigate(App.NURSES);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
    goToNursePage();
  };

  const nextPage = async () => {
    setIsSubmitting(false);
    setErrorMessage("");
    if (areAllFields) {
      await Axios.post(APIVariables.USERS_SIGNUP_CONNECTED_NURSES, nurseForm)
        .then((res) => {
          if (res?.status === 200) {
            setConfirmModal(true);
          }
        })
        .catch(function (error) {
          handleCatch(error);
          const errors = error?.response?.data;
          const errorsArray = [];
          errorsArray.push(errors);
          setErrorMessage(errorsArray);
        });
    } else {
      setIsSubmitting(true);
    }
  };

  const validateForm = ({
    first_name = "",
    last_name = "",
    job_title = "",
    email = "",
    registration_number = "",
  }) => ({
    first_name: {
      isValid: first_name.trim().length > 0,
    },
    last_name: {
      isValid: last_name.trim().length > 0,
    },
    job_title: {
      isValid: job_title.trim().length > 0,
    },
    registration_number: {
      isValid: registration_number.trim().length > 0,
    },
    email: {
      isValid: email.trim().length > 0,
    },
    emailValid: {
      isValid: validateEmail(email),
    },
  });

  const { first_name, last_name, job_title, email, registration_number } =
    nurseForm;

  const validateScreenFields = validateForm({
    first_name,
    last_name,
    job_title,
    email,
    registration_number,
  });

  let areAllFields = Object.values(validateScreenFields).every((field) => {
    return field.isValid;
  });

  const isEmailInvalid = errorMessage && errorMessage[0]?.email;

  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal">
          <div className="close-icon" onClick={closeForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          <div className="step font-size-18 color-tif-label">Step 1/1</div>
          <h1 className="select-category-txt mineShaft-color">
            Connected Nurse Registration Form
          </h1>
          <hr className="gray-hr" />

          <h2 className="title-details color-tif-label mineShaft-color">
            Nurse Details
          </h2>

          <div className="container mb-5">
            <div className="row">
              <div className="col">
                <label>Nurse First Name:*</label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="first_name"
                  value={nurseForm.first_name || ""}
                  maxLength={30}
                  className={
                    isSubmitting && !validateScreenFields.first_name.isValid
                      ? "error"
                      : ""
                  }
                />
                {isSubmitting && !validateScreenFields.first_name?.isValid && (
                  <span className="required-text">Required</span>
                )}
              </div>
              <div className="col">
                <label>Nurse Last Name:*</label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="last_name"
                  value={nurseForm.last_name || ""}
                  maxLength={30}
                  className={
                    isSubmitting && !validateScreenFields.last_name.isValid
                      ? "error"
                      : ""
                  }
                />
                {isSubmitting && !validateScreenFields.last_name?.isValid && (
                  <span className="required-text">Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Registration number:*</label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="registration_number"
                  value={nurseForm.registration_number || ""}
                  maxLength={30}
                  className={
                    isSubmitting &&
                    !validateScreenFields.registration_number.isValid
                      ? "error"
                      : ""
                  }
                />
                {isSubmitting &&
                  !validateScreenFields.registration_number?.isValid && (
                    <span className="required-text">Required</span>
                  )}
              </div>
              <div className="col">
                <label>Job Title:*</label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="job_title"
                  value={nurseForm.job_title || ""}
                  maxLength={30}
                  className={
                    isSubmitting && !validateScreenFields.job_title.isValid
                      ? "error"
                      : ""
                  }
                />
                {isSubmitting && !validateScreenFields.job_title?.isValid && (
                  <span className="required-text">Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Hospital Name:</label>
                <input
                  type="text"
                  name="hospital_label"
                  value={hospitalDets?.hospital_name || ""}
                  disabled
                />
              </div>
              <div className="col">
                <label>
                  Email Address: (A link will be sent to verify your email
                  address)*
                </label>
                <input
                  type="text"
                  onChange={handleChange}
                  name="email"
                  value={nurseForm.email || ""}
                  maxLength={50}
                  className={
                    (isSubmitting && !validateScreenFields.email.isValid) ||
                    (isSubmitting &&
                      !validateScreenFields.emailValid.isValid &&
                      validateScreenFields.email.isValid)
                      ? "error"
                      : ""
                  }
                />
                {isSubmitting && !validateScreenFields.email?.isValid && (
                  <span className="required-text">Required</span>
                )}
                {isSubmitting &&
                  !validateScreenFields.emailValid.isValid &&
                  validateScreenFields.email.isValid && (
                    <span className="required-text">Invalid Email</span>
                  )}
                {isEmailInvalid && (
                  <span className="required-text">{isEmailInvalid}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Hospital Address:</label>
                <input
                  type="text"
                  name="hospital_address"
                  value={hospitalDets?.hospital_address || ""}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="wrapper-buttons-select-type">
            <div className="symbol-idication">
              *symbol indicating a mandatory field
            </div>
            <div>
              {!noChangesOnForm ? (
                <button
                  className="undo-changes font-size-18 mineShaft-color undo-changes-nurse"
                  onClick={() => setNurseForm({})}
                >
                  <Undo /> Undo Changes
                </button>
              ) : (
                ""
              )}

              <button className="primary-button" onClick={nextPage}>
                Save
              </button>
            </div>
          </div>
        </div>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={quitModal}
            description="Are you sure you want to cancel filling out this form? All progress will be lost and you will need to start again."
          />
        )}
        {confirmModalIsOpen && (
          <ConfirmNurseModal
            closeModal={handleCloseConfirmModal}
            modalIsOpen={confirmModalIsOpen}
          />
        )}
      </StyledRegister>
    </React.Fragment>
  );
};
export default NurseAdd;
