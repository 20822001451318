import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import Select, { components } from "react-select";

import CloseIcon from "../../../assets/icons/close.svg";
import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import { ReactComponent as RevertApprover } from "../../../assets/icons/revertApproverModal.svg";

import { ReactComponent as DropdownArrowDown } from "../../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../../assets/icons/dropdownArrowUp.svg";
import StyledModal from "../../common/Modal/StyledModal";
import APIVariables from "../../../services/api-variables";
import { handleCatch } from "../../../shared/helpers";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";

const ConfirmationModal = ({
  statePAFModal,
  closeConfirmationModal,
  message,
  submitError,
  submitValidate,
  handleChangeSelect,
  form,
  revert,
  handleChange,
  handleErrors,
  errors,
  detailsPAF,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };
  const [stateTheReasonOfReject, setStateTheReasonOfReject] = useState("");
  const prescriptionId = window.location.href.split("/")[5];
  const { isHomecare, isOutpatient, isPharmacy } = useRoles();

  const renameKeys = async (toBeRenamed) => {
    await toBeRenamed.forEach((elem) => {
      elem["label"] = elem.value;
      elem["value"] = elem.id;
      delete elem["id"];
    });
    return toBeRenamed;
  };

  useEffect(() => {
    if (
      !isHomecare &&
      !isOutpatient &&
      detailsPAF[0].approved_or_rejected_by.includes("Awaiting approval")
    ) {
      const getRejectionReasons = async () => {
        const res = await Axios.get(
          APIVariables.PRESCRIPTIONS_REJECT + prescriptionId
        ).catch((error) => {
          handleCatch(error);
        });
        setStateTheReasonOfReject(await renameKeys(res.data.rejection_reason));
      };
      getRejectionReasons();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      message.state === 3 &&
      (isHomecare || isOutpatient || isPharmacy) &&
      !detailsPAF[0].approved_or_rejected_by.includes("Awaiting approval")
    ) {
      const getRevertReasons = async () => {
        const res = await Axios.get(
          APIVariables.PRESCRIPTIONS_REVERT + prescriptionId
        ).catch((error) => {
          handleCatch(error);
        });
        setStateTheReasonOfReject(await renameKeys(res.data.rejection_reason));
      };
      getRevertReasons();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
        maxHeight: "90%",
      });

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <DropdownArrowUp />
          ) : (
            <DropdownArrowDown />
          )}
        </components.DropdownIndicator>
      )
    );
  };
  return (
    <StyledModal>
      <Modal
        isOpen={statePAFModal}
        onRequestClose={closeConfirmationModal}
        style={customStyles}
        contentLabel="Confirmation Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button onClick={closeConfirmationModal} className="close-icon-button">
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="wrapper-modal">
          <div className="image">
            {message.type === "error" ? (
              <Error />
            ) : message.type === "revert" ? (
              <RevertApprover />
            ) : (
              ""
            )}
          </div>
          <div
            className={
              message.type === "error"
                ? "main-text-error"
                : message.type === "revert"
                ? "revert-text"
                : "main-text green-haze"
            }
          >
            {message.title}
          </div>
          {message.description &&
            (typeof message.description === "object" ? (
              <div className="second-text">{message.description}</div>
            ) : (
              <div
                className="second-text"
                dangerouslySetInnerHTML={{
                  __html: message.description,
                }}
              ></div>
            ))}

          {message.state === 2 && (
            <div className="col" style={{ height: "100px" }}>
              <div className="custom-rejection">
                <Select
                  classNamePrefix="custom-select-error"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  value={form.reason_rejection}
                  onChange={(selectedOption) => {
                    handleChangeSelect(selectedOption, "reason_rejection");
                  }}
                  options={stateTheReasonOfReject}
                  placeholder="Reason for rejection"
                />
                {errors.reason_rejection && (
                  <span className="input-rejection-error">
                    {errors.reason_rejection}
                  </span>
                )}
              </div>
              {form?.reason_rejection?.is_rejection_reason_other === true && (
                <div className="other-input-reject">
                  <input
                    className="input-rejection"
                    type="text"
                    onChange={(e) =>
                      handleChange("reason_rejection_input", e.target.value)
                    }
                    name="reason_rejection_input"
                  />
                  {errors.reason_rejection_input && (
                    <span className="input-rejection-error">
                      {errors.reason_rejection_input}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {message.state === 3 && (
            <div className="col" style={{ height: "100px" }}>
              <div className="custom-rejection">
                <Select
                  classNamePrefix="custom-select-revert"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  value={revert.reason_revert}
                  onChange={(selectedOption) => {
                    handleChangeSelect(selectedOption, "reason_reverting");
                  }}
                  options={stateTheReasonOfReject}
                  placeholder="Reason for reverting"
                />
                {errors.reason_revert && (
                  <span className="input-rejection-error">
                    {errors.reason_revert}
                  </span>
                )}
              </div>
              {revert?.reason_revert?.is_revert_reason_other === true && (
                <div className="other-input-reject">
                  <input
                    className="input-rejection"
                    type="text"
                    onChange={(e) =>
                      handleChange("reason_revert_input", e.target.value)
                    }
                    name="reason_revert_input"
                  />
                  {errors.reason_revert_input && (
                    <span className="input-rejection-error">
                      {errors.reason_revert_input}
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeConfirmationModal}>
            Cancel
          </button>
          <button
            className={
              message.type === "error"
                ? "red-button"
                : message.type === "revert"
                ? "purple-button "
                : "green-button green-haze-background "
            }
            onClick={
              message.type === "error" && message.state === 1
                ? submitError
                : message.type === "error" && message.state === 2
                ? handleErrors
                : message.type === "revert" && message.state === 3
                ? handleErrors
                : submitValidate
            }
          >
            {message.buttonText}
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

ConfirmationModal.propTypes = {
  statePAFModal: PropTypes.bool,
  closeConfirmationModal: PropTypes.func,
  message: PropTypes.object,
  submitError: PropTypes.func,
  submitValidate: PropTypes.func,
  handleChangeSelect: PropTypes.func,
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
  errors: PropTypes.object,
};

export default ConfirmationModal;
