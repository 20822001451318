import React, { useState, useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import StyledNotifications from "./StyledNotifications";

import SearchGray from "../../../assets/icons/searchGray.svg";
import Info from "../../../assets/icons/info.svg";
import { Virtuoso } from "react-virtuoso";
import { App } from "../../../routes/Constants";
import APIVariables from "../../../services/api-variables";
import Loader from "../../common/Loader/Loader";
import moment from "moment";
import { handleCatch, useQuery } from "../../../shared/helpers";
import Filter from "../../../assets/icons/filter.svg";
import { useClickOutside } from "../../../hooks/useClickOutside";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Notifications = () => {
  const filterRef = useRef(null);
  const query = useQuery();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersAdded, setFiltersAdded] = useState([]);
  const existMoreItems = useRef(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchAdminValue, setSearchAdminValue] = useState("");
  const [adminNotifications, setAdminNotifications] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [isFilters, setIsFilters] = useState(false);
  const queryFilters = query.getAll("status").map((filter) => parseInt(filter));
  const { isPrescriber, isPharmacy, isHomecare, isOutpatient } = useRoles();

  const controller = useRef(new AbortController()); // Scope: cancel the current call if another call is called

  const tableRef = React.useRef(null); // Scope: When apply filter, scroll to the top of the table

  // const isFetchingNotificationsRef = useRef(false); // Scope: update variable faster than the state for updating calls for the filters Virtuoso
  const typingRef = React.useRef(false);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isLoadingAdminTable, setIsLoadingAdminTable] = useState(true);
  const currentReadingNotifications = useRef([]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getNotificationsGeneral(false);

      tableRef?.current?.scrollToIndex({
        index: 0,
      });
    }, 500);
    return () => {
      clearTimeout(delayDebounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, query]);

  const getNotificationsGeneral = async (isScrolling) => {
    // if (isFetchingNotificationsRef.current) {
    //   controller.current.abort();
    //   controller.current = new AbortController();
    // }
    // isFetchingNotificationsRef.current = true;

    setIsLoadingTable(true);

    try {
      if (isScrolling) {
        await fetchMoreData(true);
      } else if (queryFilters.length === 0) {
        await getNotifications();
        typingRef.current = false;
      } else if (queryFilters.length > 0) {
        await getNotificationsByFilter();
        typingRef.current = false;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setIsLoadingTable(false);
      }, 500);
    }

    // isFetchingNotificationsRef.current = false;
  };

  const fetchNotifications = async (filters) => {
    const URL =
      APIVariables.PRESCRIPTIONS_NOTIFICATIONS + `?${filters ? filters : ""}`;

    return await Axios.get(URL, {
      signal: controller.current.signal,
    }).catch((error) => {
      handleCatch(error);
    });
  };

  const getAdminNotifications = async () => {
    const res = await Axios.get(
      APIVariables.ADMIN_NOTIFICATIONS +
        (searchAdminValue && `?search=${searchAdminValue}`)
    ).catch((error) => {
      handleCatch(error);
    });
    if (res?.status === 200) {
      setTimeout(() => {
        setIsLoadingAdminTable(false);
      }, 500);

      setAdminNotifications(res.data);
    }
  };
  useEffect(() => {
    getAdminNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchAdminValue]);

  const getNotifications = async () => {
    const getValue = searchValue;

    const res = await fetchNotifications(getValue ? `search=${getValue}` : "");

    if (res?.status === 200) {
      setIsLoading(false);
      setItems(res?.data?.results);
      setFilters(res?.data?.filter_data?.status);

      // // If prev link contains any status and now it is reseted the filters scroll to top
      if (existMoreItems.current && existMoreItems.current.includes("status")) {
        tableRef?.current?.scrollToIndex({
          index: 0,
        });
      }

      const areMoreMoreItems = !!res.data.next;
      if (areMoreMoreItems) {
        existMoreItems.current = res.data.next;
      } else {
        existMoreItems.current = false;
      }
    }
  };

  const currentitemIncluesFilter = (filters, baseURL) => {
    const URL = baseURL || existMoreItems.current;

    if (URL === false) {
      return "?";
    }
    if (URL?.includes("?")) {
      if (queryFilters.length && !URL.includes(filters)) {
        return "&";
      } else {
        return "";
      }
    } else {
      return "?";
    }
  };

  const fetchMoreData = async (isScrolling) => {
    const filters = queryFilters.map((num) => `status=${num}`).join("&");
    const areFiltersAlreadyApplied =
      existMoreItems?.current && existMoreItems?.current?.includes(filters);

    let URLLink = existMoreItems?.current
      ? existMoreItems?.current + `${currentitemIncluesFilter()}`
      : process.env.REACT_APP_BASE_URL +
        APIVariables.PRESCRIPTIONS_NOTIFICATIONS +
        `${currentitemIncluesFilter()}`;

    if (!areFiltersAlreadyApplied) {
      URLLink = URLLink.concat(filters);
    }

    if (!URLLink.includes("?")) {
      if (searchValue) {
        URLLink = URLLink.concat(`?search=${searchValue}`);
      }
    }

    let turl = new URL(URLLink);

    const params = new URLSearchParams(turl.search);

    if (searchValue !== "") {
      params.set("search", searchValue);
    }

    if (searchValue === "") {
      params.delete("search");
    }

    // if (typingRef.current) {
    //   params.set("page", "1");
    // }

    if (
      existMoreItems.current &&
      existMoreItems.current.includes(searchValue) === false
    ) {
      params.set("page", "1");
    }

    turl = URLLink.split("?")[0] + "?" + params.toString();

    if (URLLink.includes("?")) {
      turl = URLLink.includes("?") ? turl : URLLink;
    }

    const res = await Axios.get(turl).catch((error) => {
      handleCatch(error);
    });
    if (isScrolling) {
      setItems(items.concat(res.data.results)); // to be applied only at scroll
    } else {
      setItems(res?.data?.results); //to be applied when add a filter or search
    }

    const areMoreMoreItems = !!res?.data?.next;

    if (areMoreMoreItems) {
      existMoreItems.current = res?.data?.next;
    } else {
      existMoreItems.current = false;
    }

    // if (searchValue) {
    //   tableRef?.current?.scrollToIndex({
    //     index: 0,
    //   });
    // }
  };

  const handleChange = async (e) => {
    let value = e.target.value;
    if (value === undefined) {
      value = "";
    }

    typingRef.current = true;
    setSearchValue(value);
    setIsLoadingTable(true);
  };

  const handleChangeAdminNotification = async (e) => {
    let value = e.target.value;
    if (value === undefined) {
      value = "";
    }

    setSearchAdminValue(value);
    setIsLoadingAdminTable(true);
  };

  const displayRejectDetails = (reason, other) => {
    return reason !== "Other (please state)" ? reason : other;
  };

  const markAsReadTheNotification = async (notificationID, isRead, name) => {
    if (currentReadingNotifications.current.includes(notificationID)) return;

    currentReadingNotifications.current.push(notificationID);

    if (isRead === false) {
      const res = await Axios.post(
        name === "admin_notification"
          ? APIVariables.PRESCRIPTIONS_ADMIN_NOTIFICATIONS_READ + notificationID
          : APIVariables.PRESCRIPTIONS_NOTIFICATIONS_READ + notificationID,
        {}
      )
        .catch(function (error) {
          handleCatch(error);
        })
        .finally(() => {
          document.dispatchEvent(new CustomEvent("fetchNotificationsCount"));
          if (name === "admin_notification") {
            getAdminNotifications();
          }
        });

      if (res?.status === 200) {
        const newItems = items.map((element) => {
          if (element.id === notificationID) {
            element.read = true;
          }

          return element;
        });

        setItems(newItems);
      }
    }
    currentReadingNotifications.current =
      currentReadingNotifications.current.filter((el) => el !== notificationID);
  };

  const getNotificationsByFilter = async () => {
    const filters = queryFilters.map((num) => `status=${num}`).join("&");

    let existingURL;

    if (existMoreItems?.current) {
      existingURL = existMoreItems?.current.includes("?")
        ? existMoreItems?.current.split("?")[0]
        : existMoreItems?.current;
    }

    const baseURL = existMoreItems?.current
      ? existingURL
      : process.env.REACT_APP_BASE_URL +
        APIVariables.PRESCRIPTIONS_NOTIFICATIONS;
    const URLLink =
      baseURL + `${currentitemIncluesFilter(filters, baseURL)}` + filters;

    const getValue = searchValue;

    let turl = new URL(URLLink);

    const params = new URLSearchParams(turl?.search);

    params.set("page", "1");

    if (getValue !== "") {
      params.set("search", getValue);
    }

    turl = params.toString();

    const res = await fetchNotifications(turl);

    if (res?.status === 200) {
      setIsLoading(false);
      setItems(res?.data?.results);
      setFilters(res?.data?.filter_data?.status);
      setFiltersAdded(queryFilters);

      const areMoreMoreItems = !!res.data.next;
      if (areMoreMoreItems) {
        existMoreItems.current = res.data.next;
      } else {
        existMoreItems.current = false;
      }
    }

    tableRef?.current?.scrollToIndex({
      index: 0,
    });
  };

  const onChange = (value) => {
    if (filtersAdded.includes(value))
      setFiltersAdded((prevState) =>
        prevState.filter((item) => item !== value)
      );
    else setFiltersAdded((prevState) => prevState.concat(value));
  };

  const setAllFiltersOn = () => {
    setFiltersAdded([]);
    filters?.forEach((filter) =>
      setFiltersAdded((prev) => prev.concat(filter.id))
    );
  };

  const handleFilter = () => {
    // setIsLoadingTable(true);
    const filter = filtersAdded.map((num) => `status=${num}`).join("&");
    setIsFilters(false);

    // navigate({
    //   pathname: App.NOTIFICATIONS,
    //   search: `?${filter}`,
    // });
    navigate(App.NOTIFICATIONS + `?${filter}`);
  };

  useClickOutside(filterRef, handleFilter);

  const notificationClick = (index, name) => {
    if (name === "admin_notification") {
      markAsReadTheNotification(
        adminNotifications[index].id,
        adminNotifications[index].read,
        "admin_notification"
      );
    } else {
      markAsReadTheNotification(items[index].id, items[index].read);
    }

    if (
      name !== "admin_notification" &&
      (isPharmacy || isHomecare || isOutpatient)
    ) {
      navigate(
        App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT +
          `${items[index].patient_id}` +
          "/" +
          `${items[index].prescription_id}`
      );
    }
  };
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const location = useLocation();

  const scrolToBottom = async () => {
    await new Promise((resolve) => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
      // Add a scroll event listener to check when the scroll animation is complete
      const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
          window.removeEventListener("scroll", handleScroll); // Remove the event listener
          resolve(); // Resolve the promise to signal that scrolling is complete
        }
      };
      window.addEventListener("scroll", handleScroll);
    });

    setScrollToBottom(false);
  };

  if (
    location.search.includes("?scrollToBottom=true") &&
    window.scrollY === 0 &&
    scrollToBottom === true
  ) {
    // ensure scrolling happens after the component has rendered
    setTimeout(scrolToBottom, 0);
  }
  return (
    <StyledNotifications>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="layout">
          <div className="row">
            <div className="col">
              <h1 className="h1 mineShaft-color">
                {isPrescriber
                  ? "Prescriber Notifications"
                  : isPharmacy
                  ? "Pharmacist Notifications"
                  : isHomecare
                  ? "Connected Homecare Notifications"
                  : isOutpatient
                  ? "Connected Outpatient Notifications"
                  : "Connected Nurse Notifications"}
              </h1>
            </div>
            <div className="col notifications-filter">
              {!(isHomecare || isOutpatient) && (
                <h1>
                  <label
                    className={"filter-container"}
                    onClick={() => setIsFilters(!isFilters)}
                  >
                    <span className={"filter-label"}>Filter</span>
                    <img src={Filter} alt="Filter" width={23} height={21} />
                  </label>
                </h1>
              )}

              {isFilters && (
                <div className="modal-absolute" ref={filterRef}>
                  <div className="arrow-top" />
                  <div className="filters-modal">
                    {(filters || []).map((data, index) => (
                      <div className="mini-div" key={index}>
                        <div>{data.value}</div>
                        <div>
                          {filtersAdded.includes(data.id) ? "On" : "Off"}{" "}
                          <ToggleSwitch
                            name={data.value}
                            id={data.id}
                            small
                            disabled={false}
                            checked={filtersAdded.includes(data.id)}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    ))}
                    <hr />
                    <div className="filter-modal-bottom-buttons">
                      <span onClick={setAllFiltersOn}>All on</span>
                      <span className="aqua-deep" onClick={handleFilter}>
                        Done
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="wrapper-infinitescroll">
            <div className="head-wrapper">
              <div className="wrapper-input-search">
                <input
                  type="search"
                  className="input-search"
                  placeholder="Search notifications..."
                  onChange={handleChange}
                  value={searchValue}
                />
                <img src={SearchGray} alt="" />
              </div>

              <button
                className="primary-button view-patients-btn"
                onClick={() => {
                  navigate(App.VIEW_PATIENTS);
                }}
              >
                View All Patients
              </button>
            </div>
            <div className="wrapper-table-infinitescroll">
              <div className="table-wrapper">
                <div className="description">Description</div>
                <div className="patient ">Patient</div>
                <div className="date">Date</div>
                <div className="status">Status</div>
              </div>

              <Virtuoso
                ref={tableRef}
                endReached={() => {
                  if (existMoreItems.current && isLoadingTable === false) {
                    getNotificationsGeneral(true);
                  }
                  // if (
                  //   existMoreItems.current &&
                  //   !isFetchingNotificationsRef.current &&
                  //   !typingRef.current
                  // ) {
                  //   getNotificationsGeneral(true);
                  // }
                }}
                style={
                  isLoadingTable
                    ? { overflowY: "hidden", height: "400px" }
                    : { height: "400px" }
                }
                data={items}
                itemContent={(index) => (
                  <div
                    className={`head-table table-wrapper ${
                      items[index]?.read === false ? "bold" : ""
                    } ${isLoadingTable ? " noClick" : ""}`}
                    key={index}
                    onClick={() => {
                      notificationClick(index);
                    }}
                  >
                    <div className="description">
                      {isLoadingTable ? (
                        <Skeleton width={200} />
                      ) : (
                        items[index]?.description
                      )}
                    </div>
                    <div className="patient">
                      {isLoadingTable ? (
                        <Skeleton width={50} />
                      ) : (
                        items[index]?.patient_name
                      )}
                    </div>
                    <div className="date">
                      {isLoadingTable ? (
                        <Skeleton width={80} />
                      ) : (
                        moment(items[index]?.datetime_sent).format(
                          "DD / MM / YYYY"
                        )
                      )}
                    </div>

                    {isLoadingTable ? (
                      <div className="status">
                        <Skeleton width={60} />
                      </div>
                    ) : (
                      <div className="status">
                        <span
                          className={
                            items[index]?.colour === "red"
                              ? "red"
                              : items[index]?.status === "Expiring" ||
                                items[index]?.status === "Overdue"
                              ? "red"
                              : ""
                          }
                        >
                          {items[index]?.status}
                        </span>
                        {items[index]?.status === "Rejected" && (
                          <div className="tooltip-status">
                            <div>
                              <img src={Info} alt="" className="info-icon" />
                            </div>
                            <span className="tooltiptext-status">
                              {displayRejectDetails(
                                items[index]?.rejection_reason,
                                items[index]?.rejection_reason_other
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          <h1 className="h1 mineShaft-color general-notification">
            General Notifications
          </h1>
          <div className="wrapper-infinitescroll">
            <div className="head-wrapper">
              <div className="wrapper-input-search">
                <input
                  type="search"
                  className="input-search"
                  placeholder="Search notifications..."
                  onChange={handleChangeAdminNotification}
                  value={searchAdminValue}
                />
                <img src={SearchGray} alt="" />
              </div>
            </div>
            <div className="wrapper-table-infinitescroll">
              <div className="table-wrapper">
                <div className="title ">Title</div>
                <div className="admin-description">Description</div>
                <div className="date">Date</div>
              </div>
              <Virtuoso
                ref={tableRef}
                style={
                  isLoadingAdminTable
                    ? { overflowY: "hidden", height: "400px" }
                    : { height: "400px" }
                }
                data={adminNotifications}
                itemContent={(index) => (
                  <div
                    className={`head-table table-wrapper ${
                      adminNotifications[index]?.read === false ? "bold" : ""
                    } ${isLoadingAdminTable ? " noClick" : ""}`}
                    key={index}
                    onClick={() => {
                      notificationClick(index, "admin_notification");
                    }}
                  >
                    <div className="title">
                      {isLoadingAdminTable ? (
                        <Skeleton width={200} />
                      ) : (
                        adminNotifications[index]?.title
                      )}
                    </div>
                    <div className="admin-description">
                      {isLoadingAdminTable ? (
                        <Skeleton width={350} />
                      ) : (
                        <div className="font-size-16">
                          <div className="tooltip-status ">
                            <div className="font-size-16 description-admin-table">
                              {adminNotifications[index]?.description.length >
                              80
                                ? adminNotifications[
                                    index
                                  ]?.description?.substring(0, 80) + "..."
                                : adminNotifications[index]?.description}
                            </div>
                            {/* I made inline style so as not to modify the tooltiptext-status classname used on view patients table */}
                            <span
                              className="tooltiptext-status"
                              style={{
                                width:
                                  adminNotifications[index]?.description
                                    ?.length > 40
                                    ? "550px"
                                    : "auto",
                                marginLeft:
                                  adminNotifications[index]?.description
                                    ?.length > 40
                                    ? "-150px"
                                    : "0px",
                              }}
                            >
                              {adminNotifications[index]?.description}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="date">
                      {isLoadingAdminTable ? (
                        <Skeleton width={80} />
                      ) : (
                        moment(adminNotifications[index]?.datetime_sent).format(
                          "DD / MM / YYYY"
                        )
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </StyledNotifications>
  );
};
export default Notifications;
