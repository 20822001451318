import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StyledTopMenu from "./StyledTopMenu";
import QuitModal from "../../common/Modal/QuitModal";
import LoginBackground from "./../../../assets/logo/logo.svg";
import SearchIcon from "../../../assets/icons/searchIcon.svg";
import Notify from "../../../assets/icons/notify.svg";
import { App, Auth } from "../../../routes/Constants";
import {
  handleCatch,
  prescriberName,
  redirectToHomepageDependingOnUser,
} from "../../../shared/helpers";
import APIVariables from "../../../services/api-variables";
import moment from "moment";
import useRoles from "../../../hooks/useRoles";
import LocalStorage from "../../../shared/localStorage";
import Axios from "../../../services/Axios";
import MenuNotifications from "./MenuNotifications";
import SearchPatientMap from "./SearchPatientMap";

const TopMenu = ({ loggedInInitials }) => {
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const {
    isPrescriber,
    isPharmacy,
    isConnectedNurse,
    isCustomerSolution,
    isHomecare,
    isOutpatient,
    isPrimaryPharmacist,
    storageData,
  } = useRoles();
  const navigate = useNavigate();
  const location = useLocation();
  const [filterValue, setFilterValue] = useState("");

  const [clickedOutsideMenu, setClickedOutsideMenu] = useState(true);
  const menuRef = useRef();

  const [clickedOutsideSearch, setClickedOutsideSearch] = useState(true);
  const searchRef = useRef();
  function closeModalQuit() {
    setIsOpenQuit(false);
  }
  const [countNotifications, setCountNotifications] = useState(0);

  function quitModal() {
    // fix for prevent loop when click on home logo when you are on a public page and you are allready loggedin in another tab (you have token alredy)
    window.location.href = redirectToHomepage;
    closeModalQuit();
  }
  const [clickedOutsideNotification, setClickedOutsideNotification] =
    useState(true);
  const notificationRef = useRef();

  const [patients, setPatients] = useState([]);

  useEffect(() => {
    if (
      storageData?.isLoggedIn &&
      (isPrescriber ||
        isPharmacy ||
        isConnectedNurse ||
        isHomecare ||
        isOutpatient)
    ) {
      getCountNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("fetchNotificationsCount", () =>
      getCountNotifications()
    );
    return () => document.removeEventListener("fetchNotificationsCount", {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (filterValue?.trim()?.length > 0) {
        getPatients();
      }
    }, 500);
    return () => {
      clearTimeout(delayDebounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const getCountNotifications = async () => {
    try {
      let res = await Axios.get(
        APIVariables.PRESCRIPTIONS_NOTIFICATIONS_COUNT
      ).catch((error) => {
        handleCatch(error);
      });
      if (res?.status === 200) {
        setCountNotifications(res?.data);
      }
    } catch (error) {}
  };

  const getPatients = async () => {
    try {
      let res = await Axios.get(
        APIVariables.PATIENTS_LIST + `?search=${filterValue}`
      ).catch((error) => {
        handleCatch(error);
      });

      if (res?.status === 200) {
        setPatients(res?.data?.results);
      }
    } catch (error) {}
  };

  const onChangeSearchValue = (event) => {
    setFilterValue(event.target.value);
  };

  const redirect = (URL) => {
    const ID =
      storageData?.registration_number ||
      storageData?.pharmacy?.registration_number;

    if (URL === App.PROFILE_DEFAULT) {
      navigate(App.PROFILE_DEFAULT + ID);
    } else {
      navigate(URL);
      setClickedOutsideNotification(true);
    }
  };

  const redirectToPatient = (patientID, formID) => {
    setFilterValue("");

    let createPath = App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT + patientID;

    if (formID) {
      createPath = createPath + "/" + formID;
    }

    navigate(createPath);
  };

  const signOut = async () => {
    Axios.get(APIVariables.USERS_LOGOUT)
      .then(() => {
        localStorage.removeItem(LocalStorage.USER_NHSPORTAL);
        window.location.href = Auth.LOGIN;
      })
      .catch(() => {
        localStorage.removeItem(LocalStorage.USER_NHSPORTAL);
        window.location.href = Auth.LOGIN;
      });
  };

  const handleClickOutsideMenu = (e) => {
    if (e.target.className === "user-credentials") {
      setClickedOutsideMenu(!clickedOutsideMenu);
      setClickedOutsideNotification(true);
      setClickedOutsideSearch(true);
    } else if (!!menuRef.current) {
      setTimeout(() => {
        setClickedOutsideMenu(true);
      }, 300);
      if (!menuRef.current?.contains(e.target)) {
        setClickedOutsideMenu(true);
      }
    }
  };

  const handleClickOutsideSearch = (e) => {
    if (e.target.className === "input-search-general") {
      setClickedOutsideSearch(false);
    } else if (!!searchRef.current) {
      if (
        e.target.className === "element" ||
        e.target.className === "name" ||
        e.target.className === "details"
      ) {
        setTimeout(() => {
          setClickedOutsideSearch(true);
        }, 300);
      } else {
        if (!searchRef.current?.contains(e.target)) {
          setClickedOutsideSearch(true);
        }
      }
    }
  };

  const handleClickOutsideNotification = (e) => {
    const notificationsClickableClasses = [
      "wrapper-icon-notify",
      "icon-click-notify",
      "notification-number",
    ];
    if (notificationsClickableClasses.includes(e.target.className)) {
      setClickedOutsideNotification(!clickedOutsideNotification);
      setClickedOutsideMenu(true);
      setClickedOutsideSearch(true);
    } else if (!!notificationRef.current) {
      if (!notificationRef.current?.contains(e.target)) {
        setClickedOutsideNotification(true);
        if (e.target.className === "user-credentials") {
          setClickedOutsideMenu(!clickedOutsideMenu);
        }
        if (e.target.className === "input-search-general") {
          setClickedOutsideSearch(true);
        }
      } else if (Boolean(e.target.closest(".wrapper-notifications-modal"))) {
        // Do not close the modal if click inside modal
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideNotification);
    document.addEventListener("mousedown", handleClickOutsideMenu);
    document.addEventListener("mousedown", handleClickOutsideSearch);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNotification);
      document.removeEventListener("mousedown", handleClickOutsideMenu);
      document.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  });

  const checkRoutes = () => {
    const routesToShow = [
      Auth.REGISTER,
      App.ADD_NURSE,
      App.PROFILE_DEFAULT,
      App.DEFAULT_EDIT_NURSE,
      App.PATIENT_INITIATION,
      App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT,
      App.PAF_RENEW_DEFAULT,
    ];

    if (routesToShow.some((path) => location.pathname.includes(path))) {
      const isEditMode = localStorage.getItem(
        LocalStorage.IS_EDIT_MODE_NHSPORTAL
      );

      if (
        !!isEditMode &&
        (location.pathname.includes(App.PROFILE_DEFAULT) ||
          location.pathname.includes(App.DEFAULT_EDIT_NURSE))
      ) {
        setIsOpenQuit(true);
      } else if (
        !isEditMode &&
        (location.pathname.includes(App.PROFILE_DEFAULT) ||
          location.pathname.includes(App.DEFAULT_EDIT_NURSE))
      ) {
        quitModal();
      } else if (location.pathname.includes(App.PAF_RENEW_DEFAULT)) {
        setIsOpenQuit(true);
      } else if (
        location.pathname.includes(App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT)
      ) {
        if (isPharmacy) {
          const isSubmitted =
            !!document.getElementsByClassName("approve-reject-paf")[0];

          if (isSubmitted) {
            setIsOpenQuit(true);
          } else {
            quitModal();
          }
        } else {
          quitModal();
        }
      } else {
        setIsOpenQuit(true);
      }
    } else {
      quitModal();
    }
  };

  const redirectToHomepage = redirectToHomepageDependingOnUser(
    storageData?.user_type
  );

  const displayDetails = (patient) => {
    return moment(patient.dob).format("DD/MM/YYYY");
  };

  const redirectNotificationToPAFDetails = (patientID, formID) => {
    if (isPharmacy || isHomecare || isOutpatient) {
      navigate(
        App.PRESCRIPTION_AUTHORISATION_FORM_DEFAULT +
          `${patientID}` +
          "/" +
          `${formID}`
      );
      setClickedOutsideNotification(true);
    }
  };

  return (
    <StyledTopMenu>
      <div className="main">
        <div className="main-max-width">
          <div
            className="title-logo"
            onClick={checkRoutes}
            title="Go to Homepage"
          >
            PathfinderRMP.co.uk
          </div>
          {!storageData?.isLoggedIn ? (
            <div className="auth">
              <a href="/register">Register</a>
              <a href="/login">Login</a>
            </div>
          ) : (
            <div className="auth">
              {/* {isPrescriber && (
                <a href={App.PATIENT_INITIATION} title="Refer a Patient">
                  <img src={AddNew} alt="Add New" />
                </a>
              )} */}

              {(isPrescriber ||
                isPharmacy ||
                isConnectedNurse ||
                isHomecare ||
                isOutpatient) && (
                <React.Fragment>
                  <div className="wraper-notify">
                    <div className="wrapper-icon-notify" title="Notifications">
                      <img
                        src={Notify}
                        alt="Notify"
                        className="icon-click-notify"
                      />

                      {countNotifications?.notification_count > 0 && (
                        <span className="notification-number">
                          {countNotifications?.notification_count}
                        </span>
                      )}
                    </div>
                    {clickedOutsideNotification === false && (
                      <div
                        className="wrapper-notifications-modal"
                        ref={notificationRef}
                      >
                        {(countNotifications.latest_notifications || []).map(
                          (notification, indexNotification) => (
                            <MenuNotifications
                              key={indexNotification}
                              redirectNotificationToPAFDetails={
                                redirectNotificationToPAFDetails
                              }
                              notification={notification}
                            />
                          )
                        )}
                        <hr />
                        <div className="view-notifications">
                          <div onClick={() => redirect(App.NOTIFICATIONS)}>
                            View All Notifications
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}

              <div className="user-circle" title="Menu">
                <span className="user-credentials">
                  {loggedInInitials?.toString()?.toUpperCase()}
                </span>

                {clickedOutsideMenu === false && (
                  <div className="wrapper-menu" ref={menuRef}>
                    {(isPrescriber ||
                      isPharmacy ||
                      isConnectedNurse ||
                      isHomecare ||
                      isOutpatient) && (
                      <button onClick={() => redirect(App.PROFILE_DEFAULT)}>
                        {prescriberName()}
                        <div className="view-profile">View Profile</div>
                      </button>
                    )}
                    {isPrescriber && (
                      <div onClick={() => redirect(App.NURSES)}>
                        Connected Nurses
                      </div>
                    )}
                    {(isPharmacy || isHomecare || isOutpatient) &&
                      isPrimaryPharmacist && (
                        <div
                          onClick={() => redirect(App.CONNECTED_PHARMACISTS)}
                        >
                          Connected Pharmacist
                        </div>
                      )}
                    {isPharmacy && (
                      <div onClick={() => redirect(App.CONNECTED_OUTPATIENT)}>
                        Connected Outpatient
                      </div>
                    )}
                    {isPharmacy && (
                      <div onClick={() => redirect(App.HOMECARE)}>
                        Connected Homecare
                      </div>
                    )}
                    {(isPrescriber ||
                      isPharmacy ||
                      isConnectedNurse ||
                      isHomecare ||
                      isOutpatient) && (
                      <div onClick={() => redirect(App.PATIENT_MATERIALS)}>
                        HCP & Patient Material
                      </div>
                    )}
                    {(isPrescriber ||
                      isPharmacy ||
                      isConnectedNurse ||
                      isHomecare ||
                      isOutpatient) && (
                      <div onClick={() => redirect(App.REPORT_EVENT)}>
                        Report an AE/Pregnancy
                      </div>
                    )}
                    {isCustomerSolution && (
                      <div onClick={() => redirect(App.PHARMACY_LIST)}>
                        Registered Pharmacy Listing
                      </div>
                    )}
                    {!isCustomerSolution && (
                      <div onClick={() => redirect(App.CONTACT_US)}>
                        Contact Us
                      </div>
                    )}
                    <button onClick={() => signOut()}>Sign Out</button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="logo">
        <div className="main-max-width">
          <img
            src={LoginBackground}
            alt="Pathfider Portal Logo"
            onClick={checkRoutes}
            title="Go to Homepage"
          />
          {storageData?.isLoggedIn && !isCustomerSolution ? (
            <React.Fragment>
              <div className="find-patient" ref={searchRef}>
                <div className="wrapper-search-input">
                  <form autoComplete="off">
                    <input
                      className="input-search-general"
                      type="search"
                      value={filterValue}
                      onChange={onChangeSearchValue}
                      placeholder="Find a Patient..."
                      autoComplete="search-patient"
                    />
                  </form>
                  {filterValue.length === 0 && (
                    <img src={SearchIcon} alt="" className="search" />
                  )}
                </div>
                {patients.length &&
                filterValue.length > 0 &&
                clickedOutsideSearch === false ? (
                  <div className="wrapper-tooltip-patients">
                    {patients.slice(0, 3).map((patient, indexPatient) => (
                      <SearchPatientMap
                        redirectToPatient={redirectToPatient}
                        patient={patient}
                        key={indexPatient}
                        isPrescriber={isPrescriber}
                        displayDetails={displayDetails}
                      />
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </React.Fragment>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <QuitModal
        quitModalIsOpen={quitModalIsOpen}
        closeModalQuit={closeModalQuit}
        quitModal={quitModal}
        description="Are you sure you want to cancel filling out this Form. All progress will be lost and you will need to start again."
      />
    </StyledTopMenu>
  );
};
export default TopMenu;
