import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../../shared/colors";
import { useNavigate } from "react-router-dom";

const Layout = styled.div`
  .element {
    display: flex;
    margin-bottom: 18px;
    cursor: pointer;

    .notification-text {
      margin-left: 5px;

      .text {
        color: ${Colors.emperor};
        margin-bottom: 5px;
      }

      .date {
        color: ${Colors.gray};
      }
    }
  }
  .red-bullet {
    color: ${Colors.chestnuteRose};
    font-size: 40px;
  }

  .orange-bullet {
    color: ${Colors.orangePeel};
    font-size: 40px;
  }

  .green-bullet {
    color: ${Colors.greenHaze};
    font-size: 40px;
  }
  .blue-bullet {
    color: ${Colors.scienceBlue};
    font-size: 40px;
  }
`;

const MenuNotifications = ({
  redirectNotificationToPAFDetails,
  notification,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    // Navigate to /notifications with the scrollToBottom query parameter
    navigate(`/notifications?scrollToBottom=true`);
  };
  return (
    <Layout>
      <div
        className="element"
        onClick={() =>
          notification.color === "blue"
            ? handleNavigation()
            : redirectNotificationToPAFDetails(
                notification.patient_id,
                notification.prescription_id
              )
        }
      >
        <div
          className={
            notification.color === "green"
              ? "green-bullet"
              : notification.color === "red"
              ? "red-bullet"
              : notification.color === "blue"
              ? "blue-bullet"
              : "orange-bullet"
          }
        >
          •
        </div>
        <div className="notification-text">
          <div className="text font-size-16">{notification.title}</div>
          <div className="date">{notification.date_difference}</div>
        </div>
      </div>
    </Layout>
  );
};

MenuNotifications.propTypes = {
  notification: PropTypes.object,
  redirectNotificationToPAFDetails: PropTypes.func,
};

export default MenuNotifications;
