import styled from "styled-components";
import Colors from "../../shared/colors";

export default styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 140px 0px 100px 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;

  .wrapper-element {
    padding: 58px 100px;
    max-width: 1100px;
  }

  .select-category-txt {
    padding-bottom: 5px;
    margin-top: 10px;
  }

  .wrapper-buttons-select-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px;
  }

  .undo-changes {
    margin-right: 10px;
    border: none;
    background: none;
    color: ${Colors.orient};
  }

  .undo-changes:hover {
    color: ${Colors.gray};
  }

  .nurse-undo {
    display: flex;
    justify-content: flex-end;
  }

  .wrapper-buttons-right-align {
    display: flex;
    justify-content: right;
    align-items: center;

    .prev {
      margin-right: 40px;
      @media screen and (max-width: 850px) {
        margin-right: initial;
      }
    }
  }

  .second-button,
  .selected-register-button,
  .green-light-button {
    width: 47%;
  }

  img {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 15px;
  }

  .title-details {
    margin-top: 30px;
    margin-bottom: 25px;

    span {
      cursor: pointer;
      margin-left: 5px;
    }

    .remove {
      color: ${Colors.valencia};
    }

    .add {
      color: ${Colors.greenHaze};
    }
    span:hover {
      text-decoration: underline;
    }
  }

  label {
    color: ${Colors.orient};
  }

  .col {
    display: grid;
    position: relative;
    margin-bottom: 5px;
  }

  .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  input {
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: ${Colors.alabaster};
    color: ${Colors.doveGray};
    border: 0;
    border-bottom: 1px solid ${Colors.silver};
    font-size: 18px;
    line-height: 22px;
  }

  .custom-print-input {
    margin-left: 0px !important;
    padding-left: 0px !important;
    border-bottom: 1px solid ${Colors.silver} !important;
  }

  .inputNameDate {
    color: ${Colors.gray};
  }

  input:focus-visible {
    outline: 0;
  }

  .group-back {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .back {
      background: ${Colors.orient};
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: 46px;
      border-radius: 25px;

      img {
        margin-top: 0;
        width: 20px;
      }
    }

    .back:hover {
      background: linear-gradient(90deg, #005eb8 -35.87%, #0083fc 1946.74%);
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${Colors.orient};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 500px;
      color: ${Colors.alabaster};
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      width: 94%;
      margin-left: 5px;
      height: 46px;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px ${Colors.alabaster} inset;
    box-shadow: 0 0 0 50px ${Colors.alabaster} inset;
  }

  .prescriber-confirmation {
    margin-top: 25px;
    margin-bottom: 35px;

    .accept {
      color: ${Colors.doveGray};
      margin-bottom: 10px;
    }

    label {
      color: ${Colors.doveGray};
      line-height: 20px;
      margin-bottom: 12px;
    }

    ul {
      counter-reset: list;
      margin-top: 15px;
    }
    ul > li {
      list-style: none;
    }
    ul > li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
    }
  }
  .confirm {
    color: ${Colors.doveGray};
    text-align: justify;
  }

  .sign {
    margin-top: 75px;

    label {
      color: ${Colors.orient};
    }
  }

  .initiation {
    margin-top: 25px;
    margin-bottom: 40px;

    label {
      color: ${Colors.orient};
    }
  }

  .disabled {
    color: ${Colors.gray};
  }

  .error {
    border-bottom: 1px solid ${Colors.valencia};
  }

  .error-txt {
    color: ${Colors.valencia};
    font-size: 16px;
    line-height: 19px;
    font-style: italic;
    font-weight: normal;
  }

  a {
    color: ${Colors.doveGray};
  }

  a:hover {
    color: ${Colors.gray};
  }

  .wrapper-brackground {
    background: ${Colors.alabaster};
  }

  .calendar {
    width: auto;
    position: absolute;
    right: 50px;
    bottom: 21px;
    z-index: -1;
  }

  // input {
  //   background-color: transparent !important;
  // }

  .dove-gray {
    color: ${Colors.doveGray};
    text-align: justify;
  }

  .full {
    width: 100%;
    margin-bottom: 30px;
  }

  .wrapper-warning {
    padding: 1px 20px;
    background: rgba(255, 0, 0, 0.1);
  }

  .wrapper-radio {
    margin-top: 20px;
    margin-bottom: 50px;

    label {
      color: ${Colors.orient};
      font-size: 16px;
      line-height: 19px;
      padding-left: 35px;
    }
  }

  .gray {
    color: ${Colors.gray};
  }

  .no-pack-quantity {
    position: absolute;
    bottom: -20px;
    font-style: italic;
    left: 10px;

    @media screen and (max-width: 850px) {
      bottom: -5px;
    }
  }

  .custom-select__control {
    min-height: initial !important;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .margin-custom-select.custom-select__control {
    margin-top: initial;
    margin-bottom: 4px;
  }

  .align-patient-details {
    display: flex;
    align-items: baseline;

    .display-patient-details-state {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .wrapper-flex {
    display: flex;
    justify-content: space-between;
    align-self: center;

    .second-button {
      width: initial;
    }
  }

  .paf-view-background {
    background-color: ${Colors.white};
    padding: 25px;
  }

  .full-button-width {
    width: 100%;
  }

  .title-flex {
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
      min-width: 165px;
    }

    .margin-right {
      margin-right: 20px;
    }
  }

  .editable-from-profile {
    color: ${Colors.valencia};
    font-size: 16px;
    line-height: 19px;
    font-style: italic;
  }

  .color-tif-label {
    color: ${Colors.orient};
  }

  .info_nurse {
    color: ${Colors.orient};
    padding-top: 35px;
    line-height: 15px;
  }

  .custom-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;

    .custom-login-arrow {
      margin-left: 10px;
    }

    .custom-login-loader {
      width: 27px;
    }
  }

  .flex-between-center {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }

  .symbol-idication {
    color: ${Colors.orient};
    font-size: 16px;
    line-height: 19px;
  }

  .patient-category-button {
    border: initial;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: rgba(0, 114, 206, 0.25);
    color: ${Colors.black};
  }
  .patient-category-button-grey {
    border: initial;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: ${Colors.gray};
    color: ${Colors.black};
    margin-bottom: 45px;
  }

  .patient-category-button svg path {
    stroke: transparent;
  }
  .required-registration {
    color: ${Colors.coralRed};
    margin: 15px 0px 0px 0px;
  }

  .product-name {
    margin: 10px;
    border-radius: 500px;
    color: ${Colors.white};
    font-size: 18px;
    line-height: 22px;
    border: 0;
    font-weight: bold;
  }

  .border-slate {
    border: 1px solid ${Colors.slateGray};
  }

  .create-paf-wrapper {
    input {
      margin-top: 2px;
    }

    .prev {
      color: ${Colors.mineShaft};
    }
    .prev:hover {
      color: ${Colors.gray};
    }
    .tif-ol {
      margin-top: 30px;
    }
    .wrapper-buttons {
      .hover-class,
      .hover-class span {
        text-decoration: initial;
      }
    }
    .error-text {
      margin-left: 30px;
    }

    .symbol-idication {
      color: ${Colors.mineShaft};
    }
  }

  .italic-min {
    min-height: 26px;
    white-space: nowrap;
  }

  .no-wrap-text {
    white-space: nowrap;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .custom-select__single-value .flex-justify div {
    font-size: 18px;
  }

  .custom-select__menu > div > div:hover div {
    background-color: ${Colors.slateGray};
    color: ${Colors.white};
  }

  .custom-select-placeholder .custom-select__control {
    cursor: pointer;
  }

  .footer-button {
    margin-left: auto;

    &-holder {
      display: flex;
      margin-top: 20px;
    }
  }
`;
