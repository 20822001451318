import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close.svg";
import Select, { components } from "react-select";
import APIVariables from "../../../services/api-variables";
import { ReactComponent as DropdownArrowDown } from "../../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../../assets/icons/dropdownArrowUp.svg";
import { ReactComponent as Undo } from "../../../assets/icons/undo.svg";
import StyledRegister from "../../authentication/StyledRegister";
import { handleCatch } from "../../../shared/helpers";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import ConfirmHomecareModal from "./modals/ConfirmHomecareModal";
import Axios from "../../../services/Axios";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropdownArrowUp />
        ) : (
          <DropdownArrowDown />
        )}
      </components.DropdownIndicator>
    )
  );
};

const HomecareAdd = () => {
  const navigate = useNavigate();
  const [homecares, setHomecares] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [confirmModalIsOpen, setConfirmModal] = useState(false);
  const [validateScreenFields, setValidateScreenFields] = useState({});
  const [form, setForm] = useState({});

  useEffect(() => {
    setValidateScreenFields(validateForm());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    getHomecaresList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHomecaresList = async () => {
    await Axios.get(APIVariables.HCP_HOMECARES_LIST)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setHomecares(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  const handleChange = (e) => {
    if (!e.target) {
      setForm((prevState) => ({
        ...prevState,
        homecare: e,
      }));
    } else {
      const { name, value } = e.target;

      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  function closeForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.HOMECARE);
  }

  const submitForm = async () => {
    setIsSubmitting(false);
    let areAllFields = Object.values(validateScreenFields).every((field) => {
      return field.isValid;
    });

    const payload = {
      connected_user_id: form.homecare?.connected_user_id,
    };

    if (areAllFields) {
      await Axios.post(APIVariables.USERS_SIGNUP_HCP_HOMECARE, payload)
        .then((res) => {
          const { status } = res;
          if (status === 200) {
            setConfirmModal(true);
          }
        })
        .catch(function (error) {
          handleCatch(error);
        });
    } else {
      setIsSubmitting(true);
    }
  };

  const validateForm = () => {
    const { homecare } = form;
    return {
      homecare: {
        isValid: homecare && Object.keys(homecare).length > 0,
      },
    };
  };

  const createMarkup = (homecare) => {
    return {
      __html: `<div class="flex-justify custom-hover-select"><div>${homecare?.name}</div><div>${homecare?.postcode}</div></div>`,
    };
  };

  const homecareMapOptions = () => {
    return (homecares || []).map((homecare) => ({
      label: <div dangerouslySetInnerHTML={createMarkup(homecare)}></div>,
      value: homecare.name + " " + homecare.postcode,
      ...homecare,
    }));
  };

  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal">
          <div className="close-icon" onClick={closeForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          <div className="step font-size-18 color-tif-label">Step 1/1</div>
          <h1 className="select-category-txt mineShaft-color">
            Homecare Connection Form
          </h1>
          <hr className="gray-hr" />

          <h2 className="title-details mineShaft-color">Homecare Details</h2>

          <div className="container mb-5">
            <div className="row">
              <div className="col custom-select-placeholder">
                <label>Homecare Name*:</label>
                <Select
                  classNamePrefix="custom-select"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  name="homecare"
                  onChange={handleChange}
                  options={homecareMapOptions()}
                  value={form.homecare}
                  placeholder="Search Pharmacy name or Postcode"
                  className={
                    isSubmitting && !validateScreenFields.homecare?.isValid
                      ? "error-select"
                      : "select-border"
                  }
                />
                {isSubmitting && !validateScreenFields.homecare?.isValid && (
                  <span className="required-text">Required</span>
                )}
              </div>
              <div className="col">
                <label>Postcode:</label>
                <input
                  type="text"
                  name="postcode"
                  value={form.homecare?.postcode || ""}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Homecare Address:</label>
                <input
                  type="text"
                  name="homecare.address"
                  value={form.homecare?.address || ""}
                  disabled
                />
              </div>
              <div className="col" />
            </div>
            <div className="row">
              <p className="info_nurse font-size-16">
                <b className="bold info_nurse font-size-16">IMPORTANT</b>:
                Connected Homecare providers will only receive an email of any
                PAF that has been sent
              </p>
            </div>
          </div>

          <div className="wrapper-buttons-select-type">
            <div className="symbol-idication">
              *symbol indicating a mandatory field
            </div>
            <div>
              {form?.homecare && Object.keys(form?.homecare).length ? (
                <button
                  className="undo-changes font-size-18"
                  onClick={() => setForm({ homecare: "" })}
                >
                  <Undo /> Undo Changes
                </button>
              ) : (
                ""
              )}

              <button className="primary-button" onClick={submitForm}>
                Save
              </button>
            </div>
          </div>
        </div>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={quitModal}
            description="Are you sure you want to cancel filling out this form? All progress will be lost and you will need to start again."
          />
        )}
        {confirmModalIsOpen && (
          <ConfirmHomecareModal
            closeModal={setConfirmModal}
            modalIsOpen={confirmModalIsOpen}
          />
        )}
      </StyledRegister>
    </React.Fragment>
  );
};
export default HomecareAdd;
