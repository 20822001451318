import React from "react";
import PropTypes from "prop-types";

const CheckboxOption = ({
  indexValue,
  stat,
  checkIsFiltering,
  onChangeFilters,
  keyType,
  labelType,
}) => {
  return (
    <label
      className={
        "flex-justify-without-center-items custom-checkbox font-size-16" +
        (indexValue === 0 ? " mt-3" : "")
      }
      key={stat.id}
    >
      {stat.value}
      <input
        type="checkbox"
        checked={checkIsFiltering(keyType, stat.id) || false}
        onChange={(e) => {
          onChangeFilters(keyType, {
            ...stat,
            label: labelType,
          });
        }}
        key={stat.id}
      />
      <span className="checkmark" />
      <p className="font-size-12 orient">{stat?.total}</p>
    </label>
  );
};

CheckboxOption.propTypes = {
  indexValue: PropTypes.number,
  stat: PropTypes.object,
  checkIsFiltering: PropTypes.func,
  onChangeFilters: PropTypes.func,
  keyType: PropTypes.string,
  labelType: PropTypes.string,
};

export default CheckboxOption;
