import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import React, { useState } from "react";

import styled from "styled-components";
import { Auth, App } from "./Constants";

import ResetPassword from "../components/authentication/Reset-Password";

import ProtectedRoute from "./protectedRoute";

import Authentification from "../components/authentication/index";
import Register from "../components/authentication/Register";
import Validate from "../components/authentication/Validate";

import TopMenu from "../components/common/TopMenu/TopMenu";

import { redirectToHomepageDependingOnUser } from "../shared/helpers";
import ResetPasswordValidate from "../components/authentication/Reset-Password-Validate";
import LocalStorage from "../shared/localStorage";
import PageNotFound from "./pageNotFound";
import useRoles from "../hooks/useRoles";
import Footer from "../components/common/Footer/Footer";
import Dashboard from "../components/pages/Dashboard";
import HomecareList from "../components/pages/Homecare/HomecareList";
import ConnectedPharmacistsList from "../components/pages/ConnectedPharmacists/ConnectedPharmacistsList";
import ConnectedPharmacistAdd from "../components/pages/ConnectedPharmacists/ConnectedPharmacistAdd";
import ConnectedPharmacistView from "../components/pages/ConnectedPharmacists/ConnectedPharmacistView";
import ConnectedOutpatients from "../components/pages/ConnectedOutpatients/ConnectedOutpatients";
import OutpatientAdd from "../components/pages/ConnectedOutpatients/OutpatientAdd";
import OutpatientEdit from "../components/pages/ConnectedOutpatients/OutpatientEdit";
import HomecareAdd from "../components/pages/Homecare/HomecarAdd";
import HomecareEdit from "../components/pages/Homecare/HomecareEdit";
import PatientMaterials from "../components/pages/PatientMaterial";
import PatientInitiation from "../components/pages/PatientInitiation";
import Nurses from "../components/pages/Nurses";
import NurseAdd from "../components/pages/NurseAdd";
import NurseEdit from "../components/pages/NurseEdit";
import PAFRenew from "../components/pages/PAFRenew";
import ReportEvent from "../components/pages/ReportEvent";
import ContactUs from "../components/pages/ContactUs/ContactUs";
import Notifications from "../components/pages/Notifications";
import PrescriptionAuthorisationForm from "../components/pages/PAF";
import ViewPatients from "../components/pages/ViewPatients";
import PharmacyList from "../components/pages/PharmacyList";
import Profile from "../components/pages/Profile";
import { Banner } from "../components/authentication/Banner/Banner";

const PublicRoutes = () => {
  const isLoggedIn = () => {
    const getLocalStorage = localStorage.getItem(LocalStorage.USER_NHSPORTAL);
    const parseLocalStorage = JSON.parse(getLocalStorage);
    return parseLocalStorage?.isLoggedIn;
  };
  const {
    isHomecare,
    isOutpatient,
    isPrimaryPharmacist,
    isPrescriber,
    isPharmacy,
    isConnectedNurse,
    isCustomerSolution,
    storageData,
  } = useRoles();

  const [loggedInInitials, setLoggedInInitials] = useState(
    storageData?.first_name?.charAt(0)?.toUpperCase() +
      storageData?.last_name?.charAt(0)?.toUpperCase()
  );

  const redirectToHomepage =
    storageData &&
    storageData?.user_type &&
    redirectToHomepageDependingOnUser(storageData?.user_type);

  const isPublicRoute = Object.values(Auth).find((element) =>
    element.includes(window.location.pathname)
  );

  if (!storageData?.isLoggedIn && !isPublicRoute && window.location.pathname) {
    localStorage.setItem(
      LocalStorage.NEXT_PAGE_NHSPORTAL,
      window.location.pathname
    );
  }

  const LoggedInRoute = ({ element }) => {
    return (
      <Layout>
        <TopMenu loggedInInitials={loggedInInitials} />
        <Banner />
        {isLoggedIn() ? <Navigate replace to={redirectToHomepage} /> : element}
        <Footer />
      </Layout>
    );
  };

  const ProtectedRouteWrapper = ({ condition, component, componentProps }) => {
    return (
      <ProtectedRoute
        component={component}
        condition={condition}
        loggedInInitials={loggedInInitials}
        setLoggedInInitials={setLoggedInInitials}
        componentProps={componentProps}
      />
    );
  };

  const router = createBrowserRouter([
    {
      path: Auth.LOGIN,
      element: <LoggedInRoute element={<Authentification />} />,
    },
    {
      path: Auth.REGISTER,
      element: <LoggedInRoute element={<Register />} />,
    },
    {
      path: Auth.VALIDATE,
      element: <LoggedInRoute element={<Validate />} />,
    },
    {
      path: Auth.RESET_PASSWORD,
      element: <LoggedInRoute element={<ResetPassword />} />,
    },
    {
      path: Auth.RESET_PASSWORD_VALIDATE,
      element: <LoggedInRoute element={<ResetPasswordValidate />} />,
    },
    {
      path: "*",
      element: (
        <Layout>
          <TopMenu loggedInInitials={loggedInInitials} />
          <PageNotFound />
        </Layout>
      ),
    },

    {
      path: App.DASHBOARD,
      element: (
        <ProtectedRouteWrapper
          condition={
            storageData?.isLoggedIn &&
            (isPrescriber ||
              isPharmacy ||
              isConnectedNurse ||
              isHomecare ||
              isOutpatient)
          }
          component={Dashboard}
        />
      ),
    },
    {
      path: App.HOMECARE,
      element: (
        <ProtectedRouteWrapper
          condition={isPharmacy}
          component={HomecareList}
        />
      ),
    },
    {
      path: App.CONNECTED_PHARMACISTS,
      element: (
        <ProtectedRouteWrapper
          condition={
            (isPharmacy || isHomecare || isOutpatient) && isPrimaryPharmacist
          }
          component={ConnectedPharmacistsList}
        />
      ),
    },
    {
      path: App.ADD_CONNECTED_PHARMACIST,
      element: (
        <ProtectedRouteWrapper
          condition={
            (isPharmacy || isHomecare || isOutpatient) && isPrimaryPharmacist
          }
          component={ConnectedPharmacistAdd}
        />
      ),
    },
    {
      path: App.VIEW_CP,
      element: (
        <ProtectedRouteWrapper
          condition={
            (isPharmacy || isHomecare || isOutpatient) && isPrimaryPharmacist
          }
          component={ConnectedPharmacistView}
        />
      ),
    },
    {
      path: App.CONNECTED_OUTPATIENT,
      element: (
        <ProtectedRouteWrapper
          condition={isPharmacy}
          component={ConnectedOutpatients}
        />
      ),
    },
    {
      path: App.CONNECTED_OUTPATIENT_ADD,
      element: (
        <ProtectedRouteWrapper
          condition={isPharmacy}
          component={OutpatientAdd}
        />
      ),
    },
    {
      path: App.CONNECTED_OUTPATIENT_EDIT,
      element: (
        <ProtectedRouteWrapper
          condition={isPharmacy}
          component={OutpatientEdit}
        />
      ),
    },
    {
      path: App.HOMECARE_ADD,
      element: (
        <ProtectedRouteWrapper condition={isPharmacy} component={HomecareAdd} />
      ),
    },
    {
      path: App.HOMECARE_EDIT,
      element: (
        <ProtectedRouteWrapper
          condition={isPharmacy}
          component={HomecareEdit}
        />
      ),
    },
    {
      path: App.PATIENT_MATERIALS,
      element: (
        <ProtectedRouteWrapper
          condition={
            isPrescriber ||
            isPharmacy ||
            isConnectedNurse ||
            isHomecare ||
            isOutpatient
          }
          component={PatientMaterials}
        />
      ),
    },
    {
      path: App.PATIENT_INITIATION,
      element: (
        <ProtectedRouteWrapper
          condition={isPrescriber}
          component={PatientInitiation}
        />
      ),
    },
    {
      path: App.NURSES,
      element: (
        <ProtectedRouteWrapper condition={isPrescriber} component={Nurses} />
      ),
    },
    {
      path: App.ADD_NURSE,
      element: (
        <ProtectedRouteWrapper condition={isPrescriber} component={NurseAdd} />
      ),
    },
    {
      path: App.EDIT_NURSE,
      element: (
        <ProtectedRouteWrapper condition={isPrescriber} component={NurseEdit} />
      ),
    },
    {
      path: App.PAF_RENEW,
      element: (
        <ProtectedRouteWrapper condition={isPrescriber} component={PAFRenew} />
      ),
    },
    {
      path: App.REPORT_EVENT,
      element: (
        <ProtectedRouteWrapper
          condition={
            isPrescriber ||
            isPharmacy ||
            isConnectedNurse ||
            isHomecare ||
            isOutpatient
          }
          component={ReportEvent}
        />
      ),
    },
    {
      path: App.CONTACT_US,
      element: <ProtectedRouteWrapper component={ContactUs} />,
    },
    {
      path: App.NOTIFICATIONS,
      element: <ProtectedRouteWrapper component={Notifications} />,
    },
    {
      path: App.PRESCRIPTION_AUTHORISATION_FORM,
      element: (
        <ProtectedRouteWrapper component={PrescriptionAuthorisationForm} />
      ),
    },
    {
      path: App.VIEW_PATIENTS,
      element: <ProtectedRouteWrapper component={ViewPatients} />,
    },
    {
      path: App.PHARMACY_LIST,
      element: (
        <ProtectedRouteWrapper
          condition={isCustomerSolution}
          component={PharmacyList}
        />
      ),
    },
    {
      path: App.PROFILE_ID,
      element: (
        <ProtectedRouteWrapper
          condition={
            isPrescriber ||
            isPharmacy ||
            isConnectedNurse ||
            isHomecare ||
            isOutpatient
          }
          component={Profile}
          setLoggedInInitials={setLoggedInInitials}
        />
      ),
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

const Layout = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  min-height: 100vh;
`;

export default PublicRoutes;
