import React from "react";
import StyledBanner from "./StyledBanner";
import { useState } from "react";
import { useEffect } from "react";
import { handleCatch } from "../../../shared/helpers";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";

export const Banner = () => {
  const [bannerText, setBannerText] = useState();
  useEffect(() => {
    const getBannerText = async () => {
      try {
        let res = await Axios.get(APIVariables.GET_BANNER_TEXT).catch(
          (error) => {
            handleCatch(error);
          }
        );
        if (res?.status === 200) {
          setBannerText(res.data);
        }
      } catch (error) {}
    };
    getBannerText();
  }, []);

  const displayBanner = window.location.pathname.includes("/login");
  return (
    <StyledBanner>
      <div
        className={
          bannerText?.length === 0 || !displayBanner
            ? "display-none"
            : "container"
        }
      >
        {bannerText?.length !== 0
          ? bannerText?.map((text, index) => (
              <ul key={index}>
                <li>
                  <b>{text.title} </b>
                </li>
                <p>
                  {text.text_note?.split("\r\n").map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                      {line}
                      {lineIndex !==
                        text.text_note?.split("\r\n").length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </ul>
            ))
          : null}
      </div>
    </StyledBanner>
  );
};
