import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  width: 100%;
  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: ${Colors.orient};
    text-align: center;
    width: 250px;
    margin: 0 auto;
  }

  .main-img {
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    max-width: 325px;
  }

  .wrapper-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;

    div {
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
    }

    span {
      color: ${Colors.doveGray};
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin-right: 5px;
    }
  }

  .wrapper-buttons div:hover {
    span {
      color: ${Colors.gray};
    }

    svg path {
      fill: ${Colors.gray};
    }
  }

  .hover-class:hover {
    a {
      color: ${Colors.gray};
    }

    span {
      color: ${Colors.gray};
    }

    svg path {
      fill: ${Colors.gray};
    }
  }

  .hover-class {
    svg {
      margin-bottom: 5px;
    }
  }

  .wrapper-modal-molecule {
    display: grid;
    text-align: center;
    padding: 0px 57px 57px 57px;
    min-height: 350px;
  }

  .display-flex {
    display: flex;
  }

  .display-brochures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .container {
    display: flex;
    width: 100%;
    flex-flow: wrap;
    padding: 0px 20px !important;
    justify-content: space-between;
  }
  .element {
    width: 40%;
    margin: 0 10px;
  }
  .details {
    font-size: 20px;
    margin-top: 10px;
  }
`;
