import React from "react";
// import { useEffect, useState } from "react";
import { handleCatch } from "../../../shared/helpers";
import StyledBrochureLink from "./StyledBrochureLink";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";

const BrochureLink = ({ hcp, className, size = 16 }) => {
  const getBrochure = async (openInNewPage = false) => {
    await Axios.get(APIVariables.PATIENTS_BROCHURES_DOWNLOAD + hcp.id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          return data?.report;
        }
        return null;
      })
      .then(async (brochureString) => {
        const base64 = await fetch(
          `data:application/pdf;base64,${brochureString}`
        );
        return base64.blob();
      })
      .then((blob) => {
        const file = new Blob([blob], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        if (openInNewPage) {
          downloadLink.target = "_blank";
        } else {
          downloadLink.download = `${hcp.description}.pdf`;
        }
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        handleCatch(error);
      });
  };

  return (
    <StyledBrochureLink
      className={className}
      size={size}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        getBrochure();
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.button === 1 || event.button === 4) {
          getBrochure(true);
        }
      }}
    >
      {hcp?.description}
    </StyledBrochureLink>
  );
};

export default BrochureLink;
