const App = {
  DASHBOARD: "/",

  //homecare
  HOMECARE: "/homecare",
  HOMECARE_ADD: "/homecare/add",
  HOMECARE_EDIT_DEFAULT: "/edit-homecare/",
  HOMECARE_EDIT: "/edit-homecare/:id",

  //connected pharmacists
  CONNECTED_PHARMACISTS: "/connected-pharmacists",
  ADD_CONNECTED_PHARMACIST: "/connected-pharmacists/add",
  VIEW_CP_DEFAULT: "/view-connected-pharmacist/",
  VIEW_CP: "/view-connected-pharmacist/:id",

  //connected outpatients
  CONNECTED_OUTPATIENT: "/connected-outpatient",
  CONNECTED_OUTPATIENT_ADD: "/connected-outpatient/add",
  CONNECTED_OUTPATIENT_EDIT: "/edit-outpatient/:id",
  CONNECTED_OUTPATIENT_EDIT_DEFAULT: "/edit-outpatient/",

  PROFILE_DEFAULT: "/profile/",
  PROFILE_ID: "/profile/:id",

  FULL_REPORT_DEFAULT: "/full-report",

  NURSES: "/nurses",
  ADD_NURSE: "/add-nurse/add",
  DEFAULT_EDIT_NURSE: "/edit-nurse/",
  EDIT_NURSE: "/edit-nurse/:id",

  PATIENT_MATERIALS: "/patient-materials",

  VIEW_PATIENTS: "/view-patients",

  REPORT_EVENT: "/report-event",

  NOTIFICATIONS: "/notifications",

  PATIENT_INITIATION: "/patient-initiation",

  PRESCRIPTION_AUTHORISATION_FORM_DEFAULT: "/prescription-authorisation-form/",

  PRESCRIPTION_AUTHORISATION_FORM:
    "/prescription-authorisation-form/:userID/:pafID?",

  PAF_RENEW_DEFAULT: "/prescription-authorisation-form/renew/",
  PAF_RENEW:
    "/prescription-authorisation-form/renew/:typeOfPatient/:patientID/:lastPAFID?",
  PHARMACY_LIST: "/pharmacy-list",
  RE_REGISTER: "/re-register",
  CONTACT_US: "/contact-us",
};

const Auth = {
  LOGIN: "/login",
  REGISTER: "/register",
  VALIDATE_DEFAULT: "/validate",
  VALIDATE: "/validate/:uid/:token",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_VALIDATE_DEFAULT: "/reset",
  RESET_PASSWORD_VALIDATE: "/reset/:nurse?/:uid/:token",
};

export { App, Auth };
