import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import CloseIcon from "../../../assets/icons/close.svg";
import { ReactComponent as CheckRegister } from "../../../assets/icons/checkLime.svg";

import StyledModal from "../../common/Modal/StyledModal";

const ConfirmNurseModal = ({ closeModal, modalIsOpen }) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "32px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Confirm Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <button onClick={() => closeModal()} className="close-icon-button ">
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <div className="wrapper-modal">
          <div className="image">
            <CheckRegister />
          </div>
          <div className="main-text-orient bold mineShaft-color">
            Connected Nurse Added
          </div>
          <div className="second-text">
            An email has been sent to this Connected Nurse. Once registered, the
            nurse will be able to view patients in your hospital
          </div>
        </div>

        <div className="wrapper-buttons">
          <button className="green-button" onClick={() => closeModal()}>
            Return
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

ConfirmNurseModal.propTypes = {
  closeModal: PropTypes.func,
  modalIsOpen: PropTypes.bool,
};
export default ConfirmNurseModal;
