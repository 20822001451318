const Colors = {
  white: "#ffffff",
  black: "#000000",
  doveGray: "#646464",
  orient: "#00567a",
  violetEggplant: "#aa198d",
  blumine: "#1f4e77",
  silver: "#c8c8c8",
  endeavour: "#00567A",
  alabaster: "#f8f8f8",
  greenHaze: "#00a03b",
  mercury: "#e6e6e6",
  gray: "#8c8c8c",
  alto: "#dcdcdc",
  valencia: "#E41818",
  emperor: "#505050",
  chestnuteRose: "#cf4b4b",
  orangePeel: "#ff9900",
  coralRed: "#fb3449",
  scienceBlue: "#0A58CA",
  mineShaft: "#3C3C3C",
  porcelain: "#e8edee",
  slateGray: "#768692",
};

export default Colors;
