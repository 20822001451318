import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.span`
  text-decoration: underline;
  background: transparent;
  font-size: ${(props) => `${props?.size}px`};
  cursor: pointer;
  &:hover {
    color: ${Colors.silver};
  }

  .maximmum-cycles-tif {
    font-size: 16px;
    text-decoration: underline;
    line-height: 20px;
  }
`;
