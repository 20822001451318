import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  position: relative;
  display: flex;

  .tooltip-status {
    position: relative;
    display: inline-block;
    margin-left: 3px;
    img {
      margin-top: initial;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .tooltip-status .tooltiptext-status {
    visibility: hidden;
    word-wrap: break-word;
    width: 215px;
    height: fit-content;
    background-color: ${Colors.greenHaze};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
    padding: 10px 21px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    right: -340%;
    font-weight: normal;

    div,
    b {
      color: ${Colors.white};
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }

    b {
      font-weight: bold;
    }
  }

  .tooltip-status .tooltiptext-status::after {
    content: "";
    position: absolute;
    top: 100%;
    transform: rotate(180deg);
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${Colors.greenHaze} transparent;
  }

  .tooltip-status:hover .tooltiptext-status {
    visibility: visible;
  }

  /* INFO FROM PATIENT INITIATION (WEEKS PER CYCLE) */

  .status {
    position: relative;
    display: inline-block;
    margin-left: 3px;
    .info-icon {
      margin-top: initial;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .status .tooltiptext {
    visibility: hidden;
    word-wrap: break-word;
    width: 315px;
    height: fit-content;
    background-color: ${Colors.orient};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
    padding: 10px 21px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    right: -500%;
    font-weight: normal;
    color: ${Colors.white};

    div {
      color: ${Colors.white};
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .status .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    transform: rotate(180deg);
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${Colors.orient} transparent;
  }

  .status:hover .tooltiptext {
    visibility: visible;
  }
`;
