import styled from "styled-components";

export default styled.div`
  .total-patients {
    text-align: center;
    max-width: 1100px;
    padding-bottom: 20px;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
