import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";

import Colors from "../../../shared/colors";

const Layout = styled.div`
  .wrapper-filter-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: ${Colors.orient};
    border-radius: 500px;
    height: 46px;
    width: auto;
    width: fit-content;
    margin-right: 5px;
    margin-bottom: 5px;

    .text-filter {
      color: ${Colors.white};
      padding-left: 10px;
      padding-right: 10px;
    }

    .remove-filter {
      font-weight: bold;
      padding-right: 10px;
      color: ${Colors.white};
      cursor: pointer;
    }
  }
`;

const FilterDataViewPatients = ({ keyRefer, filter, onChangeFilters }) => {
  const isDate = filter.dataType === "date";

  return (
    <Layout>
      <div className="wrapper-filter-button">
        <div className="text-filter">
          {filter.label}:{" "}
          {!isDate
            ? filter.value
            : filter.date.length === 2
            ? moment(filter.after).format("DD/MM/YYYY") +
              " - " +
              moment(filter.before).format("DD/MM/YYYY")
            : moment(filter.value).format("DD / MM / YYYY")}
        </div>
        <div
          className="remove-filter"
          onClick={() => {
            onChangeFilters(
              keyRefer,
              {
                ...filter,
              },
              true
            );
          }}
        >
          x
        </div>
      </div>
    </Layout>
  );
};

FilterDataViewPatients.propTypes = {
  keyRefer: PropTypes.string,
  filter: PropTypes.object,
  deleteFilter: PropTypes.func,
};

export default FilterDataViewPatients;
