import React from "react";
import infoConstants from "../../../shared/infoConstants";
import HBIconLoginSvg from "../../../assets/images/healthbeacon.svg";
import pharmacareLoginSvg from "../../../assets/images/pharmacare.svg";

export const SupportText = ({
  className = "",
  className2 = "",
  className3 = "",
  showLogos,
}) => {
  return (
    <div className={className}>
      <div>
        {showLogos && (
          <>
            <div className={className3}>
              <img src={HBIconLoginSvg} alt="HB Logo" />
              <img src={pharmacareLoginSvg} alt="Pharmacare Logo" />
            </div>
            <p className="text-below-logos">
              <b> Please contact below if you wish to request a user guide</b>
            </p>
          </>
        )}
      </div>
      <span className={`${className2} text-align-center mb-1 `}>
        For support, contact Pharmacare Group at
        <a href={`tel:${infoConstants.nhsPhoneNumber}`}>
          <b> {infoConstants.nhsPhoneNumber}</b>
        </a>
        , or email
        <a href={`mailto: ${infoConstants.nhsEmail}`}>
          <b> {infoConstants.nhsEmail}</b>
        </a>
      </span>
    </div>
  );
};
