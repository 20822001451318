import { createRoot } from "react-dom/client";
import React from "react";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const app = document.getElementById("root");

// create a root
const root = createRoot(app);

//render app to root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
