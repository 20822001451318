import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close.svg";
import APIVariables from "../../../services/api-variables";
import { ReactComponent as Undo } from "../../../assets/icons/undo.svg";
import StyledRegister from "../../authentication/StyledRegister";
import { handleCatch, validateEmail } from "../../../shared/helpers";
import { App } from "../../../routes/Constants";
import QuitModal from "../../common/Modal/QuitModal";
import ConfirmConnectedPharma from "./modals/ConfirmConnectedPharmaModal";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";

const ConnectedPharmacistAdd = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quitModalIsOpen, setIsOpenQuit] = useState(false);
  const [confirmModalIsOpen, setConfirmModal] = useState(false);
  const [validateScreenFields, setValidateScreenFields] = useState({});
  const { storageData } = useRoles();
  const [form, setForm] = useState({
    pharmacist: {
      first_name: "",
      last_name: "",
      registration_number: "",
      job_title: "",
      email: "",
    },
  });

  useEffect(() => {
    setValidateScreenFields(validateForm());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ pharmacist: { ...form.pharmacist, [name]: value } });
  };

  function closeForm() {
    setIsOpenQuit(true);
  }

  function closeModalQuit() {
    setIsOpenQuit(false);
  }

  function quitModal() {
    navigate(App.CONNECTED_PHARMACISTS);
  }

  const submitForm = async () => {
    setIsSubmitting(false);
    let areAllFields = Object.values(validateScreenFields).every((field) => {
      return field.isValid;
    });

    if (areAllFields) {
      await Axios.post(APIVariables.HCP_CON_PHARMACIST_ADD, form.pharmacist)
        .then((res) => {
          const { status } = res;
          if (status === 201) {
            setConfirmModal(true);
          }
        })
        .catch(function (error) {
          handleCatch(error);
          let serverErrors = error?.response?.data;
          if (!!serverErrors) {
            let currentValidation = validateScreenFields;
            Object.keys(serverErrors)?.forEach((key) => {
              currentValidation[key].isValid = false;
              currentValidation[key].serverError = serverErrors[key]?.join(" ");
            });
            setIsSubmitting(true);
            setValidateScreenFields(currentValidation);
          }
        });
    } else {
      setIsSubmitting(true);
    }
  };

  const validateForm = () => {
    const { first_name, last_name, registration_number, job_title, email } =
      form.pharmacist;
    return {
      first_name: {
        isValid: !!first_name.length,
      },
      last_name: {
        isValid: !!last_name.length,
      },
      registration_number: {
        isValid: !!registration_number.length,
      },
      job_title: {
        isValid: !!job_title.length,
      },
      email: {
        isValid: validateEmail(email),
      },
    };
  };

  const noChangesOnForm = () => {
    return Object.values(form?.pharmacist).every((item) => item.length === 0);
  };

  return (
    <React.Fragment>
      <StyledRegister>
        <div className="layout-modal">
          <div className="close-icon" onClick={closeForm}>
            <img src={CloseIcon} alt="Close Icon" />
          </div>
          <div className="step font-size-18 color-tif-label">Step 1/1</div>
          <h1 className="select-category-txt mineShaft-color">
            Connected Pharmacist Registration Form
          </h1>
          <hr className="gray-hr" />

          <h2 className="title-details mineShaft-color">Pharmacist Details</h2>

          <div className="container mb-5">
            <div className="row">
              <div className="col">
                <label
                  className={
                    isSubmitting && !validateScreenFields.first_name?.isValid
                      ? "required-label"
                      : ""
                  }
                >
                  Pharmacist First Name:*
                </label>
                <input
                  className={
                    isSubmitting && !validateScreenFields.first_name?.isValid
                      ? "required-label"
                      : ""
                  }
                  type="text"
                  onChange={handleChange}
                  name="first_name"
                  value={form.pharmacist?.first_name || ""}
                  maxLength={30}
                />
                {isSubmitting && !validateScreenFields.first_name?.isValid && (
                  <span className="required-text">
                    {validateScreenFields.first_name?.serverError || "Required"}
                  </span>
                )}
              </div>
              <div className="col">
                <label
                  className={
                    isSubmitting && !validateScreenFields.last_name?.isValid
                      ? "required-label"
                      : ""
                  }
                >
                  Pharmacist Last Name:*
                </label>
                <input
                  className={
                    isSubmitting && !validateScreenFields.last_name?.isValid
                      ? "required-label"
                      : ""
                  }
                  type="text"
                  onChange={handleChange}
                  name="last_name"
                  value={form.pharmacist?.last_name || ""}
                  maxLength={30}
                />
                {isSubmitting && !validateScreenFields.last_name?.isValid && (
                  <span className="required-text">
                    {validateScreenFields.last_name?.serverError || "Required"}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label
                  className={
                    isSubmitting &&
                    !validateScreenFields.registration_number?.isValid
                      ? "required-label"
                      : ""
                  }
                >
                  GPhC / PSNI Registration Number:*
                </label>
                <input
                  className={
                    isSubmitting &&
                    !validateScreenFields.registration_number?.isValid
                      ? "required-label"
                      : ""
                  }
                  type="text"
                  onChange={handleChange}
                  name="registration_number"
                  value={form.pharmacist?.registration_number || ""}
                  maxLength={30}
                />
                {isSubmitting &&
                  !validateScreenFields.registration_number?.isValid && (
                    <span className="required-text">
                      {validateScreenFields.registration_number?.serverError ||
                        "Required"}
                    </span>
                  )}
              </div>
              <div className="col">
                <label
                  className={
                    isSubmitting && !validateScreenFields.job_title?.isValid
                      ? "required-label"
                      : ""
                  }
                >
                  Job Title:*
                </label>
                <input
                  className={
                    isSubmitting && !validateScreenFields.job_title?.isValid
                      ? "required-label"
                      : ""
                  }
                  type="text"
                  name="job_title"
                  value={form.pharmacist?.job_title || ""}
                  onChange={handleChange}
                  maxLength={30}
                />
                {isSubmitting && !validateScreenFields.job_title?.isValid && (
                  <span className="required-text">
                    {validateScreenFields.job_title?.serverError || "Required"}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Pharmacy Name:</label>
                <input
                  type="text"
                  name="email"
                  maxLength={50}
                  value={storageData?.pharmacy?.value || ""}
                  disabled
                />
              </div>
              <div className="col">
                <label
                  className={
                    isSubmitting && !validateScreenFields.email?.isValid
                      ? "required-label"
                      : ""
                  }
                >
                  Email Address: (A link will be sent to verify your email
                  address)*
                </label>
                <input
                  className={
                    isSubmitting && !validateScreenFields.email?.isValid
                      ? "required-label"
                      : ""
                  }
                  type="text"
                  onChange={handleChange}
                  name="email"
                  value={form.pharmacist?.email || ""}
                  maxLength={50}
                />
                {isSubmitting && !validateScreenFields.email?.isValid && (
                  <span className="required-text">
                    {validateScreenFields.email?.serverError || "Required"}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Pharmacy address:</label>
                <input
                  type="text"
                  maxLength={50}
                  value={storageData?.pharmacy?.address || ""}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="wrapper-buttons-select-type">
            <div className="symbol-idication mineShaft-color">
              *symbol indicating a mandatory field
            </div>
            <div>
              <button
                className="undo-changes font-size-18"
                disabled={noChangesOnForm()}
                onClick={() =>
                  setForm({
                    pharmacist: {
                      first_name: "",
                      last_name: "",
                      registration_number: "",
                      job_title: "",
                      email: "",
                    },
                  })
                }
              >
                <Undo /> Undo Changes
              </button>
              <button className="primary-button" onClick={submitForm}>
                Save
              </button>
            </div>
          </div>
        </div>

        {quitModalIsOpen && (
          <QuitModal
            quitModalIsOpen={quitModalIsOpen}
            closeModalQuit={closeModalQuit}
            quitModal={quitModal}
            description="Are you sure you want to cancel filling out this form? All progress will be lost and you will need to start again."
          />
        )}
        {confirmModalIsOpen && (
          <ConfirmConnectedPharma
            closeModal={setConfirmModal}
            modalIsOpen={confirmModalIsOpen}
          />
        )}
      </StyledRegister>
    </React.Fragment>
  );
};
export default ConnectedPharmacistAdd;
