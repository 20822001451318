import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { registrationCategory } from "../constants/register.constants";
import { App, Auth } from "../routes/Constants";
import LocalStorage from "../shared/localStorage";

const getLocalStorage = localStorage.getItem(LocalStorage.USER_NHSPORTAL);
const parseLocalStorage = JSON.parse(getLocalStorage);

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
  return re.test(email);
}

export function validatePassword(password) {
  return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&~]).{12,64})/.test(
    password
  );
}

export function keepOnlyDigits(str) {
  return str.replace(/\D/g, "");
}

export function verifyUserType(type) {
  return type === parseLocalStorage?.user_type;
}

export function verifyUserTypeConnectedRegistrar(type) {
  return type === parseLocalStorage?.connected_nurse?.connected_type;
}

export function redirectToHomepageDependingOnUser(userType) {
  switch (userType) {
    case registrationCategory.PRESCRIBER:
    case registrationCategory.PHARMACY:
    case registrationCategory.CONNECTED_NURSE:
    case registrationCategory.CONNECTED_HOMECARE:
    case registrationCategory.CONNECTED_OUTPATIENT:
      return App.DASHBOARD;
    default:
      return App.PHARMACY_LIST;
  }
}

export function formatDateDash(data) {
  const date = new Date(data);

  let day = date.getDate();
  day = day < 10 ? "0" + day : day;

  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;

  const year = date.getFullYear();

  return year + "-" + month + "-" + day;
}

export const getFirstNumberFromString = (string, separator = " ") => {
  if (typeof string === "number") {
    return string;
  }
  if (!string || typeof string !== "string" || !string?.includes(separator)) {
    return null;
  }
  let arr = string.split(separator);
  return arr.length ? (!isNaN(parseInt(arr[0])) ? parseInt(arr[0]) : 0) : null;
};

export function isEmptyObject(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function isObject(obj) {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function prescriberName() {
  const getLocalStorage = localStorage.getItem(LocalStorage.USER_NHSPORTAL);
  const parseLocalStorage = JSON.parse(getLocalStorage);

  return (
    parseLocalStorage?.first_name.charAt(0)?.toUpperCase() +
    parseLocalStorage?.first_name.slice(1) +
    " " +
    parseLocalStorage?.last_name.charAt(0)?.toUpperCase() +
    parseLocalStorage?.last_name.slice(1)
  );
}

export function isDispensingMoreThan7DaysBetween2Dates(data1, data2) {
  if (data1 && data2) {
    var date1 = new Intl.DateTimeFormat("en-US").format(new Date(data1));
    var date2 = new Intl.DateTimeFormat("en-US").format(new Date(data2));

    return Math.abs(moment(date1).diff(moment(date2), "days")) > 7;
  }
  return;
}

export function isDispensingMoreThan3DaysBetween2Dates(data1, data2) {
  if (data1 && data2) {
    var date1 = new Intl.DateTimeFormat("en-US").format(new Date(data1));
    var date2 = new Intl.DateTimeFormat("en-US").format(new Date(data2));

    return Math.abs(moment(date1).diff(moment(date2), "days")) > 3;
  }
  return;
}

export function getAge(DOB) {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function getTotalCapsules(form) {
  let capsulesValue = form?.capsules_per_cycle?.value;
  if (capsulesValue === 0) {
    capsulesValue = form?.capsules_per_cycle_other;
  }

  const cyclesValue = form?.number_cycles?.value;

  return capsulesValue * cyclesValue;
}

export const displayPhoneNumberWithSpace = (number) => {
  const phone = [number?.slice(0, 5), " ", number?.slice(5)].join("");
  return phone;
};

export const formatPhoneNumber = (phoneNumber) => {
  let newPhoneNumber = phoneNumber;
  if (newPhoneNumber.length === 5) {
    return (newPhoneNumber = newPhoneNumber.replace(/ /g, ""));
  } else if (newPhoneNumber.length > 4) {
    return (newPhoneNumber = displayPhoneNumberWithSpace(
      newPhoneNumber.replace(/ /g, "")
    ));
  } else {
    return newPhoneNumber;
  }
};

export const getPatientInitials = (patientName) => {
  let patientInitials = "";
  patientName &&
    patientName
      .split(" ")
      .forEach((splitedNames) => (patientInitials += splitedNames[0]));
  return patientInitials;
};

export const handleCatch = (error) => {
  if (error?.response?.status === 404) {
    window.location.href = "/404";
    return;
  }
  if (error?.response?.status === 401 && error?.response?.data?.tokenExpired) {
    const message = error?.response?.data?.tokenExpired;
    localStorage.setItem(
      LocalStorage.EXPIRED_SESSION_MESSAGE_NHSPORTAL,
      message
    );
    window.location.href = Auth.LOGIN;
  }

  if (error?.response?.status === 403) {
    const message = error?.response?.data?.detail;
    localStorage.setItem(
      LocalStorage.EXPIRED_SESSION_MESSAGE_NHSPORTAL,
      message
    );
    localStorage.removeItem(LocalStorage.USER_NHSPORTAL);
    window.location.href = Auth.LOGIN;
  }

  if (
    error?.response?.data?.detail === "Invalid token." ||
    error?.response?.status === 401
  ) {
    localStorage.removeItem(LocalStorage.USER_NHSPORTAL);
    window.location.href = Auth.LOGIN;
  }
};

export const getUserGuidePdf = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? "https://pathfinderrmp-files-live.s3.eu-west-1.amazonaws.com/Pathfinder+User+Guide.pdf"
    : process.env.REACT_APP_NODE_ENV === "development"
    ? "https://pathfinderrmp-files-dev.s3.eu-west-1.amazonaws.com/Pathfinder+User+Guide.pdf"
    : "https://pathfinderrmp-files-stg.s3.eu-west-1.amazonaws.com/Pathfinder+User+Guide.pdf";
};

export const getPrescriberTrainingVideo = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? "https://pathfinderrmp-files-live.s3.eu-west-1.amazonaws.com/Prescriber+Training+Video.mp4"
    : process.env.REACT_APP_NODE_ENV === "development"
    ? "https://pathfinderrmp-files-dev.s3.eu-west-1.amazonaws.com/Prescriber+Training+Video.mp4"
    : "https://pathfinderrmp-files-stg.s3.eu-west-1.amazonaws.com/Prescriber+Training+Video.mp4";
};

export const getPharmacyTrainingVideo = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? "https://pathfinderrmp-files-live.s3.eu-west-1.amazonaws.com/Pharmacists+Training+Video.mp4"
    : process.env.REACT_APP_NODE_ENV === "development"
    ? "https://pathfinderrmp-files-dev.s3.eu-west-1.amazonaws.com/Pharmacists+Training+Video.mp4"
    : "https://pathfinderrmp-files-stg.s3.eu-west-1.amazonaws.com/Pharmacists+Training+Video.mp4";
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export function isValidTimestamp(_timestamp) {
  const newTimestamp = new Date(_timestamp).getTime();
  return isNumeric(newTimestamp);
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const removeEmptyValueAndSelectLabel = (form) => {
  Object.keys(form)?.forEach((key) => {
    if (form[key] === "" || form[key]?.label === "Select") {
      delete form[key];
    }
  });
  return form;
};

// This function take the title and push it in the object
export function mapArraysWithTitle(obj) {
  const result = [];

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      result.push({ title: key, items: obj[key] });
    }
  }

  return result;
}

// Afisare "Number of Cycles/Number weeks" in PAF-uri
export const getLabelForScheduleType = (type) => {
  switch (type) {
    case "weeks":
      return "Number of supply weeks:*";
    case "weeks_per_cycle":
      return "Number of Cycles:*";
    default:
      return "Number of Cycles:*";
  }
};

export const getTotalCapsulesLabel = (type) => {
  switch (type) {
    case "weeks":
      return "Total capsules supplied:";
    case "weeks_per_cycle":
      return "Total Supply Prescribed:*";
    case "cycles":
      return "Total Capsules:";
    default:
      return "Total Capsules:";
  }
};
