import React, { useState } from "react";
import StyledAuthentification from "./StyledAuthentification";
import { ReactComponent as ArrowNext } from "../../assets/icons/arrowNext.svg";
import { Auth } from "../../routes/Constants";
import APIVariables from "../../services/api-variables";
import { validateEmail } from "../../shared/helpers";
import Axios from "../../services/Axios";

const ResetPassword = () => {
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  const [message, setMessage] = useState({ status: "", text: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkInputs = async () => {
    var emailError = {
      status: "",
      text: "",
    };
    if (!validateEmail(loginCredentials.email)) {
      emailError.text = "Email is invalid";
      setMessage(emailError);
    } else setMessage(emailError);

    return emailError;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    var errors = await checkInputs();

    if (errors.text === "") {
      const res = await Axios.post(APIVariables.USERS_PASSWORD_RESET, {
        email: loginCredentials.email,
      }).catch(function (error) {
        setMessage({ text: error.message ?? error.email[0] });
      });

      if (res?.status === 200) {
        setMessage({ text: res?.data?.message ?? res?.data?.email[0] });
      }
    }
  };
  return (
    <StyledAuthentification>
      {/* {message.status !== 200 ? (
        <div className="wrapper-login">
          <div className="wrapper-auth">
            <div className="successMessage">
              <img
                src={checkRegister}
                alt="Success"
                style={{ width: "80px" }}
              />
              <h1>Email sent</h1>
            </div>
            <div className="successMessage font-size-18">
              <p>{message.text}</p>
            </div>
          </div>
        </div>
      ) : ( */}
      <div className="wrapper-login">
        <div className="wrapper-auth">
          <div className="wrapper-title" />
          <div className="doveGray-color font-size-18 mb-5 mt-4">
            Please enter your email address below and we will send you a link to
            reset your password.
          </div>

          <form onSubmit={onSubmit}>
            <div>
              <label>Email Address:</label>
              <input
                type="email"
                onChange={handleChange}
                name="email"
                className={message.text.length > 0 ? "error-select" : ""}
              />
              {message.text.length > 0 && (
                <span className="required-text-reset font-size-18">
                  {message.text}
                </span>
              )}
            </div>
            <button className="primary-button mt-3">Reset</button>
          </form>
        </div>
        <hr />
        <a className="wrapper-register" href={Auth.REGISTER}>
          <div className="font-size-18">Register for an account</div>
          <ArrowNext />
        </a>
      </div>
    </StyledAuthentification>
  );
};
export default ResetPassword;
