import styled from "styled-components";
import Colors from "../../../shared/colors";

export default styled.div`
  .wrapper-patient-materials {
    background: #f8f8f8;
    padding: 140px 0px 100px 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
  }

  .wrapper {
    background: ${Colors.alabaster};
    padding: 140px 0px 100px 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
  }

  .layout {
    max-width: 1146px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }

    @media screen and (max-width: 850px) {
      max-width: 600px;
    }
  }

  .h1 {
    margin-bottom: 20px;
  }

  .text {
    font-size: 16px;
    line-height: 19px;
    color: ${Colors.doveGray};
    text-align: justify;

    a {
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.endeavour} !important;
    }

    a:hover {
      color: ${Colors.gray};
    }
  }

  .container {
    padding-top: 55px;
  }

  .text {
    font-size: 16px;
    line-height: 19px;
    color: ${Colors.doveGray};
    text-align: justify;

    a {
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.doveGray};
    }

    a:hover {
      color: ${Colors.gray};
      cursor: pointer;
    }
    span {
      margin-right: 5px;
    }
  }

  .wrapper-buttons {
    justify-content: space-evenly;

    span {
      font-size: 16px;
      line-height: 19px;
      color: ${Colors.doveGray};
      text-decoration: underline;
    }

    span:hover {
      color: ${Colors.gray};
      cursor: pointer;
    }
  }

  .text .url-text {
    font-size: 20px;
    line-height: 19px;
  }

  .pharmacy-cards-container {
    justify-content: space-between;
  }

  .cardsContainer > div {
    display: flex;
    flex-flow: wrap;
  }

  .view-card-container {
    margin-top: 50px;
  }
  .title-brochure {
    width: 100%;
  }
`;
