import React, { useState, useEffect } from "react";
import { ReactComponent as Pen } from "../../../assets/icons/pen.svg";
import { ReactComponent as Undo } from "../../../assets/icons/undo.svg";
import APIVariables from "../../../services/api-variables";
import StyledProfile from "./StyledProfile";
import {
  handleCatch,
  isEmptyObject,
  prescriberName,
} from "../../../shared/helpers";
import LocalStorage from "../../../shared/localStorage";
import Loader from "../../common/Loader/Loader";
import useRoles from "../../../hooks/useRoles";
import Axios from "../../../services/Axios";
import Wholesaler from "../../common/Wholesaler/Wholesaler";
import { errorMessages } from "../../../constants/errorMessages.constants";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import { DrugRegistration } from "./DrugRegistration/DrugRegistration";

const Profile = ({ setLoggedInInitials }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    isPrescriber,
    isPharmacy,
    isConnectedNurse,
    isHomecare,
    isOutpatient,
  } = useRoles();

  const [profileDetails, setProfileDetails] = useState({});
  const [unchangeProfileDetails, setUnchangeProfileDetails] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);

  const invalidWholeSaler = (errorMessage || []).find((element) =>
    element.includes("wholesalers_data")
  );
  const getProfileData = async () => {
    await Axios.get(APIVariables.USERS_PROFILE)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setProfileDetails(data);
          setUnchangeProfileDetails(data);
        }
      })
      .catch((error) => {
        handleCatch(error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getProfileData();

    // When destroy profile component, remove IS_EDIT_MODE_NHSPORTAL  from localstorage
    return () => {
      localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "delivery_address" || name === "ordering_address") {
      const copyProfileDetails = {
        ...profileDetails,
        pharmacy: { ...profileDetails.pharmacy, [name]: value },
      };
      setProfileDetails(copyProfileDetails);
    } else {
      setProfileDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const removeWhiteSpaces = (element) => {
    return element.replace(/\s{2,}/g, " ").trim();
  };

  const handleChangeWholeSaler = (e) => {
    const { name, value } = e.target;

    const amendCurrentWholeSaler = profileDetails.wholesalers_data.map(
      (element) => {
        if (element.id === +name) {
          return { ...element, account_number: value };
        } else {
          return element;
        }
      }
    );

    const copyProfileDetails = {
      ...profileDetails,
      wholesalers_data: amendCurrentWholeSaler,
    };
    setProfileDetails(copyProfileDetails);
  };

  const handleSaveButtonClick = async () => {
    setIsLoadingSaveButton(true);
    await onClickEditSave();
    setIsLoadingSaveButton(false);
  };

  const onClickEditSave = async () => {
    setIsSubmitting(false);
    setErrorMessage("");
    if (!isEditMode) {
      setIsEditMode(true);
      localStorage.setItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL, true);
    }
    if (isEditMode) {
      setIsSubmitting(true);
      localStorage.removeItem(LocalStorage.IS_EDIT_MODE_NHSPORTAL);
      if (areAllFields) {
        const {
          last_name,
          job_title,
          wholesalers_data,
          pharmacy,
          receive_new_paf_emails,
        } = profileDetails;

        const checkObject = {
          ...(unchangeProfileDetails.last_name !== last_name && {
            last_name: removeWhiteSpaces(last_name),
          }),
          ...(unchangeProfileDetails.job_title !== job_title && {
            job_title: removeWhiteSpaces(job_title),
          }),
          ...(unchangeProfileDetails.wholesalers_data !== wholesalers_data && {
            wholesalers_data,
          }),
          ...((isPharmacy || isHomecare || isOutpatient) && {
            ...(unchangeProfileDetails.pharmacy.delivery_address !==
              pharmacy.delivery_address && {
              wholesalers_data,
            }),
            ...(unchangeProfileDetails.pharmacy.ordering_address !==
              pharmacy.ordering_address && {
              wholesalers_data,
            }),
            ...(unchangeProfileDetails.receive_new_paf_emails !==
              receive_new_paf_emails && {
              receive_new_paf_emails,
            }),
          }),
        };

        if (!isEmptyObject(checkObject)) {
          let res;

          const object = {
            last_name,
            job_title,
            wholesalers_data,
            ...((isPharmacy || isHomecare || isOutpatient) && {
              delivery_address: pharmacy.delivery_address,
              ordering_address: pharmacy.ordering_address,
              receive_new_paf_emails: receive_new_paf_emails,
            }),
          };

          res = await Axios.put(APIVariables.USERS_PROFILE_EDIT, object).catch(
            function (error) {
              handleCatch(error);

              const errors = error.response.data;
              const errorsArray = [];

              if (typeof errors === "string") {
                errorsArray.push(errorMessages.INTERNAL_SERVER_ERROR);
              } else {
                Object.keys(errors).forEach((key) => {
                  errorsArray.push(key + " - " + errors[key]);
                });
              }
              setErrorMessage(errorsArray);
            }
          );

          if (res?.status === 200) {
            const newProfileDetails = {
              ...profileDetails,
              last_name: removeWhiteSpaces(last_name),
              job_title: removeWhiteSpaces(job_title),
              wholesalers_data,
            };

            updateLocalStorage(newProfileDetails);

            setLoggedInInitials(
              `${newProfileDetails.first_name?.charAt(
                0
              )}${newProfileDetails.last_name?.charAt(0)}`
            );

            setUnchangeProfileDetails(newProfileDetails);
            setProfileDetails(newProfileDetails);

            setIsEditMode(false);
          }
        } else {
          setIsEditMode(false);
        }
      }
    }
  };

  const updateLocalStorage = () => {
    const storage = localStorage.getItem(LocalStorage.USER_NHSPORTAL);
    let parseStorage = JSON.parse(storage);

    parseStorage = {
      ...parseStorage,
      last_name: profileDetails.last_name,
      job_title: profileDetails.job_title,
    };

    localStorage.setItem(
      LocalStorage.USER_NHSPORTAL,
      JSON.stringify(parseStorage)
    );
  };

  const atLeastOneWhoSalertContainsValue = (wholesalers_data) => {
    return (wholesalers_data || []).some(
      (element) => element.account_number.length
    );
  };

  const validateForm = ({
    last_name = "",
    job_title = "",
    wholesalers_data = "",
  }) => ({
    last_name: {
      isValid: last_name?.trim().length > 0,
    },
    job_title: {
      isValid: job_title?.trim().length > 0,
    },
    wholesalers_data: {
      isValid:
        isPharmacy || isHomecare || isOutpatient
          ? atLeastOneWhoSalertContainsValue(wholesalers_data)
          : true,
    },
  });

  const { last_name, job_title, wholesalers_data } = profileDetails;

  const validateScreenFields = validateForm({
    last_name,
    job_title,
    wholesalers_data,
  });

  const areAllFields = Object.values(validateScreenFields).every((field) => {
    return field.isValid;
  });

  const areChanges = () => {
    return (
      JSON.stringify(unchangeProfileDetails) !== JSON.stringify(profileDetails)
    );
  };

  const setUndoChanges = () => {
    setProfileDetails(unchangeProfileDetails);
    setIsEditMode(false);
  };

  const onChangeCheckEmail = () => {
    const newProfile = {
      ...profileDetails,
      receive_new_paf_emails: !profileDetails.receive_new_paf_emails,
    };
    setProfileDetails(newProfile);
  };

  return (
    <StyledProfile>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="layout-modal mt-4 white-background">
            <div className="wrapper-header">
              <h1 className="select-category-txt">{`${
                isPrescriber || isConnectedNurse
                  ? prescriberName()
                  : profileDetails?.pharmacy?.address
              }`}</h1>
              <div className="wrapper-header">
                {areChanges() && (
                  <button
                    className="undo-changes font-size-18"
                    onClick={() => setUndoChanges()}
                  >
                    <Undo /> Undo Changes
                  </button>
                )}

                <button
                  disabled={isLoadingSaveButton}
                  className="second-button font-size-20"
                  onClick={handleSaveButtonClick}
                >
                  {!isEditMode ? "Edit Profile" : "Save Edits"} <Pen />
                </button>
              </div>
            </div>

            <hr className="gray-hr" />

            <h2 className="title-details orient mt-5">Personal Details</h2>

            <div className={"container " + (!isEditMode ? "sign" : "")}>
              <div className="row">
                <div className="col">
                  <label className="disable-label orient">
                    {isPrescriber
                      ? "Prescriber First Name:"
                      : isPharmacy || isHomecare || isOutpatient
                      ? "Pharmacist First Name:"
                      : "Nurse First Name:"}
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={profileDetails.first_name}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="orient">
                    {isPrescriber
                      ? "Prescriber Last Name*"
                      : isPharmacy || isHomecare || isOutpatient
                      ? "Pharmacist Last Name*"
                      : "Nurse Last Name*"}
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={profileDetails.last_name}
                    disabled={!isEditMode}
                    onChange={handleChange}
                    maxLength={30}
                    className={
                      isEditMode
                        ? "inputEditing"
                        : isSubmitting &&
                          !validateScreenFields.last_name.isValid
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !validateScreenFields.last_name?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="disable-label orient">
                    {isPrescriber || isConnectedNurse
                      ? "Registration Number:"
                      : "GPhC / PSNI Registration Number:"}
                  </label>
                  <input
                    type="text"
                    name="registration_number"
                    value={profileDetails.registration_number}
                    disabled
                  />
                </div>

                <div className="col">
                  <label className="orient">Job Title*</label>
                  <input
                    type="text"
                    name="job_title"
                    value={profileDetails.job_title}
                    disabled={!isEditMode}
                    onChange={handleChange}
                    maxLength={30}
                    className={
                      isEditMode
                        ? "inputEditing"
                        : isSubmitting &&
                          !validateScreenFields.job_title.isValid
                        ? "error"
                        : ""
                    }
                  />
                  {isSubmitting && !validateScreenFields.job_title?.isValid && (
                    <span className="required-text">Required</span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="disable-label orient">
                    {isPrescriber || isConnectedNurse
                      ? "Hospital Name:"
                      : "Pharmacy Name"}
                  </label>
                  <input
                    type="text"
                    name="hospital_name"
                    value={
                      isPrescriber || isConnectedNurse
                        ? profileDetails?.hospital?.value
                        : profileDetails?.pharmacy?.value
                    }
                    disabled
                  />
                </div>

                <div className="col">
                  <label className="disable-label orient">
                    {isPrescriber || isConnectedNurse
                      ? "Address:"
                      : "Pharmacy Address:"}
                  </label>
                  <input
                    type="text"
                    name="hospital_address"
                    value={
                      isPrescriber || isConnectedNurse
                        ? profileDetails?.hospital?.address
                        : profileDetails?.pharmacy?.address
                    }
                    disabled
                  />
                </div>
              </div>

              {isPharmacy || isHomecare || isOutpatient ? (
                <div className="row">
                  <div className="col">
                    <label className="orient">Delivery Address:</label>
                    <input
                      type="text"
                      name="delivery_address"
                      value={profileDetails?.pharmacy?.delivery_address}
                      maxLength="75"
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                  </div>

                  <div className="col">
                    <label className="orient">Ordering Address:</label>
                    <input
                      type="text"
                      name="ordering_address"
                      value={profileDetails?.pharmacy?.ordering_address}
                      maxLength="75"
                      onChange={handleChange}
                      disabled={!isEditMode}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <label className="disable-label orient">
                    {isPrescriber || isConnectedNurse
                      ? "Email:"
                      : "Pharmacy Contact Number:"}
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={
                      isPrescriber || isConnectedNurse
                        ? profileDetails.email
                        : profileDetails.phone_number
                    }
                    disabled
                  />
                </div>
                {isPharmacy || isHomecare || isOutpatient ? (
                  <div className="col">
                    <label className="disable-label orient">Email</label>
                    <input
                      type="text"
                      name="pharmacy_address"
                      value={profileDetails.email}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="col" />
                )}
              </div>
            </div>

            {(isPharmacy || isHomecare || isOutpatient) && (
              <React.Fragment>
                <h2 className="title-details orient mt-5 mb-4">
                  Wholesaler Details
                </h2>

                <div className="row mb-3" style={{ position: "relative" }}>
                  {(profileDetails.wholesalers_data || []).map((wholesaler) => (
                    <div
                      className={"col-md-6 " + (!isEditMode ? "sign" : "")}
                      key={wholesaler.id}
                    >
                      <Wholesaler
                        wholesaler={wholesaler}
                        handleChangeWholeSaler={handleChangeWholeSaler}
                        formFields={validateScreenFields}
                        isSubmitting={isSubmitting}
                        isEditMode={isEditMode}
                      />
                    </div>
                  ))}
                  {isSubmitting &&
                  !validateScreenFields.wholesalers_data?.isValid ? (
                    <span className="required-text wholesaler-error">
                      At least one Wholesaler Detail is required
                    </span>
                  ) : invalidWholeSaler?.length ? (
                    <span className="required-text wholesaler-error">
                      {invalidWholeSaler?.split("-")[1]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            )}

            {(isPharmacy || isHomecare || isOutpatient) && (
              <React.Fragment>
                <h2 className="title-details orient mt-5 mb-4">
                  Email Management
                </h2>

                <div className="email-management">
                  <span className="font-size-16 mineShaft-color">
                    {profileDetails.receive_new_paf_emails ? "On" : "Off"}{" "}
                  </span>
                  <span>
                    <ToggleSwitch
                      name="receive_new_paf_emails"
                      id="receive_new_paf_emails"
                      small
                      disabled={isEditMode ? false : true}
                      checked={profileDetails.receive_new_paf_emails}
                      onChange={onChangeCheckEmail}
                    />
                  </span>
                  <span className="orient"> PAF emails: </span>
                  <span className="font-size-16 silver">
                    {profileDetails.receive_new_paf_emails
                      ? "You will receive emails for new PAFs and for PAFs that have not been actioned."
                      : "You will not receive emails for new PAFs, only for PAFs that have not been actioned."}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}

      {!isLoading &&
      (isPrescriber || isPharmacy || isHomecare || isOutpatient) ? (
        <DrugRegistration
          profileDetails={profileDetails}
          getProfileData={getProfileData}
        />
      ) : null}
    </StyledProfile>
  );
};
export default Profile;
