import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import StyledModal from "../../common/Modal/StyledModal";

const FinishModal = ({
  finishModalOpen,
  closeFinishModal,
  onFinish,
  title,
  description,
}) => {
  let customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      borderRadius: "30px",
    },
  };

  const mediaMatch = window.matchMedia("(max-width: 850px)");
  mediaMatch.matches === true
    ? (customStyles.content = {
        ...customStyles.content,
        width: "70%",
      })
    : (customStyles.content = {
        ...customStyles.content,
        width: "37%",
      });

  return (
    <StyledModal>
      <Modal
        isOpen={finishModalOpen}
        onRequestClose={closeFinishModal}
        style={customStyles}
        contentLabel="Quit Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        {/* <button onClick={closeFinishModal} className="close-icon-button ">
          <img src={CloseIcon} alt="Close Icon" />
        </button> */}
        <div className="wrapper-modal">
          <div className="image"></div>
          <div className="main-text orient">{title}</div>
          <div
            className="second-text"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        <div className="wrapper-buttons">
          <button className="cancel mr-6" onClick={closeFinishModal}>
            Cancel
          </button>
          <button
            className="green-button green-haze-background"
            onClick={() => {
              onFinish();
              closeFinishModal();
            }}
          >
            Finish
          </button>
        </div>
      </Modal>
    </StyledModal>
  );
};

FinishModal.propTypes = {
  finishModalOpen: PropTypes.bool,
  closeFinishModal: PropTypes.func,
  onFinish: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FinishModal;
