import React from "react";
import { useNavigate } from "react-router-dom";
import { App } from "../../../../routes/Constants";
import Line from "../../../../assets/icons/line.svg";
import ViewPatientsPrescriberGif from "../../../../assets/images/view-patients.gif";
import ViewPatientsPrescriberPng from "../../../../assets/images/view-patients.png";
import ViewPatientsPharmacyGif from "../../../../assets/images/dashboard-view-patient-pharmacy.gif";
import ViewPatientsPharmacyPng from "../../../../assets/images/dashboard-view-patient-pharmacy.png";
import useRoles from "../../../../hooks/useRoles";
import { useState } from "react";

export const ViewPatientsBox = () => {
  const navigate = useNavigate();
  const { isPrescriber } = useRoles();

  const [gifPaused, setGifPaused] = useState(false);

  const handleHover = () => {
    setGifPaused(true);
    setTimeout(
      () => {
        setGifPaused(false);
      },
      isPrescriber ? 2000 : 1500
    );
  };

  const handleLeave = () => {
    setGifPaused(false);
  };

  return (
    <div className="col">
      <img src={Line} alt="White Line" />
      <div className="main-text font-size-30">
        {isPrescriber ? "View Patients/Renew PAF" : "View Patients"}
      </div>
      <div className="second-text font-size-16">
        {isPrescriber
          ? "View existing patients or renew a PAF"
          : "View existing patients that are sent to your pharmacy"}
      </div>
      <button
        className={
          "primary-button font-size-16 " +
          (isPrescriber ? "" : "generate-paf-btn")
        }
        onClick={() => {
          navigate(App.VIEW_PATIENTS);
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        {isPrescriber ? "View Patients/Renew PAF" : "View Patients"}{" "}
      </button>
      {isPrescriber ? (
        <img
          src={
            gifPaused ? ViewPatientsPrescriberGif : ViewPatientsPrescriberPng
          }
          alt="Refer a Patient"
          className="card-image view-patients image-card"
        />
      ) : (
        <img
          src={gifPaused ? ViewPatientsPharmacyGif : ViewPatientsPharmacyPng}
          alt="Refer a Patient"
          className="card-image view-patients-pharmacy"
        />
      )}
    </div>
  );
};
