import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import StyledAuthentification from "./StyledAuthentification";

import { ReactComponent as ArrowNext } from "../../assets/icons/arrowNext.svg";
import { Auth } from "../../routes/Constants";
import axios from "axios";
import APIVariables from "../../services/api-variables";

import { validatePassword } from "../../shared/helpers";
import CheckRegister from "../../assets/icons/checkRegister.svg";
import Error from "../../assets/icons/warning-red.svg";
import { errorMessages } from "../../constants/errorMessages.constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token, uid } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState({
    status: "",
    text: "",
    passwordsError: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    const getEmail = async () => {
      const { data: response } = await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            APIVariables.USERS_PASSWORD_RESET_GET_EMAIL +
            uid +
            "/" +
            token +
            "/"
        )
        .catch((error) => {
          if (error?.response?.status === 400) {
            setMessage({
              ...message,
              status: 400,
              text: error?.response?.data?.detail,
            });
          }
        });

      if (response?.email) {
        setLoginCredentials({
          ...loginCredentials,
          email: response?.email,
        });
      }
    };
    getEmail(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitting) checkInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginCredentials]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkInputs = () => {
    var passwordsErrors = {
      newPassword: "",
      confirmPassword: "",
    };
    if (loginCredentials.confirmPassword.length === 0) {
      passwordsErrors.confirmPassword = "required";
    }
    if (loginCredentials.newPassword.length === 0) {
      passwordsErrors.newPassword = "required";
    } else if (
      loginCredentials.newPassword.length < 12 ||
      loginCredentials.newPassword.length > 64 ||
      !validatePassword(loginCredentials.newPassword)
    ) {
      passwordsErrors.newPassword = "sample";
    } else if (
      loginCredentials.newPassword !== loginCredentials.confirmPassword
    ) {
      passwordsErrors.newPassword = "match";
    } else {
      passwordsErrors.newPassword = "";
    }

    setMessage({
      ...message,
      passwordsError: passwordsErrors,
      text: "",
    });

    return passwordsErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    var errors = checkInputs();
    setIsSubmitting(true);
    if (
      errors.newPassword.length === 0 &&
      errors.confirmPassword.length === 0
    ) {
      const { data: responseResetPassword } = await axios
        .post(
          process.env.REACT_APP_BASE_URL +
            APIVariables.USERS_PASSWORD_RESET +
            uid +
            "/" +
            token +
            "/",
          {
            password1: loginCredentials.newPassword,
            password2: loginCredentials.confirmPassword,
          }
        )
        .catch((error) => {
          if (error.response.data.password1.length > 0) {
            setMessage({
              ...message,
              status: "",
              text: error?.response?.data?.password1[0],
            });
          }
          return;
        });

      setMessage({
        ...message,
        status: responseResetPassword?.message?.includes("Success") ? 200 : "",
        text: responseResetPassword?.message,
      });
    }
  };

  const redirectToLogin = (event) => {
    event.preventDefault();
    navigate(Auth.LOGIN);
    navigate(0);
  };
  return (
    <StyledAuthentification>
      {message.status === 200 ? (
        <div className="wrapper-login" style={{ minHeight: 450 }}>
          <div className="wrapper-auth">
            <div className="successMessage">
              <div className="mb-4">
                <img
                  src={CheckRegister}
                  alt="Validate"
                  style={{ width: "80px" }}
                />
                <h1
                  style={{ marginTop: "30px", marginBottom: "15px" }}
                  className="h1"
                >
                  Password reset
                </h1>
                <p className="h3 p-0 m-0">Your password has been updated.</p>
              </div>
              <button className="green-button mt-5" onClick={redirectToLogin}>
                Login
              </button>
            </div>
          </div>
        </div>
      ) : message.status === 400 ? (
        <div className="wrapper-login" style={{ minHeight: 450 }}>
          <div className="wrapper-auth">
            <div className="successMessage">
              <img src={Error} alt="Error" style={{ width: "80px" }} />
              <h1
                style={{
                  marginTop: "30px",
                  color: "#E41818",
                  marginBottom: "15px",
                }}
              >
                {message.text}
              </h1>
              <p className="h3 p-0 m-0">
                Please click/copy the link again to reset your password.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper-login">
          <div
            className="wrapper-auth"
            style={{ overflowY: "auto", maxHeight: "100%" }}
          >
            <div className="wrapper-title" />

            <div className="doveGray-color font-size-18 resetPassword mb-5 mt-4">
              {!token
                ? "Please enter your email address below and we will send you a link to reset your password."
                : "Please enter and confirm your new password below."}
            </div>
            <form className="margin-form" onSubmit={onSubmit}>
              <div>
                <label
                // className="disabledText"
                >
                  Email Address:
                </label>
                <input
                  type="email"
                  name="email"
                  disabled
                  value={loginCredentials.email}
                  // className="disabledText"
                />
              </div>
              <div className="resetPasswordInputDiv">
                <div className="newPassword">
                  <label>New Password:</label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="newPassword"
                    className={
                      message.passwordsError.newPassword.length > 0
                        ? "error-select"
                        : ""
                    }
                  />
                </div>
                <div className="confirmPassword">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="confirmPassword"
                    className={
                      message.passwordsError.confirmPassword.length > 0
                        ? "error-select"
                        : ""
                    }
                  />
                  <div
                    className="error-text"
                    style={{
                      marginTop: "-12px",
                    }}
                  >
                    {message.passwordsError.confirmPassword === "required" && (
                      <p>Required</p>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="error-text error-text-reset"
                style={{
                  marginTop: "-19px",
                }}
              >
                {message.passwordsError.newPassword === "required" && (
                  <p>Required</p>
                )}
                {message.passwordsError.newPassword === "sample" &&
                  loginCredentials.newPassword.length < 64 && (
                    <>
                      <p>Minimum length of 12 characters</p>
                      <p>Minimum of 1 uppercase letter.</p>
                      <p>At least 1 non-alphanumeric character (~!@#$%)</p>
                      <p>At least 1 number</p>
                    </>
                  )}
                {message.passwordsError.newPassword === "sample" &&
                  loginCredentials.newPassword.length > 64 && (
                    <>
                      <p>Ensure this value has at most 64 characters</p>
                    </>
                  )}
                {message.passwordsError.newPassword === "match" && (
                  <p>{errorMessages.PASSWORD_NOT_MATCH}</p>
                )}
                {message?.text.length > 0 && <p>{message.text}</p>}
              </div>

              <br />
              <button className="primary-button mt-3">Reset</button>
            </form>
          </div>
          <hr />
          <a className="wrapper-register" href={Auth.REGISTER}>
            <div className="font-size-18 doveGray-color">
              Register for an account
            </div>
            <ArrowNext />
          </a>
        </div>
      )}
    </StyledAuthentification>
  );
};
export default ResetPassword;
